import React, { Dispatch, useContext } from 'react';
import { AnyAction } from 'redux';
import { Job, JobGroupMultiAssignPlacement } from 'lib/types';
import { InitialQueryData } from '../ClientJobGroupScheduler/types';
import {
  AnalyseJobVisitPlacementQueryData,
  AnalyseUpdatedJobVisitPlacementQueryData,
  CommitMultiAssignmentMutationData,
  StartMultiAssignmentMutationData,
} from '../ClientJobGroupSchedulerMultiAssignHelper/types';
import {
  ClientJobGroupState,
  JobGroupQueryData,
  MultiAssignHelperMode,
} from './types';
import { parseMultiAssignPlacements } from './utils';

export const initialState: ClientJobGroupState = {
  jobGroup: null,
  selectedJob: null,
  futurePlacementCount: 10,
  multiAssignHelper: {
    mode: 'GENERATED',
  },
  scheduler: {
    readOnly: false,
    selectMode: undefined,
    scroll: 0,
  },
};

export const ReducerContext = React.createContext<
  [ClientJobGroupState, Dispatch<AnyAction>]
>([initialState, () => {}]);

export const useReducerContext = () => useContext(ReducerContext);

export const jobGroupQueryOnCompleted = (responseData: JobGroupQueryData) => ({
  type: 'JOB_GROUP_QUERY_ON_COMPLETED',
  jobGroup: responseData.jobGroup,
});

export const startMultiAssignmentMutationOnCompleted = (
  responseData: StartMultiAssignmentMutationData
) => ({
  type: 'START_MULTI_ASSIGNMENT_MUTATION_ON_COMPLETED',
  activeMultiAssignPlacementBatch:
    responseData.startMultiAssignment.activeMultiAssignPlacementBatch,
});

export const analyseJobVisitPlacementQueryOnCompleted = (
  responseData: AnalyseJobVisitPlacementQueryData
) => ({
  type: 'ANALYSE_JOB_VISIT_PLACEMENT_QUERY_ON_COMPLETED',
  placements: responseData.analyseJobVisitPlacement,
});

export const analyseUpdatedJobVisitPlacementQueryOnCompleted = (
  originalId: JobGroupMultiAssignPlacement['id'],
  responseData: AnalyseUpdatedJobVisitPlacementQueryData
) => ({
  type: 'ANALYSE_UPDATED_JOB_VISIT_PLACEMENT_QUERY_ON_COMPLETED',
  originalId,
  placement: responseData.analyseUpdatedJobVisitPlacement,
});

export const toggleLockJobVisitPlacement = (
  placementId: JobGroupMultiAssignPlacement['id'],
  override?: boolean
) => ({
  type: 'TOGGLE_LOCK_JOB_VISIT_PLACEMENT',
  placementId,
  override,
});

export const toggleMuteJobVisitPlacement = (
  placementId: JobGroupMultiAssignPlacement['id'],
  override?: boolean
) => ({
  type: 'TOGGLE_MUTE_JOB_VISIT_PLACEMENT',
  placementId,
  override,
});

export const updateGeneratedPlacement = (
  placementId: JobGroupMultiAssignPlacement['id'],
  updates: Pick<
    JobGroupMultiAssignPlacement,
    'dateTimeStart' | 'dateTimeEnd' | 'user'
  >
) => ({
  type: 'UPDATE_GENERATED_PLACEMENT',
  placementId,
  updates,
});

export const setMultiAssignHelperMode = (mode: MultiAssignHelperMode) => ({
  type: 'SET_MULTI_ASSIGN_HELPER_MODE',
  mode,
});

export const commitMultiAssigmentMutationOnCompleted = (
  responseData: CommitMultiAssignmentMutationData
) => ({
  type: 'COMMIT_MULTI_ASSIGNMENT_MUTATION_ON_COMPLETED',
  multiAssignmentBatch: responseData.commitMultiAssignment.multiAssignmentBatch,
  jobVisits: responseData.commitMultiAssignment.jobVisits,
});

export const schedulerInitialQueryOnCompleted = (
  responseData: InitialQueryData
) => ({
  type: 'SCHEDULER_INITIAL_QUERY_ON_COMPLETED',
  jobGroup: responseData.jobGroup,
});

export const selectStartJob = (jobId: Job['id']) => ({
  type: 'SELECT_START_JOB',
  jobId,
});

export const resetMultiAssignment = () => ({
  type: 'RESET_MULTI_ASSIGNMENT',
});

export const setFuturePlacementCount = (value: number) => ({
  type: 'SET_FUTURE_PLACEMENT_COUNT',
  value,
});

const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case 'JOB_GROUP_QUERY_ON_COMPLETED': {
      const thisAction = action as ReturnType<typeof jobGroupQueryOnCompleted>;
      return {
        ...state,
        jobGroup: thisAction.jobGroup,
        selectedJob:
          thisAction.jobGroup.nextUnassignedJob ??
          thisAction.jobGroup.nextJobOfInterest,
        multiAssignHelper: {
          ...state.multiAssignHelper,
          isOpen: !!thisAction.jobGroup.activeMultiAssignPlacementBatch,
        },
      };
    }

    case 'SCHEDULER_INITIAL_QUERY_ON_COMPLETED': {
      if (!state.jobGroup) return state;
      const thisAction = action as ReturnType<
        typeof schedulerInitialQueryOnCompleted
      >;
      return {
        ...state,
        jobGroup: {
          ...state.jobGroup,
          activeMultiAssignPlacementBatch: thisAction.jobGroup
            .activeMultiAssignPlacementBatch
            ? {
                ...thisAction.jobGroup.activeMultiAssignPlacementBatch,
                placements: parseMultiAssignPlacements(
                  thisAction.jobGroup.activeMultiAssignPlacementBatch.placements
                ),
              }
            : null,
          multiAssignPlacementBatches:
            thisAction.jobGroup.multiAssignPlacementBatches,
        },
      };
    }

    case 'START_MULTI_ASSIGNMENT_MUTATION_ON_COMPLETED': {
      if (!state.jobGroup) return state;
      const thisAction = action as ReturnType<
        typeof startMultiAssignmentMutationOnCompleted
      >;
      return {
        ...state,
        jobGroup: {
          ...state.jobGroup,
          activeMultiAssignPlacementBatch:
            thisAction.activeMultiAssignPlacementBatch,
        },
      };
    }

    case 'ANALYSE_JOB_VISIT_PLACEMENT_QUERY_ON_COMPLETED': {
      if (!state.jobGroup || !state.jobGroup.activeMultiAssignPlacementBatch)
        return state;
      const thisAction = action as ReturnType<
        typeof analyseJobVisitPlacementQueryOnCompleted
      >;
      return {
        ...state,
        jobGroup: {
          ...state.jobGroup,
          activeMultiAssignPlacementBatch: {
            ...state.jobGroup.activeMultiAssignPlacementBatch,
            placements: parseMultiAssignPlacements(thisAction.placements),
          },
        },
      };
    }

    case 'ANALYSE_UPDATED_JOB_VISIT_PLACEMENT_QUERY_ON_COMPLETED': {
      if (!state.jobGroup || !state.jobGroup.activeMultiAssignPlacementBatch)
        return state;
      const thisAction = action as ReturnType<
        typeof analyseUpdatedJobVisitPlacementQueryOnCompleted
      >;
      return {
        ...state,
        jobGroup: {
          ...state.jobGroup,
          activeMultiAssignPlacementBatch: {
            ...state.jobGroup.activeMultiAssignPlacementBatch,
            placements: parseMultiAssignPlacements(
              state.jobGroup.activeMultiAssignPlacementBatch.placements.map(
                (placement) =>
                  placement.id === thisAction.originalId
                    ? { ...placement, ...thisAction.placement }
                    : placement
              )
            ),
          },
        },
      };
    }

    case 'TOGGLE_LOCK_JOB_VISIT_PLACEMENT': {
      if (!state.jobGroup || !state.jobGroup.activeMultiAssignPlacementBatch)
        return state;
      const thisAction = action as ReturnType<
        typeof toggleLockJobVisitPlacement
      >;
      const placement =
        state.jobGroup.activeMultiAssignPlacementBatch.placements.find(
          (innerPlacement) => innerPlacement.id === thisAction.placementId
        ) as JobGroupMultiAssignPlacement;
      return {
        ...state,
        jobGroup: {
          ...state.jobGroup,
          activeMultiAssignPlacementBatch: {
            ...state.jobGroup.activeMultiAssignPlacementBatch,
            placements:
              state.jobGroup.activeMultiAssignPlacementBatch.placements.map(
                (innerPlacement) =>
                  innerPlacement.id === thisAction.placementId
                    ? {
                        ...innerPlacement,
                        isLocked: thisAction.override ?? !placement.isLocked,
                      }
                    : innerPlacement
              ),
          },
        },
      };
    }

    case 'TOGGLE_MUTE_JOB_VISIT_PLACEMENT': {
      if (!state.jobGroup || !state.jobGroup.activeMultiAssignPlacementBatch)
        return state;
      const thisAction = action as ReturnType<
        typeof toggleMuteJobVisitPlacement
      >;
      const placement =
        state.jobGroup.activeMultiAssignPlacementBatch.placements.find(
          (innerPlacement) => innerPlacement.id === thisAction.placementId
        ) as JobGroupMultiAssignPlacement;
      return {
        ...state,
        jobGroup: {
          ...state.jobGroup,
          activeMultiAssignPlacementBatch: {
            ...state.jobGroup.activeMultiAssignPlacementBatch,
            placements:
              state.jobGroup.activeMultiAssignPlacementBatch.placements.map(
                (innerPlacement) =>
                  innerPlacement.id === thisAction.placementId
                    ? {
                        ...innerPlacement,
                        isMuted: thisAction.override ?? !placement.isMuted,
                      }
                    : innerPlacement
              ),
          },
        },
      };
    }

    case 'UPDATE_GENERATED_PLACEMENT': {
      if (!state.jobGroup || !state.jobGroup.activeMultiAssignPlacementBatch)
        return state;
      const thisAction = action as ReturnType<typeof updateGeneratedPlacement>;
      return {
        ...state,
        jobGroup: {
          ...state.jobGroup,
          activeMultiAssignPlacementBatch: {
            ...state.jobGroup.activeMultiAssignPlacementBatch,
            placements:
              state.jobGroup.activeMultiAssignPlacementBatch.placements.map(
                (placement) =>
                  placement.id === thisAction.placementId
                    ? { ...placement, ...thisAction.updates }
                    : placement
              ),
          },
        },
      };
    }

    case 'SET_MULTI_ASSIGN_HELPER_MODE': {
      const thisAction = action as ReturnType<typeof setMultiAssignHelperMode>;
      return {
        ...state,
        multiAssignHelper: {
          ...state.multiAssignHelper,
          mode: thisAction.mode,
        },
      };
    }

    case 'COMMIT_MULTI_ASSIGNMENT_MUTATION_ON_COMPLETED': {
      if (!state.jobGroup) return state;
      const thisAction = action as ReturnType<
        typeof commitMultiAssigmentMutationOnCompleted
      >;
      return {
        ...state,
        jobGroup: {
          ...state.jobGroup,
          jobs: state.jobGroup.jobs.map((job) => {
            const jobVisit = thisAction.jobVisits.find(
              (innerJobVisit) => innerJobVisit.job.id === job.id
            );
            return jobVisit ? { ...job, ...jobVisit.job } : job;
          }),
          activeMultiAssignPlacementBatch: null,
          multiAssignPlacementBatches: [
            ...state.jobGroup.multiAssignPlacementBatches,
            thisAction.multiAssignmentBatch,
          ],
        },
      };
    }

    case 'SELECT_START_JOB': {
      if (!state.jobGroup) return state;
      const thisAction = action as ReturnType<typeof selectStartJob>;
      return {
        ...state,
        selectedJob:
          state.jobGroup.jobs.find((job) => job.id === thisAction.jobId) ??
          null,
      };
    }

    case 'RESET_MULTI_ASSIGNMENT':
      if (!state.jobGroup) return state;
      return {
        ...state,
        jobGroup: {
          ...state.jobGroup,
          activeMultiAssignPlacementBatch: null,
        },
      };

    case 'SET_FUTURE_PLACEMENT_COUNT': {
      const thisAction = action as ReturnType<typeof setFuturePlacementCount>;
      return {
        ...state,
        futurePlacementCount: thisAction.value,
      };
    }

    default:
      return state;
  }
};

export default reducer;
