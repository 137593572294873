import styled from 'styled-components';
import { WrapperProps } from './types';

export const Wrapper = styled.div<WrapperProps>`
  display: inline-block;
  padding: 0.5rem 0.75rem;
  border-radius: 1rem;
  background-color: ${({ theme }) => theme.color.contentBackground.hex()};
  font-size: 0.8rem;

  p {
    margin-bottom: 0;
  }
`;

export const ChatMessageAttachmentWrapper = styled.div`
  display: block;
  padding: 0.5rem 0.75rem;
  border-radius: 0.25rem 0.25rem 1rem 1rem;
  background-color: ${({ theme }) =>
    theme.color.contentBackground.brighten(0.1).hex()};
  font-size: 0.8rem;
  margin-top: 0.15rem;
`;
