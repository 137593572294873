import React from 'react';
import styled from 'styled-components';
import Table from '../Table';

const Wrapper = styled.div``;

const Rectangle = styled.div`
  height: 16px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.05);
  margin-bottom: 1px;
  border-radius: 0.25rem;
`;

const Circle = styled(Rectangle)`
  border-radius: 50%;
  width: 16px;
  float: right;
`;

const LogItem = () => (
  <tr style={{ height: 37 }}>
    <td className="pr-5 pl-3" style={{ padding: '10px 0' }}>
      <Rectangle />
    </td>
    <td className="pr-5" style={{ padding: '10px 0' }}>
      <Rectangle />
    </td>
    <td className="pr-3" style={{ padding: '10px 0' }}>
      <Circle />
    </td>
  </tr>
);

const Loader = () => (
  <Wrapper>
    <Table flexBases={[30, 63, 7]}>
      <tbody>
        <LogItem />
        <LogItem />
        <LogItem />
        <LogItem />
        <LogItem />
      </tbody>
    </Table>
  </Wrapper>
);

export default Loader;
