import { useCallback, useEffect, useRef, useState } from 'react';

const useDelay = <T>(
  value: T,
  {
    delay,
    delayOnStarted,
    delayOnCompleted,
  }: {
    delay: number;
    delayOnStarted?: (value: T) => void;
    delayOnCompleted?: (value: T) => void;
  } = { delay: 1000 }
) => {
  const [valueDelayed, setValueDelayed] = useState(value);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();

  const previousValueRef = useRef<T>();
  useEffect(() => {
    previousValueRef.current = value;
  }, [value]);
  const previousValue = previousValueRef.current;

  const handleCallback = useCallback(() => {
    setValueDelayed(value);
    if (delayOnCompleted) delayOnCompleted(value);
  }, [value, delayOnCompleted]);

  const handleCleanUp = useCallback(() => {
    if (timeoutId) clearTimeout(timeoutId);
  }, [timeoutId]);

  useEffect(() => {
    if (previousValue === value) return handleCleanUp;
    if (timeoutId) clearTimeout(timeoutId);
    if (delayOnStarted) delayOnStarted(value);
    setTimeoutId(setTimeout(handleCallback, delay));
    return handleCleanUp;
  }, [
    delay,
    delayOnStarted,
    handleCleanUp,
    previousValue,
    timeoutId,
    value,
    handleCallback,
  ]);
  return valueDelayed;
};

export default useDelay;
