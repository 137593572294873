import styled from 'styled-components';

export const Grid = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  gap: 1.5rem;
  grid-template-columns: 2fr 2fr 2fr 3fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    'incoming issues reports immediate'
    'inprogress inprogress inprogress activity';
`;

export const Cell = styled.div`
  min-height: 0;
`;
