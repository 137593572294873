import { gql } from '@apollo/client';

export const INITIAL_QUERY = gql`
  query ($clientId: ID!) {
    incomingJobs(clientId: $clientId) {
      count
    }
    unassignedJobs: jobs(clientId: $clientId, jobStatusName: "UNASSIGNED") {
      count
    }
    lateResponseJobs(clientId: $clientId) {
      count
    }
    jobsRequiringFurtherVisit: jobs(
      clientId: $clientId
      jobStatusName: "REQUIRES_FURTHER_VISIT"
    ) {
      count
    }
    lateCompletionJobs(clientId: $clientId) {
      count
    }
  }
`;
