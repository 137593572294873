import React from 'react';
import { Row, Col } from 'reactstrap';
import CardWrapper from '../CardWrapper';
import FigureBox from '../FigureBox';
import PeopleTable from '../PeopleTable';
import Table from '../Table';

const PeopleDashboard = () => {
  return (
    <div className="h-100 d-flex flex-column">
      <Row noGutters className="mb-4 h-25">
        <Col>
          <CardWrapper className="h-100">
            <div className="d-flex mb-3">
              <FigureBox value={0} />
              <h6 className="mb-0" style={{ lineHeight: '28px' }}>
                User accounts
              </h6>
            </div>
            <div className="d-flex">
              <FigureBox value={0} />
              <h6 className="mb-0" style={{ lineHeight: '28px' }}>
                Total people
              </h6>
            </div>
          </CardWrapper>
        </Col>
        <Col className="mx-4">
          <CardWrapper className="h-100">Hello</CardWrapper>
        </Col>
        <Col>
          <CardWrapper className="h-100 p-0">
            <div className="m-3">Account requests</div>
            <Table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody />
            </Table>
          </CardWrapper>
        </Col>
      </Row>
      <CardWrapper className="p-0 h-100">
        <PeopleTable />
      </CardWrapper>
    </div>
  );
};

export default PeopleDashboard;
