import React from 'react';
import { Link, useRoute } from 'wouter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { TabProps } from './types';
import { TabBarWrapper, TabBarInner, TabWrapper } from './styled';

const Tab = ({ name, tab, match }: TabProps) => {
  const to = `/${tab}`;
  const [active] = useRoute(match);
  return (
    <Link to={to}>
      <TabWrapper active={active}>
        <span>{name}</span>
      </TabWrapper>
    </Link>
  );
};

const HomeTabBar = ({
  breadcrumbRef,
}: {
  breadcrumbRef: React.RefObject<HTMLDivElement>;
}) => {
  return (
    <TabBarWrapper className="pl-4">
      <div className="d-flex flex-column justify-content-center h-100">
        <div className="d-flex">
          <h4 className="text-75">Home</h4>
          <FontAwesomeIcon
            className="mx-2"
            style={{ marginTop: 6 }}
            icon={faChevronRight}
            fixedWidth
          />
          <div ref={breadcrumbRef} id="home__tab-bar-breadcrumb" />
        </div>
      </div>
      <TabBarInner className="d-flex">
        <Tab name="Dashboard" tab="" match="/" />
        <Tab name="Clients" tab="clients" match="/clients" />
        <Tab name="Jobs" tab="jobs" match="/jobs" />
      </TabBarInner>
    </TabBarWrapper>
  );
};

export default HomeTabBar;
