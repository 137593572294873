import { gql } from '@apollo/client';

export const REPORTS_QUERY = gql`
  query ($jobId: ID) {
    job(jobId: $jobId) {
      reports {
        id
        name
        number
        status
        template {
          id
          name
        }
        latestVersion {
          id
          versionNumber
          pdfUrl
          editUrl
          pageCount
          pages {
            id
            pageNumber
            status
          }
        }
        createdForJobVisit {
          id
          dateTimeStart
          dateTimeEnd
          number
          user {
            id
            name
            initials
          }
        }
        visits {
          id
          dateTimeStart
          dateTimeEnd
          number
          user {
            id
            name
            initials
          }
        }
      }
    }
  }
`;
