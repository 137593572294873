import { gql } from '@apollo/client';

export const INITIAL_QUERY = gql`
  query (
    $clientId: ID!
    $jobGroupId: ID!
    $dateTimeStart: DateTime
    $dateTimeEnd: DateTime
  ) {
    userGroups(clientId: $clientId, includeInScheduler: true) {
      id
      name
      namePlural
      users {
        id
        name
      }
    }
    jobVisits(
      clientId: $clientId
      dateTimeStart: $dateTimeStart
      dateTimeEnd: $dateTimeEnd
      noAssigneePreference: false
      unassigned: false
    ) {
      id
      label
      number
      dateTimeStart
      dateTimeEnd
      unallocated
      status {
        id
        name
        nameDisplay
        color
      }
      job {
        id
        reference
        name
      }
      user {
        id
        name
      }
    }
    jobGroup(jobGroupId: $jobGroupId) {
      multiAssignPlacementBatches {
        id
        placements {
          id
          isOriginal
          isLocked
          isMuted
          isCommitted
          job {
            id
            reference
            targetDateTimeStart
            targetDateTimeEnd
          }
          dateTimeStart
          dateTimeEnd
          user {
            id
            initials
            name
          }
          statuses {
            type
            meta {
              overlappingJobVisits {
                id
              }
            }
          }
          jobVisit {
            id
          }
        }
      }
      activeMultiAssignPlacementBatch {
        id
        placements {
          id
          isOriginal
          isLocked
          isMuted
          isCommitted
          job {
            id
            reference
            targetDateTimeStart
            targetDateTimeEnd
          }
          dateTimeStart
          dateTimeEnd
          user {
            id
            initials
            name
          }
          statuses {
            type
            meta {
              overlappingJobVisits {
                id
              }
            }
          }
          jobVisit {
            id
          }
        }
      }
    }
  }
`;

export const CANCEL_MULTI_ASSIGNMENT_MUTATION = gql`
  mutation ($jobGroupId: ID!) {
    cancelMultiAssignment(jobGroupId: $jobGroupId) {
      cancelled
    }
  }
`;
