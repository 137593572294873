import styled from 'styled-components';

export const Wrapper = styled.a`
  display: block;
  color: ${({ theme }) => theme.color.cardText.hex()};
  transition: background-color 150ms;

  &:hover {
    color: ${({ theme }) => theme.color.cardText.hex()};
    text-decoration: none;
    background-color: ${({ theme }) =>
      theme.color.cardBackground.darken(0.25).hex()};
    transition: background-color 50ms;
  }
`;
