import styled from 'styled-components';
import { SmallProps } from './types';

export const Small = styled.small<SmallProps>`
  font-weight: bold;
  color: ${({ theme }) => theme.color.cardText.alpha(0.5).hex()};
  text-transform: ${({ capitalize = true }) =>
    capitalize ? 'uppercase' : 'none'};
`;

export const Text = styled.div`
  .text-100 {
    color: ${({ theme }) => theme.color.cardText.hex()};
  }

  .text-75 {
    color: ${({ theme }) => theme.color.cardText.alpha(0.75).hex()};
  }

  .text-50 {
    color: ${({ theme }) => theme.color.cardText.alpha(0.5).hex()};
  }
`;
