import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link, useLocation } from 'wouter';
import { faTrain } from '@fortawesome/pro-duotone-svg-icons/faTrain';
import { faWrench } from '@fortawesome/pro-duotone-svg-icons/faWrench';
import PortalBreadcrumb from '../PortalBreadcrumb';
import CardWrapper from '../CardWrapper';
import Table from '../Table';
import SimpleButton from '../SimpleButton';

const HomeDashboard = () => {
  const [, setLocation] = useLocation();

  return (
    <>
      <PortalBreadcrumb>
        <h4>Dashboard</h4>
      </PortalBreadcrumb>
      <div className="h-100 d-flex flex-column">
        <Row className="h-25 mb-4">
          <Col xl={3}>
            <CardWrapper className="h-100">
              <h3 className="mb-0">Welcome, Jamie Williams</h3>
            </CardWrapper>
          </Col>
          <Col>
            <CardWrapper className="h-100">Hello</CardWrapper>
          </Col>
          <Col>
            <CardWrapper className="h-100">Hello</CardWrapper>
          </Col>
          <Col>
            <CardWrapper className="h-100">Hello</CardWrapper>
          </Col>
        </Row>
        <Row className="flex-grow-1">
          <Col xl={3}>
            <CardWrapper className="h-100 p-0">
              <div className="d-flex justify-content-between m-3">
                <h5 className="mb-0">Clients</h5>
                <Link to="/clients">
                  <small style={{ display: 'block', lineHeight: '24px' }}>
                    <SimpleButton icon={faTrain} hoverStyles={false}>
                      View all clients
                    </SimpleButton>
                  </small>
                </Link>
              </div>
              <div>
                <Table flexBases={[85, 5, 5, 5]}>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th />
                      <th />
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    <tr onClick={() => setLocation('/clients/bexley-council')}>
                      <td>Bexley Council</td>
                      <td>0</td>
                      <td>0</td>
                      <td>0</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardWrapper>
          </Col>
          <Col>
            <CardWrapper className="h-100 p-0">
              <div className="d-flex justify-content-between m-3">
                <h5 className="mb-0">Jobs</h5>
                <Link to="/jobs">
                  <small style={{ display: 'block', lineHeight: '24px' }}>
                    <SimpleButton icon={faWrench} hoverStyles={false}>
                      View all jobs
                    </SimpleButton>
                  </small>
                </Link>
              </div>
              <div>
                <Table flexBases={[20, 20, 60]}>
                  <thead>
                    <tr>
                      <th>Client</th>
                      <th>Name</th>
                      <th>Visits</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      onClick={() =>
                        setLocation('/clients/bexley-council/jobs/30')
                      }
                    >
                      <td>Bexley Council</td>
                      <td>Track Repair</td>
                      <td>Test</td>
                    </tr>
                    <tr
                      onClick={() =>
                        setLocation('/clients/network-rail/jobs/31')
                      }
                    >
                      <td>Network Rail</td>
                      <td>Station Maintenance</td>
                      <td>Test</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </CardWrapper>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default HomeDashboard;
