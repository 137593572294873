import React, { Dispatch, useContext } from 'react';
import {
  ManagementTasksQueryData,
  ManagementTaskQueryData,
  State,
} from './types';
import { AnyAction } from 'redux';
import { ManagementTask } from 'lib/types';

export const initialState: State = {
  managementTaskTypes: [] as ManagementTask['type'][],
  managementTaskType: null,
  managementTasks: {
    results: [] as ManagementTask[],
    count: 0,
  },
  managementTask: null,
};

export const ReducerContext = React.createContext<[State, Dispatch<AnyAction>]>(
  [initialState, () => {}]
);

export const useReducerContext = () => useContext(ReducerContext);

export const managementTasksQueryOnCompleted = (
  data: ManagementTasksQueryData
) => ({
  type: 'MANAGEMENT_TASKS_QUERY_ON_COMPLETED',
  managementTaskTypes: data.managementTaskTypes,
  managementTasks: data.managementTasks,
});

export const managementTasksRefetchOnCompleted = (
  managementTaskType: ManagementTask['type'],
  data: ManagementTasksQueryData
) => ({
  type: 'MANAGEMENT_TASKS_REFETCH_ON_COMPLETED',
  managementTaskTypes: data.managementTaskTypes,
  managementTaskType,
  managementTasks: data.managementTasks,
});

export const managementTaskQueryOnCompleted = (
  data: ManagementTaskQueryData
) => ({
  type: 'MANAGEMENT_TASK_QUERY_ON_COMPLETED',
  managementTask: data.managementTask,
});

const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case 'MANAGEMENT_TASKS_QUERY_ON_COMPLETED': {
      const thisAction = action as ReturnType<
        typeof managementTasksQueryOnCompleted
      >;
      return {
        ...state,
        managementTaskTypes: thisAction.managementTaskTypes,
        managementTasks: thisAction.managementTasks,
      };
    }
    case 'MANAGEMENT_TASKS_REFETCH_ON_COMPLETED': {
      const thisAction = action as ReturnType<
        typeof managementTasksRefetchOnCompleted
      >;
      return {
        ...state,
        managementTaskType: thisAction.managementTaskType,
        managementTasks: thisAction.managementTasks,
      };
    }
    case 'MANAGEMENT_TASK_QUERY_ON_COMPLETED': {
      const thisAction = action as ReturnType<
        typeof managementTaskQueryOnCompleted
      >;
      return {
        ...state,
        managementTaskType: thisAction.managementTask.type,
        managementTask: thisAction.managementTask,
      };
    }
    default:
      return state;
  }
};

export default reducer;
