import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;

  div.map-label {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background-color: ${({ theme }) => theme.color.cardBackground.hex()};
    z-index: 9999;
  }
`;
