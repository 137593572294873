import { gql } from '@apollo/client';

export const CREATE_USER_TODO_MUTATION = gql`
    mutation($logBlockId: ID!) {
        createUserTodo(logBlockId: $logBlockId) {
            userTodo {
                id
            }
        }
    }
`;
