import React from 'react';
import { Link, useLocation } from 'wouter';
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import { useClient } from 'lib/hooks';
import TableFilterDescription from '../ClientJobsPPMTableFilterDescription';
import SearchBar from '../SearchBar';
import { ClientJobsPPMTableFilterProps } from './types';
import ButtonMulti from '../ButtonMulti';

const ClientJobsReactiveTableFilter = ({
  jobGroups,
  searchTerm,
  searchTermOnChange,
}: ClientJobsPPMTableFilterProps) => {
  const client = useClient();
  const [, setLocation] = useLocation();

  const handleCreateReactiveJobOnClick = () => {
    setLocation(`/clients/${client?.slug}/jobs/create-reactive`);
  };

  const handleCreatePPMJobOnClick = () => {
    setLocation(`/clients/${client?.slug}/jobs/create-ppm`);
  };

  return (
    <div>
      <div className="d-flex px-3">
        <div className="flex-grow-1">
          <p className="mb-2 text-50">Search</p>
          <SearchBar
            style={{ borderColor: searchTerm !== '' ? '#106c12' : '' }}
            value={searchTerm}
            placeholder="Search job groups..."
            onChange={searchTermOnChange}
          />
        </div>
        <div style={{ marginTop: '2rem' }}>
          <Link href={`/clients/${client.slug}/jobs/create`}>
            <ButtonMulti
              label="Create"
              icon={faPlus}
              options={[
                {
                  label: 'PPM job',
                  icon: faPlus,
                  onClick: handleCreatePPMJobOnClick,
                },
                {
                  label: 'Reactive job',
                  icon: faPlus,
                  onClick: handleCreateReactiveJobOnClick,
                },
              ]}
            />
          </Link>
        </div>
      </div>
      <div className="text-50 px-3 my-3">
        <TableFilterDescription
          count={jobGroups?.count || 0}
          searchTerm={searchTerm}
        />
      </div>
    </div>
  );
};

export default ClientJobsReactiveTableFilter;
