import { gql } from '@apollo/client';

export const SEARCH_SITES_QUERY = gql`
  query ($clientId: ID!, $searchTerm: String) {
    sites: clientSites(clientId: $clientId, searchTerm: $searchTerm) {
      results {
        id
        name
        location {
          address
          meta
          point {
            longitude
            latitude
          }
        }
        buildings {
          id
        }
      }
      polygon
      centroid {
        longitude
        latitude
      }
    }
  }
`;
