import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons/faSpinnerThird';
import { DateTime } from 'luxon';
import { Wrapper } from './styled';
import { ClientJobsAllTableTimelineProps } from './types';
import LogBlock from '../LogBlock';
import ScrollDiv from '../ScrollDiv';

const ClientJobsAllTableTimeline = ({
  logBlocks,
}: ClientJobsAllTableTimelineProps) => {
  if (!logBlocks)
    return (
      <Wrapper>
        <p>
          <FontAwesomeIcon
            className="mr-1"
            icon={faSpinnerThird}
            spin
            fixedWidth
          />
          Loading timeline...
        </p>
      </Wrapper>
    );
  if (logBlocks.length === 0) {
    return (
      <Wrapper>
        <p className="text-75">This job has no history.</p>
      </Wrapper>
    );
  }
  return (
    <Wrapper className="h-100 d-flex flex-column p-0">
      <div className="m-2 d-flex justify-content-between">
        <p className="mb-0">Timeline</p>
        <p className="mb-0 text-75">
          <small>
            Last update:{' '}
            {DateTime.fromISO(logBlocks[0].dateTime).toRelativeCalendar()}
          </small>
        </p>
      </div>
      <ScrollDiv className="flex-grow-1" style={{ overflowY: 'auto' }}>
        {logBlocks.map((logBlock, index) => {
          const first = index === 0;
          const last = index === logBlocks?.length - 1;
          return (
            <LogBlock
              key={logBlock.id}
              logBlock={logBlock}
              first={first}
              last={last}
              invertColors
            />
          );
        })}
      </ScrollDiv>
    </Wrapper>
  );
};

export default ClientJobsAllTableTimeline;
