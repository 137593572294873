import React, { Dispatch, useContext } from 'react';
import { AnyAction } from 'redux';
import { addOrUpdate, findById, toggleSidePanel } from 'lib/utils';
import { ClientSite } from 'lib/types';
import { ClientSitesState, SitesQueryData } from './types';
import {
  CreateSiteMutationData,
  UpdateSiteMutationData,
} from '../ClientsSitesSidePanel/types';
import { SearchSitesQueryData } from '../ClientSitesTableFilter/types';

export const initialState: ClientSitesState = {
  sites: [],
  site: null,
  sitesSidePanel: {
    isOpen: false,
  },
  map: {
    polygon: null,
    viewState: {
      longitude: -0.115,
      latitude: 51.5074,
      zoom: 12,
      pitch: 45,
      bearing: -10,
    },
  },
};

export const ReducerContext = React.createContext<
  [ClientSitesState, Dispatch<AnyAction>]
>([initialState, () => {}]);

export const useReducerContext = () => useContext(ReducerContext);

export const sitesQueryOnCompleted = (responseData: SitesQueryData) => ({
  type: 'SITES_QUERY_ON_COMPLETED',
  sites: responseData.sites.results.map((site, index) => ({ index, ...site })),
  polygon: responseData.sites.polygon,
  centroid: responseData.sites.centroid,
});

export const createSiteMutationOnCompleted = (
  responseData: CreateSiteMutationData
) => ({
  type: 'CREATE_SITE_MUTATION_ON_COMPLETED',
  site: responseData.createSite.site,
});

export const updateSiteMutationOnCompleted = (
  responseData: UpdateSiteMutationData
) => ({
  type: 'UPDATE_SITE_MUTATION_ON_COMPLETED',
  site: responseData.updateSite.site,
});

export const toggleSitesSidePanel = (override?: boolean) => ({
  type: 'TOGGLE_SITES_SIDE_PANEL',
  override,
});

export const sitesSidePanelOnClosed = () => ({
  type: 'SITES_SIDE_PANEL_ON_CLOSED',
});

export const mapViewStateOnChange = (newViewState: any) => ({
  type: 'MAP_VIEW_STATE_ON_CHANGE',
  newViewState,
});

export const siteTableRowOnClick = (id: ClientSite['id']) => ({
  type: 'SITE_TABLE_ROW_ON_CLICK',
  id,
});

export const searchSitesQueryOnCompleted = (
  responseData: SearchSitesQueryData
) => ({
  type: 'SEARCH_SITES_QUERY_ON_COMPLETED',
  sites: responseData.sites.results.map((site, index) => ({ index, ...site })),
  polygon: responseData.sites.polygon,
  centroid: responseData.sites.centroid,
});

const reducer = (state: ClientSitesState, action: AnyAction) => {
  switch (action.type) {
    case 'SITES_QUERY_ON_COMPLETED': {
      if (!action.centroid.longitude || !action.centroid.latitude) {
        return {
          ...state,
          sites: action.sites,
        };
      }
      return {
        ...state,
        sites: action.sites,
        map: {
          ...state.map,
          polygon: action.polygon,
          viewState: {
            ...state.map.viewState,
            longitude: action.centroid.longitude,
            latitude: action.centroid.latitude,
          },
        },
      };
    }
    case 'CREATE_SITE_MUTATION_ON_COMPLETED':
      return {
        ...state,
        sites: addOrUpdate(state.sites, action.site),
        sitesSidePanel: {
          ...state.sitesSidePanel,
          isOpen: false,
        },
      };
    case 'UPDATE_SITE_MUTATION_ON_COMPLETED':
      return {
        ...state,
        sites: addOrUpdate(state.sites, action.site),
        sitesSidePanel: {
          ...state.sitesSidePanel,
          isOpen: false,
        },
      };
    case 'TOGGLE_SITES_SIDE_PANEL':
      return {
        ...state,
        sitesSidePanel: {
          ...state.sitesSidePanel,
          isOpen: toggleSidePanel(state.sitesSidePanel.isOpen, action.override),
        },
      };
    case 'SITES_SIDE_PANEL_ON_CLOSED':
      return {
        ...state,
        site: null,
      };
    case 'MAP_VIEW_STATE_ON_CHANGE':
      return {
        ...state,
        map: {
          ...state.map,
          viewState: {
            ...state.map.viewState,
            ...action.newViewState,
          },
        },
      };
    case 'SITE_TABLE_ROW_ON_CLICK':
      return {
        ...state,
        site: findById(state.sites, action.id) || null,
        sitesSidePanel: {
          ...state.sitesSidePanel,
          isOpen: true,
        },
      };
    case 'SEARCH_SITES_QUERY_ON_COMPLETED':
      if (!action.centroid) {
        return {
          ...state,
          sites: action.sites,
          map: {
            ...state.map,
            polygon: null,
          },
        };
      }
      return {
        ...state,
        sites: action.sites,
        map: {
          ...state.map,
          polygon: action.polygon,
          viewState: {
            ...state.map.viewState,
            longitude: action.centroid.longitude,
            latitude: action.centroid.latitude,
          },
        },
        // map: {
        //   ...state.map,
        //   viewState: {
        //     ...state.map.viewState,
        //     longitude: action.centroid.longitude,
        //     latitude: action.centroid.latitude,
        //   },
        // },
      };
    default:
      return state;
  }
};

export default reducer;
