import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDotCircle } from '@fortawesome/pro-regular-svg-icons/faDotCircle';
import { JobReport } from 'lib/types';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons/faCheckCircle';
import { faClock } from '@fortawesome/pro-regular-svg-icons/faClock';
import { ClientJobReportStatusPillProps } from './types';
import { Wrapper } from './styled';

const getIcon = (status: JobReport['status']) => {
  switch (status) {
    case 'SCHEDULED':
      return faClock;
    case 'STARTED':
      return faDotCircle;
    case 'COMPLETED':
      return faCheckCircle;
    default:
      return faDotCircle;
  }
};

// TODO: Status pills should have a window that opens on hover (or click?)
//  that explains to the user the meaning of the status and some extra data
//  pertaining to it.

const ClientJobReportStatusPill = ({
  status,
  ...rest
}: ClientJobReportStatusPillProps) => {
  return (
    <Wrapper status={status} {...rest}>
      <FontAwesomeIcon className="mr-1" icon={getIcon(status)} fixedWidth />
      <span className="text-capitalize">{status.toLowerCase()}</span>
    </Wrapper>
  );
};

export default ClientJobReportStatusPill;
