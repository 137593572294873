import { DateTime } from 'luxon';
import React from 'react';
import { Area, AreaChart, ResponsiveContainer, XAxis } from 'recharts';
import { Link } from 'wouter';
import { useClientSlug } from 'lib/hooks';
import { displayNumber } from 'lib/utils';
import CardWrapper from '../CardWrapper';
import { ClientDashboardOverviewCardProps } from './types';

const ClientDashboardOverviewCard = ({
  total,
  label,
  data,
  areaOptions,
  color,
}: ClientDashboardOverviewCardProps) => {
  const clientSlug = useClientSlug();
  return (
    <CardWrapper
      className="py-2"
      style={{
        overflow: 'visible',
        borderLeft: `4px solid ${total !== undefined ? color : 'transparent'}`,
      }}
    >
      <div>
        <div className="d-flex">
          <h1 className="mb-0" style={{ lineHeight: '56px' }}>
            {displayNumber(total || 0)}
          </h1>
          <div className="flex-grow-1" style={{ minWidth: 0 }}>
            {data && (
              <ResponsiveContainer
                minWidth={0}
                width="100%"
                minHeight={0}
                height="100%"
              >
                <AreaChart
                  data={data.items}
                  margin={{ top: 16, right: 16, bottom: 16, left: 16 }}
                >
                  <XAxis
                    hide
                    type="number"
                    dataKey="timestamp"
                    domain={['auto', 'auto']}
                    tickFormatter={(timestamp: number) =>
                      DateTime.fromSeconds(timestamp).toFormat('HH:mm')
                    }
                  />
                  {data.keys.map(({ name, color: innerColor }: any) => (
                    <Area
                      key={name}
                      type="monotone"
                      dot={false}
                      dataKey={name}
                      stroke={innerColor}
                      strokeWidth={2}
                      strokeLinecap="round"
                      fillOpacity={1}
                      fill="url(#colorPv)"
                      {...areaOptions}
                    />
                  ))}
                </AreaChart>
              </ResponsiveContainer>
            )}
          </div>
        </div>
        <div className="mb-2 d-flex justify-content-between">
          <span>{label}</span>
          <Link
            className="text-50"
            to={`/clients/${clientSlug}/jobs?flagType=`}
          >
            View all
          </Link>
        </div>
      </div>
    </CardWrapper>
  );
};

export default ClientDashboardOverviewCard;
