import React, { useEffect } from 'react';
import { DateTime, Interval } from 'luxon';
import classNames from 'classnames';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
import { useHoveredId } from '../../lib/hooks';
import SimpleButton from '../SimpleButton';
import { Weekday, Week, Day } from './styled';
import { DatePickerProps } from './types';

const DatePicker = ({ date, counts, onChange }: DatePickerProps) => {
  const dates = Interval.fromDateTimes(
    date.startOf('month').startOf('week'),
    date.startOf('month').startOf('week').plus({ days: 35 })
  );

  const handleDayOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { value: dayString } = event.currentTarget;
    onChange(DateTime.fromISO(dayString));
  };

  const maxCount = counts && Object.values(counts).sort((a, b) => b - a)[0];

  const [[hoveredDate, setHoveredDate], hoveredCallbacks] = useHoveredId(
    date.toISO()
  );

  useEffect(() => {
    setHoveredDate(date.toISO());
  }, [date, setHoveredDate]);

  return (
    <div className="h-100" onMouseLeave={() => setHoveredDate(date.toISO())}>
      <div className="d-flex justify-content-between mb-2">
        <SimpleButton
          icon={faChevronLeft}
          onClick={() => onChange(date.minus({ months: 1 }))}
        />
        <span style={{ lineHeight: '34px' }}>
          {date.monthLong} {date.year}
        </span>
        <SimpleButton
          icon={faChevronRight}
          onClick={() => onChange(date.plus({ months: 1 }))}
        />
      </div>
      <div className="d-flex justify-content-between mb-2">
        {Interval.fromDateTimes(
          date.startOf('week'),
          date.startOf('week').plus({ weeks: 1 })
        )
          .splitBy({ days: 1 })
          .map((day) => (
            <div
              key={day.toISO()}
              className="text-center"
              style={{
                width: 32,
              }}
            >
              <Weekday
                hovered={
                  DateTime.fromISO(hoveredDate).weekday === day.start.weekday
                }
              >
                {day.start.toFormat('EEE')}
              </Weekday>
            </div>
          ))}
      </div>
      <div className="d-flex flex-column justify-content-between">
        {dates.splitBy({ weeks: 1 }).map((week, index) => (
          <Week
            key={week.toISO()}
            className={classNames('d-flex justify-content-between ', {
              'mb-2': index !== 4,
            })}
          >
            {week.splitBy({ days: 1 }).map((day) => {
              const count = counts?.[day.start.toFormat('yyyy-MM-dd')];
              return (
                <Day
                  key={day.toISO()}
                  data-id={day.start.toISO()}
                  className="mono"
                  count={count}
                  maxCount={maxCount}
                  active={date.startOf('day').equals(day.start)}
                  style={{ opacity: day.start.month === date.month ? 1 : 0.5 }}
                  value={day.start.toISO()}
                  onClick={handleDayOnClick}
                  {...hoveredCallbacks}
                >
                  {day.start.toFormat('d')}
                </Day>
              );
            })}
          </Week>
        ))}
      </div>
    </div>
  );
};

export default DatePicker;
