import React from 'react';
import { JobPriorityPillProps } from './types';
import { Wrapper, PriorityCode, PriorityName } from './styled';

const JobPriorityPill = ({
  job,
  includeCode = true,
  type,
  ...rest
}: JobPriorityPillProps) => {
  const getPriorityText = () => {
    switch (type) {
      case 'response':
        return job.priority.responseName;
      case 'completion':
        return job.priority.completionName;
      case 'both':
        return `${job.priority.responseName} - ${job.priority.completionName}`;
      default:
        return '';
    }
  };

  if (!job) return null;
  if (job.workType === 'PPM') return null;
  if (!job?.priority) return <>No priority set</>;
  return (
    <Wrapper className="center-vertically" {...rest}>
      <small className="d-flex">
        {includeCode && (
          <PriorityCode className="mono" color={job.priority.color}>
            <strong>{job.priority.code}</strong>
          </PriorityCode>
        )}
        <PriorityName
          className="text-75"
          color={job.priority.color}
          includeCode={includeCode}
        >
          {getPriorityText()}
        </PriorityName>
      </small>
    </Wrapper>
  );
};

export default JobPriorityPill;
