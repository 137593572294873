import styled from 'styled-components';

export const Wrapper = styled.div`
  min-width: 350px;
`;

export const Duplicates = styled.div`
  opacity: 0.5;
  transition: opacity 150ms;

  &:hover {
    opacity: 1;
    transition: opacity 50ms;
  }
`;
