import { ApolloQueryResult } from '@apollo/client/core/types';
import {
  ProjectModel,
  SchedulerProConfig,
} from '@bryntum/schedulerpro/schedulerpro.umd.js';
import chroma from 'chroma-js';
import { DefaultTheme } from 'styled-components';
import { JobVisit, UserGroup } from 'lib/types';
import { InitialQueryData } from '../ClientJobGroupScheduler/types';

export const schedulerConfig: Partial<SchedulerProConfig> = {
  viewPreset: {
    id: 'customHourAndDay',
    base: 'hourAndDay',
    headers: [
      {
        unit: 'day',
        dateFormat: 'dddd Do MMMM',
      },
      {
        unit: 'minute',
        increment: 30,
        dateFormat: 'HH:mm',
      },
    ],
  },
  eventStyle: 'colored',
};

export const addResources = (
  userGroups: UserGroup[],
  project: ProjectModel,
  theme: DefaultTheme
) => {
  const colors = chroma
    .scale([theme.color.primary, theme.color.contentBackground])
    .colors(userGroups.flatMap((userGroup) => userGroup.users).length);
  let userIndex = 0;
  userGroups.forEach((userGroup) => {
    project.resourceStore.add(
      userGroup.users.map((user) => {
        const ret = {
          id: user.id,
          name: user.name,
          category: userGroup.namePlural,
          userGroup,
          eventColor: colors[userIndex],
        };
        userIndex += 1;
        return ret;
      })
    );
  });
};

export const addVisits = (visits: JobVisit[], project: ProjectModel) => {
  project.eventStore.add(
    visits.map((visit: JobVisit) => {
      return {
        id: visit.id,
        job: visit?.job,
        jobVisit: visit,
        resourceId: visit.user.id,
        name: visit.label,
        number: visit.number,
        eventColor: visit.status.color,
        eventStyles: { color: 'blue' },
        originalColor: visit.status.color,
        draggable: false,
        resizable: false,
        dependencies: false,
        startDate: visit.dateTimeStart,
        endDate: visit.dateTimeEnd,
      };
    })
  );
};

export const fetchMoreVisitsOnCompleted = (
  response: ApolloQueryResult<InitialQueryData>,
  project: ProjectModel
) => {
  if (!response.data) return;
  project.eventStore.add(
    response.data.jobVisits.map((visit: JobVisit) => {
      return {
        id: visit.id,
        resourceId: visit.user ? visit.user.id : null,
        job: visit?.job,
        jobVisit: visit,
        name: visit.label,
        number: visit.number,
        eventColor: visit.status.color,
        originalColor: visit.status.color,
        draggable: false,
        resizable: false,
        dependencies: false,
        startDate: visit.dateTimeStart,
        endDate: visit.dateTimeEnd,
      };
    })
  );
};
