import React from 'react';
import { useTheme } from 'styled-components';
import classNames from 'classnames';
import { parseDateTime } from 'lib/utils';
import { ClientJobIssueBlockProps } from './types';
import { ClientJobIssueBlockWrapper } from './styled';
import CardWrapper from '../CardWrapper';

const ClientJobIssueBlock = ({
  innerRef,
  isLinked,
  index,
  header,
  headerClassName,
  headerPaddingClass = 'px-3',
  headerStyle,
  opensIssue,
  closesIssue,
  dateTime,
  children,
  last,
  user,
  ...rest
}: ClientJobIssueBlockProps) => {
  const theme = useTheme();
  const getBorder = () => {
    if (isLinked) return '2px solid orange';
    if (opensIssue) return `2px solid ${theme.color.danger.alpha(0.35).hex()}`;
    if (closesIssue)
      return `2px solid ${theme.color.primary.alpha(0.35).hex()}`;
    return '';
  };

  return (
    <ClientJobIssueBlockWrapper className={classNames({ 'mb-0': last })}>
      <div className="user-decoration">
        <div
          className="user-decoration-icon center"
          style={{ border: isLinked ? '2px dashed orange' : '' }}
          ref={innerRef}
        >
          {user?.initials}
        </div>
        <div
          className="user-decoration-arrow"
          style={{ borderLeft: getBorder(), borderBottom: getBorder() }}
        />
      </div>
      <CardWrapper className="p-0" style={{ border: getBorder() }} {...rest}>
        <div
          className={classNames(
            'block-header d-flex justify-content-between',
            headerClassName,
            headerPaddingClass
          )}
          style={headerStyle}
        >
          {header}
          {dateTime && (
            <div className="center-vertically">
              <small className="mono text-50">{parseDateTime(dateTime)}</small>
            </div>
          )}
        </div>
        <div style={{ fontSize: '0.9rem' }}>{children}</div>
      </CardWrapper>
      {!last && <div className="line-decoration" />}
    </ClientJobIssueBlockWrapper>
  );
};

export default ClientJobIssueBlock;
