import styled from 'styled-components';
import { PriorityCodeProps, PriorityNameProps } from './types';

export const Wrapper = styled.div`
  height: 24px;
`;

export const PriorityCode = styled.span<PriorityCodeProps>`
  background-color: ${({ color }) => color};
  padding: 0 0.5rem;
  border-radius: 0.25rem 0 0 0.25rem;
  line-height: 21px;
`;

export const PriorityName = styled.span<PriorityNameProps>`
  background-color: ${({ theme }) => theme.color.contentBackground.hex()};
  padding: 0 0.5rem;
  border-radius: ${({ includeCode }) =>
    includeCode ? '0 0.25rem 0.25rem 0' : '0.25rem'};
  border: 1px solid ${({ color }) => color};
`;
