import React from 'react';
import { useDelay } from 'lib/hooks';
import { FadeProps } from './types';

const TransitionFade = ({ children, show, delay = 200 }: FadeProps) => {
  const showDelayedShort = useDelay(show, { delay: 10 });
  const showDelayed = useDelay(show, { delay });
  return (
    <>
      {show || showDelayed
        ? children({ show: showDelayedShort, showDelayed, delay })
        : null}
    </>
  );
};

export default TransitionFade;
