import React from 'react';
import { useDelay } from 'lib/hooks';
import Portal from '../Portal';
import { Overlay } from './styled';
import { LayoutOverlayProps } from './types';

const LayoutOverlay = ({ isOpen, onClick, ...rest }: LayoutOverlayProps) => {
  const isOpenDelayed = useDelay(isOpen, { delay: 250 });
  const isOpenDelayedShort = useDelay(isOpen, { delay: 10 });

  return isOpen || isOpenDelayed ? (
    <Portal>
      <Overlay {...rest} isOpen={isOpenDelayedShort} onClick={onClick} />
    </Portal>
  ) : null;
};

export default LayoutOverlay;
