import { useCallback, useEffect, useState } from 'react';
import { useLayoutSpacer } from '../../components/Layout';

const useScroll = () => {
  const [scroll, setScroll] = useState(0);
  const layoutSpacerRef = useLayoutSpacer();

  const handleScroll = useCallback(() => {
    const layoutSpacerElement = layoutSpacerRef?.current;
    if (!layoutSpacerElement) return;
    setScroll(layoutSpacerElement.scrollTop);
  }, [layoutSpacerRef]);

  useEffect(() => {
    const layoutSpacerElement = layoutSpacerRef?.current;
    if (!layoutSpacerElement) return () => {};
    layoutSpacerElement.addEventListener('scroll', handleScroll);
    return () =>
      layoutSpacerElement.removeEventListener('scroll', handleScroll);
  }, [handleScroll, layoutSpacerRef]);
  return scroll;
};

export default useScroll;
