import styled from 'styled-components';

export const Wrapper = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  height: calc(100% - 124px - 56px);
`;

export const TableStyled = styled.table`
  position: relative;
  border-collapse: collapse;

  thead {
    tr {
      position: sticky;
      top: 0;
    }

    th {
      padding-left: 1rem;
      padding-bottom: 0.5rem;
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      cursor: pointer;
      transition: background-color 100ms;

      button {
        margin-right: 1rem;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }

    td {
      padding: 1rem 0 1rem 1rem;
    }
  }
`;
