import React, { Dispatch, useContext } from 'react';
import { AnyAction } from 'redux';
import {
  ClientDashboardState,
  ImmediateActionJobsQueryData,
  InitialQueryData,
} from './types';
import { LogBlockQueryData } from '../ClientDashboardActivityLog/types';

export const initialState: ClientDashboardState = {
  immediateActionJobs: undefined,
};

export const ReducerContext = React.createContext<
  [ClientDashboardState, Dispatch<AnyAction>]
>([initialState, () => {}]);

export const useReducerContext = () => useContext(ReducerContext);

export const initialQueryOnCompleted = (responseData: InitialQueryData) => ({
  type: 'INITIAL_QUERY_ON_COMPLETED',
  incomingJobs: responseData.incomingJobs,
  incomingJobsChart: responseData.incomingJobsChart,
  jobIssues: responseData.jobIssues,
  jobIssuesChart: responseData.jobIssuesChart,
  jobReports: responseData.jobReports,
  jobReportsChart: responseData.jobReportsChart,
});

export const immediateActionJobsQueryOnCompleted = (
  responseData: ImmediateActionJobsQueryData
) => ({
  type: 'IMMEDIATE_ACTION_JOBS_QUERY_ON_COMPLETED',
  immediateActionJobs: responseData.immediateActionJobs,
});

export const inProgressJobsQueryOnCompleted = (responseData: any) => ({
  type: 'IN_PROGRESS_JOBS_QUERY_ON_COMPLETED',
  inProgressJobs: responseData.inProgressJobs,
});

export const logBlocksQueryOnCompleted = (responseData: LogBlockQueryData) => ({
  type: 'LOG_BLOCKS_QUERY_ON_COMPLETED',
  logBlocks: responseData.logBlocks,
});

const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case 'INITIAL_QUERY_ON_COMPLETED':
      return {
        ...state,
        incomingJobs: action.incomingJobs,
        incomingJobsChart: action.incomingJobsChart,
        jobIssues: action.jobIssues,
        jobIssuesChart: action.jobIssuesChart,
        jobReports: action.jobReports,
        jobReportsChart: action.jobReportsChart,
      };
    case 'IMMEDIATE_ACTION_JOBS_QUERY_ON_COMPLETED':
      return {
        ...state,
        immediateActionJobs: action.immediateActionJobs,
      };
    case 'IN_PROGRESS_JOBS_QUERY_ON_COMPLETED':
      return {
        ...state,
        inProgressJobs: action.inProgressJobs,
      };
    case 'LOG_BLOCKS_QUERY_ON_COMPLETED':
      return {
        ...state,
        logBlocks: action.logBlocks,
      };
    default:
      return state;
  }
};

export default reducer;
