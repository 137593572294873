import React from 'react';
import { useQuery } from '@apollo/client';
import CardWrapper from '../CardWrapper';
import { USER_QUERY } from './query';
import { PeoplePersonProps, UserQueryData, UserQueryVariables } from './types';
import { useReducerContext, userQueryOnCompleted } from '../People/reducer';

const PeoplePerson = ({ params }: PeoplePersonProps) => {
  const [{ user }, dispatch] = useReducerContext();

  useQuery<UserQueryData, UserQueryVariables>(USER_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: { userId: params?.id },
    onCompleted: (data) => {
      dispatch(userQueryOnCompleted(data));
    },
  });

  if (!user) return null;
  return (
    <div>
      <CardWrapper>{user.name}</CardWrapper>
    </div>
  );
};

export default PeoplePerson;
