import React, { useCallback, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Job } from 'lib/types';
import LinkWithWindow from '../LinkWithWindow';
import { JobLinkProps } from './types';
import { JOB_QUERY } from './query';
import { Small } from '../Typography';
import JobStatusFlag from '../JobStatusFlag';

const JobLink = ({
  job: initialJob,
  client,
  children,
  windowProps,
}: JobLinkProps) => {
  const [job, setJob] = useState<Partial<Job>>(initialJob);
  const [getJob] = useLazyQuery(JOB_QUERY, {
    fetchPolicy: 'no-cache',
    variables: { jobId: job.id, jobReference: job.reference },
    onCompleted: (data) => {
      setJob((prevJob) => ({ ...prevJob, ...data.job }));
    },
  });

  const handleOnOpened = () => {
    getJob();
  };

  const renderWindowChildren = useCallback(
    () => (
      <div className="p-3">
        <div>
          <Small className="mono">{job.reference}</Small>
          {job.status && (
            <Small className="ml-2">
              <JobStatusFlag className="mr-1" flag={job.status.flag} />
              {job.status.name}
            </Small>
          )}
        </div>
        <p className="mb-0">{job.name}</p>
      </div>
    ),
    [job]
  );

  if (!job) return null;
  return (
    <LinkWithWindow
      windowProps={windowProps}
      href={
        client
          ? `/clients/${client.slug}/jobs/${job.reference}`
          : `/clients/${job?.client?.slug}/jobs/${job.reference}`
      }
      windowChildren={renderWindowChildren}
      onOpened={handleOnOpened}
    >
      {children || job.reference}
    </LinkWithWindow>
  );
};

export default JobLink;
