import styled, { DefaultTheme } from 'styled-components';
import { JobIssue } from 'lib/types';
import { WrapperProps } from './types';

const getColor = (theme: DefaultTheme, status: JobIssue['status']) => {
  switch (status) {
    case 'OPEN':
      return theme.color.danger.alpha(0.25).hex();
    case 'CLOSED':
      return theme.color.primary.alpha(0.25).hex();
    default:
      return '';
  }
};

export const Wrapper = styled.div<WrapperProps>`
  display: inline;
  padding: 0.25rem 0.5rem;
  background-color: ${({ theme, status }) => getColor(theme, status)};
  height: 100%;
  border-radius: 0.25rem;
`;
