import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useQuery } from '@apollo/client';
import { Col, Row } from 'reactstrap';
import {
  JobIssue,
  JobIssueBlockLogItem,
  JobIssueBlockMessage,
  JobIssueMessage,
  LogItem,
} from 'lib/types';
import classNames from 'classnames';
import styled, { useTheme } from 'styled-components';
import { useDelay, useScroll, useScrollTo } from 'lib/hooks';
import chroma from 'chroma-js';
import { useReducerContext } from '../ClientJob/reducer';
import { JOB_ISSUE_QUERY } from './query';
import ClientJobIssueEventBlock from '../ClientJobIssueEventBlock';
import ClientJobIssueMessageBlock from '../ClientJobIssueMessageBlock';
import ClientJobIssueControlBlock from '../ClientJobIssueControlBlock';
import ClientJobIssueHeaderBlock from '../ClientJobIssueHeaderBlock';
import ClientJobTabBarExtension from '../ClientJobTabBarExtension';
import SimpleButton from '../SimpleButton';
import ClientJobIssueStatusPill from '../ClientJobIssueStatusPill';
import Transition from '../Transition';
import Portal from '../Portal';

const Parent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
`;

const LinkerSVG = styled.svg`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

const Linker = ({
  children,
  links,
}: React.PropsWithChildren<{
  links?: [number, number][];
}>) => {
  const orderedLinks = links?.sort(
    (a, b) => Math.abs(a[0] - a[1]) - Math.abs(b[0] - b[1])
  );
  const childrenRef = useRef<Record<number, HTMLDivElement>>({});
  const [test] = useState(false);
  const testDelay = useDelay(test);

  const renderSvg = useCallback(
    () => (
      <LinkerSVG width={60}>
        {Object.values(childrenRef.current).length > 0 &&
          orderedLinks?.map((link, index) => {
            const from = childrenRef.current[link[0]];
            const to = childrenRef.current[link[1]];
            if (!from || !to) return null;
            const fromRect = from.getBoundingClientRect();
            const toRect = to.getBoundingClientRect();
            const color = chroma('orange').darken(index).hex();
            return (
              <>
                <line
                  key={`from-${link.join('-')}`}
                  x1={fromRect.x}
                  y1={fromRect.y + 16}
                  x2={toRect.x - 40 - index * 40}
                  y2={fromRect.y + 16}
                  stroke={color}
                  strokeWidth={2}
                  strokeLinejoin="round"
                  strokeDasharray={5}
                />
                <line
                  key={link.join('-')}
                  x1={toRect.x - 40 - index * 40}
                  y1={fromRect.y + 16}
                  x2={toRect.x - 40 - index * 40}
                  y2={toRect.y + 20}
                  stroke={color}
                  strokeWidth={2}
                  strokeLinejoin="round"
                  strokeDasharray={5}
                />
                <line
                  key={`to-${link.join('-')}`}
                  x1={toRect.x}
                  y1={toRect.y + 20}
                  x2={toRect.x - 40 - index * 40}
                  y2={toRect.y + 20}
                  stroke={color}
                  strokeWidth={2}
                  strokeLinejoin="round"
                  strokeDasharray={5}
                />
              </>
            );
          })}
      </LinkerSVG>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [orderedLinks, testDelay]
  );

  if (!links) return <>{children}</>;
  return (
    <>
      <Portal>
        <Parent>{renderSvg()}</Parent>
      </Portal>
      {React.Children.map(children, (child, index) =>
        React.cloneElement(child as ReactElement, {
          innerRef: (ref: React.RefObject<HTMLDivElement>) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            childrenRef.current[index] = ref;
          },
          isLinked: links.map((link) => link[1]).includes(index),
        })
      )}
    </>
  );
};

const ClientJobIssue = ({ params }: any) => {
  const theme = useTheme();
  const [{ job }] = useReducerContext();
  const [issue, setIssue] = useState<JobIssue>();

  useQuery(JOB_ISSUE_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      jobId: job?.id,
      jobIssueNumber: parseInt(params.issueNumber, 10),
    },
    onCompleted: (data) => {
      setIssue(data.jobIssue);
    },
  });

  const scroll = useScroll();
  const headerRef = useRef<HTMLDivElement>(null);
  const [showHeader, setShowHeader] = useState(false);
  useEffect(() => {
    const headerElement = headerRef.current;
    if (!headerElement) return;
    setShowHeader(scroll > headerElement.getBoundingClientRect().bottom);
  }, [scroll]);

  const scrollTo = useScrollTo();

  const getLogBlockIndex = (logBlock: LogItem | JobIssueBlockLogItem) => {
    return issue?.blocks?.findIndex((block) =>
      block.type === 'LOG_BLOCK' ? block.item.id === logBlock.id : false
    );
  };

  const links: [number, number][] = issue?.blocks
    ? (issue.blocks
        .filter((block) => block.type === 'LOG_BLOCK')
        .flatMap((block) =>
          (block.item as JobIssueBlockLogItem).links.map((linkedLogBlock) => [
            getLogBlockIndex(block.item as JobIssueBlockLogItem) || -1,
            getLogBlockIndex(linkedLogBlock) || -1,
          ])
        ) as [number, number][])
    : ([[-1, -1]] as [number, number][]);

  if (!job || !issue) return null;
  return (
    <>
      <ClientJobTabBarExtension>
        <Transition.Fade show={showHeader}>
          {({ show, delay }) => (
            <div
              className="ml-2"
              style={{
                opacity: show ? 1 : 0,
                transition: `opacity ${delay}ms`,
              }}
            >
              <span className="text-50 mr-2" style={{ lineHeight: '32px' }}>
                Current issue:
              </span>
              <SimpleButton
                active
                className="py-0 pl-0 pr-2 overflow-hidden"
                onClick={() => scrollTo(0, 0)}
              >
                <div
                  className="d-flex"
                  style={{
                    backgroundColor: theme.color.contentBackground.hex(),
                  }}
                >
                  <div>
                    <span
                      className="mono text-50 mx-2"
                      style={{ lineHeight: '32px' }}
                    >
                      #{issue.number}
                    </span>
                  </div>
                  <ClientJobIssueStatusPill
                    className="rounded-0"
                    status={issue.status}
                  />
                  <span
                    className={classNames('ml-2', {
                      'font-weight-bold': issue.status === 'OPEN',
                    })}
                    style={{ lineHeight: '32px' }}
                  >
                    {issue.issueTypeDisplay}
                  </span>
                </div>
              </SimpleButton>
            </div>
          )}
        </Transition.Fade>
      </ClientJobTabBarExtension>
      <div>
        <Row>
          <Col xs={3} />
          <Col xs={6}>
            <ClientJobIssueHeaderBlock innerRef={headerRef} issue={issue} />
            <Linker links={links}>
              {issue.blocks.map((block, index) => {
                switch (block.type) {
                  case 'LOG_BLOCK':
                    return (
                      <ClientJobIssueEventBlock
                        key={`${block.type.toLowerCase()}-${block.item.id}`}
                        index={index}
                        issue={issue}
                        logBlock={
                          {
                            ...block.item,
                            user: (block.item as JobIssueBlockLogItem)
                              .logItemUser,
                          } as LogItem
                        }
                      />
                    );
                  case 'MESSAGE':
                    return (
                      <ClientJobIssueMessageBlock
                        key={`${block.type.toLowerCase()}-${block.item.id}`}
                        index={index}
                        message={
                          {
                            ...block.item,
                            user: (block.item as JobIssueBlockMessage)
                              .messageUser,
                          } as JobIssueMessage
                        }
                      />
                    );
                  default:
                    return null;
                }
              })}
            </Linker>
          </Col>
          <Col xs={3} />
        </Row>
        <ClientJobIssueControlBlock issue={issue} setIssue={setIssue} />
      </div>
    </>
  );
};

export default ClientJobIssue;
