import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

type Props = {
  type: string;
  label: string;
  path: string;
};

const useSetTaskbarData = (props: Props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: 'UTILS__SET_TASKBAR_DATA',
      payload: props,
    });

    return () => {
      dispatch({ type: 'UTILS__SET_TASKBAR_DATA', payload: null });
    };
  }, [dispatch, props]);
};

export default useSetTaskbarData;
