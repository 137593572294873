import { faComments } from '@fortawesome/pro-duotone-svg-icons/faComments';
import React from 'react';
import { useReducerContext } from '../ClientJob/reducer';
import SimpleButtonWithWindow from '../SimpleButtonWithWindow';
import CommunicationChats from '../CommunicationChats';

const ClientJobChatWindow = () => {
  const [{ job }] = useReducerContext();

  const renderWindowChildren = () => {
    if (!job) return null;
    return (
      <div>
        <CommunicationChats job={job} />
      </div>
    );
  };

  return (
    <SimpleButtonWithWindow
      icon={faComments}
      windowChildren={renderWindowChildren}
      inButtonToolbar
      openMode="click"
    >
      Chats
    </SimpleButtonWithWindow>
  );
};

export default ClientJobChatWindow;
