import styled from 'styled-components';
import { getColorForFlagType } from 'lib/utils';
import { ButtonStyledAttrProps, ButtonStyledProps } from './types';

export const ButtonStyled = styled.button.attrs<ButtonStyledAttrProps>(
  ({ theme, flagType }) => getColorForFlagType(theme, flagType)
)<ButtonStyledProps>`
  border-radius: 0.25rem;
  padding: 0.25rem 0.75rem;
  border-width: 2px;
  border-style: solid;
  border-color: ${({ active }) => (active ? '#106c12' : 'rgba(0, 0, 0, 0.05)')};
  background-color: ${({ theme }) => theme.color.buttonBackground.hex()};
  color: ${({ theme }) => theme.color.buttonText.hex()};
  transition: 150ms border-color;
  height: 38px;

  span.inner {
    margin-top: 2px;
    display: inline-block;
    height: 24px;
  }

  .icon {
    margin-right: 0.5rem;
    color: ${({ theme, color = '' }) =>
      // TODO: This is a bit hacky. Look into this,
      color !== 'black' ? color : theme.color.buttonText.hex()};
  }

  &:hover {
    transition: 50ms border-color;
    border-color: ${({ theme, active }) =>
      active ? '#106c12' : theme.color.buttonHover.hex()};
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    color: ${({ theme }) => theme.color.buttonText.alpha(0.5).hex()};
    cursor: not-allowed;

    .icon {
      color: ${({ theme }) => theme.color.buttonText.alpha(0.5).hex()};
    }

    &:hover {
      border-color: ${({ active }) =>
        active ? '#106c12' : 'rgba(0, 0, 0, 0.05)'};
    }
  }
`;
