import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { ValueType } from 'react-select';
import { DateTime } from 'luxon';
import Select from '../Select';
import { Small } from '../Typography';
import { TimeOption } from './types';

const formatHour = (hour: number) => (hour < 10 ? `0${hour}` : hour.toString());

const formatMinute = (minute: number) =>
  minute < 10 ? `0${minute}` : minute.toString();

const hourOptions = Array(24)
  .fill(null)
  .map((_, index) => ({
    value: index,
    label: formatHour(index),
  }));

const minuteOptions = Array(12)
  .fill(null)
  .map((_, index) => {
    const minute = index * 5;
    return {
      value: minute,
      label: formatMinute(minute),
    };
  });

const hourCustomStyles = {
  control: {
    borderRadius: '0.25rem 0.1rem 0.1rem 0.25rem',
  },
};

const minuteCustomStyles = {
  control: {
    borderRadius: '0.1rem 0.25rem 0.25rem 0.1rem',
  },
};
const TimeField = ({ input, label }: FieldRenderProps<string>) => {
  const handleHourOnChange = (option: ValueType<TimeOption, false>) => {
    if (!option) return;
    input.onChange(
      DateTime.fromISO(input.value).set({ hour: option.value }).toISO()
    );
  };

  const handleMinuteOnChange = (option: ValueType<TimeOption, false>) => {
    if (!option) return;
    input.onChange(
      DateTime.fromISO(input.value).set({ minute: option.value }).toISO()
    );
  };

  return (
    <div>
      <div>
        <Small className="mb-1 ml-2">{label || input.name}</Small>
      </div>
      <div className="d-flex">
        <div className="flex-grow-1 mr-1">
          <Select<TimeOption>
            menuPortalTarget={document.body}
            options={hourOptions}
            placeholder="Hour"
            customStyles={hourCustomStyles}
            onChange={handleHourOnChange}
            value={{
              value: DateTime.fromISO(input.value).hour,
              label: formatHour(DateTime.fromISO(input.value).hour),
            }}
          />
        </div>
        <div className="flex-grow-1 ml-1">
          <Select<TimeOption>
            menuPortalTarget={document.body}
            options={minuteOptions}
            placeholder="Minute"
            customStyles={minuteCustomStyles}
            onChange={handleMinuteOnChange}
            value={{
              value: DateTime.fromISO(input.value).minute,
              label: formatMinute(DateTime.fromISO(input.value).minute),
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default TimeField;
