import React, { useEffect, useRef } from 'react';
import { Link } from 'wouter';
import { useToggle } from 'react-use';
import { LinkWithWindowProps } from './types';
import Window from '../Window';
import { LinkAnchor } from './styled';

const LinkWithWindow = ({
  windowChildren,
  windowProps,
  href,
  children,
  onOpened,
  onClosed,
  ...props
}: LinkWithWindowProps) => {
  const linkRef = useRef<HTMLAnchorElement>(null);
  const windowRef = useRef<HTMLDivElement>(null);
  const [isOpen, toggle] = useToggle(false);

  useEffect(() => {
    const linkElement = linkRef.current;
    const windowElement = windowRef.current;
    if (!linkElement || !windowElement) return;
    const linkRect = linkElement.getBoundingClientRect();
    if (isOpen) {
      windowElement.style.opacity = '1';
      windowElement.style.top = `${linkRect.bottom}px`;
      const left = Math.min(
        linkRect.left,
        window.innerWidth - windowElement.offsetWidth
      );
      windowElement.style.left = `${left}px`;
    } else {
      windowElement.removeAttribute('style');
    }
  });

  const handleOnMouseEnter = () => {
    toggle(true);
    if (onOpened) onOpened();
  };

  const handleOnMouseLeave = () => {
    toggle(false);
    if (onClosed) onClosed();
  };

  const handleClose = () => {
    toggle(false);
    if (onClosed) onClosed();
  };

  return (
    <>
      <Link {...props} href={href}>
        <LinkAnchor
          ref={linkRef}
          href={href}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        >
          {children}
        </LinkAnchor>
      </Link>
      {isOpen && (
        <Window
          {...windowProps}
          innerRef={windowRef}
          onMouseEnter={handleOnMouseEnter}
          onMouseLeave={handleOnMouseLeave}
        >
          {windowChildren({ close: handleClose })}
        </Window>
      )}
    </>
  );
};

export default LinkWithWindow;
