import React from 'react';
import classNames from 'classnames';
import { NestableItemProps } from './types';
import { NestableList } from '../Nestable/styled';
import { NestableItemWrapper, NestableItemHandler } from './styled';
import { Item } from '../../types';

const NestableItem = <T extends Item<T>>({
  index,
  item,
  options,
  depth = 0,
  isCopy,
}: NestableItemProps<T>) => {
  const { dragItem, renderItem, handler, renderCollapseIcon } = options;

  const isCollapsed = options.isCollapsed(item);
  const isDragging = !isCopy && dragItem && dragItem.id === item.id;
  const hasChildren = item.children && item.children.length > 0;

  let rowProps = {};
  let handlerProps = {};
  let wrappedHandler;

  if (!isCopy) {
    if (dragItem) {
      rowProps = {
        ...rowProps,
        onMouseEnter: (event: React.MouseEvent) =>
          options.onMouseEnter(event, item),
      };
    } else {
      handlerProps = {
        ...handlerProps,
        draggable: true,
        onDragStart: (event: React.DragEvent) =>
          options.onDragStart(event, item),
      };
    }
  }

  if (handler) {
    wrappedHandler = (
      <NestableItemHandler className="nestable-item-handler" {...handlerProps}>
        {handler}
      </NestableItemHandler>
    );
  } else {
    rowProps = {
      ...rowProps,
      ...handlerProps,
    };
  }

  const collapseIcon = hasChildren ? (
    <span onClick={() => options.onToggleCollapse(item, false)}>
      {renderCollapseIcon({ isCollapsed })}
    </span>
  ) : null;

  const baseClassName = `nestable-item${isCopy ? '-copy' : ''}`;
  const itemProps = {
    className: classNames(baseClassName, `${baseClassName}-${item.id}`, {
      'is-dragging': isDragging,
      [`${baseClassName}--with-children`]: hasChildren,
      [`${baseClassName}--children-open`]: hasChildren && !isCollapsed,
      [`${baseClassName}--children-collapsed`]: hasChildren && isCollapsed,
    }),
  };

  const content = renderItem({
    collapseIcon,
    isCollapsed,
    depth,
    handler: wrappedHandler,
    index,
    item,
  });

  if (!content) return null;

  return (
    <NestableItemWrapper {...itemProps}>
      <div className="nestable-item-name" {...rowProps}>
        {content}
      </div>

      {hasChildren && !isCollapsed && (
        <NestableList className="nestable-list">
          {item.children.map((childItem, index) => (
            <NestableItem
              key={index}
              index={index}
              depth={depth + 1}
              item={childItem}
              options={options}
              isCopy={isCopy}
            />
          ))}
        </NestableList>
      )}
    </NestableItemWrapper>
  );
};

export default NestableItem;
