import { useState } from 'react';
import { ApolloQueryResult } from '@apollo/client/core/types';
import useSortTable from './useSortTable';

type TableHeadNames = ReadonlyArray<string>;

type SortState<T extends Readonly<TableHeadNames>> = {
  activeName: T[number];
  columns: {
    [key in T[number]]: {
      active: boolean;
      order: boolean;
    };
  };
};

type Return<T extends Readonly<TableHeadNames>> = [
  SortState<T>,
  (name: T[number]) => void,
  { loading: boolean }
];

const useSortTableRefetch = <T extends Readonly<TableHeadNames>, TData = any>(
  tableHeadNames: T,
  refetch: (variables?: Partial<any>) => Promise<ApolloQueryResult<TData>>,
  {
    defaultActiveName,
    variables,
    onCompleted,
  }: {
    defaultActiveName: T[number];
    variables?: any;
    onCompleted: (response: ApolloQueryResult<TData>) => void;
  }
): Return<T> => {
  const [refetching, setRefetching] = useState(false);
  const [sortState, handleOnSort] = useSortTable(tableHeadNames, {
    defaultActiveName,
    onSort: (sort) => {
      setRefetching(true);
      refetch({ ...variables, page: 1, sort }).then((response) => {
        setRefetching(false);
        return onCompleted(response);
      });
    },
  });
  return [sortState, handleOnSort, { loading: refetching }];
};

export default useSortTableRefetch;
