import { gql } from '@apollo/client';

export const JOB_VISIT_QUERY = gql`
  query ($jobVisitId: ID!) {
    jobVisit(jobVisitId: $jobVisitId) {
      id
      label
      user {
        id
        name
        initials
      }
      dateTimeStart
      dateTimeEnd
      workTypes {
        id
        name
        nameDisplay
      }
      status {
        name
      }
      children {
        id
        label
        number
        dateTimeStart
        dateTimeEnd
        user {
          id
          name
          initials
        }
      }
      reports {
        id
        name
        number
        status
        statusDisplay
        template {
          id
          name
        }
        latestVersion {
          versionNumber
          pdfUrl
        }
      }
    }
  }
`;

export const DELETE_JOB_REPORT_MUTATION = gql`
  mutation ($jobReportId: ID!, $jobVisitId: ID!) {
    deleteJobReport(jobReportId: $jobReportId, jobVisitId: $jobVisitId) {
      isDeleted
      deletedId
      logBlock {
        id
        status
        type
        dateTime
        instanceContentType
        data
        meta
        context
        children {
          id
          status
          type
          dateTime
          instanceContentType
          data
          meta
          context
        }
      }
    }
  }
`;
