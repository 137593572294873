import React, {
  Reducer,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { faAbacus } from '@fortawesome/pro-duotone-svg-icons/faAbacus';
import { faCalendarAlt } from '@fortawesome/pro-duotone-svg-icons/faCalendarAlt';
import { faCheckSquare } from '@fortawesome/pro-duotone-svg-icons/faCheckSquare';
import { faText } from '@fortawesome/pro-duotone-svg-icons/faText';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
import { faCodeMerge } from '@fortawesome/pro-light-svg-icons/faCodeMerge';
import { faGripVertical } from '@fortawesome/pro-solid-svg-icons/faGripVertical';
import classNames from 'classnames';
import { Nestable } from 'lib/nestable';
import {
  NestedReportTemplateBlock,
  ReportTemplate,
  ReportTemplateBlock,
  ReportTemplateRootBlock,
  ReportTemplateState,
  ValueType,
} from 'lib/types';
import { Col, Row } from 'reactstrap';
import { AnyAction } from 'redux';
import { Descendant } from 'slate';
import { RouteComponentProps } from 'wouter';
import { useDebounceCallback } from 'lib/hooks';
import SimpleButton from '../SimpleButton';
import SimpleButtonWithWindow from '../SimpleButtonWithWindow';
import SlateEditor from '../SlateEditor';
import SlateViewer from '../SlateViewer';
import {
  REPORT_TEMPLATE_QUERY,
  UPDATE_REPORT_TEMPLATE_BLOCK,
  UPDATE_REPORT_TEMPLATE_MUTATION,
  UPDATE_REPORT_TEMPLATE_STATE_COLLAPSED_GROUPS_MUTATION,
  UPDATE_REPORT_TEMPLATE_STATE_LOGIC_MUTATION,
} from './query';
import reducer, {
  addBlock,
  addLogicToBlock,
  initialQueryOnCompleted,
  initialState,
  nestedLayoutOnChange,
  onSelect,
  ReducerContext,
  reportTemplateBlockOnChange,
  reportTemplateStateOnChange,
  setSelectedRect,
  useReducerContext,
} from './reducer';
import {
  TemplateEditorHeader,
  TemplateBlockControlsWrapper,
  TemplateBlockLogicWrapper,
  TemplateBlockWrapper,
  ControlsWrapper,
  TemplateEditorFooter,
} from './styled';
import { InitialQueryData, InitialQueryVariables, State } from './types';
import { generateId, generateLabel } from './utils';
import ClientSettingsReportTemplatePreview from '../ClientSettingsReportTemplatePreview';

const Context = React.createContext<{
  templateBlocks: ReportTemplateBlock[];
  templateState: ReportTemplateState;
  focusedTemplateBlock?: NestedReportTemplateBlock;
  focusedTemplateBlockRef: null | React.RefObject<HTMLDivElement>;
  focusTemplateBlock: (templateBlock?: NestedReportTemplateBlock) => void;
  updateTemplateBlock: (
    id: ReportTemplateBlock['id'],
    diff: Partial<ReportTemplateBlock>
  ) => void;
  updateReportTemplateState: (
    id: ReportTemplateBlock['id'],
    value: any
  ) => void;
}>({
  templateBlocks: [],
  templateState: {
    id: '',
    value: {},
    valueDefaults: {},
    collapsedGroups: [],
  },
  focusedTemplateBlockRef: null,
  focusTemplateBlock: () => {},
  updateTemplateBlock: () => {},
  updateReportTemplateState: () => {},
});

const TemplateBlockValueTypeEditor = ({
  item,
}: {
  item: NestedReportTemplateBlock;
}) => {
  const [, dispatch] = useReducerContext();

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { value: valueType } = event.currentTarget;
    dispatch(
      reportTemplateBlockOnChange(item.id, {
        valueType: valueType as ValueType,
        logic: [],
        children: [],
      })
    );
  };

  const renderWindowChildren = () => {
    return (
      <div>
        <div>
          <SimpleButton
            className="d-block w-100 text-left rounded-0"
            value="CHAR"
            icon={faText}
            onClick={handleOnClick}
          >
            Text
          </SimpleButton>
          <SimpleButton
            className="d-block w-100 text-left rounded-0"
            value="NUMBER"
            icon={faAbacus}
            onClick={handleOnClick}
          >
            Number
          </SimpleButton>
          <SimpleButton
            className="d-block w-100 text-left rounded-0"
            value="BOOLEAN"
            icon={faCheckSquare}
            onClick={handleOnClick}
          >
            Checkbox
          </SimpleButton>
          <SimpleButton
            className="d-block w-100 text-left rounded-0"
            value="DATETIME"
            icon={faCalendarAlt}
            onClick={handleOnClick}
          >
            Date & Time
          </SimpleButton>
        </div>
      </div>
    );
  };

  const getValueTypeLabel = (valueType: ValueType) => {
    switch (valueType) {
      case 'CONTAINER':
        return 'Section';
      case 'CHAR':
        return 'Text';
      case 'NUMBER':
        return 'Number';
      case 'BOOL':
        return 'Checkbox';
      case 'DATETIME':
        return 'Date & Time';
      default:
        return '';
    }
  };

  return (
    <div>
      <SimpleButtonWithWindow
        className="text-capitalize"
        openMode="click"
        windowChildren={renderWindowChildren}
      >
        {getValueTypeLabel(item.valueType)}
      </SimpleButtonWithWindow>
    </div>
  );
};

const TemplateBlockControls = ({
  item,
}: {
  item: NestedReportTemplateBlock;
}) => {
  const [, dispatch] = useReducerContext();

  const handleToggleRepeatSection = () => {};

  const handleAddLogicOnClick = () => {
    const newLogic: Pick<
      ReportTemplateBlock,
      'id' | 'logicConditionType' | 'children' | 'logic' | 'logicActionType'
    > = {
      id: generateId(),
      logicConditionType: 'IS_NOT_NULL',
      logicActionType: 'ASK_QUESTIONS',
      children: [],
      logic: [] as never[],
    };
    dispatch(addLogicToBlock(item, newLogic));
  };

  return (
    <TemplateBlockControlsWrapper>
      {item.valueType === 'CONTAINER' && (
        <SimpleButton icon={faCodeMerge} onClick={handleToggleRepeatSection}>
          Repeat section
        </SimpleButton>
      )}
      {item.valueType !== 'CONTAINER' && (
        <SimpleButton icon={faCodeMerge} onClick={handleAddLogicOnClick}>
          Add logic
        </SimpleButton>
      )}
    </TemplateBlockControlsWrapper>
  );
};

const getLogicText = (logic: ReportTemplateBlock) => {
  switch (logic.logicConditionType) {
    case 'IS_NOT_NULL':
      return 'If answer is not empty then';
    default:
      return logic.logicConditionValue;
  }
};

const TemplateBlockLogic = ({ item }: { item: NestedReportTemplateBlock }) => {
  const context = useContext(Context);

  const value =
    context.templateState.value[item.id] ||
    context.templateState.valueDefaults[item.id];

  return (
    <TemplateBlockLogicWrapper>
      {item.logic.map((logic, index) => {
        const text = getLogicText(logic);
        const last = index === item.logic.length - 1;
        return (
          <SimpleButton
            key={logic.id}
            className={classNames({ 'mr-2': !last })}
            active={value === logic.id}
            onClick={() => context.updateReportTemplateState(item.id, logic.id)}
          >
            {text}
          </SimpleButton>
        );
      })}
    </TemplateBlockLogicWrapper>
  );
};

const TemplateBlock = ({
  item,
  handler,
  collapseIcon,
  isCollapsed,
}: {
  item: NestedReportTemplateBlock;
  handler: React.ReactNode;
  collapseIcon: React.ReactNode;
  isCollapsed: boolean;
}) => {
  const [, dispatch] = useReducerContext();
  const context = useContext(Context);

  const ref = useRef<HTMLDivElement>(null);

  const value = context.templateBlocks.find((block) => block.id === item.id);

  const handleLabelOnChange = (newValue: Descendant[]) => {
    context.updateTemplateBlock(item.id, { label: newValue });
  };

  const handleOnFocus = () => {
    context.focusTemplateBlock(item);
  };

  const handleOnMouseEnter = () => {
    if (!ref.current) return;
    const rect = ref.current.getBoundingClientRect();
    dispatch(
      setSelectedRect({ top: rect.top, left: rect.left, bottom: rect.bottom })
    );
    dispatch(onSelect(item));
  };

  const [editing, setEditing] = useState(false);
  const handleOnDoubleClick = () => {
    setEditing(true);
  };

  const handleOnBlur = () => {
    setEditing(false);
  };

  const focused = context?.focusedTemplateBlock?.id === item.id;

  if (!value) return null;
  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
    <div tabIndex={0} onFocus={handleOnFocus}>
      <TemplateBlockWrapper
        id={`template-block-${item.id}`}
        ref={ref}
        className="d-flex"
        style={{
          userSelect: editing ? 'auto' : 'none',
          borderRadius:
            !focused && (isCollapsed || item.children.length === 0)
              ? 0
              : '0 0 0 0.25rem',
        }}
        onMouseEnter={handleOnMouseEnter}
        onDoubleClick={handleOnDoubleClick}
        onBlur={handleOnBlur}
      >
        <div className="decoration" />
        {item.children.length > 0 && <div>{collapseIcon}</div>}
        {(isCollapsed || item.children.length === 0) && <div>{handler}</div>}
        <div className="flex-grow-1" style={{ marginTop: 6, marginLeft: 8 }}>
          {editing ? (
            <SlateEditor value={value.label} onChange={handleLabelOnChange} />
          ) : (
            <SlateViewer value={value.label} />
          )}
        </div>
        <div>
          <TemplateBlockValueTypeEditor item={item} />
        </div>
      </TemplateBlockWrapper>
      {!isCollapsed && focused && <TemplateBlockControls item={item} />}
      {!isCollapsed && item.logic.length > 0 && (
        <TemplateBlockLogic item={item} />
      )}
    </div>
  );
};

const ClientSettingsReportTemplateEditor = ({
  params,
}: RouteComponentProps<{ id: ReportTemplate['id'] }>) => {
  const [state, dispatch] = useReducer<Reducer<State, AnyAction>>(
    reducer,
    initialState
  );

  const { reportTemplate, reportTemplateState } = state;

  const wrapperRef = useRef<HTMLDivElement>(null);

  const [focusedTemplateBlock, setFocusedTemplateBlock] =
    useState<NestedReportTemplateBlock>();
  const focusedTemplateBlockRef = useRef<HTMLDivElement>(null);

  const [controlsTop, setControlsTop] = useState<number>(0);

  useEffect(() => {
    if (!wrapperRef.current) return;
    if (!focusedTemplateBlockRef.current) return;
    setControlsTop(
      focusedTemplateBlockRef.current.getBoundingClientRect().top -
        wrapperRef.current.getBoundingClientRect().top
    );
  }, [focusedTemplateBlock]);

  useQuery<InitialQueryData, InitialQueryVariables>(REPORT_TEMPLATE_QUERY, {
    fetchPolicy: 'no-cache',
    variables: { reportTemplateId: params.id },
    onCompleted: (data) => {
      dispatch(initialQueryOnCompleted(data));
    },
  });

  const blocks = useMemo<ReportTemplateBlock[]>(() => {
    if (!reportTemplate) return [] as ReportTemplateBlock[];
    return (reportTemplate.root as ReportTemplateRootBlock).blocks.map(
      (block) => {
        if (block.logic.length > 0) {
          const value =
            reportTemplateState.value[block.id] ||
            reportTemplateState.valueDefaults[block.id];
          const logic = block.logic.find(
            (innerLogic) => innerLogic.id === value
          );
          if (logic) {
            return {
              ...block,
              children: logic.children,
            };
          }
        }
        return block;
      }
    );
  }, [
    reportTemplate,
    reportTemplateState.value,
    reportTemplateState.valueDefaults,
  ]);

  const getBlocks = useCallback(
    (blockIds: ReportTemplateBlock['id'][]) => {
      if (!reportTemplate) return [] as ReportTemplateBlock[];
      return blocks
        .filter((block) => blockIds.includes(block.id))
        .sort(
          (a, b) => blockIds.indexOf(a.id) - blockIds.indexOf(b.id)
        ) as ReportTemplateBlock[];
    },
    [blocks, reportTemplate]
  );

  const getBlock = useCallback(
    (blockId: ReportTemplateBlock['id']) => {
      if (!reportTemplate) return {} as ReportTemplateBlock;
      return blocks.find(
        (block) => blockId === block.id
      ) as ReportTemplateBlock;
    },
    [blocks, reportTemplate]
  );

  const nestChildren = useCallback(
    (item: ReportTemplateBlock): NestedReportTemplateBlock => {
      if (item.children.length > 0) {
        return {
          ...item,
          children: getBlocks(item.children).map(nestChildren),
        };
      }
      return {
        ...getBlock(item.id),
        children: [] as NestedReportTemplateBlock[],
      };
    },
    [getBlock, getBlocks]
  );

  const nestedItems = useMemo(() => {
    if (!reportTemplate) return [];
    return getBlocks(reportTemplate.root.children).map(nestChildren);
  }, [getBlocks, nestChildren, reportTemplate]);

  const focusTemplateBlock = (block?: NestedReportTemplateBlock) => {
    setFocusedTemplateBlock(block);
  };

  const [updateReportTemplateBlock] = useMutation(UPDATE_REPORT_TEMPLATE_BLOCK);
  const debounceCallback = useDebounceCallback(updateReportTemplateBlock);

  const updateTemplateBlock = (
    id: ReportTemplateBlock['id'],
    diff: Partial<ReportTemplateBlock>
  ) => {
    dispatch(reportTemplateBlockOnChange(id, diff));
    debounceCallback({ variables: { reportTemplateBlock: { id, ...diff } } });
  };

  const handleOnChange = async (event: {
    items: NestedReportTemplateBlock[];
    dragItem: NestedReportTemplateBlock;
    targetPath: number[];
  }) => {
    dispatch(nestedLayoutOnChange(event));
  };

  const [updateReportTemplate] = useMutation(UPDATE_REPORT_TEMPLATE_MUTATION);

  useEffect(() => {
    if (!reportTemplate) return;
    updateReportTemplate({
      variables: {
        reportTemplateData: JSON.parse(
          JSON.stringify(reportTemplate),
          (key, value) => (key === '__typename' ? undefined : value)
        ),
      },
    });
  }, [reportTemplate, updateReportTemplate]);

  const [updateReportTemplateStateLogic] = useMutation(
    UPDATE_REPORT_TEMPLATE_STATE_LOGIC_MUTATION
  );

  const updateReportTemplateState = (
    id: ReportTemplateBlock['id'],
    value: any
  ) => {
    dispatch(reportTemplateStateOnChange(id, value));
    updateReportTemplateStateLogic({
      variables: {
        reportTemplateStateId: reportTemplateState.id,
        reportTemplateBlockId: id,
        reportTemplateLogicId: value,
      },
    });
  };

  const handleOnConfirmChange = ({
    destinationParent,
  }: {
    destinationParent: NestedReportTemplateBlock | null;
  }) => {
    if (!destinationParent) return true;

    if (destinationParent.logic.length > 0) {
      const value =
        reportTemplateState.value[destinationParent.id] ||
        reportTemplateState.valueDefaults[destinationParent.id];
      const logic = destinationParent.logic.find(
        (innerLogic) => innerLogic.id === value
      );
      return !!logic && logic.logicActionType === 'ASK_QUESTIONS';
    }

    return destinationParent.valueType === 'CONTAINER';
  };

  const handleAddContainerOnClick = () => {
    if (!focusedTemplateBlock) return;
    const newBlock: Partial<ReportTemplateBlock> = {
      id: generateId(),
      label: generateLabel(),
      children: [],
      logic: [],
      parent: focusedTemplateBlock.parent,
      valueType: 'CONTAINER',
    };
    dispatch(addBlock(newBlock));
  };

  const handleAddQuestionOnClick = () => {
    if (!focusedTemplateBlock) return;
    const newBlock: Partial<ReportTemplateBlock> = {
      id: generateId(),
      label: generateLabel(),
      children: [],
      logic: [],
      parent: focusedTemplateBlock.parent,
      valueType: 'CHAR',
    };
    dispatch(addBlock(newBlock));
  };

  const [updateReportTemplateStateCollapsedGroups] = useMutation(
    UPDATE_REPORT_TEMPLATE_STATE_COLLAPSED_GROUPS_MUTATION
  );

  const handleToggleCollapseGroup = (item: NestedReportTemplateBlock) => {
    updateReportTemplateStateCollapsedGroups({
      variables: {
        reportTemplateStateId: reportTemplateState.id,
        reportTemplateBlockId: item.id,
      },
    });
  };

  const nestedRef = useRef<HTMLDivElement>(null);

  if (!reportTemplate) return null;
  return (
    <ReducerContext.Provider value={[state, dispatch]}>
      <div ref={wrapperRef}>
        <Row>
          <Col xs={6} className="position-relative offset-3 pb-5">
            <Context.Provider
              value={{
                templateBlocks: (reportTemplate.root as ReportTemplateRootBlock)
                  .blocks,
                templateState: reportTemplateState,
                focusedTemplateBlock,
                focusedTemplateBlockRef,
                focusTemplateBlock,
                updateTemplateBlock,
                updateReportTemplateState,
              }}
            >
              <ControlsWrapper style={{ top: controlsTop }}>
                <div>
                  <SimpleButton onClick={handleAddContainerOnClick}>
                    Add container
                  </SimpleButton>
                  <SimpleButton onClick={handleAddQuestionOnClick}>
                    Add question
                  </SimpleButton>
                </div>
              </ControlsWrapper>
              <TemplateEditorHeader className="d-flex justify-content-between">
                <div>Question</div>
                <div>Response type</div>
              </TemplateEditorHeader>
              <div ref={nestedRef} className="position-relative">
                <Nestable<NestedReportTemplateBlock>
                  items={nestedItems}
                  renderItem={(props: any) => (
                    <TemplateBlock key={props.item.id} {...props} />
                  )}
                  defaultCollapsedGroups={reportTemplateState.collapsedGroups}
                  onToggleCollapseGroup={handleToggleCollapseGroup}
                  onChange={handleOnChange}
                  confirmChange={handleOnConfirmChange}
                  renderCollapseIcon={({ isCollapsed }) => (
                    <SimpleButton
                      style={{ width: 34, borderRadius: '50%' }}
                      className="text-center"
                      icon={faChevronRight}
                      iconProps={{
                        fixedWidth: false,
                        rotation: isCollapsed ? undefined : 90,
                        style: { marginLeft: 2 },
                      }}
                    />
                  )}
                  handler={
                    <SimpleButton
                      style={{ width: 34, borderRadius: '50%', cursor: 'grab' }}
                      className="text-center"
                      icon={faGripVertical}
                      iconProps={{
                        fixedWidth: false,
                        style: { marginLeft: 2 },
                      }}
                    />
                  }
                />
              </div>
              <TemplateEditorFooter>Add page</TemplateEditorFooter>
            </Context.Provider>
          </Col>
          <Col xs={3}>
            <ClientSettingsReportTemplatePreview />
          </Col>
        </Row>
      </div>
    </ReducerContext.Provider>
  );
};

export default ClientSettingsReportTemplateEditor;
