import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useClient } from 'lib/hooks';
import { JobVisitWorkType, Option } from 'lib/types';
import { FieldRenderProps } from 'react-final-form';
import { ValueType } from 'react-select';
import Select from '../Select';
import { JOB_VISIT_WORK_TYPE_QUERY } from './query';

const JobVisitWorkTypeField = ({
  input,
}: FieldRenderProps<JobVisitWorkType[]>) => {
  const client = useClient();

  const [jobVisitWorkTypes, setJobVisitWorkTypes] = useState<
    JobVisitWorkType[]
  >([]);

  const { loading } = useQuery(JOB_VISIT_WORK_TYPE_QUERY, {
    fetchPolicy: 'no-cache',
    variables: { clientId: client.id },
    onCompleted: (data) => {
      setJobVisitWorkTypes(data.jobVisitWorkTypes);
    },
  });

  const handleOnChange = (selected: ValueType<Option, true> | null) => {
    if (!selected) return;
    input.onChange(
      selected.map((selection) =>
        jobVisitWorkTypes.find((workType) => workType.id === selection.value)
      )
    );
  };

  return (
    <div>
      <Select<any, true>
        menuPortalTarget={document.body}
        isMulti
        isLoading={loading}
        options={jobVisitWorkTypes.map((jobVisitWorkType) => ({
          value: jobVisitWorkType.id,
          label: jobVisitWorkType.nameDisplay,
        }))}
        value={(input.value || []).map((jobVisitWorkType) => ({
          value: jobVisitWorkType.id,
          label: jobVisitWorkType.nameDisplay,
        }))}
        onChange={handleOnChange}
      />
    </div>
  );
};

export default JobVisitWorkTypeField;
