// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import runtimeEnv from '@mars/heroku-js-runtime-env';

const getUri = () => {
  const env = runtimeEnv();
  return env.REACT_APP_API_HOST as string;
};

export default getUri;
