import React, { useState } from 'react';
import { Form, Field, FieldRenderProps } from 'react-final-form';
import { useQuery } from '@apollo/client';
import Switch from 'react-switch';
import { useDispatch } from 'react-redux';
import { User } from 'lib/types';
import CardWrapper from '../CardWrapper';
import { SETTINGS_QUERY } from './query';

const ThemeModeField = ({ input }: FieldRenderProps<'LIGHT' | 'DARK'>) => {
  const handleOnChange = (value: boolean) => {
    input.onChange(value ? 'LIGHT' : 'DARK');
  };

  return (
    <div>
      <Switch checked={input.value === 'LIGHT'} onChange={handleOnChange} />
    </div>
  );
};

const Settings = () => {
  const [canMount, setCanMount] = useState(false);
  const [user, setUser] = useState<null | User>(null);

  useQuery(SETTINGS_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setUser(data.user);
      setCanMount(true);
    },
  });

  const dispatch = useDispatch();

  const handleThemeModeOnChange = (value: 'LIGHT' | 'DARK') => {
    dispatch({
      type: 'SET_THEME_MODE',
      payload: value,
    });
  };

  return canMount ? (
    <div className="m-4">
      <CardWrapper>
        <Form
          initialValues={{
            themeMode: user?.themeMode,
          }}
          onSubmit={() => {}}
        >
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Field
                name="themeMode"
                component={ThemeModeField}
                parse={(value) => {
                  handleThemeModeOnChange(value);
                  return value;
                }}
              />
            </form>
          )}
        </Form>
      </CardWrapper>
    </div>
  ) : null;
};

export default Settings;
