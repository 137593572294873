import { useState } from 'react';

type TableHeadNames = ReadonlyArray<string>;

export type OnSort<T extends Readonly<TableHeadNames>> = ({
  name,
  order,
}: {
  name: T[number];
  order: boolean;
}) => void;

type Options<T extends Readonly<TableHeadNames>> = {
  defaultActiveName: T[number];
  onSort: OnSort<T>;
};

export type SortState<T extends Readonly<TableHeadNames>> = {
  activeName: T[number];
  columns: {
    [key in T[number]]: {
      active: boolean;
      order: boolean;
    };
  };
};

const useSortTable = <T extends Readonly<TableHeadNames>>(
  tableHeadNames: T,
  options: Options<T>
): [SortState<T>, (name: T[number]) => void] => {
  const { defaultActiveName, onSort } = options;
  const defaultActiveHeadState = { active: true, order: false };
  const defaultInactiveHeadState = { active: false, order: false };
  const initialSortState = {
    activeName: defaultActiveName,
    columns: tableHeadNames.reduce(
      (obj, tableHeadName: T[number]) => ({
        ...obj,
        [tableHeadName]:
          tableHeadName === defaultActiveName
            ? defaultActiveHeadState
            : defaultInactiveHeadState,
      }),
      {} as SortState<T>['columns']
    ),
  };
  const [sortState, setSortState] = useState<SortState<T>>(initialSortState);
  const sort = (name: T[number]) => {
    setSortState((prevSortState) => {
      const order =
        name === sortState.activeName
          ? !sortState.columns[name].order
          : sortState.columns[name].order;
      onSort({ name, order });
      return {
        ...prevSortState,
        activeName: name,
        columns: {
          ...prevSortState.columns,
          [prevSortState.activeName as T[number]]: {
            ...prevSortState.columns[prevSortState.activeName],
            active: false,
          },
          [name as T[number]]: { active: true, order },
        },
      };
    });
  };
  return [sortState, sort];
};

export default useSortTable;
