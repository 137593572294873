import { gql } from '@apollo/client';

export const JOB_GROUPS_QUERY = gql`
  query ($clientId: ID!, $searchTerm: String, $page: Int, $sort: Sort) {
    jobGroups(
      clientId: $clientId
      jobType: "PPM"
      searchTerm: $searchTerm
      page: $page
      sort: $sort
    ) {
      results {
        id
        reference
        name
        jobCount
        previousJob {
          reference
          client {
            slug
          }
        }
        currentJob {
          reference
          client {
            slug
          }
        }
        nextJob {
          reference
          client {
            slug
          }
        }
      }
      count
    }
  }
`;
