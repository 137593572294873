import { useRoute } from 'wouter';

const useClientSlug = () => {
  const [, params] = useRoute('/clients/:slug/:rest*');
  if (!params?.slug) {
    throw Error('useClientSlug hook used incorrectly');
  }
  return params.slug;
};

export default useClientSlug;
