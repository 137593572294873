import React from 'react';
import { Link, useLocation, useRoute } from 'wouter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { useClient } from 'lib/hooks';
import { multiPathRouteMatcher } from 'lib/utils';
import ClientSearch from '../ClientSearch';
import { TabProps } from './types';
import { TabBarWrapper, TabBarInner, TabWrapper } from './styled';

const Tab = ({ name, tab, match }: TabProps) => {
  const [, params] = useRoute('/clients/:slug/:rest*');
  const [location] = useLocation();
  const to = `/clients/${params?.slug}${tab === '' ? '' : `/${tab}`}`;
  const [active] = multiPathRouteMatcher(match as string, location);
  return (
    <Link to={to}>
      <TabWrapper active={active}>
        <span>{name}</span>
      </TabWrapper>
    </Link>
  );
};

const ClientTabBar = ({
  breadcrumbRef,
}: {
  breadcrumbRef: React.RefObject<HTMLDivElement>;
}) => {
  const client = useClient();

  return (
    <TabBarWrapper className="pl-4">
      <div className="center-vertically h-100">
        <div className="d-flex">
          <div
            className="d-flex"
            style={{
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            <Link to={`/clients/${client?.slug}`}>
              <h4 className="text-75 client__tab-bar-breadcrumb-link">
                {client?.name}
              </h4>
            </Link>
            <FontAwesomeIcon
              className="mx-2"
              style={{ marginTop: 6 }}
              icon={faChevronRight}
              fixedWidth
            />
            <div ref={breadcrumbRef} id="client__tab-bar-breadcrumb" />
          </div>
          <div className="center-vertically flex-grow-1 mr-2">
            <div className="d-flex justify-content-end">
              <ClientSearch />
            </div>
          </div>
        </div>
      </div>
      <TabBarInner className="d-flex">
        <Tab name="Dashboard" tab="" match="/clients/:slug" />
        <Tab
          name="Jobs"
          tab="jobs"
          match={[
            '/clients/:slug/jobs/:rest*',
            '/clients/:slug/job-groups/:rest*',
          ]}
        />
        <Tab
          name={
            <span>
              {client?.immediateActionCount > 0 ? (
                <span className="mono text-danger">
                  {client?.immediateActionCount}
                </span>
              ) : (
                <span className="mono text-primary">0</span>
              )}{' '}
              Immediate Actions
            </span>
          }
          tab="immediate-actions"
          match="/clients/:slug/immediate-actions/:rest*"
        />
        <Tab name="Sites" tab="sites" match="/clients/:slug/sites/:rest*" />
        <Tab name="People" tab="people" match="/clients/:slug/people/:rest*" />
        <Tab
          name="Settings"
          tab="settings"
          match="/clients/:slug/settings/:rest*"
        />
      </TabBarInner>
    </TabBarWrapper>
  );
};

export default ClientTabBar;
