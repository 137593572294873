import React from 'react';
import { ClientJobsTableFilterDescriptionProps } from './types';

const ClientJobsAllTableFilterDescription = ({
  count,
  searchTerm,
}: ClientJobsTableFilterDescriptionProps) => {
  return (
    <span>
      Retrieved <span className="text-100">{count}</span> job groups
      {searchTerm && (
        <span>
          {' '}
          for search term &#34;
          <span className="text-100">{searchTerm}</span>&#34;
        </span>
      )}
      .
    </span>
  );
};

export default ClientJobsAllTableFilterDescription;
