import React, { Dispatch, useContext } from 'react';
import { AnyAction } from 'redux';
import { User } from 'lib/types';
import { addOrMerge } from 'lib/utils';
import { ClientPeopleState, InitialQueryData } from './types';
import {
  UserGroupQueryData,
  UserGroupsQueryData,
} from '../ClientPeopleGroupsTable/types';
import { AddUserMutationData } from '../ClientPeopleGroupUsersSidePanel/types';
import {
  RemoveUserMutationData,
  UsersQueryData,
} from '../ClientPeopleGroup/types';
import { UpdateUserGroupMutationData } from '../ClientPeopleGroupSidePanel/types';

export const initialState: ClientPeopleState = {
  userGroups: [],
  userGroup: null,
  selectedUsers: [],
};

export const ReducerContext = React.createContext<
  [ClientPeopleState, Dispatch<AnyAction>]
>([initialState, () => {}]);

export const useReducerContext = () => useContext(ReducerContext);

export const initialQueryOnCompleted = (data: InitialQueryData) => ({
  type: 'INITIAL_QUERY_ON_COMPLETED',
  userGroups: data.userGroups,
  userGroup: data.userGroup,
});

export const userGroupsQueryOnCompleted = (data: UserGroupsQueryData) => ({
  type: 'USER_GROUPS_QUERY_ON_COMPLETED',
  userGroups: data.userGroups,
});

export const userGroupQueryOnCompleted = (data: UserGroupQueryData) => ({
  type: 'USER_GROUP_QUERY_ON_COMPLETED',
  userGroup: data.userGroup,
});

export const usersQueryOnCompleted = (data: UsersQueryData) => ({
  type: 'USERS_QUERY_ON_COMPLETED',
  users: data.users,
});

export const selectUser = (id: User['id']) => ({
  type: 'SELECT_USER',
  id,
});

export const updateUserGroupMutationOnCompleted = (
  data: UpdateUserGroupMutationData
) => ({
  type: 'UPDATE_USER_GROUP_MUTATION',
  userGroup: data.updateUserGroup.userGroup,
});

export const usersToAddQueryOnCompleted = (data: UsersQueryData) => ({
  type: 'USERS_TO_ADD_QUERY_ON_COMPLETED',
  users: data.users,
});

export const addUserMutationOnCompleted = (data: AddUserMutationData) => ({
  type: 'ADD_USER_MUTATION_ON_COMPLETED',
  user: data.addUserToUserGroup.user,
});

export const removeUserMutationOnCompleted = (
  data: RemoveUserMutationData
) => ({
  type: 'REMOVE_USER_MUTATION_ON_COMPLETED',
  user: data.removeUserFromUserGroup.user,
});

const reducer = (state: ClientPeopleState, action: AnyAction) => {
  switch (action.type) {
    case 'INITIAL_QUERY_ON_COMPLETED':
      return {
        ...state,
        userGroups: action.userGroups,
        userGroup: action.userGroup,
      };
    case 'USER_GROUPS_QUERY_ON_COMPLETED':
      return {
        ...state,
        userGroups: action.userGroups,
      };
    case 'USER_GROUP_QUERY_ON_COMPLETED':
      return {
        ...state,
        userGroup: action.userGroup,
        selectedUsers: [],
      };
    case 'USERS_QUERY_ON_COMPLETED':
      if (!state.userGroup) return state;
      return {
        ...state,
        userGroup: {
          ...state.userGroup,
          users: action.users,
        },
        selectedUsers: [],
      };
    case 'SELECT_USER':
      return {
        ...state,
        selectedUsers: state.selectedUsers.includes(action.id)
          ? state.selectedUsers.filter((id) => id !== action.id)
          : [...state.selectedUsers, action.id],
      };
    case 'UPDATE_USER_GROUP_MUTATION':
      return {
        ...state,
        userGroups: addOrMerge(state.userGroups, action.userGroup),
        userGroup: {
          ...state.userGroup,
          ...action.userGroup,
        },
      };
    case 'ADD_USER_MUTATION_ON_COMPLETED':
      if (!state.userGroup) return state;
      return {
        ...state,
        userGroup: {
          ...state.userGroup,
          users: [...state.userGroup.users, action.user].sort((a, b) =>
            a.name > b.name ? 1 : -1
          ),
        },
      };
    case 'REMOVE_USER_MUTATION_ON_COMPLETED':
      if (!state.userGroup) return state;
      return {
        ...state,
        userGroup: {
          ...state.userGroup,
          users: state.userGroup.users.filter(
            (user) => user.id !== action.user.id
          ),
        },
      };
    default:
      return state;
  }
};

export default reducer;
