import React from 'react';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/pro-duotone-svg-icons/faUpload';
import { DropzoneWrapper, DropzoneInner } from './styled';
import { DropzoneProps } from './types';

const Dropzone = ({ label, onDrop }: DropzoneProps) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  return (
    <DropzoneWrapper
      className="center"
      {...getRootProps()}
      isDragActive={isDragActive}
    >
      <input {...getInputProps()} />
      <DropzoneInner>
        <FontAwesomeIcon className="mr-1" icon={faUpload} fixedWidth />
        {label}
      </DropzoneInner>
    </DropzoneWrapper>
  );
};

export default Dropzone;
