import React from 'react';
import { getColorForFlagType } from 'lib/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag as faFlagDuotone } from '@fortawesome/pro-duotone-svg-icons/faFlag';
import { useTheme } from 'styled-components';
import { ClientJobsTableFilterDescriptionProps } from './types';

const ClientJobsAllTableFilterDescription = ({
  count,
  searchTerm,
  activeFlagName,
  activeStatusName,
}: ClientJobsTableFilterDescriptionProps) => {
  const theme = useTheme();

  return (
    <span>
      Retrieved <span className="text-100 mono">{count}</span>
      {activeFlagName && (
        <>
          {activeFlagName !== 'all' && (
            <>
              <span
                className="text-capitalize"
                style={{
                  color: getColorForFlagType(theme, activeFlagName).color,
                }}
              >
                {' '}
                <small>
                  <FontAwesomeIcon
                    className="mr-1"
                    icon={faFlagDuotone}
                    fixedWidth
                  />
                </small>
                {activeFlagName.toLowerCase()}
              </span>
              <span> flag</span>
            </>
          )}
          <span> job{count === 1 ? '' : 's'}</span>
        </>
      )}
      {activeStatusName && (
        <span>
          {' '}
          of status <span className="text-100">{activeStatusName}</span>
        </span>
      )}
      {searchTerm && (
        <span>
          {' '}
          for search term &#34;
          <span className="text-100">{searchTerm}</span>&#34;
        </span>
      )}
      .
    </span>
  );
};

export default ClientJobsAllTableFilterDescription;
