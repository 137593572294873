import React from 'react';
import { Row, Col } from 'reactstrap';
import { useQuery } from '@apollo/client';
import { JobReport } from 'lib/types';
import { useTheme } from 'styled-components';
import classNames from 'classnames';
import { Link } from 'wouter';
import { useClientSlug } from 'lib/hooks';
import CardWrapper from '../CardWrapper';
import {
  jobReportsQueryOnCompleted,
  useReducerContext,
} from '../ClientJob/reducer';
import JobReportPDFPreview from '../JobReportPDFPreview';
import { REPORTS_QUERY } from './query';
import Select from '../Select';
import SearchBar from '../SearchBar';
import { ClientJobReportCardHeader } from './styled';
import ClientJobReportStatusPill from '../ClientJobReportStatusPill';
import JobVisitCard from '../JobVisitCard';
import { Small } from '../Typography';

const ClientJobReportCard = ({
  jobReport,
  jobReport: { number, status, template, createdForJobVisit, jobVisits },
}: {
  jobReport: JobReport;
}) => {
  const theme = useTheme();
  const clientSlug = useClientSlug();
  const [{ job }] = useReducerContext();

  if (!job) return null;
  return (
    <CardWrapper className="p-0 mb-4">
      <ClientJobReportCardHeader>
        <div className="d-flex">
          <div style={{ backgroundColor: theme.color.contentBackground.hex() }}>
            <span className="mono text-50 mx-2" style={{ lineHeight: '32px' }}>
              #{number}
            </span>
          </div>
          <ClientJobReportStatusPill className="rounded-0" status={status} />
          <Link
            to={`/clients/${clientSlug}/jobs/${job.reference}/reports/${number}`}
          >
            <a
              href={`/clients/${clientSlug}/jobs/${job.reference}/reports/${number}`}
            >
              <p
                className={classNames('ml-2', {
                  'font-weight-bold': status === 'STARTED',
                })}
              >
                {template?.name}{' '}
              </p>
            </a>
          </Link>
        </div>
      </ClientJobReportCardHeader>
      <div className="m-3 d-flex">
        <div className="mr-4">
          <JobReportPDFPreview
            jobReport={jobReport}
            width={100}
            includeNumber={false}
          />
        </div>
        <div>
          <Small>Parent visit</Small>
          {createdForJobVisit && (
            <JobVisitCard className="p-0" jobVisit={createdForJobVisit} />
          )}
          {(jobVisits ?? []).map((jobVisit) => (
            <JobVisitCard jobVisit={jobVisit} />
          ))}
        </div>
      </div>
    </CardWrapper>
  );
};

const ClientJobReports = () => {
  const [{ job }, dispatch] = useReducerContext();

  useQuery(REPORTS_QUERY, {
    fetchPolicy: 'no-cache',
    variables: { jobId: job?.id },
    onCompleted: (data) => {
      dispatch(jobReportsQueryOnCompleted(data));
    },
  });

  const reports = job?.reports || [];

  return (
    <Row className="h-100">
      <Col xl={3}>
        <div className="position-sticky" style={{ top: 0, maxHeight: '100%' }}>
          <CardWrapper className="h-100 p-0">
            <div className="m-3">
              <h5 className="mb-0">Reports</h5>
              <small className="text-75">
                When an operative completes a report it will appear here.
              </small>
            </div>
          </CardWrapper>
        </div>
      </Col>
      <Col xl={6}>
        <CardWrapper className="p-0 mb-3">
          <div className="d-flex">
            <div className="d-flex w-100">
              <div style={{ width: 200, marginRight: 1 }}>
                <Select
                  placeholder="Status"
                  defaultValue={{ label: 'Started', value: 'STARTED' }}
                  isSearchable={false}
                  isClearable
                  options={[
                    { label: 'Scheduled', value: 'SCHEDULED' },
                    { label: 'Started', value: 'STARTED' },
                    { label: 'Completed', value: 'COMPLETED' },
                  ]}
                  menuPortalTarget={document.body}
                />
              </div>
              <SearchBar
                wrapperClassName="flex-grow-1"
                wrapperStyle={{ marginRight: 1 }}
                placeholder="Search reports..."
              />
            </div>
          </div>
        </CardWrapper>
        <div>
          {reports.length > 0 ? (
            reports.map((jobReport) => (
              <ClientJobReportCard key={jobReport.id} jobReport={jobReport} />
            ))
          ) : (
            <div>No reports</div>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default ClientJobReports;
