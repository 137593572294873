import { gql } from '@apollo/client';

export const INITIAL_QUERY = gql`
  query ($before: ID) {
    notifications(before: $before) {
      results {
        id
        dateTime
        logBlock {
          id
          status
          type
          dateTime
          instanceContentType
          data
          meta
          context
          user {
            id
            name
          }
          integration
        }
        type
        read
        color
        icon
        link
      }
    }
  }
`;

export const MARK_ALL_NOTIFICATIONS_AS_READ_MUTATION = gql`
  mutation {
    markAllNotificationsAsRead {
      result
      notificationCount
    }
  }
`;

export const MARK_NOTIFICATION_AS_READ_MUTATION = gql`
  mutation ($notificationId: ID!) {
    markNotificationAsRead(notificationId: $notificationId) {
      result
      notificationId
      notificationCount
    }
  }
`;
