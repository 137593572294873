import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const SearchWrapper = styled.div`
  position: relative;
  width: 350px;
  //min-width: 150px;
`;

export const SearchInput = styled.input`
  width: 100%;
  border: none;
  height: 34px;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.color.contentBackground.hex()};
  color: ${({ theme }) => theme.color.cardText.hex()};
  padding: 0 1rem 0 2rem;
`;

export const SearchIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 9px;
  color: ${({ theme }) => theme.color.cardText.alpha(0.5).hex()};
`;
