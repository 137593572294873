import styled from 'styled-components';

const ScrollDiv = styled.div`
  /* width */
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.color.scrollBarTrack.hex()};
    border-radius: 1px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.color.scrollBarThumb.hex()};
    border-radius: 1px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.color.scrollBarThumbHover.hex()};
  }
`;

export default ScrollDiv;
