import React from 'react';
import BaseSelect, {
  Theme,
  StylesConfig,
  NamedProps,
  ControlProps,
} from 'react-select';
import { Option } from 'lib/types';
import { ThemeState } from 'lib/redux/types';
import { useTheme } from 'styled-components';
import chroma from 'chroma-js';

const getBorderColor = (
  state: ControlProps<any, any>,
  active: boolean,
  hasError: boolean
) => {
  if (hasError) {
    return 'rgba(255, 0, 0, 0.5)';
  }
  if (state.isFocused || active) {
    return state.theme.colors.primary;
  }
  return 'rgba(0, 0, 0, 0.05)';
};

const styles = <T, IsMulti extends boolean>({
  active,
  height,
  hasError,
  customStyles,
}: {
  active: boolean;
  height?: number;
  hasError: boolean;
  customStyles?: StylesConfig<T, IsMulti>;
}): StylesConfig<T, IsMulti> => ({
  control: (provided, state) => ({
    ...provided,
    height,
    minHeight: state.isMulti ? (height ?? 0) * 1.25 : height,
    borderRadius: '0.25rem',
    paddingLeft: state.isMulti ? '0' : '0.5rem',
    borderWidth: state.isFocused ? 2 : 2,
    // eslint-disable-next-line no-nested-ternary
    borderColor: getBorderColor(state, active, hasError),
    backgroundColor: state.theme.colors.background,
    boxShadow: 'none',
    ':hover': {
      borderColor: state.theme.colors.hover,
    },
    ...customStyles?.control,
  }),
  input: (provided, state) => ({
    ...provided,
    color: state.theme.colors.text,
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    backgroundColor: state.theme.colors.separator,
    ...customStyles?.indicatorSeparator,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.theme.colors.text,
    ...customStyles?.singleValue,
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: state.theme.colors.separator,
    ...customStyles?.multiValue,
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: state.theme.colors.text,
    ...customStyles?.multiValue,
  }),
  menu: (provided, state) => ({
    ...provided,
    borderRadius: '0.25rem',
    backgroundColor: state.theme.colors.background,
    overflow: 'hidden',
    ...customStyles?.menu,
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9001,
    ...customStyles?.menuPortal,
  }),
  option: (provided, state) => ({
    ...provided,
    ':hover': {
      backgroundColor: chroma(state.theme.colors.background).darken(0.5).hex(),
    },
    backgroundColor: state.isFocused
      ? chroma(state.theme.colors.background).darken(0.5).hex()
      : state.theme.colors.background,
    color: state.theme.colors.text,
    ...customStyles?.option,
  }),
});

const getTheme =
  (theme: ThemeState, height: number) => (defaultTheme: Theme) => ({
    ...defaultTheme,
    controlHeight: height,
    zIndex: 9001,
    colors: {
      ...defaultTheme.colors,
      primary: theme.color.primary.hex(),
      text: theme.color.cardText.hex(),
      background: theme.color.inputBackground.hex(),
      hover: theme.color.buttonHover.hex(),
      separator: theme.color.cardBackground.brighten(0.25).hex(),
    },
  });

const Select = <T extends Option, IsMulti extends boolean = false>({
  active = false,
  customStyles = {},
  height = 38,
  hasError = false,
  ...props
}: NamedProps<T, IsMulti> & {
  active?: boolean;
  customStyles?: any;
  height?: number;
  hasError?: boolean;
}) => {
  const theme = useTheme();
  return (
    <BaseSelect
      styles={styles<T, IsMulti>({ active, customStyles, height, hasError })}
      theme={getTheme(theme, height)}
      {...props}
    />
  );
};

export default Select;
