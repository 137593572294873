import React from 'react';
import { Link } from 'wouter';
import { parseDateTime } from 'lib/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getLogAction } from 'lib/logs';
import { faDotCircle } from '@fortawesome/pro-regular-svg-icons/faDotCircle';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons/faCheckCircle';
import { faEnvelope } from '@fortawesome/pro-duotone-svg-icons/faEnvelope';
import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons/faLongArrowRight';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons/faExclamationCircle';
import { useClientSlug } from 'lib/hooks';
import { ClientJobIssueMarkerWrapper } from '../ClientJobIssue/styled';
import { Small, Text } from '../Typography';
import {
  ClientJobIssueEventBlockProps,
  ClientJobIssueEventBodyProps,
  ClientJobIssueMarkerProps,
} from '../ClientJobIssue/types';
import ClientJobIssueBlock from '../ClientJobIssueBlock';
import Duration from '../Duration';
import SlateViewer from '../SlateViewer';

const ClientJobIssueEventBody = ({
  logBlock,
}: ClientJobIssueEventBodyProps) => {
  let inner;
  switch (logBlock.type) {
    case 'CREATE_JOB_VISIT':
      inner = (
        <div className="d-flex">
          <div className="mr-4">
            <Small>Start date</Small>
            <p className="mb-0 mono">
              {parseDateTime(logBlock.data.dateTimeStart)}
            </p>
          </div>
          <div>
            <Small>End date</Small>
            <p className="mb-0 mono">
              {parseDateTime(logBlock.data.dateTimeEnd)}
            </p>
          </div>
        </div>
      );
      break;
    case 'UPDATE_JOB_VISIT':
      inner = (
        <div className="d-flex">
          <div className="mr-4">
            <Small>Start date</Small>
            <p className="mb-0 mono">
              {parseDateTime(logBlock.data.dateTimeStart)}
            </p>
          </div>
          <div>
            <Small>End date</Small>
            <p className="mb-0 mono">
              {parseDateTime(logBlock.data.dateTimeEnd)}
            </p>
          </div>
        </div>
      );
      break;
    case 'UPLOAD_JOB_QUOTE':
      inner = (
        <div>
          <div>Quote uploaded</div>
        </div>
      );
      break;
    case 'UPDATE_JOB_QUOTE':
      inner = (
        <div className="d-flex">
          <div className="mr-4">
            <Small>Quote Response</Small>
            <p className="mb-0">{logBlock.data.statusDisplay}</p>
          </div>
        </div>
      );
      break;
    case 'CREATE_EOT_REQUEST':
      inner = (
        <div>
          <div className="d-flex mb-4">
            <div>
              <Small>From</Small>
              <p className="mb-0 mono">
                {parseDateTime(logBlock.data.dateTimeOld)}
              </p>
            </div>
            <div className="d-flex mr-4">
              <div className="mx-3">
                <FontAwesomeIcon icon={faLongArrowRight} />
              </div>
              <div>
                <Small>To</Small>
                <p className="mb-0 mono">
                  {parseDateTime(logBlock.data.dateTimeNew)}
                </p>
              </div>
            </div>
            <div className="mr-4">
              <Small>Moved forward by</Small>
              <p className="mb-0 mono">
                <Duration
                  dateTimeStart={logBlock.data.dateTimeOld}
                  dateTimeEnd={logBlock.data.dateTimeNew}
                />
              </p>
            </div>
          </div>
          <div>
            <Small>Message sent to client</Small>
            <SlateViewer value={logBlock.liveData.message} />
          </div>
        </div>
      );
      break;
    case 'UPDATE_EOT_REQUEST':
      inner = (
        <div className="d-flex">
          <div className="mr-4">
            <Small>EOT Request Response</Small>
            <p className="mb-0">{logBlock.data.statusDisplay}</p>
          </div>
          <div>
            <Small>New target response</Small>
            <p className="mb-0 mono">
              <FontAwesomeIcon
                className="mr-1 text-primary"
                icon={faCheckCircle}
              />
              {parseDateTime(logBlock.data.dateTimeNew)}
            </p>
          </div>
        </div>
      );
      break;
    default:
      inner = <></>;
  }
  return inner;
};

const ClientJobIssueMarker = ({
  children,
  innerRef,
  icon,
  ...rest
}: ClientJobIssueMarkerProps) => {
  return (
    <ClientJobIssueMarkerWrapper>
      <div className="d-flex">
        <div ref={innerRef} className="icon-decoration center" {...rest}>
          <FontAwesomeIcon icon={icon} />
        </div>
        <div className="center-vertically" style={{ fontSize: '0.9rem' }}>
          {children}
        </div>
      </div>
      <div className="line-decoration" />
    </ClientJobIssueMarkerWrapper>
  );
};

const ClientJobIssueEventBlock = ({
  innerRef,
  index,
  issue,
  logBlock,
  isLinked,
}: ClientJobIssueEventBlockProps) => {
  const clientSlug = useClientSlug();

  const inner = (
    <ClientJobIssueBlock
      isLinked={isLinked}
      innerRef={logBlock.links.length > 0 ? undefined : innerRef}
      index={index}
      user={logBlock.user}
      dateTime={logBlock.dateTime}
      opensIssue={logBlock.opensIssue}
      closesIssue={logBlock.closesIssue}
      header={
        <div className="center-vertically">
          <small>
            <strong>{logBlock?.user?.name}</strong>{' '}
            <span className="text-75">{getLogAction(logBlock)}</span>
          </small>
        </div>
      }
    >
      <div className="p-3">
        <ClientJobIssueEventBody logBlock={logBlock} />
      </div>
    </ClientJobIssueBlock>
  );
  if (logBlock.opensIssue) {
    return (
      <>
        {inner}
        <ClientJobIssueMarker icon={faDotCircle}>
          <Text>
            <span className="text-75">Issue opened at </span>
            <span className="mono">{parseDateTime(logBlock.dateTime)}</span>
          </Text>
        </ClientJobIssueMarker>
      </>
    );
  }
  if (logBlock.closesIssue) {
    return (
      <>
        {inner}
        <ClientJobIssueMarker icon={faCheckCircle}>
          <Text>
            <span className="text-75">Issue closed at </span>
            <span className="mono">{parseDateTime(logBlock.dateTime)}</span>
          </Text>
        </ClientJobIssueMarker>
        {issue.transferredTo && (
          <ClientJobIssueMarker icon={faDotCircle}>
            <Text>
              <span className="text-75">Opened related issue </span>
              <Link
                href={`/clients/${clientSlug}/jobs/${issue.transferredTo.job.reference}/issues/${issue.transferredTo.number}`}
              >
                <span className="mono link-inner">
                  #{issue.transferredTo.number}
                </span>
              </Link>
            </Text>
          </ClientJobIssueMarker>
        )}
      </>
    );
  }
  if (logBlock.links.length > 0) {
    let linkInner;
    switch (logBlock.links[0].reason) {
      case 'JOB_VISIT_MOVED_TO_AFTER_EOT':
        linkInner = (
          <>
            Visit start time after requested EOT. If EOT is approved it will{' '}
            <strong>not</strong> resolve this issue.
          </>
        );
        break;
      default:
        linkInner = <></>;
    }
    return (
      <>
        {inner}
        <ClientJobIssueMarker
          innerRef={logBlock.links.length > 0 ? innerRef : undefined}
          icon={faExclamationCircle}
          style={{ border: '2px dashed orange' }}
        >
          <Text>
            <span className="text-75">Warning: </span>
            {linkInner}
          </Text>
        </ClientJobIssueMarker>
      </>
    );
  }
  if (logBlock.type === 'UPLOAD_JOB_QUOTE') {
    return (
      <>
        {inner}
        <ClientJobIssueMarker icon={faEnvelope}>
          <Text>
            <span>Quote sent to client.</span>
          </Text>
        </ClientJobIssueMarker>
      </>
    );
  }
  return inner;
};

export default ClientJobIssueEventBlock;
