import styled from 'styled-components';

export const MarkerWrapper = styled.div`
  position: relative;

  .marker-inner {
    position: absolute;
    bottom: 100%;
    transform: translate(-50%, -100%);
    padding: 0.75rem;
    border-radius: 0.25rem;
    background-color: ${({ theme }) => theme.color.cardBackground.hex()};
    white-space: nowrap;

    .text-75 {
      color: ${({ theme }) => theme.color.cardText.alpha(0.75).hex()};
    }
  }

  .marker-icon {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -100%);
    font-size: 2rem;
    color: ${({ theme }) => theme.color.cardText.hex()};
  }
`;
