import React, { useState } from 'react';
import CardWrapper from '../CardWrapper';
import ClientSitesMap from '../ClientSitesMap';
import { InfoPanelWrapper } from './styled';
import { Small } from '../Typography';
import JobStatusFlag from '../JobStatusFlag';
import { useReducerContext } from '../ClientJob/reducer';

const ClientJobMap = () => {
  const [{ job }] = useReducerContext();

  const [viewState, setViewState] = useState({
    latitude: 0,
    longitude: 0,
    ...job?.site?.location?.point,
    zoom: 15,
    pitch: 45,
    bearing: -10,
  });

  const handleOnViewStateChange = ({ viewState: newViewState }: any) => {
    setViewState(newViewState);
  };

  return (
    <>
      {job && (
        <CardWrapper className="p-1 h-100">
          <div className="position-relative w-100 h-100">
            <InfoPanelWrapper className="shadow-lg">
              <div className="mb-3">
                <Small>Ref: {job.reference}</Small>
                <h2>{job.name}</h2>
              </div>
              <div className="mb-3">
                <Small>Status</Small>
                <h4>
                  <JobStatusFlag className="mr-2" flag={job.status.flag} />
                  {job.status.name}
                </h4>
              </div>
              <div className="mb-3">
                <Small>Site</Small>
                <h5 className="mb-0">{job.site.name}</h5>
                <p className="text-75">{job.site.location.address}</p>
              </div>
            </InfoPanelWrapper>
            <ClientSitesMap
              sites={[job.site]}
              viewState={viewState}
              onViewStateChange={handleOnViewStateChange}
              height="100%"
            />
          </div>
        </CardWrapper>
      )}
    </>
  );
};

export default ClientJobMap;
