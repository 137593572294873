import React, { useState } from 'react';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle';
import { faLink } from '@fortawesome/pro-light-svg-icons/faLink';
import { faCalendarAlt } from '@fortawesome/pro-duotone-svg-icons/faCalendarAlt';
import { faSync } from '@fortawesome/pro-duotone-svg-icons/faSync';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons/faArrowRight';
import { faCloudRain } from '@fortawesome/pro-duotone-svg-icons/faCloudRain';
import { faEyeSlash } from '@fortawesome/pro-duotone-svg-icons/faEyeSlash';
import { faEye } from '@fortawesome/pro-duotone-svg-icons/faEye';
import { useTheme } from 'styled-components';
import { useClientSlug } from 'lib/hooks';
import { useLazyQuery } from '@apollo/client';
import { DateTime } from 'luxon';
import { faFilter } from '@fortawesome/pro-duotone-svg-icons/faFilter';
import { faExpand } from '@fortawesome/pro-light-svg-icons/faExpand';
import { faCompressWide } from '@fortawesome/pro-light-svg-icons/faCompressWide';
import { faClock } from '@fortawesome/pro-light-svg-icons/faClock';
import SimpleButton from '../SimpleButton';
import SimpleButtonLink from '../SimpleButtonLink';
import { Text } from '../Typography';
import SimpleButtonWithWindow from '../SimpleButtonWithWindow';
import { JobVisitSchedulerControlsProps } from './types';
import { JOB_WEATHER_QUERY } from '../JobVisitScheduler/query';

const JobVisitSchedulerControls = ({
  schedulerRef,
  schedulerProject,
  readOnly,
  job,
  renderUnassignedGrid,
  showUnassignedGrid,
  expanded,
  currentTime,
  onForward,
  onBackward,
  toggleUnassignedGrid,
  toggleFilter,
  toggleCurrentTime,
  toggleExpanded,
  resetView,
}: JobVisitSchedulerControlsProps) => {
  const theme = useTheme();
  const clientSlug = useClientSlug();

  const [showGetWeatherButton] = useState(true);

  const [getWeather, { loading: gettingWeather }] = useLazyQuery(
    JOB_WEATHER_QUERY,
    {
      onCompleted: (data) => {
        schedulerProject.resourceTimeRangeStore.add(
          schedulerProject.resourceStore.records
            .filter(
              (resourceRecord) =>
                !(resourceRecord.id as string).includes('group-header')
            )
            .flatMap((resourceRecord, index) => {
              const first = index === 0;
              return data.jobWeather[0].intervals.map((interval: any) => ({
                id: `weather-${interval.startTime}-${resourceRecord.id}`,
                name: first
                  ? `${interval.values.precipitationProbability}%`
                  : '',
                startDate: DateTime.fromISO(interval.startTime).toJSDate(),
                endDate: DateTime.fromISO(interval.startTime)
                  .plus({ hour: 1 })
                  .toJSDate(),
                resourceId: resourceRecord.id,
                duration: 1,
                durationUnit: 'h',
                style: `background-color: rgba(0, 0, 255, ${
                  (interval.values.precipitationProbability / 100) ** 3
                })`,
              }));
            })
        );
      },
    }
  );

  const handleGetWeatherOnClick = () => {
    if (!schedulerRef.current || !job) return;
    const { startDate, endDate } = schedulerRef.current.instance;
    getWeather({
      variables: {
        jobId: job.id,
        dateTimeStart: DateTime.fromJSDate(startDate).toISO(),
        dateTimeEnd: DateTime.fromJSDate(endDate).minus({ hour: 1 }).toISO(),
      },
    });
  };

  return (
    <div className="position-relative d-flex justify-content-between p-2">
      <div style={{ zIndex: 1 }}>
        {readOnly && (
          <SimpleButtonWithWindow
            className="mr-2"
            active
            icon={faExclamationTriangle}
            windowChildren={() => (
              <div
                className="p-3"
                style={{
                  borderRadius: '0.25rem',
                  backgroundColor: theme.color.contentBackground.hex(),
                }}
              >
                <Text>
                  This scheduler is in read only mode. <br />
                  {job && (
                    <>
                      <span className="text-50">
                        To edit the visits assigned to this job click
                      </span>
                      <strong>
                        <SimpleButtonLink
                          buttonProps={{ icon: faLink }}
                          to={`/clients/${clientSlug}/jobs/${job?.id}`}
                        >
                          here.
                        </SimpleButtonLink>
                      </strong>
                    </>
                  )}
                </Text>
              </div>
            )}
          >
            Read only mode
          </SimpleButtonWithWindow>
        )}
        <SimpleButton className="mr-2" icon={faCalendarAlt}>
          Pick range
        </SimpleButton>
        <SimpleButton className="mr-2" icon={faSync} onClick={resetView}>
          Reset view
        </SimpleButton>
        <SimpleButton className="mr-2" icon={faFilter} onClick={toggleFilter}>
          Filter
        </SimpleButton>
      </div>
      <div
        className="position-absolute w-100 d-flex justify-content-center"
        style={{ zIndex: 0 }}
      >
        <SimpleButton icon={faArrowLeft} onClick={() => onBackward('month')}>
          Month
        </SimpleButton>
        <SimpleButton icon={faArrowLeft} onClick={() => onBackward('week')}>
          Week
        </SimpleButton>
        <SimpleButton icon={faArrowLeft} onClick={() => onBackward('day')}>
          Day
        </SimpleButton>
        <SimpleButton
          icon={faArrowRight}
          iconSide="right"
          onClick={() => onForward('day')}
        >
          Day
        </SimpleButton>
        <SimpleButton
          icon={faArrowRight}
          iconSide="right"
          onClick={() => onForward('week')}
        >
          Week
        </SimpleButton>
        <SimpleButton
          icon={faArrowRight}
          iconSide="right"
          onClick={() => onForward('month')}
        >
          Month
        </SimpleButton>
      </div>
      <div style={{ zIndex: 1 }}>
        {showGetWeatherButton && (
          <SimpleButton
            className="mr-2"
            icon={faCloudRain}
            loading={gettingWeather}
            onClick={handleGetWeatherOnClick}
          >
            Get weather
          </SimpleButton>
        )}
        {renderUnassignedGrid && (
          <SimpleButton
            className="mr-2"
            icon={showUnassignedGrid ? faEyeSlash : faEye}
            onClick={toggleUnassignedGrid}
          >
            {showUnassignedGrid ? 'Hide unassigned' : 'Show unassigned'}
          </SimpleButton>
        )}
        <SimpleButton
          className="mr-2"
          icon={faClock}
          active={currentTime}
          onClick={toggleCurrentTime}
        />
        <SimpleButton
          icon={expanded ? faCompressWide : faExpand}
          onClick={toggleExpanded}
        />
      </div>
    </div>
  );
};

export default JobVisitSchedulerControls;
