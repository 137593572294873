import styled from 'styled-components';

export const LayoutTopbarWrapper = styled.div`
  position: relative;
  transition: box-shadow 150ms;
  z-index: 13;

  div.layout-topbar-upper {
    margin-left: 80px;
    width: calc(100% - 80px);
    height: 80px;
    padding-right: 1.5rem;
    z-index: 10;
    border-left: ${({ theme }) => theme.border};
    border-bottom: ${({ theme }) => theme.border};
    background-color: ${({ theme }) => theme.color.topbarBackground.hex()};
  }
`;

export const LayoutTopbarImmediateActions = styled.div`
  border-radius: 0.25rem;
  cursor: pointer;
  background-color: ${({ theme }) => theme.color.sidebarBackground.hex()};
  color: #ffffff;
  transition: background-color 100ms;
  height: 32px;

  .name {
    font-size: 1rem;
    padding: 0 1rem;
  }

  .count {
    width: 40px;
    height: 40px;
    padding: 1px;
    margin: 5px;
    border-radius: 50%;
    border: 2px solid rgba(255, 0, 0, 0.5);
    text-align: center;
    background-color: white;

    span {
      color: rgba(255, 0, 0, 0.5);
      font-size: 24px;
    }
  }

  &:hover {
    background-color: ${({ theme }) =>
      theme.color.sidebarBackgroundDarker.hex()};
  }

  div.text {
    display: none;
  }

  @media (min-width: 1400px) {
    div.text {
      display: flex;
    }
  }
`;

export const LayoutTopbarProfile = styled.div`
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 100ms;
  background-color: ${({ theme }) => theme.color.sidebarBackground.hex()};
  color: #ffffff;
  height: 32px;

  span {
    font-size: 1rem;
    padding: 0 calc(1rem - 5px) 0 1rem;
  }

  .layout-topbar__profile-picture {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 4px;
    object-fit: cover;
  }

  &:hover {
    background-color: ${({ theme }) =>
      theme.color.sidebarBackgroundDarker.hex()};
  }

  div.text {
    display: none;
  }

  @media (min-width: 1550px) {
    div.text {
      display: flex;
    }
  }
`;
