import React from 'react';
import classNames from 'classnames';
import { LogItem } from '../types';

export const getLogText = (logItem: LogItem) => {
  const error = logItem.status === 'ERROR';
  const participle = logItem.status === 'INITIATED' || error;
  let inner;
  switch (logItem.type) {
    case 'RECEIVE_JOB':
      inner = <>Received job from {logItem.meta.fromDisplay}</>;
      break;
    case 'INSTANTIATE_JOB':
      inner = <>Instantiated job</>;
      break;
    case 'CREATE_JOB_VISIT':
      if (!logItem.data) {
        inner = <>created a visit with no assignment</>;
      } else {
        inner = (
          <>
            assigned visit to <strong>{logItem.data.user.name}</strong>
          </>
        );
      }
      break;
    case 'UPDATE_JOB_VISIT':
      inner = (
        <>
          updated visit assigned to{' '}
          <strong>
            {logItem?.meta?.type === 'REASSIGNED'
              ? logItem?.meta?.from.name
              : logItem?.data?.user?.name}
          </strong>
        </>
      );
      break;
    case 'DELETE_JOB_VISIT':
      inner = (
        <>
          deleted visit assigned to <strong>{logItem.data.user.name}</strong>
        </>
      );
      break;
    case 'CREATE_GEOOP_JOB':
      inner = (
        <>
          {error && 'error '}
          {participle ? 'creating' : 'created'} job in GeoOp
        </>
      );
      break;
    case 'CREATE_GEOOP_VISIT':
      inner = (
        <>
          {error && 'error '}
          {participle ? 'creating' : 'created'} visit in GeoOp
        </>
      );
      break;
    case 'UPDATE_GEOOP_VISIT':
      inner = (
        <>
          {error && 'error '}
          {participle ? 'syncing' : 'synced'} visit update with GeoOp
        </>
      );
      break;
    case 'DELETE_GEOOP_VISIT':
      inner = (
        <>
          {error && 'error '}
          {participle ? 'deleting' : 'deleted'} visit in GeoOp
        </>
      );
      break;
    case 'CREATE_IAUDITOR_INSPECTION':
      inner = (
        <>{participle ? 'Initialising' : 'Initialised'} report in iAuditor</>
      );
      break;
    case 'ADD_USER_TO_IAUDITOR_INSPECTION':
      inner = (
        <>
          {participle ? 'Adding' : 'Added'}{' '}
          {logItem.meta?.name ? <strong>{logItem.meta?.name}</strong> : 'user'}{' '}
          to report in iAuditor
        </>
      );
      break;
    case 'ADD_USER_TO_USER_GROUP':
      inner = (
        <>
          Added <strong>{logItem.data.name}</strong> to{' '}
          <strong>{logItem.context.namePlural}</strong>
        </>
      );
      break;
    case 'MOVE_USER_TO_USER_GROUP':
      inner = (
        <>
          Moved <strong>{logItem.meta.user.name}</strong> to{' '}
          <strong>{logItem.meta.to.namePlural}</strong>
        </>
      );
      break;
    case 'REMOVE_USER_FROM_USER_GROUP':
      inner = (
        <>
          Removed <strong>{logItem.data.name}</strong> from{' '}
          <strong>{logItem.context.namePlural}</strong>
        </>
      );
      break;
    case 'UPLOAD_JOB_QUOTE':
      inner = <>uploaded a quote</>;
      break;
    case 'UPDATE_JOB_QUOTE':
      inner = <>submitted client response to quote</>;
      break;
    case 'CREATE_EOT_REQUEST':
      inner = <>requested an extension of time</>;
      break;
    case 'UPDATE_EOT_REQUEST':
      inner = <>submitted client response to EOT request</>;
      break;
    default:
      inner = null;
  }
  return (
    <span className={classNames('log-item-text', { error })}>{inner}</span>
  );
};

export default getLogText;
