import { gql } from '@apollo/client';

export const IN_PROGRESS_JOBS_QUERY = gql`
  query ($clientId: ID!, $page: Int) {
    inProgressJobs: jobs(
      clientId: $clientId
      jobStatusName: "IN_PROGRESS"
      page: $page
    ) {
      results {
        id
        reference
        name
        status {
          nameDisplay
          flag {
            color
            icon
          }
        }
        client {
          slug
        }
        site {
          name
          location {
            point {
              latitude
              longitude
            }
            meta
          }
        }
        assignees {
          initials
        }
      }
      count
    }
  }
`;
