import React, { useCallback, useMemo } from 'react';
import { Slate, Editable, withReact, ReactEditor } from 'slate-react';
import { createEditor } from 'slate';
import {
  SlateViewerElementProps,
  SlateViewerLeafProps,
  SlateViewerProps,
} from './types';

const SlateViewerElement = ({
  attributes,
  children,
  element,
}: SlateViewerElementProps) => {
  switch (element.type) {
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const SlateViewerLeaf = ({ attributes, children }: SlateViewerLeafProps) => {
  return <span {...attributes}>{children}</span>;
};

const SlateViewer = ({ value }: SlateViewerProps) => {
  const editor = useMemo(() => withReact(createEditor() as ReactEditor), []);
  const renderElement = useCallback(
    ({ children, ...props }) => (
      <SlateViewerElement {...props}>{children}</SlateViewerElement>
    ),
    []
  );
  const renderLeaf = useCallback(
    ({ children, ...props }) => (
      <SlateViewerLeaf {...props}>{children}</SlateViewerLeaf>
    ),
    []
  );
  return (
    <Slate editor={editor} value={value} onChange={() => {}}>
      <Editable
        readOnly
        renderElement={renderElement}
        renderLeaf={renderLeaf}
      />
    </Slate>
  );
};

export default SlateViewer;
