import { pluralize } from 'inflection';
import React from 'react';
import classNames from 'classnames';
import { useDelay, useScroll, useUser } from 'lib/hooks';
import { useLocation, Link } from 'wouter';
import buildMatcher from 'wouter/matcher';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'lib/redux/types';
import { LayoutTopbarWrapper } from './styled';
import HomeTabBar from '../HomeTabBar';
import ClientTabBar from '../ClientTabBar';
import PeopleTabBar from '../PeopleTabBar';
import SimpleButton from '../SimpleButton';

const matcher = buildMatcher();

const TopBarRouter = ({
  breadcrumbRef,
}: {
  breadcrumbRef: React.RefObject<HTMLDivElement>;
}) => {
  const [location] = useLocation();
  if (
    matcher('/', location)[0] ||
    matcher('/clients', location)[0] ||
    matcher('/jobs', location)[0]
  ) {
    return <HomeTabBar breadcrumbRef={breadcrumbRef} />;
  }
  if (matcher('/clients/:rest*', location)[0]) {
    return <ClientTabBar breadcrumbRef={breadcrumbRef} />;
  }
  if (matcher('/people/:rest*', location)[0]) {
    return <PeopleTabBar breadcrumbRef={breadcrumbRef} />;
  }
  return null;
};

const LayoutTopbar = ({
  breadcrumbRef,
  extensionRef,
}: {
  breadcrumbRef: React.RefObject<HTMLDivElement>;
  extensionRef: React.RefObject<HTMLDivElement>;
}) => {
  const dispatch = useDispatch();
  const scroll = useScroll();
  const user = useUser();

  const { notificationCount } = useSelector(
    (state: State) => state.notifications
  );

  const handleNotificationsOnClick = () => {
    dispatch({ type: 'NOTIFICATIONS__TOGGLE' });
  };

  const { isOpen: notificationsIsOpen } = useSelector(
    (state: State) => state.notifications
  );

  const notificationsIsOpenDelayed = useDelay(notificationsIsOpen, {
    delay: 250,
  });

  return (
    <LayoutTopbarWrapper
      className={classNames({ shadow: scroll > 0 })}
      style={{
        zIndex: notificationsIsOpen || notificationsIsOpenDelayed ? 9 : 0,
      }}
    >
      <div className="d-flex justify-content-between layout-topbar-upper">
        <div id="layout-topbar" className="flex-grow-1">
          <TopBarRouter breadcrumbRef={breadcrumbRef} />
        </div>
        <div className="d-flex flex-column justify-content-center h-100">
          <div className="d-flex">
            <SimpleButton
              active
              className="mr-2"
              style={{ zIndex: notificationsIsOpen ? 15 : 1 }}
              onClick={handleNotificationsOnClick}
            >
              <strong className="mono">{notificationCount}</strong>{' '}
              {notificationCount === 1
                ? 'Notification'
                : pluralize('Notification')}
            </SimpleButton>
            <Link to="/profile">
              <SimpleButton active>{user.name}</SimpleButton>
            </Link>
          </div>
        </div>
      </div>
      <div
        ref={extensionRef}
        id="layout-topbar-extension"
        style={{ marginLeft: 80 }}
      />
    </LayoutTopbarWrapper>
  );
};

export default LayoutTopbar;
