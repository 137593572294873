import { useState } from 'react';

function useHovered(): [
  boolean,
  { onMouseEnter: () => void; onMouseLeave: () => void }
];

function useHovered() {
  const [hovered, setHovered] = useState(false);
  const onMouseEnter = () => setHovered(true);
  const onMouseLeave = () => setHovered(false);
  return [hovered, { onMouseEnter, onMouseLeave }];
}

export default useHovered;
