import styled from 'styled-components';

export const Overlay = styled.div`
  position: absolute;
  top: 1rem;
  left: 1rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: ${({ theme }) => theme.color.cardBackground.hex()};
  color: ${({ theme }) => theme.color.cardText.hex()};
  z-index: 999;
`;
