import React, { useState } from 'react';
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import { useClient } from 'lib/hooks';
import { useLazyQuery } from '@apollo/client';
import SearchBar from '../SearchBar';
import Button from '../Button';
import {
  searchSitesQueryOnCompleted,
  toggleSitesSidePanel,
  useReducerContext,
} from '../ClientSites/reducer';
import { SEARCH_SITES_QUERY } from './query';
import { SearchSitesQueryData, SearchSitesQueryVariables } from './types';

const ClientSitesTableFilter = () => {
  const [, dispatch] = useReducerContext();

  const client = useClient();

  const [searchTerm, setSearchTerm] = useState('');

  const [getSites] = useLazyQuery<
    SearchSitesQueryData,
    SearchSitesQueryVariables
  >(SEARCH_SITES_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      dispatch(searchSitesQueryOnCompleted(data));
    },
  });

  const handleSearchTermOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value: newSearchTerm } = event.target;
    setSearchTerm(newSearchTerm);
    getSites({
      variables: { clientId: client?.id, searchTerm: newSearchTerm },
    });
  };

  const createSiteOnClick = () => {
    dispatch(toggleSitesSidePanel(true));
  };

  return (
    <div className="m-3 d-flex">
      <div className="w-75 mr-2">
        <SearchBar
          placeholder={`Search ${client?.name}'s sites...`}
          wrapperClassName="mr-0"
          value={searchTerm}
          onChange={handleSearchTermOnChange}
        />
      </div>
      <div className="flex-grow-1">
        <Button className="w-100" icon={faPlus} onClick={createSiteOnClick}>
          Create site
        </Button>
      </div>
    </div>
  );
};

export default ClientSitesTableFilter;
