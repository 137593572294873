import React from 'react';
import { Row, Col } from 'reactstrap';
import { msalApplication } from 'lib/msal';
import Logo from 'static/logo_white.svg';
import { faMicrosoft } from '@fortawesome/free-brands-svg-icons/faMicrosoft';
import { Wrapper, LoginWrapper } from './styled';
import SimpleButton from '../SimpleButton';

const Login = () => {
  const handleOnClick = () => {
    msalApplication.loginRedirect({ prompt: 'select_account' });
  };

  return (
    <Wrapper>
      <LoginWrapper className="h-100 d-flex flex-column justify-content-between">
        <Row noGutters className="login-row">
          <Col xl={4} className="login-col offset-4 d-flex shadow">
            <div className="login-sidebar">
              <img className="logo" src={Logo} alt="Logo" />
            </div>
            <div className="login-body flex-grow-1 m-3">
              <h3 className="mb-0">Sign in to continue</h3>
              <p className="text-75">Use your Microsoft account to sign in</p>
              <SimpleButton
                className="login-button"
                icon={faMicrosoft}
                onClick={handleOnClick}
              >
                Login with Microsoft
              </SimpleButton>
            </div>
          </Col>
        </Row>
        <div className="text-center mb-3 login-footer">
          &copy; B. &amp; M. McHugh Ltd.
        </div>
      </LoginWrapper>
    </Wrapper>
  );
};

export default Login;
