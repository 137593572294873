import { gql } from '@apollo/client';

export const UPDATE_USER_TASKBAR_MUTATION = gql`
  mutation ($tabs: JSONScalarInput, $isVisible: Boolean) {
    updateUserTaskbar(tabs: $tabs, isVisible: $isVisible) {
      userTaskbar {
        tabs
        isVisible
      }
    }
  }
`;
