import React from 'react';

const ClientSettingsReports = () => {
  return (
    <div>
      <div />
    </div>
  );
};

export default ClientSettingsReports;
