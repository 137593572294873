import styled from 'styled-components';

export const Wrapper = styled.div`
  display: inline-block;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.color.contentBackground.hex()};
  font-size: 14px;
`;
