import React, { useState } from 'react';
import classNames from 'classnames';
import { useLazyQuery } from '@apollo/client';
import { JobTagProps } from './types';
import { Wrapper } from './styled';
import JobStatusFlag from '../JobStatusFlag';
import SimpleButtonWithWindow from '../SimpleButtonWithWindow';
import { JOB_TAG_META_QUERY } from './query';

const JobTag = ({ job, tag, last }: JobTagProps) => {
  const [meta, setMeta] = useState<any | null>(null);
  const [getMeta] = useLazyQuery(JOB_TAG_META_QUERY, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setMeta(data.jobTagMeta);
    },
  });

  const handleOnOpened = () => {
    getMeta({ variables: { jobId: job.id, jobTagId: tag.id } });
  };

  const renderWindowChildren = () => {
    if (!meta || Object.keys(meta).length === 0) return null;
    let inner;
    switch (tag.name) {
      case 'LATE_RESPONSE':
        inner = (
          <div>
            {meta.actualDateTimeStart}
            {meta.targetDateTimeStart}
          </div>
        );
        break;
      default:
        inner = <></>;
    }
    return <div className="p-2">{inner}</div>;
  };

  return (
    <Wrapper className={classNames({ 'mr-2': !last })} color={tag.flag.color}>
      <SimpleButtonWithWindow
        hoverStyles={false}
        style={{ height: 'auto' }}
        openMode="hover"
        windowChildren={renderWindowChildren}
        onOpened={handleOnOpened}
      >
        <JobStatusFlag style={{ marginRight: 2 }} flag={tag.flag} />
        <span className="text-75">{tag.nameDisplay}</span>
      </SimpleButtonWithWindow>
    </Wrapper>
  );
};

export default JobTag;
