import { gql } from '@apollo/client';

export const INITIAL_QUERY = gql`
  query (
    $clientId: ID!
    $dateTimeStart: DateTime
    $dateTimeEnd: DateTime
    $jobId: ID
  ) {
    userGroups(clientId: $clientId, includeInScheduler: true) {
      id
      name
      namePlural
      users {
        id
        name
      }
    }
    assignedVisits: jobVisits(
      clientId: $clientId
      dateTimeStart: $dateTimeStart
      dateTimeEnd: $dateTimeEnd
      noAssigneePreference: false
      unassigned: false
    ) {
      id
      label
      number
      dateTimeStart
      dateTimeEnd
      unallocated
      status {
        id
        name
        nameDisplay
        color
      }
      job {
        id
        reference
        name
      }
      user {
        id
        name
      }
    }
    unassignedVisits: jobVisits(
      clientId: $clientId
      dateTimeStart: $dateTimeStart
      dateTimeEnd: $dateTimeEnd
      jobId: $jobId
      noAssigneePreference: false
      unassigned: true
    ) {
      id
      label
      number
      dateTimeStart
      dateTimeEnd
      unallocated
      job {
        id
        reference
        name
      }
    }
  }
`;

export const JOB_VISIT_QUERY = gql`
  query ($id: ID!) {
    jobVisit(jobVisitId: $id) {
      id
      dateTimeStart
      dateTimeEnd
      number
      status {
        name
        nameDisplay
        color
      }
      job {
        id
        reference
        name
        site {
          name
        }
        status {
          id
          name
          nameDisplay
          flag {
            id
            name
            color
          }
        }
      }
    }
  }
`;

export const JOB_VISIT_ROUTE_QUERY = gql`
  query ($originJobVisitId: ID!, $destinationJobId: ID!) {
    jobVisitRoute(
      originJobVisitId: $originJobVisitId
      destinationJobId: $destinationJobId
    )
  }
`;

export const JOB_WEATHER_QUERY = gql`
  query ($jobId: ID!, $dateTimeStart: DateTime!, $dateTimeEnd: DateTime!) {
    jobWeather(
      jobId: $jobId
      dateTimeStart: $dateTimeStart
      dateTimeEnd: $dateTimeEnd
    )
  }
`;
