import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form, Field } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'lib/redux/types';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons/faArrowRight';
import CardWrapper from '../CardWrapper';
import { Wrapper } from './styled';
import SlateField from '../SlateField';
import { Small } from '../Typography';
import SimpleButton from '../SimpleButton';
import SidePanel from '../SidePanel';

const ErrorWindow = () => {
  const dispatch = useDispatch();
  const { errorWindowIsOpen } = useSelector((state: State) => state.errors);

  const handleOnClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    dispatch({ type: 'SET_ERROR_WINDOW_IS_OPEN', payload: false });
  };

  const handleOnSubmit = () => {
    dispatch({ type: 'SET_ERROR_WINDOW_IS_OPEN', payload: false });
  };

  return (
    <SidePanel
      className="vh-100 bg-transparent shadow-none p-0 m-0"
      isOpen={errorWindowIsOpen}
      toggle={() => {}}
    >
      <Wrapper>
        <CardWrapper className="h-100 d-flex flex-column justify-content-between shadow">
          <div>
            <h4 className="text-nowrap">
              <FontAwesomeIcon
                className="mr-2 text-danger"
                icon={faExclamationTriangle}
              />
              There was a server error
            </h4>
            <p className="text-75">
              This issue has already been logged by our error tracking system
              and the development team have been notified.
            </p>
          </div>
          <Form onSubmit={handleOnSubmit}>
            {({ handleSubmit }) => (
              <form
                className="flex-grow-1 d-flex flex-column justify-content-between"
                onSubmit={handleSubmit}
              >
                <div>
                  <div className="mb-2">
                    <Small>Additional details (optional)</Small>
                  </div>
                  <Field
                    name="message"
                    component={SlateField}
                    placeholder="Add message..."
                  />
                </div>
                <div className="d-flex justify-content-between">
                  <SimpleButton icon={faTimes} onClick={handleOnClose}>
                    Close
                  </SimpleButton>
                  <SimpleButton icon={faArrowRight} iconSide="right">
                    Submit additional details
                  </SimpleButton>
                </div>
              </form>
            )}
          </Form>
        </CardWrapper>
      </Wrapper>
    </SidePanel>
  );
};

export default ErrorWindow;
