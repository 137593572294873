import { gql } from '@apollo/client';

export const INITIAL_QUERY = gql`
  query ($jobId: ID) {
    chats(jobId: $jobId) {
      chats {
        id
        messages {
          id
          body
          isPrimary
          device
        }
      }
      jobChat {
        id
        name
        messages {
          id
          body
          isPrimary
          device
        }
      }
    }
  }
`;

export const CREATE_CHAT_MUTATION = gql`
  mutation ($jobId: ID!, $body: JSONScalarInput!) {
    createChat(jobId: $jobId, body: $body) {
      chat {
        id
        isGeneral
        messages {
          id
          body
          device
        }
      }
    }
  }
`;
