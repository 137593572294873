import { gql } from '@apollo/client';

export const CREATE_SITE_MUTATION = gql`
  mutation($clientId: ID, $data: GenericScalar) {
    createSite(clientId: $clientId, data: $data) {
      site {
        id
        name
        location {
          meta
          point {
            longitude
            latitude
          }
        }
      }
    }
  }
`;

export const UPDATE_SITE_MUTATION = gql`
  mutation($id: ID, $data: GenericScalar) {
    updateSite(siteId: $id, data: $data) {
      site {
        id
        name
        location {
          meta
          point {
            longitude
            latitude
          }
        }
      }
    }
  }
`;
