import { faCircle } from '@fortawesome/pro-solid-svg-icons/faCircle';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Wrapper, LegendItemWrapper } from './styled';
import { LegendItemProps } from './types';

const LegendItem = ({ color, status }: LegendItemProps) => {
  return (
    <LegendItemWrapper>
      <FontAwesomeIcon className="mr-1" icon={faCircle} color={color} />
      <span className="text-75">{status}</span>
    </LegendItemWrapper>
  );
};

const JobVisitSchedulerLegend = () => {
  return (
    <Wrapper>
      <LegendItem color="#106c12" status="Scheduled" />
      <LegendItem color="#00ffff" status="En route" />
      <LegendItem color="orange" status="In progress" />
      <LegendItem color="white" status="Completed" />
    </Wrapper>
  );
};

export default JobVisitSchedulerLegend;
