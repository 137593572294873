import React, { useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { useClient } from 'lib/hooks';
import { useQuery } from '@apollo/client';
import { JobReport, JobReportTemplate } from 'lib/types';
import Switch from 'react-switch';
import { JOB_REPORT_TEMPLATES_QUERY } from './query';
import {
  JobReportTemplatesQueryData,
  JobReportTemplatesQueryVariables,
} from './types';
import { Small } from '../Typography';

const ReportsField = ({
  input,
  jobId,
  jobInstantiationTypeId,
  userGroupId,
}: FieldRenderProps<{
  templates: JobReportTemplate[];
  reports: JobReport[];
}>) => {
  const client = useClient();
  const [templates, setTemplates] = useState<JobReportTemplate[]>([]);
  useQuery<JobReportTemplatesQueryData, JobReportTemplatesQueryVariables>(
    JOB_REPORT_TEMPLATES_QUERY,
    {
      fetchPolicy: 'no-cache',
      variables: {
        clientId: client.id,
        userGroupId,
        jobId,
        jobInstantiationTypeId,
      },
      onCompleted: (data) => {
        const { jobReportTemplates } = data;
        setTemplates(jobReportTemplates.results);
        input.onChange({
          ...input.value,
          reportTemplates: jobReportTemplates.results.filter(
            (template) => template.isDefault
          ),
        });
      },
    }
  );

  const toggleTemplateSelection = (id: JobReportTemplate['id']) => {
    const template = templates.find((innerTemplate) => innerTemplate.id === id);
    if (!template) return;
    if (input.value.templates.includes(template)) {
      input.onChange({
        ...input.value,
        templates: input.value.templates.filter(
          (innerTemplate) => innerTemplate.id !== id
        ),
      });
    } else {
      input.onChange({
        ...input.value,
        templates: [...input.value.templates, template],
      });
    }
  };

  const handleOnToggleDefaultTemplate = (
    checked: boolean,
    event: React.SyntheticEvent<MouseEvent | KeyboardEvent> | MouseEvent,
    id: string
  ) => {
    toggleTemplateSelection(id);
  };

  const defaultTemplates = templates.filter((template) => template.isDefault);

  return (
    <div>
      <div className="d-flex justify-content-between mb-2">
        <Small className="text-nowrap" style={{ lineHeight: '34px' }}>
          Create new reports
        </Small>
      </div>
      {(userGroupId || jobInstantiationTypeId) && (
        <div className="mb-3">
          {defaultTemplates.length > 0 ? (
            defaultTemplates.map((template) => (
              <div key={template.id}>
                <label className="d-flex" htmlFor={template.id}>
                  <Switch
                    id={template.id}
                    className="ml-1"
                    checked={input.value.templates.includes(template)}
                    onColor="#106c12"
                    onChange={handleOnToggleDefaultTemplate}
                  />
                  <p
                    className="mb-0 ml-2"
                    style={{
                      cursor: 'pointer',
                      userSelect: 'none',
                      lineHeight: '28px',
                    }}
                  >
                    {template.name}
                  </p>
                </label>
              </div>
            ))
          ) : (
            <div className="text-75">
              No default templates for this user group.
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ReportsField;
