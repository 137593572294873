import React from 'react';
import { ReportTemplateBlock } from 'lib/types';
import { PreviewHeader } from './styled';
import { useReducerContext } from '../ClientSettingsReportTemplateEditor/reducer';
import SlateViewer from '../SlateViewer';

const ClientSettingsReportTemplatePreview = () => {
  const [{ reportTemplate, reportTemplateState }] = useReducerContext();

  if (!reportTemplate) return null;

  const getBlock = (childId: ReportTemplateBlock['id']) => {
    return reportTemplate.root.blocks.find(
      (block) => block.id === childId
    ) as ReportTemplateBlock;
  };

  const renderBlock = (blockId: ReportTemplateBlock['id']) => {
    const block = getBlock(blockId);

    const value =
      reportTemplateState.value[blockId] ||
      reportTemplateState.valueDefaults[blockId];

    const logic = block.logic.find((innerLogic) => innerLogic.id === value);

    if (!block) return null;
    return (
      <div>
        <div>
          <SlateViewer value={block.label} />
        </div>
        <div />
        {logic && <div>{logic.children.map(renderBlock)}</div>}
      </div>
    );
  };

  return (
    <div>
      <PreviewHeader>Preview</PreviewHeader>
      {reportTemplate.root.children.map(renderBlock)}
    </div>
  );
};

export default ClientSettingsReportTemplatePreview;
