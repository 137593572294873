import React, { useMemo } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { createEditor, Descendant } from 'slate';
import { Slate, Editable, withReact, ReactEditor } from 'slate-react';
import { withHistory } from 'slate-history';
import { Wrapper } from './styed';

const defaultValue: Descendant[] = [
  { type: 'paragraph', children: [{ text: '' }] },
];

const SlateField = ({
  input,
  placeholder,
  minHeight = 200,
  height = 200,
  ...rest
}: FieldRenderProps<Descendant[]>) => {
  const handleOnChange = (value: Descendant[]) => {
    input.onChange(value);
  };

  const editor = useMemo<ReactEditor>(
    () => withHistory(withReact(createEditor() as ReactEditor)),
    []
  );

  return (
    <Wrapper style={{ minHeight, height }}>
      <Slate
        editor={editor}
        value={input.value || defaultValue}
        onChange={handleOnChange}
        {...rest}
      >
        <Editable
          style={{ minHeight }}
          className="h-100"
          placeholder={placeholder}
        />
      </Slate>
    </Wrapper>
  );
};

export default SlateField;
