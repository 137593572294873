import React from 'react';
import { parseDuration } from 'lib/utils';
import { DateTime } from 'luxon';
import { DurationProps } from './types';

const Duration = ({
  seconds: providedSeconds,
  dateTimeStart,
  dateTimeEnd,
  units,
}: DurationProps) => {
  let seconds = providedSeconds;
  if (!seconds) {
    seconds = Math.abs(
      DateTime.fromISO(dateTimeStart as string)
        .diff(DateTime.fromISO(dateTimeEnd as string))
        .as('seconds')
    );
  }
  return (
    <>
      {parseDuration(seconds, units).map((delta) => (
        <span key={delta.label} className="mr-2">
          <span className="mono text-100">{delta.value}</span>{' '}
          <small className="text-75">{delta.label}</small>
        </span>
      ))}
    </>
  );
};

export default Duration;
