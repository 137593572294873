import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/pro-duotone-svg-icons/faFileExcel';
import { useTheme } from 'styled-components';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import Dropzone from '../Dropzone';
import SimpleButton from '../SimpleButton';

const DropzoneField = ({ input, label }: FieldRenderProps<File>) => {
  const handleOnDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) return;
    input.onChange(acceptedFiles[0]);
  };

  const handleOnClear = () => {
    input.onChange(null);
  };

  const theme = useTheme();
  if (input.value) {
    return (
      <div className="d-flex w-100">
        <FontAwesomeIcon className="mr-2" icon={faFileExcel} size="4x" />
        <div style={{ height: 64 }}>
          <h5 className="mb-0">{input.value.name}</h5>
          <div className="text-75">
            <SimpleButton
              hoverStyles={false}
              icon={faTimes}
              iconProps={{ className: 'mr-0' }}
              style={{ color: theme.color.cardText.alpha(0.75).hex() }}
              onClick={handleOnClear}
            >
              Remove
            </SimpleButton>
          </div>
        </div>
      </div>
    );
  }
  return <Dropzone accept={['.xlsx']} label={label} onDrop={handleOnDrop} />;
};

export default DropzoneField;
