import { gql } from '@apollo/client';

export const JOB_INSTANTIATION_SUGGESTION_QUERY = gql`
  query ($clientId: ID!, $jobId: ID!) {
    jobInstantiationTypes(clientId: $clientId) {
      id
      name
    }
    jobInstantiationSuggestion(jobId: $jobId) {
      type {
        id
        name
        slug
      }
      meta
    }
  }
`;

export const UPDATE_JOB_INSTANTIATION_STATE_MUTATION = gql`
  mutation (
    $jobId: ID!
    $instantiationTypeId: ID!
    $instantiationMeta: JSONScalarInput
  ) {
    updateJobInstantiationState(
      jobId: $jobId
      instantiationTypeId: $instantiationTypeId
      instantiationMeta: $instantiationMeta
    ) {
      instantiationType {
        id
      }
      instantiationDetails {
        type
        name
        job {
          id
          reference
          name
        }
      }
    }
  }
`;

export const INSTANTIATE_JOB_MUTATION = gql`
  mutation ($sessionId: ID!, $jobId: ID!, $data: JobInstantiationDataInput!) {
    instantiateJob(sessionId: $sessionId, jobId: $jobId, data: $data) {
      job {
        id
        instantiated
        status {
          id
          name
          nameDisplay
          flag {
            id
            name
            nameDisplay
            color
            icon
          }
        }
      }
      logBlock {
        id
        status
        type
        dateTime
        instanceContentType
        data
        meta
        context
        children {
          id
          status
          type
          dateTime
          instanceContentType
          data
          meta
          context
        }
      }
    }
  }
`;
