import React, { useEffect, useState } from 'react';
import { useHovered } from 'lib/hooks';
import { Wrapper } from './styled';
import { ClickToCopyProps } from './types';

const ClickToCopy = ({
  children,
  copy,
  onClick,
  ...rest
}: ClickToCopyProps) => {
  const [copied, setCopied] = useState(false);
  const [hide, setHide] = useState(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();

  const handleOnClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    await navigator.clipboard.writeText(copy);
    setCopied(true);
    setTimeoutId(
      setTimeout(() => {
        setCopied(false);
        setHide(true);
      }, 1000)
    );
    if (onClick) onClick(event);
  };

  useEffect(() => {
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [timeoutId]);

  const [hovered, defaultHoveredCallbacks] = useHovered();

  const hoveredCallbacks = {
    onMouseEnter: () => {
      setHide(false);
      defaultHoveredCallbacks.onMouseEnter();
    },
    onMouseLeave: defaultHoveredCallbacks.onMouseLeave,
  };

  return (
    <Wrapper {...rest} {...hoveredCallbacks} onClick={handleOnClick}>
      {(hovered || copied) && !hide && (
        <div className="click-to-copy-tooltip">
          {copied ? 'Copied' : 'Click to copy'}
        </div>
      )}
      {children}
    </Wrapper>
  );
};

export default ClickToCopy;
