import styled from 'styled-components';
import { PDFProps } from './types';

export const PDF = styled.div<PDFProps>`
  position: relative;
  background-color: ${({ theme }) => theme.color.contentBackground.hex()};
  width: ${({ width }) => width}px;
  height: ${({ width }) => Math.sqrt(2) * width}px;
  border-radius: 0.25rem;
  border-width: 2px;
  border-style: solid;
  overflow: hidden;

  span.job-report-card-number {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0 0 0.25rem 0;
  }
`;
