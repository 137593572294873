import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useLazyQuery } from '@apollo/client';
import { Link, useLocation } from 'wouter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag } from '@fortawesome/pro-duotone-svg-icons/faFlag';
import { faExclamationCircle } from '@fortawesome/pro-duotone-svg-icons/faExclamationCircle';
import { Client } from 'lib/types';
import { batch, useDispatch, useSelector } from 'react-redux';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { getId } from 'lib/utils';
import { State } from 'lib/redux/types';
import { LayoutSidebarClientMenuItemProps } from './types';
import {
  LayoutSidebarClientMenuWrapper,
  LayoutSidebarClientMenuItemsWrapper,
  LayoutSidebarClientMenuItemWrapper,
} from './styled';
import { CLIENTS_QUERY } from './query';
import SearchBar from '../SearchBar';
import Button from '../Button';
import Figure from '../Figure';

const LayoutSidebarClientMenuItem = ({
  name,
  to,
  immediateActions,
  jobStatusFlags,
  last,
  onClick,
}: LayoutSidebarClientMenuItemProps) => {
  const [location] = useLocation();
  const active = location.includes(to);
  return (
    <Link to={to} onClick={onClick}>
      <LayoutSidebarClientMenuItemWrapper
        className={classNames('d-flex justify-content-between', {
          'border-bottom-0': last,
          active,
        })}
        active={active}
      >
        <div>
          <p
            className={classNames('mb-1', {
              'font-weight-bold': active,
              'text-50': !active,
            })}
          >
            {name}
          </p>
          <div className="layout-sidebar-client-menu__jobs">
            {jobStatusFlags.map((jobStatusFlag) => (
              <span
                key={jobStatusFlag.id}
                className="text-nowrap"
                style={{ color: jobStatusFlag.color }}
              >
                <FontAwesomeIcon className="mr-1" icon={faFlag} fixedWidth />
                <Figure
                  value={jobStatusFlag.count}
                  brackets={false}
                  small={false}
                />
              </span>
            ))}
            <span className="red">
              <FontAwesomeIcon
                className="mr-1"
                icon={faExclamationCircle}
                fixedWidth
              />
              {immediateActions}
            </span>
          </div>
        </div>
      </LayoutSidebarClientMenuItemWrapper>
    </Link>
  );
};

const LayoutSidebarClientMenu = () => {
  const { isOpen } = useSelector((state: State) => state.clients);
  const [clients, setClients] = useState<Client[]>([]);

  const [getClients, { refetch }] = useLazyQuery(CLIENTS_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setClients(data.clients);
    },
  });

  useEffect(() => {
    if (isOpen) getClients();
  }, [getClients, isOpen]);

  const [searching, setSearching] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchTermOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setSearchTerm(value);
    setSearching(true);
    if (refetch) {
      refetch({ searchTerm: value }).then((response) => {
        setClients(response.data.clients);
        setSearching(false);
      });
    }
  };

  const dispatch = useDispatch();
  const handleClientOnClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    const id = getId(event);
    batch(() => {
      dispatch({ type: 'CLIENTS__CLOSE_SIDEBAR' });
      dispatch({
        type: 'CLIENTS__SET_CLIENT',
        payload: { id },
      });
    });
  };

  const handleCloseSideMenu = () => {
    dispatch({ type: 'CLIENTS__CLOSE_SIDEBAR' });
  };

  return (
    <LayoutSidebarClientMenuWrapper>
      <div className="layout-sidebar__menu-header px-3">
        <div className="d-flex flex-column justify-content-center h-100">
          <div className="d-flex justify-content-between">
            <div>
              <h4>Clients</h4>
              <p className="mb-0 text-50">Select a client</p>
            </div>
            <div>
              <Button icon={faTimes} onClick={handleCloseSideMenu}>
                Close
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="px-3">
        <div className="mt-3">
          <SearchBar
            placeholder="Search clients..."
            wrapperClassName="m-0"
            value={searchTerm}
            onChange={handleSearchTermOnChange}
            loading={searching}
          />
        </div>
        <LayoutSidebarClientMenuItemsWrapper>
          {clients.map((client: Client, index: number) => {
            const last = index === clients.length - 1;
            return (
              <LayoutSidebarClientMenuItem
                key={client.id}
                name={client.name}
                last={last}
                to={`/clients/${client.slug}`}
                jobStatusFlags={client.jobStatusFlags}
                immediateActions={client.immediateActionCount}
                data-id={client.id}
                onClick={handleClientOnClick}
              />
            );
          })}
        </LayoutSidebarClientMenuItemsWrapper>
      </div>
    </LayoutSidebarClientMenuWrapper>
  );
};

export default LayoutSidebarClientMenu;
