import { gql } from '@apollo/client';

export const INITIAL_QUERY = gql`
  query ($clientId: ID!, $dateTimeStart: DateTime, $dateTimeEnd: DateTime) {
    userGroups(clientId: $clientId, includeInScheduler: true) {
      id
      name
      namePlural
      users {
        id
        name
      }
    }
    jobVisits(
      clientId: $clientId
      dateTimeStart: $dateTimeStart
      dateTimeEnd: $dateTimeEnd
      noAssigneePreference: false
      unassigned: false
    ) {
      id
      label
      number
      dateTimeStart
      dateTimeEnd
      unallocated
      status {
        id
        name
        nameDisplay
        color
      }
      job {
        id
        reference
        name
      }
      user {
        id
        name
      }
    }
  }
`;

export const JOBS_OF_INTEREST_QUERY = gql`
  query ($clientId: ID!, $dateTimeStart: DateTime!, $dateTimeEnd: DateTime!) {
    jobsOfInterest(clientId: $clientId, dateTime: $dateTimeStart) {
      id
      reference
      name
      workType
      priority {
        code
        color
        responseDuration
      }
      status {
        nameDisplay
        flag {
          nameDisplay
          color
        }
      }
      site {
        name
      }
      visits {
        id
      }
    }
    jobStatusFlagCounts(
      clientId: $clientId
      dateTimeStart: $dateTimeStart
      dateTimeEnd: $dateTimeEnd
    ) {
      id
      color
      count
    }
  }
`;

export const JOB_VISITS_PER_DAY_QUERY = gql`
  query ($clientId: ID!, $dateTimeStart: DateTime!, $dateTimeEnd: DateTime!) {
    jobVisitsPerDay(
      clientId: $clientId
      dateTimeStart: $dateTimeStart
      dateTimeEnd: $dateTimeEnd
    )
  }
`;
