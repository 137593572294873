import React from 'react';
import PortalBreadcrumb from '../PortalBreadcrumb';

const HomeClients = () => {
  return (
    <>
      <PortalBreadcrumb>
        <h4>Clients</h4>
      </PortalBreadcrumb>
      <div>
        <div />
      </div>
    </>
  );
};

export default HomeClients;
