import { gql } from '@apollo/client';

export const CREATE_JOB_ISSUE_MESSAGE_MUTATION = gql`
  mutation ($jobIssueId: ID!, $data: CreateJobIssueMessageInput!) {
    createJobIssueMessage(jobIssueId: $jobIssueId, data: $data) {
      jobIssue {
        id
        controls
        tags {
          id
          name
          color
        }
      }
      block {
        ... on JobIssueBlockMessage {
          type
          item {
            id
            dateTimeCreated
            body
            messageUser: user {
              id
              initials
              name
            }
          }
        }
      }
    }
  }
`;
