import { useLayoutSpacer } from 'components/Layout';

const useScrollTo = () => {
  const layoutSpacer = useLayoutSpacer();
  return (x: number, y: number) => {
    if (!layoutSpacer?.current) return;
    layoutSpacer.current.scrollTo(x, y);
  };
};

export default useScrollTo;
