import { Dispatch, SetStateAction, useState } from 'react';
import { ApolloQueryResult } from '@apollo/client/core/types';
import { OperationVariables } from '@apollo/client';
import useTablePagination from './useTablePagination';

type Delta = 1 | -1;

type Return = [
  number,
  (delta: Delta) => void,
  { loading: boolean; setPage: Dispatch<SetStateAction<number>> }
];

const useTablePaginationRefetch = <
  TData = any,
  TVariables = OperationVariables
>(
  refetch: (
    variables?: Partial<TVariables>
  ) => Promise<ApolloQueryResult<TData>>,
  {
    variables,
    onCompleted,
  }: {
    variables?: any;
    onCompleted: (response: ApolloQueryResult<TData>) => void;
  }
): Return => {
  const [refetching, setRefetching] = useState(false);
  const [page, handleOnChange, setPage] = useTablePagination({
    onChange: (newPage) => {
      setRefetching(true);
      refetch({ page: newPage, ...variables }).then((response) => {
        setRefetching(false);
        return onCompleted(response);
      });
    },
  });
  return [page, handleOnChange, { loading: refetching, setPage }];
};

export default useTablePaginationRefetch;
