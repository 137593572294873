import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons/faSpinnerThird';
import { SimpleButtonProps } from './types';
import { ButtonWithRemovedStyles } from './styled';

const SimpleButton = ({
  innerRef,
  as,
  className,
  icon,
  iconStyle,
  iconSide = 'left',
  iconProps,
  loading,
  loadingIcon = faSpinnerThird,
  children,
  hoverStyles = true,
  inButtonToolbar = false,
  backgroundHoverColor,
  backgroundActiveColor,
  end = false,
  active = false,
  secondary = false,
  value,
  ...rest
}: React.PropsWithChildren<SimpleButtonProps>) => (
  <ButtonWithRemovedStyles
    ref={innerRef}
    as={as as undefined}
    className={classNames(className, {
      'text-75': inButtonToolbar && !active,
      'mr-2': inButtonToolbar && !end,
    })}
    hoverStyles={hoverStyles}
    backgroundHoverColor={backgroundHoverColor}
    backgroundActiveColor={backgroundActiveColor}
    active={active && hoverStyles}
    value={value}
    secondary={secondary}
    {...rest}
  >
    {icon && iconSide === 'left' && (
      <>
        {loading ? (
          <FontAwesomeIcon
            icon={loadingIcon}
            spin={loading}
            fixedWidth
            style={iconStyle}
            className={classNames('simple-button__icon', {
              'mr-1': !!children,
            })}
            {...iconProps}
          />
        ) : (
          <FontAwesomeIcon
            style={iconStyle}
            icon={icon}
            spin={loading}
            fixedWidth
            className={classNames('simple-button__icon', {
              'mr-1': !!children,
            })}
            {...iconProps}
          />
        )}
      </>
    )}
    {children}
    {icon && iconSide === 'right' && (
      <>
        {loading ? (
          <FontAwesomeIcon
            style={iconStyle}
            icon={loadingIcon}
            spin={loading}
            fixedWidth
            className={classNames('simple-button__icon', {
              'ml-1': !!children,
            })}
            {...iconProps}
          />
        ) : (
          <FontAwesomeIcon
            style={iconStyle}
            icon={icon}
            spin={loading}
            fixedWidth
            className={classNames('simple-button__icon', {
              'ml-1': !!children,
            })}
            {...iconProps}
          />
        )}
      </>
    )}
  </ButtonWithRemovedStyles>
);

export default SimpleButton;
