import { gql } from '@apollo/client';

export const START_MULTI_ASSIGNMENT_MUTATION = gql`
  mutation ($jobGroupId: ID!, $startJobId: ID!) {
    startMultiAssignment(jobGroupId: $jobGroupId, startJobId: $startJobId) {
      activeMultiAssignPlacementBatch {
        id
        placements {
          id
        }
      }
    }
  }
`;

export const ANALYSE_JOB_VISIT_PLACEMENT_QUERY = gql`
  query (
    $jobGroupId: ID!
    $jobVisitPlacement: JobVisitPlacementInput!
    $futurePlacementCount: Int!
  ) {
    analyseJobVisitPlacement(
      jobGroupId: $jobGroupId
      jobVisitPlacement: $jobVisitPlacement
      futurePlacementCount: $futurePlacementCount
    ) {
      id
      isOriginal
      isLocked
      job {
        id
        reference
        targetDateTimeStart
        targetDateTimeEnd
      }
      dateTimeStart
      dateTimeEnd
      user {
        id
        initials
        name
      }
      statuses {
        type
        meta {
          overlappingJobVisits {
            id
          }
        }
      }
    }
  }
`;

export const ANALYSE_UPDATED_JOB_VISIT_PLACEMENT_QUERY = gql`
  query ($jobVisitPlacement: JobVisitPlacementInput!) {
    analyseUpdatedJobVisitPlacement(jobVisitPlacement: $jobVisitPlacement) {
      id
      isOriginal
      isLocked
      job {
        id
        reference
        targetDateTimeStart
        targetDateTimeEnd
      }
      dateTimeStart
      dateTimeEnd
      user {
        id
        initials
        name
      }
      statuses {
        type
        meta {
          overlappingJobVisits {
            id
          }
        }
      }
    }
  }
`;

export const TOGGLE_LOCK_JOB_VISIT_PLACEMENT_MUTATION = gql`
  mutation ($jobVisitPlacementId: ID!) {
    toggleLockJobVisitPlacement(jobVisitPlacementId: $jobVisitPlacementId) {
      isLocked
    }
  }
`;

export const TOGGLE_MUTE_JOB_VISIT_PLACEMENT_MUTATION = gql`
  mutation ($jobVisitPlacementId: ID!) {
    toggleMuteJobVisitPlacement(jobVisitPlacementId: $jobVisitPlacementId) {
      isMuted
    }
  }
`;

export const COMMIT_MULTI_ASSIGNMENT_MUTATION = gql`
  mutation ($jobGroupId: ID!, $reportTemplateIds: [ID!]!) {
    commitMultiAssignment(
      jobGroupId: $jobGroupId
      reportTemplateIds: $reportTemplateIds
    ) {
      multiAssignmentBatch {
        id
        placements {
          id
          isOriginal
          isLocked
          isMuted
          isCommitted
          job {
            id
            reference
            targetDateTimeStart
            targetDateTimeEnd
          }
          dateTimeStart
          dateTimeEnd
          user {
            id
            initials
            name
          }
          statuses {
            type
            meta {
              overlappingJobVisits {
                id
              }
            }
          }
          jobVisit {
            id
          }
        }
      }
      jobVisits {
        id
        label
        number
        dateTimeStart
        dateTimeEnd
        unallocated
        status {
          id
          name
          nameDisplay
          color
        }
        user {
          id
          name
        }
        job {
          id
          reference
          name
          status {
            id
            name
            nameDisplay
            flag {
              id
              color
              name
            }
          }
          assignees {
            id
            name
          }
        }
      }
    }
  }
`;
