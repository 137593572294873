import React, { useEffect, useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { JobVisit } from 'lib/types';
import { DateTime, DurationObject } from 'luxon';
import {
  setSchedulerReadOnly,
  setSchedulerSelectMode,
  useReducerContext,
} from '../ClientJob/reducer';
import ClientJobScheduler from '../ClientJobScheduler';
import { Small } from '../Typography';
import DurationPicker from '../DurationPicker';

const ClientJobDateTimeField = ({ input }: FieldRenderProps<string>) => {
  const [, dispatch] = useReducerContext();

  const [visit, setVisit] = useState<JobVisit>();
  const [offset, setOffset] = useState<DurationObject>({ hours: 1 });

  useEffect(() => {
    dispatch(setSchedulerReadOnly(true));
    dispatch(setSchedulerSelectMode('eot'));
  }, [dispatch]);

  const getDateTime = (dateTimeStart: string) => {
    return DateTime.fromISO(dateTimeStart).plus(offset).toISO();
  };

  const handleVisitOnSelect = (selectedVisit: JobVisit) => {
    return new Promise<JobVisit>((resolve) => {
      setVisit(selectedVisit);
      input.onChange(getDateTime(selectedVisit.dateTimeStart));
      resolve(selectedVisit);
    });
  };

  const handleOffsetOnChange = (newOffset: DurationObject) => {
    if (!visit) return;
    setOffset(newOffset);
    input.onChange(getDateTime(visit.dateTimeStart));
  };

  return (
    <div>
      <div className="d-flex p-3">
        <div className="mr-5">
          <Small>Select the problematic visit</Small>
          <p>
            Use the scheduler below to select the visit that has caused this
            issue.
          </p>
        </div>
        <div>
          <DurationPicker value={offset} onChange={handleOffsetOnChange} />
        </div>
      </div>
      <div style={{ height: 800 }}>
        <ClientJobScheduler
          viewDefault="response"
          visitSelectOffset={offset}
          visitOnSelect={handleVisitOnSelect}
        />
      </div>
    </div>
  );
};

export default ClientJobDateTimeField;
