import chroma from 'chroma-js';
import { ThemeState } from '../redux/types';

const getColorForFlagType = (theme: ThemeState, flagType: string) => {
  if (!flagType) {
    return {
      color: 'black',
      backgroundColor: '#efefef',
    };
  }
  switch (flagType.toUpperCase()) {
    case 'IMMEDIATE':
      return {
        color: chroma('red').alpha(0.75).hex(),
        backgroundColor: 'red',
      };
    case 'BLUE':
      return {
        color: 'cyan',
        backgroundColor: 'cyan',
      };
    case 'GREEN':
      return {
        color: '#106c12',
        backgroundColor: '#106c12',
      };
    case 'AMBER':
      return {
        color: 'orange',
        backgroundColor: 'orange',
      };
    case 'RED':
      return {
        color: 'red',
        backgroundColor: 'red',
      };
    case 'WHITE':
      return {
        color: theme.color.cardText.hex(),
        backgroundColor: 'white',
      };
    default:
      return {
        color: 'black',
        backgroundColor: '#efefef',
      };
  }
};

export default getColorForFlagType;
