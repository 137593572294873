import React, { Dispatch, useContext } from 'react';
import { AnyAction } from 'redux';
import { PeopleState, PeopleQueryData, SyncUsersMutationData } from './types';
import { UserQueryData } from '../PeoplePerson/types';

export const initialState: PeopleState = {
  users: [],
  user: null,
  userRequests: [],
};

export const ReducerContext = React.createContext<
  [PeopleState, Dispatch<AnyAction>]
>([initialState, () => {}]);

export const useReducerContext = () => useContext(ReducerContext);

export const usersQueryOnCompleted = (responseData: PeopleQueryData) => ({
  type: 'USERS_QUERY_ON_COMPLETED',
  users: responseData.users,
});

export const syncUsersMutationOnCompleted = (
  responseData: SyncUsersMutationData
) => ({
  type: 'SYNC_USERS_MUTATION_ON_COMPLETED',
  users: responseData.syncUsers.users,
});

export const userQueryOnCompleted = (responseData: UserQueryData) => ({
  type: 'USER_QUERY_ON_COMPLETED',
  user: responseData.user,
});

export const clearUser = () => ({
  type: 'CLEAR_USER',
});

const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case 'USERS_QUERY_ON_COMPLETED':
      return {
        ...state,
        users: action.users,
      };
    case 'USER_QUERY_ON_COMPLETED':
      return {
        ...state,
        user: action.user,
      };
    case 'CLEAR_USER':
      return {
        ...state,
        user: null,
      };
    case 'SYNC_USERS_MUTATION_ON_COMPLETED':
      return {
        ...state,
        users: [...state.users, ...action.users],
      };
    default:
      return state;
  }
};

export default reducer;
