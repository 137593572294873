import React from 'react';
import { Router, Switch } from 'wouter';
import { multiPathRouteMatcher } from 'lib/utils';
import Layout from '../Layout';
import ProtectedRoute from '../ProtectedRoute';
import HomeDashboard from '../HomeDashboard';
import HomeClients from '../HomeClients';
import HomeJobs from '../HomeJobs';
import ClientDashboard from '../ClientDashboard';
import ClientJobs from '../ClientJobs';
import ClientJobInstantiation from '../ClientJobInstantiation';
import ClientJobGroup from '../ClientJobGroup';
import ClientJob from '../ClientJob';
import ClientJobsCreateReactive from '../ClientJobsCreateReactive';
import ClientJobsCreatePPM from '../ClientJobsCreatePPM';
import ClientPeople from '../ClientPeople';
import ClientImmediateActions from '../ClientImmediateActions';
import ClientSites from '../ClientSites';
import ClientWorkflow from '../ClientWorkflow';
import Settings from '../Settings';
import People from '../People';
import Profile from '../Profile';
import ClientSettings from '../ClientSettings';

const HomeRoutes = () => (
  <Switch>
    <ProtectedRoute path="/" component={HomeDashboard} />
    <ProtectedRoute path="/clients" component={HomeClients} />
    <ProtectedRoute path="/jobs" component={HomeJobs} />
  </Switch>
);

const ClientRoutes = () => (
  <Switch>
    <ProtectedRoute
      path={['/clients/:slug', '/clients/:slug/schedule']}
      component={ClientDashboard}
    />
    <ProtectedRoute
      path={[
        '/clients/:slug/jobs',
        '/clients/:slug/jobs/ppm',
        '/clients/:slug/jobs/reactive',
      ]}
      component={ClientJobs}
    />
    <ProtectedRoute
      path="/clients/:slug/jobs/instantiate"
      component={ClientJobInstantiation}
    />
    <ProtectedRoute
      path="/clients/:slug/jobs/create-reactive"
      component={ClientJobsCreateReactive}
    />
    <ProtectedRoute
      path="/clients/:slug/jobs/create-ppm"
      component={ClientJobsCreatePPM}
    />
    <ProtectedRoute<{ reference: string }>
      path="/clients/:slug/job-groups/:reference/:rest*"
      component={ClientJobGroup}
    />
    <ProtectedRoute
      path="/clients/:slug/jobs/:reference/:rest*"
      component={ClientJob}
    />
    <ProtectedRoute path="/clients/:slug/people" component={ClientPeople} />
    <ProtectedRoute
      path="/clients/:slug/immediate-actions/:rest*"
      component={ClientImmediateActions}
    />
    <ProtectedRoute path="/clients/:slug/sites" component={ClientSites} />
    <ProtectedRoute path="/clients/:slug/workflow" component={ClientWorkflow} />
    <ProtectedRoute
      path="/clients/:slug/settings/:rest*"
      component={ClientSettings}
    />
  </Switch>
);

const LayoutRouter = () => (
  <Layout>
    <Router matcher={multiPathRouteMatcher}>
      <Switch>
        <ProtectedRoute path="/clients/:slug/:rest*" component={ClientRoutes} />
        <ProtectedRoute path="/profile/:rest*" component={Profile} />
        <ProtectedRoute path="/people/:rest*" component={People} />
        <ProtectedRoute path="/settings" component={Settings} />
        <ProtectedRoute path="/:rest*" component={HomeRoutes} />
      </Switch>
    </Router>
  </Layout>
);

export default LayoutRouter;
