import { useCallback, useState } from 'react';

type Return<T extends HTMLElement> = [T | undefined, (node: T) => void];

const useCallbackRef = <T extends HTMLElement>(): Return<T> => {
  const [current, setCurrent] = useState<T>();
  const callbackRef = useCallback((node: T) => {
    if (node !== null) {
      setCurrent(node);
    }
  }, []);
  return [current, callbackRef];
};

export default useCallbackRef;
