import styled from 'styled-components';
import { TableStyledProps } from './types';

export const TableStyled = styled.table<TableStyledProps>`
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  display: block;
  empty-cells: show;

  thead {
    display: block;
    position: relative;
    width: 100%;

    tr {
      width: 100%;
      display: flex;
      border-bottom: ${({ theme }) => theme.border};
      padding-right: 5px;
    }

    ${({ longColumns = [] }) =>
      longColumns.map(
        (longColumn) => `
        th:nth-child(${longColumn}) {
          flex-basis: 100%;
          flex-grow: 2;
        }
      `
      )};

    ${({ flexBases = [] }) =>
      flexBases.map(
        (flexBasis, index) => `
      th:nth-child(${index + 1}) {
        flex-basis: ${flexBasis}%;
      } 
    `
      )};

    th:first-child {
      padding-left: 1rem;
    }

    th {
      ${({ flexBases }) => !flexBases && 'flex-basis: 50%'};
      flex-grow: 1;
      display: block;
      padding-bottom: 0.5rem;
    }
  }

  tbody {
    display: block;
    position: relative;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100% - 34px);

    /* width */
    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: ${({ theme }) => theme.color.scrollBarTrack.hex()};
      border-radius: 1px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.color.scrollBarThumb.hex()};
      border-radius: 1px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: ${({ theme }) => theme.color.scrollBarThumbHover.hex()};
    }

    tr {
      width: 100%;
      display: flex;
      cursor: pointer;
      border-bottom: ${({ theme }) => theme.border};
      transition: 100ms all;

      &.empty-message {
        cursor: auto;
        border-bottom: none;

        &:hover {
          background-color: transparent;
        }
      }

      &.last {
        border-bottom: none;
      }

      &:hover {
        transition: 30ms all;
        background-color: ${({ theme }) =>
          theme.color.tableBackgroundHover.brighten(0.05).hex()};

        td.show-on-hover {
          opacity: 1;
        }
      }

      &.active {
        background-color: ${({ theme }) =>
          theme.color.tableBackgroundHover.hex()};
      }

      td:first-child {
        padding-left: 1rem;
      }

      a {
        color: ${({ theme }) => theme.color.cardText.hex()};
      }
    }

    tr:last-child {
      border-bottom: none;
    }

    ${({ longColumns = [] }) =>
      longColumns.map(
        (longColumn) => `
          td:nth-child(${longColumn}) {
            flex-basis: 100%;
            flex-grow: 2;
          }
        `
      )};

    ${({ flexBases = [] }) =>
      flexBases.map(
        (flexBasis, index) => `
      td:nth-child(${index + 1}) {
        flex-basis: ${flexBasis}%;
      } 
    `
      )};

    td {
      ${({ flexBases }) => !flexBases && 'flex-basis: 50%'};
      flex-grow: 1;
      display: block;
      padding: 1rem 0 1rem 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &.table-cell-with-button {
        padding: 11px 0;
        overflow: visible;

        button {
          line-height: 1;
          margin-left: -0.7rem;
        }
      }

      &.table-cell-with-checkbox {
        label {
          margin: 0;
        }
      }
    }

    td.show-on-hover {
      opacity: 0;
    }
  }

  table.inner-table {
    tr {
      background-color: ${({ theme }) =>
        theme.color.cardBackground.darken(0.1).hex()};
    }
  }
`;
