import { useSelector } from 'react-redux';
import { State } from '../redux/types';
import { User } from '../types';

const useUser = (): User => {
  const { user } = useSelector((state: State) => state);
  return user;
};

export default useUser;
