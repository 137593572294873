import { gql } from '@apollo/client';

export const REPORT_QUERY = gql`
  query ($jobId: ID, $jobReportNumber: Int) {
    jobReport(jobId: $jobId, jobReportNumber: $jobReportNumber) {
      id
      name
      number
      template {
        id
        name
      }
      sentToClient
      dateTimeSentToClient
      defaultMessage
      versions {
        id
        versionNumber
        isLatest
        pdfUrl
        editUrl
        pageCount
        pages {
          id
          pageNumber
          status
        }
      }
    }
  }
`;

export const UPDATE_REPORT_VERSION_PAGE_MUTATION = gql`
  mutation ($sessionId: ID, $jobReportVersionPageId: ID, $status: String) {
    updateJobReportVersionPage(
      sessionId: $sessionId
      jobReportVersionPageId: $jobReportVersionPageId
      status: $status
    ) {
      jobReportVersionPage {
        id
        pageNumber
        status
      }
    }
  }
`;

export const SEND_REPORT_TO_CLIENT_MUTATION = gql`
  mutation ($sessionId: ID, $id: ID) {
    sendJobReportToClient(sessionId: $sessionId, jobReportId: $id) {
      jobReport {
        id
        sentToClient
        dateTimeSentToClient
        latestVersion {
          id
          versionNumber
          isLatest
          pdfUrl
          editUrl
          pageCount
          pages {
            id
            pageNumber
            status
          }
        }
        versions {
          id
          versionNumber
          isLatest
          pdfUrl
          editUrl
          pageCount
          pages {
            id
            pageNumber
            status
          }
        }
      }
    }
  }
`;
