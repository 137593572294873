import React, { useState } from 'react';
import { Form, Field, useFormState } from 'react-final-form';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { DateTime } from 'luxon';
import { SchedulerEventModel } from '@bryntum/schedulerpro/schedulerpro.umd.js';
import { JobReportTemplate, JobVisitNumber, JobVisitWorkType } from 'lib/types';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons/faArrowRight';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons/faArrowLeft';
import { useCaptureServerError } from 'lib/hooks';
import SidePanel from '../SidePanel';
import JobVisitAssigneeField from '../JobVisitAssigneeField';
import TimeField from '../TimeField';
import SimpleButton from '../SimpleButton';
import ReportsField from '../ReportsField';
import { JobVisitSchedulerCreateSidePanelProps } from './types';
import CharField from '../CharField';
import Slides, { Slide } from '../Slides';
import {
  setSchedulerReadOnly,
  setSchedulerSelectMode,
  useReducerContext,
} from '../ClientJob/reducer';
import JobVisitSchedulerReportsField from '../JobVisitSchedulerReportsField';
import { Small } from '../Typography';
import JobVisitWorkTypeField from '../JobVisitWorkTypeField';

const ValuesDescription = () => {
  const { values } = useFormState();
  const steps = [];
  values.createNewReports.templates.forEach((template: any) => {
    steps.push(
      <>
        Create new report: <strong>{template.name}</strong>
      </>
    );
  });
  if (values.addToReports.parent) {
    steps.push(
      <>
        Set visit <strong>{values.addToReports.parent.id}</strong> as parent
      </>
    );
  }
  values.addToReports.reports.forEach((report: any) => {
    steps.push(
      <>
        Add to report: <strong>{report.template.name}</strong>
      </>
    );
  });
  return (
    <div>
      <div className="mb-2">
        <Small>Result</Small>
      </div>
      <small>
        <ul className="pl-3">
          {steps.map((step, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={index}>{step}</li>
          ))}
        </ul>
      </small>
    </div>
  );
};

const JobVisitSchedulerCreateSidePanel = ({
  job,
  schedulerRef,
  isOpen,
  eventRecord,
  setEventRecord,
  toggle,
  visitOnCreate,
}: JobVisitSchedulerCreateSidePanelProps) => {
  const [{ selectedJobVisit }, dispatch] = useReducerContext();
  const [overlay, setOverlay] = useState(true);

  const getEventRecordObject = (): SchedulerEventModel | null => {
    if (!schedulerRef.current || !eventRecord) return null;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return schedulerRef.current.instance.eventStore.getById(eventRecord);
  };

  const captureServerError = useCaptureServerError();

  const handleOnSubmit = (values: any) => {
    if (!eventRecord) return;
    const data = {
      label: values.label,
      dateTimeStart: values.dateTimeStart,
      dateTimeEnd: values.dateTimeEnd,
      userId: values.user.id,
      reportTemplates: values.createNewReports.templates.map(
        (template: JobReportTemplate) => template.id
      ),
      reports: [],
      workTypes: (values.workTypes ?? []).map(
        (workType: JobVisitWorkType) => workType.id
      ),
    };
    visitOnCreate([data])
      .then((result) => {
        if (!result) return;
        const [createdVisit, responseData] = result;
        const eventRecordObject = getEventRecordObject();
        if (eventRecordObject) {
          eventRecordObject.id = createdVisit.id;
          eventRecordObject.name = createdVisit.label;
          eventRecordObject.isCreating = false;
          eventRecordObject.set('name', createdVisit.label);
          eventRecordObject.set('job', createdVisit.job);
          eventRecordObject.set('jobVisit', createdVisit);
          eventRecordObject.set('inSeries', true);
        }
        responseData.createJobVisit.jobVisitNumbers.forEach(
          (jobVisitNumber: JobVisitNumber) => {
            schedulerRef.current?.instance.eventStore
              .getById(jobVisitNumber.id)
              .set('number', jobVisitNumber.number);
          }
        );
      })
      .catch((error) => {
        if (!error.graphQLErrors) return;
        const [graphqlError] = error.graphQLErrors;
        eventRecord.remove();
        captureServerError(graphqlError);
      });
    toggle(false);
  };

  const handleOnCancel = () => {
    if (!schedulerRef.current) return;
    if (!eventRecord) return;
    schedulerRef.current.instance.eventStore.remove(eventRecord);
    dispatch(setSchedulerReadOnly(false));
    dispatch(setSchedulerSelectMode(undefined));
    setEventRecord(null);
    toggle(false);
  };

  return (
    <>
      <SidePanel
        title="Create visit"
        isOpen={isOpen}
        overlay={overlay}
        toggle={handleOnCancel}
      >
        {eventRecord && (
          <Form
            initialValues={{
              label: 'New Visit',
              user: eventRecord.resource,
              dateTimeStart: DateTime.fromJSDate(
                eventRecord.startDate as Date
              ).toISO(),
              dateTimeEnd: DateTime.fromJSDate(
                eventRecord.endDate as Date
              ).toISO(),
              createNewReports: {
                templates: [],
                reports: [],
              },
              addToReports: {
                parent: selectedJobVisit,
                reports: [],
              },
            }}
            onSubmit={handleOnSubmit}
          >
            {({ handleSubmit }) => (
              <form onSubmit={() => {}}>
                <Slides
                  renderButtonToolbar={({ page, nextPage, prevPage }) => (
                    <div className="d-flex justify-content-end">
                      {page > 1 && (
                        <SimpleButton icon={faArrowLeft} onClick={prevPage}>
                          Back
                        </SimpleButton>
                      )}
                      {page < 2 && (
                        <SimpleButton
                          icon={faArrowRight}
                          iconSide="right"
                          onClick={nextPage}
                        >
                          Continue
                        </SimpleButton>
                      )}
                      {page === 2 && (
                        <SimpleButton icon={faCheck} onClick={handleSubmit}>
                          Create visit
                        </SimpleButton>
                      )}
                    </div>
                  )}
                >
                  <Slide>
                    <div className="mb-3">
                      <Field
                        name="label"
                        component={CharField}
                        parse={(value) => {
                          const eventRecordObject = getEventRecordObject();
                          if (eventRecordObject) {
                            eventRecordObject.name = value;
                          }
                          return value;
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <Field
                        name="user"
                        component={JobVisitAssigneeField}
                        schedulerRef={schedulerRef}
                        parse={(value) => {
                          const eventRecordObject = getEventRecordObject();
                          if (eventRecordObject) {
                            eventRecordObject.resourceId = value.id;
                          }
                          return value;
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <Field
                        label="Start time"
                        name="dateTimeStart"
                        component={TimeField}
                        parse={(value) => {
                          const eventRecordObject = getEventRecordObject();
                          if (eventRecordObject) {
                            eventRecordObject.startDate = value;
                          }
                          return value;
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <Field
                        label="End time"
                        name="dateTimeEnd"
                        component={TimeField}
                        parse={(value) => {
                          const eventRecordObject = getEventRecordObject();
                          if (eventRecordObject) {
                            eventRecordObject.endDate = value;
                          }
                          return value;
                        }}
                      />
                    </div>
                    <div className="mb-4">
                      <div>
                        <Small className="ml-2">Work types (optional)</Small>
                      </div>
                      <Field
                        label="Work types (optional)"
                        name="workTypes"
                        component={JobVisitWorkTypeField}
                      />
                    </div>
                  </Slide>
                  <Slide page={2}>
                    {({ active }) => (
                      <>
                        <div className="mb-4">
                          {eventRecord?.resource && (
                            <>
                              <div className="mb-4">
                                <Field
                                  name="createNewReports"
                                  component={ReportsField}
                                  userGroupId={
                                    // @ts-ignore
                                    eventRecord.resource.userGroup.id
                                  }
                                  jobId={job.id}
                                />
                              </div>
                            </>
                          )}
                        </div>
                        <div className="mb-4">
                          <Field
                            name="addToReports"
                            component={JobVisitSchedulerReportsField}
                            active={active}
                            setOverlay={setOverlay}
                          />
                        </div>
                        <div className="mb-4">
                          <ValuesDescription />
                        </div>
                      </>
                    )}
                  </Slide>
                </Slides>
              </form>
            )}
          </Form>
        )}
      </SidePanel>
    </>
  );
};

export default JobVisitSchedulerCreateSidePanel;
