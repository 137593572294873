import styled from 'styled-components';
import { JobRowProps, JobVisitRowProps } from './types';
import Table from '../Table';

export const Wrapper = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
`;

export const TableRowStyled = styled.tr`
  span {
    line-height: 34px;
  }
`;

export const JobTable = styled(Table)`
  thead {
    th {
      padding-top: 0.5rem;
    }
  }

  th.decoration-wrapper {
    padding-bottom: 0;

    div.decoration {
      position: relative;
      height: calc(100% + 0.5rem);
      margin-top: -0.5rem;
      width: 100%;
      border-left: 2px solid rgb(128, 128, 128);
      margin-left: 1rem;
      margin-right: 0.5rem;
    }
  }
`;

export const JobRow = styled.tr<JobRowProps>`
  border-radius: ${({ last }) => (last ? '0 0 0.5rem 0.5rem' : 0)};

  div.decoration {
    position: relative;
    height: 50%;
    width: calc(100% - 2rem);
    border-radius: 0 0 0 6px;
    border-left: 2px solid rgb(128, 128, 128);
    border-bottom: 2px solid rgb(128, 128, 128);
    margin-left: 1rem;
    margin-right: 0.5rem;

    &::before {
      content: '';
      position: absolute;
      top: -17px;
      left: -2px;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 18px;
      border-left: 2px solid rgb(128, 128, 128);
    }

    ${({ last }) =>
      !last &&
      `      
      &::after {
        content: '';
        position: absolute;
        top: 8px;
        bottom: -17px;
        left: -2px;
        right: 0;
        width: 100%;
        height: 48px;
        border-left: 2px solid rgb(128, 128, 128);
      }
    `}
  }
`;

export const JobVisitRow = styled.tr<JobVisitRowProps>`
  div.decoration {
    position: relative;
    height: 100%;
    width: calc(100% - 2rem);
    border-left: 2px solid rgb(128, 128, 128);
    margin-left: 1rem;
    margin-right: 0.5rem;
  }

  div.second-decoration {
    position: relative;
    height: 50%;
    width: calc(100% - 2rem);
    border-radius: 0 0 0 6px;
    border-left: 2px solid rgb(128, 128, 128);
    border-bottom: 2px solid rgb(128, 128, 128);
    margin-left: 1rem;
    margin-right: 0.5rem;

    ${({ last }) =>
      !last &&
      `      
      &::after {
        content: '';
        position: absolute;
        top: 8px;
        bottom: -17px;
        left: -2px;
        right: 0;
        width: 100%;
        height: 32px;
        border-left: 2px solid rgb(128, 128, 128);
      }
    `}
  }
`;
