import React, {
  Reducer,
  useCallback,
  useEffect,
  useReducer,
  useRef,
} from 'react';
import { AnyAction } from 'redux';
import { Spec } from 'immutability-helper';
import classNames from 'classnames';
import reducer, {
  getInitialState,
  onDragApply,
  onDragRevert,
  onDragStart,
  onItemsUpdate,
  onMoveItems,
  ReducerContext,
  toggleCollapse,
} from './reducer';
import { NestableProps, State } from './types';
import {
  getClosestElement,
  getOffsetRect,
  getTotalScroll,
  getTransformProps,
  listWithChildren,
} from '../../utils';
import { Item, Path } from '../../types';
import NestableItem from '../NestableItem';
import { NestableWrapper, NestableDragLayer, NestableList } from './styled';

const Nestable = <T extends Item<T>>({
  items: initialItems,
  maxDepth = 10,
  threshold = 30,
  handler,
  defaultCollapsedGroups = [],
  renderItem,
  renderCollapseIcon,
  confirmChange,
  onChange,
  onToggleCollapseGroup,
}: NestableProps<T>) => {
  const group = '123';

  const [state, dispatch] = useReducer<Reducer<State<T>, AnyAction>>(
    reducer<T>(),
    getInitialState<T>(defaultCollapsedGroups)
  );

  const { items, dragItem, collapsedGroups, meta } = state;

  const itemsRef = useRef<T[]>([]);
  useEffect(() => {
    itemsRef.current = items;
  }, [items]);

  const dragItemRef = useRef<T | null>(null);
  useEffect(() => {
    dragItemRef.current = dragItem;
  }, [dragItem]);

  const isDirtyRef = useRef<boolean>(false);
  useEffect(() => {
    isDirtyRef.current = meta.isDirty;
  }, [meta.isDirty]);

  const elementRef = useRef<HTMLElement | null>(null);
  const elementStyleRef = useRef<React.CSSProperties>({});
  const mouseRef = useRef<{ last: { x: number }; shift: { x: number } }>({
    last: { x: 0 },
    shift: { x: 0 },
  });

  const getPathById = (
    id: T['id'] | undefined,
    nextItems: T[] = itemsRef.current
  ) => {
    let path: Path = [];

    nextItems.every((item, index) => {
      if (item.id === id) {
        path.push(index);
      } else if (item.children) {
        const childrenPath = getPathById(id, item.children);

        if (childrenPath.length) {
          path = [...path, index, ...childrenPath];
        }
      }

      return path.length === 0;
    });

    return path;
  };

  const getItemByPath = (
    path: number[],
    nextItems: T[] = itemsRef.current
  ): T | null => {
    let item: T | null = null;

    path.forEach((pathIndex) => {
      const list = item ? item.children : nextItems;
      item = list[pathIndex];
    });

    return item;
  };

  const getItemSize = (item: T) => {
    let depth = 1;

    if (item.children.length > 0) {
      const childrenDepths = item.children.map(getItemSize);
      depth += Math.max(...childrenDepths);
    }

    return depth;
  };

  const getIsCollapsed = (item: T) => {
    return collapsedGroups.includes(item.id);
  };

  const getRealNextPath = (
    prevPath: Path,
    nextPath: Path,
    dragItemSize: number
  ): Path => {
    const prevPathLastIndex = prevPath.length - 1;
    const nextPathLastIndex = nextPath.length - 1;
    const newDepth = nextPath.length + dragItemSize - 1;

    if (prevPath.length < nextPath.length) {
      let wasShifted = false;

      if (newDepth > maxDepth && nextPath.length) {
        return getRealNextPath(prevPath, nextPath.slice(0, -1), dragItemSize);
      }

      return nextPath.map((nextIndex, index) => {
        if (wasShifted) {
          return index === nextPathLastIndex ? nextIndex + 1 : nextIndex;
        }

        if (typeof prevPath[index] !== 'number') {
          return nextIndex;
        }

        if (nextPath[index] > prevPath[index] && index === prevPathLastIndex) {
          wasShifted = true;
          return nextIndex - 1;
        }

        return nextIndex;
      });
    }
    if (prevPath.length === nextPath.length) {
      if (nextPath[nextPathLastIndex] > prevPath[nextPathLastIndex]) {
        const target = getItemByPath(nextPath);

        if (
          newDepth < maxDepth &&
          target &&
          target.children &&
          target.children.length &&
          !getIsCollapsed(target)
        ) {
          return [...nextPath.slice(0, -1), nextPath[nextPathLastIndex] - 1, 0];
        }
      }
    }

    return nextPath;
  };

  const onToggleCollapse = (item: T) => {
    dispatch(toggleCollapse(item));
    if (onToggleCollapseGroup) onToggleCollapseGroup(item);
  };

  const moveItem = ({
    item,
    pathFrom,
    pathTo,
  }: {
    item: T;
    pathFrom: Path;
    pathTo: Path;
  }) => {
    const dragItemSize = getItemSize(item);
    const realPathTo = getRealNextPath(pathFrom, pathTo, dragItemSize);

    if (realPathTo.length === 0) return;

    const destinationPath =
      realPathTo.length > pathTo.length ? pathTo : pathTo.slice(0, -1);
    const destinationParent = getItemByPath(destinationPath);

    if (!confirmChange({ dragItem: item, destinationParent })) return;

    const removePath = getSplicePath(pathFrom, { numberToRemove: 1 });

    const insertPath = getSplicePath(realPathTo, {
      numberToRemove: 0,
      itemsToInsert: [item],
    });

    dispatch(onMoveItems(removePath, insertPath));
  };

  const tryIncreaseDepth = (item: T | null) => {
    if (!item) return;
    const pathFrom = getPathById(item.id);
    const itemIndex = pathFrom[pathFrom.length - 1];
    const newDepth = pathFrom.length + getItemSize(item);

    if (itemIndex > 0 && newDepth <= maxDepth) {
      const prevSibling = getItemByPath([
        ...pathFrom.slice(0, -1),
        itemIndex - 1,
      ]);

      if (!prevSibling) return;

      if (!prevSibling.children.length || !getIsCollapsed(prevSibling)) {
        const pathTo = [
          ...pathFrom.slice(0, -1),
          itemIndex - 1,
          prevSibling.children.length,
        ];

        moveItem({ item, pathFrom, pathTo });
      }
    }
  };

  const tryDecreaseDepth = (item: T | null) => {
    if (!item) return;
    const pathFrom = getPathById(item.id);
    const itemIndex = pathFrom[pathFrom.length - 1];

    if (pathFrom.length > 1) {
      const parent = getItemByPath(pathFrom.slice(0, -1));
      if (!parent) return;

      if (itemIndex + 1 === parent.children.length) {
        const pathTo = pathFrom.slice(0, -1);
        pathTo[pathTo.length - 1] += 1;

        moveItem({ item, pathFrom, pathTo });
      }
    }
  };

  const startTrackMouse = () => {
    document.addEventListener('mousemove', handleOnMouseMove);
    // @ts-ignore
    document.addEventListener('mouseup', handleOnDragEnd);
    document.addEventListener('keydown', handleOnKeyDown);
  };

  const stopTrackMouse = () => {
    document.removeEventListener('mousemove', handleOnMouseMove);
    // @ts-ignore
    document.removeEventListener('mouseup', handleOnDragEnd);
    document.removeEventListener('keydown', handleOnKeyDown);
    elementStyleRef.current = {};
  };

  const handleOnMouseMove = (event: MouseEvent) => {
    const item = dragItemRef.current;
    const { clientX, clientY } = event;
    const transformProps = getTransformProps(clientX, clientY);
    const elementCopy = document.querySelector(
      `.nestable-${group} .nestable-drag-layer > .nestable-list`
    ) as HTMLElement;

    if (!elementRef.current) return;
    if (!elementStyleRef.current) {
      const offset = getOffsetRect(elementRef.current);
      const scroll = getTotalScroll(elementRef.current);

      elementStyleRef.current = {
        marginTop: offset.top - clientY - scroll.top,
        marginLeft: offset.left - clientX - scroll.left,
        ...transformProps,
      };
    } else {
      elementStyleRef.current = {
        ...elementStyleRef.current,
        ...transformProps,
      };

      for (const key in transformProps) {
        if (transformProps.hasOwnProperty(key)) {
          if (!elementCopy?.style) continue;
          // @ts-ignore
          elementCopy.style[
            key as keyof Omit<CSSStyleDeclaration, 'length' | 'parentRule'>
          ] = transformProps[key as keyof React.CSSProperties];
        }
      }

      const diffX = clientX - mouseRef.current.last.x;
      if (
        (diffX >= 0 && mouseRef.current.shift.x >= 0) ||
        (diffX <= 0 && mouseRef.current.shift.x <= 0)
      ) {
        mouseRef.current.shift.x += diffX;
      } else {
        mouseRef.current.shift.x = 0;
      }
      mouseRef.current.last.x = clientX;

      if (Math.abs(mouseRef.current.shift.x) > threshold) {
        if (mouseRef.current.shift.x > 0) {
          tryIncreaseDepth(item);
        } else {
          tryDecreaseDepth(item);
        }

        mouseRef.current.shift.x = 0;
      }
    }
  };

  const dragApply = () => {
    if (dragItemRef.current && isDirtyRef.current) {
      const targetPath = getPathById(dragItemRef.current.id);
      onChange({
        items: itemsRef.current,
        dragItem: dragItemRef.current,
        targetPath,
      });
    }

    dispatch(onDragApply());
  };

  const dragRevert = () => {
    dispatch(onDragRevert());
  };

  const handleOnDragStart = (event: MouseEvent, item: T) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    elementRef.current = getClosestElement(
      event.target as HTMLElement,
      '.nestable-item'
    );

    startTrackMouse();
    handleOnMouseMove(event);

    dispatch(onDragStart(item));
  };

  const handleOnDragEnd = (event: MouseEvent, isCancel: any) => {
    if (event) {
      event.preventDefault();
    }

    stopTrackMouse();
    elementRef.current = null;

    isCancel ? dragRevert() : dragApply();
  };

  const handleOnKeyDown = () => {};

  useEffect(() => {
    stopTrackMouse();

    dispatch(onItemsUpdate(listWithChildren<T>(initialItems ?? [])));

    return () => {
      stopTrackMouse();
    };
  }, [initialItems]);

  const handleOnMouseEnter = (event: MouseEvent, item: T) => {
    if (!dragItem) return;

    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    const pathFrom = getPathById(dragItem.id, items);
    const pathTo = getPathById(item.id, items);

    moveItem({ item: dragItem, pathFrom, pathTo });
  };

  const getSplicePath = (
    path: Path,
    options: { numberToRemove?: number; itemsToInsert?: T[] } = {}
  ) => {
    const splicePath: { $splice?: Spec<T>; [name: number]: any } = {};
    const numberToRemove = options.numberToRemove || 0;
    const itemsToInsert = options.itemsToInsert || [];
    const lastIndex = path.length - 1;
    let currentPath = splicePath;

    path.forEach((pathIndex, index) => {
      if (index === lastIndex) {
        // @ts-ignore
        currentPath.$splice = [[pathIndex, numberToRemove, ...itemsToInsert]];
      } else {
        const nextPath = {};
        currentPath[pathIndex] = { children: nextPath };
        currentPath = nextPath;
      }
    });

    return splicePath;
  };

  const getItemOptions = useCallback(() => {
    return {
      dragItem,
      renderItem,
      renderCollapseIcon,
      handler,
      onDragStart: handleOnDragStart,
      onMouseEnter: handleOnMouseEnter,
      isCollapsed: getIsCollapsed,
      onToggleCollapse,
    };
  }, [
    dragItem,
    getIsCollapsed,
    handleOnDragStart,
    handleOnMouseEnter,
    handler,
    onToggleCollapse,
    renderCollapseIcon,
    renderItem,
  ]);

  const renderDragLayer = () => {
    if (!dragItem) return null;

    const element = document.querySelector(
      `.nestable-${group} .nestable-item-${dragItem.id}`
    );

    let listStyle: React.CSSProperties = {};
    if (element) {
      listStyle.width = element.clientWidth;
    }
    if (elementStyleRef.current) {
      listStyle = {
        ...listStyle,
        ...elementStyleRef.current,
      };
    }

    const options = getItemOptions();
    return (
      <NestableDragLayer className="nestable-drag-layer">
        <NestableList className="nestable-list" style={listStyle}>
          <NestableItem item={dragItem} options={options} isCopy />
        </NestableList>
      </NestableDragLayer>
    );
  };

  const options = getItemOptions();
  return (
    <ReducerContext.Provider value={[state, dispatch]}>
      <NestableWrapper
        className={classNames('nestable', `nestable-${group}`, {
          'is-drag-active': !!dragItem,
        })}
      >
        <NestableList className="nestable-list nestable-group">
          {items.map((item, index) => (
            <NestableItem
              key={index}
              index={index}
              item={item}
              options={options}
            />
          ))}
        </NestableList>

        {dragItem && renderDragLayer()}
      </NestableWrapper>
    </ReducerContext.Provider>
  );
};

export default Nestable;
