import React, { useMemo } from 'react';
import { TableStyled } from './styled';
import { TableFCChildren, TableProps } from './types';

const Table = <T extends any[]>({
  children,
  selectableRows,
  data,
  renderRow,
  rowOnSelect,
  ...tableProps
}: TableProps<T>) => {
  const rows = useMemo(() => {
    if (!selectableRows || !renderRow || !data) return null;
    return data.map((datum) => renderRow(datum));
  }, [data, renderRow, selectableRows]);

  if (!selectableRows) {
    return <TableStyled {...tableProps}>{children}</TableStyled>;
  }

  const handleSelectRow = () => {};

  return (
    <TableStyled {...tableProps}>
      {(children as TableFCChildren)({ rows, handleSelectRow })}
    </TableStyled>
  );
};

export default Table;
