import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { Link } from 'wouter';
import {
  ProjectModel,
  SchedulerEventModel,
  SchedulerPro,
  SchedulerProConfig,
} from '@bryntum/schedulerpro/schedulerpro.umd.js';
import { faFlag } from '@fortawesome/pro-duotone-svg-icons/faFlag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useApolloClient, useLazyQuery, useQuery } from '@apollo/client';
import { BryntumSchedulerPro } from '@bryntum/schedulerpro-react';
import { DateTime } from 'luxon';
import { useClient, useHoveredId, useSetTaskbarData } from 'lib/hooks';
import { Col, Row } from 'reactstrap';
import { useTheme } from 'styled-components';
import { Job, JobStatusFlag as JobStatusFlagType, JobVisit } from 'lib/types';
import ClickToCopy from '../ClickToCopy';
import Figure from '../Figure';
import JobStatusFlag from '../JobStatusFlag';
import { JOB_VISIT_QUERY } from '../JobVisitScheduler/query';
import { renderTooltip } from '../JobVisitScheduler/renderers';
import { Small } from '../Typography';
import { renderColumn, renderEvent } from './renderers';
import {
  InitialQueryData,
  InitialQueryVariables,
} from '../ClientJobGroupScheduler/types';
import {
  schedulerConfig,
  addResources,
  fetchMoreVisitsOnCompleted,
  addVisits,
} from './utils';
import JobVisitSchedulerControls from '../JobVisitSchedulerControls';
import PortalBreadcrumb from '../PortalBreadcrumb';
import {
  INITIAL_QUERY,
  JOB_VISITS_PER_DAY_QUERY,
  JOBS_OF_INTEREST_QUERY,
} from './query';
import CardWrapper from '../CardWrapper';
import DatePicker from '../DatePicker';
import ScrollDiv from '../ScrollDiv';
import { JobOfInterest } from './styled';
import JobVisitSchedulerLegend from '../JobVisitSchedulerLegend';

// TODO: This can be optimised: on initial query use the webserver to determine
//  the current date and send the required visits, counts and jobs. Then when
//  the date changes retrieve the new visits, counts and jobs in one query.

const ClientDashboardSchedule = () => {
  const theme = useTheme();
  const client = useClient();

  useSetTaskbarData({
    type: 'SCHEDULE',
    label: `${client.name} schedule`,
    path: `/clients/${client.slug}/schedule`,
  });

  const schedulerRef = useRef<{ instance: SchedulerPro }>(null);
  const [schedulerProject] = useState(new ProjectModel());

  const initialViewDateTimeStart = useMemo(
    () => DateTime.local().startOf('day').plus({ hours: 6 }),
    []
  );
  const initialViewDateTimeEnd = useMemo(
    () => DateTime.local().startOf('day').plus({ day: 1 }).minus({ hours: 6 }),
    []
  );

  const [date, setDate] = useState(initialViewDateTimeStart);
  const [jobs, setJobs] = useState<Job[]>([]);
  const [totals, setTotals] = useState<JobStatusFlagType[]>([]);

  useQuery(JOBS_OF_INTEREST_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      clientId: client.id,
      dateTimeStart: date.toISO(),
      dateTimeEnd: date.plus({ day: 1 }).toISO(),
    },
    onCompleted: (data) => {
      setJobs(data.jobsOfInterest);
      setTotals(data.jobStatusFlagCounts);
    },
  });

  useEffect(() => {
    if (!schedulerRef.current) return;
    schedulerRef.current.instance.setTimeSpan(
      date.startOf('day').plus({ hours: 6 }).toJSDate(),
      date.startOf('day').plus({ day: 1 }).minus({ hours: 6 }).toJSDate()
    );
  }, [date]);

  const { fetchMore } = useQuery<InitialQueryData, InitialQueryVariables>(
    INITIAL_QUERY,
    {
      fetchPolicy: 'no-cache',
      variables: {
        clientId: client.id,
        dateTimeStart: initialViewDateTimeStart.toISO(),
        dateTimeEnd: initialViewDateTimeEnd.toISO(),
      },
      onCompleted: (data) => {
        if (!schedulerRef.current) return;
        addResources(data.userGroups, schedulerProject, theme);
        addVisits(data.jobVisits, schedulerProject);
      },
    }
  );

  const handleOnForward = (unit: string) => {
    setDate((prevDate) => prevDate.plus({ [unit]: 1 }));
  };

  const handleOnBackward = (unit: string) => {
    setDate((prevDate) => prevDate.minus({ [unit]: 1 }));
  };

  const handleOnTimeAxisChange = useCallback(
    ({ config }: { config: SchedulerProConfig }) => {
      const newDateTimeStart = DateTime.fromJSDate(config.startDate as Date);
      const newDateTimeEnd = DateTime.fromJSDate(config.endDate as Date);
      fetchMore({
        variables: {
          dateTimeStart: newDateTimeStart.toISO(),
          dateTimeEnd: newDateTimeEnd.toISO(),
        },
      }).then((response) => {
        fetchMoreVisitsOnCompleted(response, schedulerProject);
      });
    },
    [fetchMore, schedulerProject]
  );

  const [jobVisitsPerDay, setJobVisitsPerDay] = useState<
    Record<string, number>
  >({});

  const [getJobVisitsPerDay] = useLazyQuery(JOB_VISITS_PER_DAY_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setJobVisitsPerDay(data.jobVisitsPerDay);
    },
  });

  useEffect(() => {
    getJobVisitsPerDay({
      variables: {
        clientId: client.id,
        dateTimeStart: date.startOf('month').startOf('week').toISO(),
        dateTimeEnd: date
          .startOf('month')
          .startOf('week')
          .plus({ days: 35 })
          .toISO(),
      },
    });
  }, [client.id, date.month, getJobVisitsPerDay]);

  const [[hoveredJobId], hoveredCallbacks] = useHoveredId('-1');

  useEffect(() => {
    const hoveredJob = jobs.find((job) => job.id === hoveredJobId);
    if (!schedulerRef.current) return;
    schedulerRef.current.instance.eventStore.allRecords.forEach(
      (eventRecord) => {
        // eslint-disable-next-line no-param-reassign
        (eventRecord as SchedulerEventModel).eventColor = (
          eventRecord as any
        ).originalColor;
      }
    );
    if (!hoveredJob) return;

    hoveredJob.visits.forEach((jobVisit) => {
      if (!schedulerRef.current) return;
      const eventRecord = schedulerRef.current.instance.eventStore.getById(
        jobVisit.id
      ) as SchedulerEventModel;
      if (eventRecord) {
        eventRecord.eventColor = 'purple';
      }
    });
  }, [hoveredJobId, jobs]);

  const apolloClient = useApolloClient();
  const getJobVisitById = useCallback(
    (id: JobVisit['id']) => {
      return apolloClient.query({
        query: JOB_VISIT_QUERY,
        fetchPolicy: 'no-cache',
        variables: { id },
      });
    },
    [apolloClient]
  );

  const tooltipTemplate = useCallback(
    ({ eventRecord }: { eventRecord: SchedulerEventModel }) => {
      if (
        eventRecord.isPhantom ||
        eventRecord.isCreating ||
        eventRecord.hasGeneratedId
      )
        return false;
      return getJobVisitById(eventRecord.id as string).then((response) => {
        const { jobVisit: visit } = response.data;
        return renderTooltip(client, visit, eventRecord);
      });
    },
    [client, getJobVisitById]
  );

  return (
    <>
      <PortalBreadcrumb>
        <h4 className="text-capitalize">Schedule</h4>
      </PortalBreadcrumb>
      <Row noGutters className="h-100">
        <Col xs={2} className="h-100 pr-3">
          <div className="d-flex flex-column h-100">
            <CardWrapper className="p-2 mb-3" style={{ overflow: 'visible' }}>
              <DatePicker
                date={date}
                counts={jobVisitsPerDay}
                onChange={setDate}
              />
            </CardWrapper>
            <CardWrapper
              className="p-0 flex-grow-1 d-flex flex-column"
              style={{ minHeight: 0, borderWidth: 4 }}
            >
              <div
                className="d-flex justify-content-between p-2"
                style={{ borderBottom: theme.border }}
              >
                <div>
                  <Figure value={jobs.length} brackets={false} small={false} />{' '}
                  <small className="text-75">jobs</small>
                </div>
                {totals.length > 0 && (
                  <div className="d-flex ">
                    {totals.map((flag, index) => {
                      const last = index === totals.length - 1;
                      return (
                        <small
                          key={flag.id}
                          className={classNames('text-nowrap', {
                            'mr-2': !last,
                          })}
                          style={{ color: flag.color, paddingTop: 2 }}
                        >
                          <FontAwesomeIcon
                            className="mr-1"
                            icon={faFlag}
                            fixedWidth
                          />
                          <Figure
                            value={flag.count}
                            brackets={false}
                            small={false}
                          />
                        </small>
                      );
                    })}
                  </div>
                )}
              </div>
              <ScrollDiv className="flex-grow-1" style={{ overflowY: 'auto' }}>
                {jobs.length > 0 ? (
                  jobs.map((job, index) => {
                    const last = index === jobs.length - 1;
                    return (
                      <Link
                        key={job.id}
                        href={`/clients/${client.slug}/jobs/${job.reference}`}
                      >
                        <JobOfInterest
                          className="mb-0 px-2"
                          style={{
                            borderLeft: `2px solid ${job.status.flag.color}`,
                            borderBottom: !last ? theme.border : 'none',
                          }}
                          data-id={job.id}
                          {...hoveredCallbacks}
                        >
                          <div className="mb-1" style={{ fontSize: 14 }}>
                            <div className="d-flex mb-0">
                              <Small
                                className="mr-2"
                                style={{ lineHeight: '26px' }}
                              >
                                Ref:{' '}
                                <ClickToCopy
                                  copy={job.reference}
                                  className="mono font-weight-normal"
                                >
                                  {job.reference}
                                </ClickToCopy>
                              </Small>
                            </div>
                            <p className="mb-0">{job.name}</p>
                          </div>
                          <div className="mb-0" style={{ fontSize: 13 }}>
                            <Small>Status</Small>
                            <p className="mb-0">
                              <JobStatusFlag
                                className="mr-2"
                                flag={job.status.flag}
                              />
                              {job.status.nameDisplay}
                            </p>
                          </div>
                          <div className="mb-2" style={{ fontSize: 13 }}>
                            <Small>Site</Small>
                            <p className="mb-0">{job.site.name}</p>
                          </div>
                        </JobOfInterest>
                      </Link>
                    );
                  })
                ) : (
                  <div className="p-2 text-75">No jobs</div>
                )}
              </ScrollDiv>
            </CardWrapper>
          </div>
        </Col>
        <Col xs={10} className="h-100">
          <CardWrapper
            className="d-flex flex-column h-100 p-0"
            style={{ borderWidth: 4 }}
          >
            <JobVisitSchedulerControls
              schedulerRef={schedulerRef}
              schedulerProject={schedulerProject}
              initialViewDateTimeStart={initialViewDateTimeStart}
              initialViewDateTimeEnd={initialViewDateTimeEnd}
              showUnassignedGrid={false}
              expanded={false}
              currentTime={false}
              onForward={handleOnForward}
              onBackward={handleOnBackward}
              resetView={() => {}}
              toggleFilter={() => {}}
              toggleCurrentTime={() => {}}
              toggleExpanded={() => {}}
            />
            <div
              className="position-relative flex-grow-1"
              style={{
                borderTop: theme.border,
                borderRadius: '0 0 0.25rem 0.25rem',
                overflow: 'hidden',
              }}
            >
              <JobVisitSchedulerLegend />
              <BryntumSchedulerPro
                ref={schedulerRef}
                {...schedulerConfig}
                startDate={initialViewDateTimeStart.toJSDate()}
                endDate={initialViewDateTimeEnd.toJSDate()}
                onTimeAxisChange={handleOnTimeAxisChange}
                rowHeight={80}
                eventRenderer={renderEvent}
                project={schedulerProject}
                sortFeature="name"
                groupFeature="category"
                columns={[
                  {
                    text: 'Name',
                    field: 'name',
                    width: 240,
                    renderer: renderColumn,
                  },
                ]}
                stripeFeature
                resourceTimeRangesFeature
                createEventOnDblClick={false}
                timeRangesFeature={{
                  showCurrentTimeLine: date
                    .startOf('day')
                    .equals(DateTime.local().startOf('day')),
                }}
                eventTooltipFeature={{
                  template: tooltipTemplate,
                  allowOver: true,
                }}
              />
            </div>
          </CardWrapper>
        </Col>
      </Row>
    </>
  );
};

export default ClientDashboardSchedule;
