import React from 'react';
import { ClientJobsTableFilterDescriptionProps } from './types';

const ClientJobLogsTableFilterDescription = ({
  count,
  activeFlagType,
}: ClientJobsTableFilterDescriptionProps) => {
  return (
    <span>
      Retrieved <span className="text-100">{count}</span>
      {activeFlagType && (
        <>
          {activeFlagType !== 'all' && (
            <>
              <span> log item{count === 1 ? '' : 's'}</span>
            </>
          )}
        </>
      )}
      .
    </span>
  );
};

export default ClientJobLogsTableFilterDescription;
