import styled from 'styled-components';

export const Wrapper = styled.div`
  .log-item-text {
    display: inline-block;

    &::first-letter {
      text-transform: uppercase;
    }
  }
`;
