import React from 'react';
import { Tab, TabBarWrapper, TabBarInner } from '../TabBar';

const PeopleTabBar = ({
  breadcrumbRef,
}: {
  breadcrumbRef: React.RefObject<HTMLDivElement>;
}) => {
  return (
    <TabBarWrapper className="pl-4">
      <div className="d-flex flex-column justify-content-center h-100">
        <h4>People</h4>
      </div>
      <TabBarInner className="d-flex">
        <Tab name="Dashboard" tab="/people" match="/people" />
      </TabBarInner>
    </TabBarWrapper>
  );
};

export default PeopleTabBar;
