import React from 'react';
import JobStatusFlag from '../JobStatusFlag';
import { JobStatusProps } from './types';

const JobStatus = ({ status, ...rest }: JobStatusProps) => {
  return (
    <span {...rest}>
      <JobStatusFlag className="mr-1" flag={status.flag} />
      {status.nameDisplay}
    </span>
  );
};

export default JobStatus;
