import styled from 'styled-components';
import { DropzoneWrapperProps } from './types';

export const DropzoneWrapper = styled.div<DropzoneWrapperProps>`
  border: 2px dashed ${({ theme }) => theme.color.contentBackground.hex()};
  border-radius: 0.5rem;
  height: 64px;
  cursor: pointer;

  ${({ theme, isDragActive }) =>
    isDragActive &&
    `
    background-color: ${theme.color.contentBackground.alpha(0.5).hex()};
    border-color: ${theme.color.primary.hex()};
  `};

  &:hover {
    background-color: ${({ theme }) =>
      theme.color.contentBackground.alpha(0.5).hex()};
  }
`;

export const DropzoneInner = styled.div`
  color: ${({ theme }) => theme.color.cardText.alpha(0.5).hex()};
`;
