import React from 'react';
import classNames from 'classnames';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
import { useTheme } from 'styled-components';
import { TableFooterProps } from './types';
import TablePageIndicator from '../TablePageIndicator';
import SimpleButton from '../SimpleButton';
import ConditionalWrapper from '../ConditionalWrapper';

const TableFooter = ({
  page,
  size = 20,
  count = 0,
  length = 0,
  wrapperProps,
  small,
  handleChangePage,
}: TableFooterProps) => {
  const theme = useTheme();
  return (
    <div
      className={classNames(
        'd-flex justify-content-between center-vertically',
        {
          'p-3': !small,
          'px-3 py-1': small,
        }
      )}
      style={{ borderTop: theme.border }}
      {...wrapperProps}
    >
      <TablePageIndicator small={small} page={page} count={count} size={size} />
      <div>
        <ConditionalWrapper
          wrap={small}
          wrapper={({ children }) => <small>{children}</small>}
        >
          <SimpleButton
            className={classNames({ 'mr-3': !small, 'p-1 mr-2': small })}
            style={{ height: small ? 24 : 34 }}
            icon={faChevronLeft}
            iconSide="left"
            iconProps={{ style: { verticalAlign: small ? '-0.05rem' : '' } }}
            disabled={page === 1}
            onClick={() => handleChangePage(-1)}
          >
            {!small && <>Previous</>}
          </SimpleButton>
          <SimpleButton
            disabled={length < size || false}
            className={classNames({ 'p-1': small })}
            style={{ height: small ? 24 : 34 }}
            icon={faChevronRight}
            iconSide="right"
            iconProps={{ style: { verticalAlign: small ? '-0.05rem' : '' } }}
            onClick={() => handleChangePage(1)}
          >
            {!small && <>Next</>}
          </SimpleButton>
        </ConditionalWrapper>
      </div>
    </div>
  );
};

export default TableFooter;
