import React from 'react';
import { Link } from 'wouter';
import classNames from 'classnames';
import { useTheme } from 'styled-components';
import JobIssuePill from '../JobIssuePill';
import JobPill from '../JobPill';
import ScrollDiv from '../ScrollDiv';
import { useReducerContext } from '../ClientDashboard/reducer';
import ClientJobIssueCardBody from '../ClientJobIssueCardBody';
import { Wrapper } from './styled';

const ClientDashboardIssues = () => {
  const theme = useTheme();
  const [{ jobIssues }] = useReducerContext();

  if (!jobIssues?.results) return <div className="p-2">No open issues</div>;
  return (
    <ScrollDiv className="h-100" style={{ overflowY: 'auto' }}>
      {jobIssues.results.map((jobIssue, index) => {
        const last = index === jobIssues.results.length - 1;
        const href = `/clients/${jobIssue.job.client.slug}/jobs/${jobIssue.job.reference}/issues/${jobIssue.number}`;
        return (
          <Link key={jobIssue.id} href={href}>
            <Wrapper
              href={href}
              className={classNames('p-2', { 'mb-3': !last })}
              style={{ borderBottom: theme.border }}
            >
              <div className="mb-2">
                <JobPill
                  className="mr-2"
                  job={jobIssue.job}
                  style={{ backgroundColor: 'transparent' }}
                />
                <JobIssuePill jobIssue={jobIssue} />
              </div>
              <small>
                <ClientJobIssueCardBody {...jobIssue} />
              </small>
            </Wrapper>
          </Link>
        );
      })}
    </ScrollDiv>
  );
};

export default ClientDashboardIssues;
