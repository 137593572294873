import React from 'react';
import JobStatusFlag from '../JobStatusFlag';
import { Wrapper, JobStatus } from './styled';
import { JobStateDescriptionProps } from './types';

const JobStateDescription = ({ job }: JobStateDescriptionProps) => {
  return (
    <Wrapper className="position-relative d-flex flex-column justify-content-center h-100">
      <JobStatus>
        <JobStatusFlag className="mr-2" flag={job.status.flag} />
        <span>{job.status.nameDisplay}</span>
      </JobStatus>
    </Wrapper>
  );
};

export default JobStateDescription;
