import React, { useRef } from 'react';
import { Link } from 'wouter';
import { useTheme } from 'styled-components';
import { parseDateTime } from 'lib/utils';
import { useReducerContext } from '../ClientDashboard/reducer';
import JobPill from '../JobPill';
import ScrollDiv from '../ScrollDiv';
import { Wrapper } from './styled';
import UserTodoButton from '../UserTodoButton';

const IncomingJob = ({ job, last }: any) => {
  const theme = useTheme();
  const href = `/clients/${job.client.slug}/jobs/${job.reference}`;
  const ref = useRef<HTMLAnchorElement>(null);
  return (
    <Link key={job.id} href={href}>
      <Wrapper
        ref={ref}
        href={href}
        className="position-relative use-todos"
        style={{
          borderBottom: last ? '' : theme.border,
          borderLeftWidth: 2,
          borderLeftStyle: 'solid',
          borderLeftColor: job?.priority?.color,
        }}
      >
        {job.firstLogBlock && (
          <UserTodoButton parentRef={ref} logBlock={job.firstLogBlock} />
        )}
        <div className="p-2">
          <div className="d-flex justify-content-between mb-1">
            <div>
              <JobPill
                job={job}
                style={{ backgroundColor: 'transparent', padding: 0 }}
              />
            </div>
            <small
              className="mono text-75"
              style={{ lineHeight: '24px', fontSize: 12 }}
            >
              {parseDateTime(job.dateTimeCreated)}
            </small>
          </div>
          <div>
            <p className="mb-2 font-weight-bold">
              {job.instantiationType.name}
            </p>
            <small className="d-block" style={{ lineHeight: '18px' }}>
              {job.priority ? (
                <>
                  {job.priority.responseName} - {job.priority.completionName}
                </>
              ) : (
                'This job has no priority'
              )}
            </small>
          </div>
        </div>
      </Wrapper>
    </Link>
  );
};

const ClientDashboardIncoming = () => {
  const [{ incomingJobs }] = useReducerContext();

  if (!incomingJobs) return null;
  return (
    <div className="d-flex flex-column h-100">
      <ScrollDiv className="h-100" style={{ overflowY: 'auto' }}>
        {incomingJobs.results.map((job, index) => {
          const last = index === incomingJobs.results.length - 1;
          return <IncomingJob job={job} last={last} />;
        })}
      </ScrollDiv>
    </div>
  );
};

export default ClientDashboardIncoming;
