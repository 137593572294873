import React from 'react';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { getLogText, getLogIcon } from 'lib/logs';
import CardWrapper from '../CardWrapper';
import ClientJobLogsTableFilter from '../ClientJobLogsTableFilter';
import Table from '../Table';
import { Wrapper } from './styled';
import { useReducerContext } from '../ClientJob/reducer';

const ClientJobLogs = () => {
  const [{ job }] = useReducerContext();

  if (!job) return null;

  return (
    <CardWrapper className="p-0 h-100">
      <div className="mt-3">
        <ClientJobLogsTableFilter
          logItems={job.logBlocks}
          searchTerm=""
          searchTermOnChange={() => {}}
        />
      </div>
      <Wrapper style={{ height: 'calc(100% - 142px)' }}>
        <Table className="h-100">
          <thead>
            <tr>
              <th>Time</th>
              <th>Description</th>
              <th>Subsequent tasks</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {job.logBlocks.map((logBlock, index) => {
              const last = index === job.logBlocks.length - 1;
              return (
                <tr key={logBlock.id} className={classNames({ last })}>
                  <td style={{ fontFamily: 'JetBrains Mono, monospace' }}>
                    <small>
                      {DateTime.fromISO(logBlock.dateTime).toFormat(
                        'd MMM HH:mm:ss'
                      )}
                    </small>
                  </td>
                  <td>
                    <small>{getLogText(logBlock)}</small>
                  </td>
                  <td>
                    <div>
                      {logBlock.children.map((logItem) => (
                        <div key={logItem.id} className="text-75">
                          <small>
                            {getLogIcon(logItem)}
                            <span className="ml-2">{getLogText(logItem)}</span>
                          </small>
                        </div>
                      ))}
                    </div>
                  </td>
                  <td>
                    <small>
                      {getLogIcon(logBlock)}
                      <span className="ml-2 ">{logBlock.status[0]}</span>
                      <span className="text-lowercase">
                        {logBlock.status.slice(1)}
                      </span>
                    </small>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Wrapper>
    </CardWrapper>
  );
};

export default ClientJobLogs;
