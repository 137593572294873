import styled from 'styled-components';

export const InfoPanelWrapper = styled.div`
  position: absolute;
  top: 2rem;
  right: 2rem;
  height: calc(100% - 4rem);
  width: 300px;
  padding: 1rem;
  background-color: ${({ theme }) => theme.color.cardBackground.hex()};
  color: ${({ theme }) => theme.color.cardText.hex()};
  border-radius: 0.5rem;
  z-index: 7000;
`;
