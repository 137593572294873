import React from 'react';
import { faPlay } from '@fortawesome/pro-solid-svg-icons/faPlay';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle';
import ClientJobLogsTableFilterDescription from '../ClientJobLogsTableFilterDescription';
import Button from '../Button';
import SearchBar from '../SearchBar';
import { ClientJobsTableFilterProps } from './types';

const ClientJobLogsTableFilter = ({
  logItems,
  searchTerm,
  searchTermOnChange,
}: ClientJobsTableFilterProps) => {
  return (
    <div>
      <div className="d-flex px-3">
        <div className="flex-grow-1">
          <p className="mb-2 text-50">Search</p>
          <SearchBar
            style={{ borderColor: searchTerm !== '' ? '#106c12' : '' }}
            value={searchTerm}
            placeholder="Search through logs..."
            onChange={searchTermOnChange}
          />
        </div>
        <div>
          <p className="mb-2 text-50">Filter by status</p>
          <Button className="mr-3 px-4" type="button">
            All logs
          </Button>
          <Button
            className="mr-3"
            icon={faPlay}
            iconProps={{ className: 'mr-2' }}
            flagType="WHITE"
            type="button"
          >
            Initiated
          </Button>
          <Button
            className="mr-3"
            icon={faCheckCircle}
            iconProps={{ className: 'mr-2' }}
            flagType="GREEN"
            type="button"
          >
            Success
          </Button>
          <Button
            className="mr-3"
            icon={faTimesCircle}
            iconProps={{ className: 'mr-2' }}
            flagType="RED"
            type="button"
          >
            Error
          </Button>
        </div>
      </div>
      <div className="text-50 px-3 my-3">
        <ClientJobLogsTableFilterDescription
          count={logItems?.length || 0}
          searchTerm={searchTerm}
          activeFlagType="ALL"
          activeStatus="PPM"
        />
      </div>
    </div>
  );
};

export default ClientJobLogsTableFilter;
