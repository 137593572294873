import React, { useEffect, useRef, useState } from 'react';
import SimpleButtonWithWindow from '../SimpleButtonWithWindow';
import { faAlarmPlus } from '@fortawesome/pro-duotone-svg-icons/faAlarmPlus';
import {
  CreateUserTodoMutationData,
  CreateUserTodoMutationVariables,
  UserTodoButtonProps,
} from './types';
import { useMutation } from '@apollo/client';
import { CREATE_USER_TODO_MUTATION } from './query';
import { useDispatch } from 'react-redux';
import { createUserTodoMutationOnCompleted } from 'lib/redux/reducer';
import SimpleButton from '../SimpleButton';
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import { Wrapper } from './styled';
import { useTheme } from 'styled-components';

const UserTodoButton = ({ parentRef, logBlock }: UserTodoButtonProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setIsOpen(true);
    setTimeout(() => {
      if (parentRef.current) parentRef.current.focus();
    }, 10);
  };

  const handleOnBlur = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (!parentRef.current) return;
    parentRef.current.onblur = handleOnBlur;
  }, [parentRef]);

  const [createUserTodo, { loading: creating }] = useMutation<
    CreateUserTodoMutationData,
    CreateUserTodoMutationVariables
  >(CREATE_USER_TODO_MUTATION);

  const handleCreateUserTodoOnClick = async () => {
    const { data } = await createUserTodo({
      variables: { logBlockId: logBlock.id },
    });
    if (!data) return;
    dispatch(createUserTodoMutationOnCompleted(data));
  };

  const renderWindowChildren = () => {
    return (
      <div ref={ref} tabIndex={0} onBlur={handleOnBlur}>
        <SimpleButton
          icon={faPlus}
          loading={creating}
          onClick={handleCreateUserTodoOnClick}
        >
          Create
        </SimpleButton>
      </div>
    );
  };

  return (
    <Wrapper className="create-todo" style={{ display: isOpen ? 'block' : '' }}>
      <SimpleButtonWithWindow
        icon={faAlarmPlus}
        style={{ borderRadius: '0 0 0 0.25rem' }}
        openMode="controlled"
        isOpen={isOpen}
        backgroundColor={theme.color.contentBackground.hex()}
        backgroundHoverColor={theme.color.cardBackground.hex()}
        backgroundActiveColor={theme.color.cardBackground.hex()}
        onClick={handleOnClick}
        windowChildren={renderWindowChildren}
      />
    </Wrapper>
  );
};

export default UserTodoButton;
