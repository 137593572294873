import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import classNames from 'classnames';
import { JobInstantiationType } from 'lib/types';
import { useScroll } from 'lib/hooks';
import Select from '../Select';

const ClientJobInstantiationConfirmationField = ({
  input,
  instantiationTypes,
  instantiationMetas,
}: FieldRenderProps<any>) => {
  useScroll();

  const handleTypeOnChange = (
    selected?: { value: string; label: string } | null
  ) => {
    if (!selected) return;
    input.onChange({
      ...input.value,
      type: instantiationTypes.find(
        (innerInstantiationType: JobInstantiationType) =>
          innerInstantiationType.id === selected.value
      ),
    });
  };

  const handleMetaOnChange = (
    selected?: { value: string; label: string } | null
  ) => {
    if (!selected) return;
    input.onChange({
      ...input.value,
      meta: instantiationMetas.find(
        (innerInstantiationMeta: any) =>
          innerInstantiationMeta.value === selected.value
      ),
    });
  };

  return (
    <div className="d-flex">
      <div
        className={classNames('flex-grow-1', { 'mr-2': !!input.value.meta })}
      >
        <Select
          menuPortalTarget={document.body}
          menuPlacement="top"
          options={instantiationTypes.map(
            (instantiationType: JobInstantiationType) => ({
              value: instantiationType.id,
              label: instantiationType.name,
            })
          )}
          value={{
            value: input.value.type.id,
            label: input.value.type.name,
          }}
          onChange={handleTypeOnChange}
        />
      </div>
      {input.value.meta && (
        <div className="flex-grow-1">
          <Select
            menuPortalTarget={document.body}
            menuPlacement="top"
            options={instantiationMetas}
            value={input.value.meta}
            onChange={handleMetaOnChange}
          />
        </div>
      )}
    </div>
  );
};

export default ClientJobInstantiationConfirmationField;
