import React, { useCallback, useState } from 'react';
import { Col, ColProps, Row } from 'reactstrap';
import { useTheme } from 'styled-components';
import { useUser } from 'lib/hooks';
import SimpleButton from '../SimpleButton';
import ClientJobIssueBlock from '../ClientJobIssueBlock';
import ClientJobQuoteForm from '../ClientJobIssueQuoteForm';
import ClientJobIssueRequestEOTForm from '../ClientJobIssueEOTRequestForm';
import ClientJobIssueRespondToEOTBlock from '../ClientJobIssueEOTResponseForm';
import ClientJobIssueRespondToQuoteForm from '../ClientJobIssueQuoteResponseForm';
import ClientJobIssueMessageForm from '../ClientJobIssueMessageForm';

const ClientJobIssueControlBlock = ({ issue, setIssue }: any) => {
  const theme = useTheme();
  const user = useUser();

  const [tab, setTab] = useState('MESSAGE');
  const handleChangeTabOnClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    const { value: nextTab } = event.currentTarget;
    setTab(nextTab);
  };

  const handleCreateMessageOnCompleted = (response: any) => {
    if (!response.data) return response;
    const { block, jobIssue } = response.data.createJobIssueMessage;
    setIssue((prevIssue: any) => ({
      ...prevIssue,
      ...jobIssue,
      blocks: [...prevIssue.blocks, block],
    }));
    return response;
  };

  const handleCreateQuoteOnCompleted = (response: any) => {
    if (!response.data) return response;
    const { block, jobIssue } = response.data.createJobQuote;
    setIssue((prevIssue: any) => ({
      ...prevIssue,
      ...jobIssue,
      blocks: [...prevIssue.blocks, block],
    }));
    return response;
  };

  const handleCreateEOTRequestOnCompleted = (response: any) => {
    if (!response.data) return response;
    const { block, jobIssue } = response.data.createJobEotRequest;
    setIssue((prevIssue: any) => ({
      ...prevIssue,
      ...jobIssue,
      blocks: [...prevIssue.blocks, block],
    }));
    return response;
  };

  const handleUpdateEotRequestOnCompleted = (response: any) => {
    if (!response.data) return response;
    const { block, jobIssue } = response.data.updateJobEotRequest;
    setIssue((prevIssue: any) => ({
      ...prevIssue,
      ...jobIssue,
      blocks: [...prevIssue.blocks, block],
    }));
    return response;
  };

  const getColProps = useCallback((): ColProps => {
    switch (tab) {
      case 'REQUEST_EOT':
        return {
          style: { paddingLeft: 64 },
          xl: 12,
        };
      default:
        return {
          className: 'offset-3',
          xl: 6,
        };
    }
  }, [tab]);

  return (
    <Row>
      <Col {...getColProps()}>
        <ClientJobIssueBlock
          last
          user={user}
          headerClassName="border-0"
          headerPaddingClass="pl-2 pr-3"
          headerStyle={{ backgroundColor: theme.color.contentBackground.hex() }}
          header={
            <div className="d-flex flex-column justify-content-end">
              <div>
                {issue.controls.includes('MESSAGE') && (
                  <SimpleButton
                    className="mr-1"
                    backgroundColor={theme.color.cardBackground.hex()}
                    backgroundHoverColor={theme.color.cardBackground.hex()}
                    style={{ borderRadius: '0.25rem 0.25rem 0 0' }}
                    active={tab !== 'MESSAGE'}
                    value="MESSAGE"
                    onClick={handleChangeTabOnClick}
                  >
                    <small>Leave message</small>
                  </SimpleButton>
                )}
                {issue.controls.includes('ADD_QUOTE') && (
                  <SimpleButton
                    backgroundColor={theme.color.cardBackground.hex()}
                    backgroundHoverColor={theme.color.cardBackground.hex()}
                    style={{ borderRadius: '0.25rem 0.25rem 0 0' }}
                    active={tab !== 'ADD_QUOTE'}
                    value="ADD_QUOTE"
                    onClick={handleChangeTabOnClick}
                  >
                    <small>Add quote</small>
                  </SimpleButton>
                )}
                {issue.controls.includes('RESPOND_TO_QUOTE') && (
                  <SimpleButton
                    backgroundColor={theme.color.cardBackground.hex()}
                    backgroundHoverColor={theme.color.cardBackground.hex()}
                    style={{ borderRadius: '0.25rem 0.25rem 0 0' }}
                    active={tab !== 'RESPOND_TO_QUOTE'}
                    value="RESPOND_TO_QUOTE"
                    onClick={handleChangeTabOnClick}
                  >
                    <small>Respond to quote</small>
                  </SimpleButton>
                )}
                {issue.controls.includes('REQUEST_EOT') && (
                  <SimpleButton
                    backgroundColor={theme.color.cardBackground.hex()}
                    backgroundHoverColor={theme.color.cardBackground.hex()}
                    style={{ borderRadius: '0.25rem 0.25rem 0 0' }}
                    active={tab !== 'REQUEST_EOT'}
                    value="REQUEST_EOT"
                    onClick={handleChangeTabOnClick}
                  >
                    <small>Request EOT</small>
                  </SimpleButton>
                )}
                {issue.controls.includes('RESPOND_TO_EOT_REQUEST') && (
                  <SimpleButton
                    backgroundColor={theme.color.cardBackground.hex()}
                    backgroundHoverColor={theme.color.cardBackground.hex()}
                    style={{ borderRadius: '0.25rem 0.25rem 0 0' }}
                    active={tab !== 'RESPOND_TO_EOT_REQUEST'}
                    value="RESPOND_TO_EOT_REQUEST"
                    onClick={handleChangeTabOnClick}
                  >
                    <small>Respond to EOT request</small>
                  </SimpleButton>
                )}
              </div>
            </div>
          }
        >
          <div>
            {tab === 'MESSAGE' && (
              <div className="p-2">
                <ClientJobIssueMessageForm
                  issue={issue}
                  onCreateCompleted={handleCreateMessageOnCompleted}
                />
              </div>
            )}
            {tab === 'ADD_QUOTE' && (
              <div className="p-3">
                <ClientJobQuoteForm
                  issue={issue}
                  onCreateCompleted={handleCreateQuoteOnCompleted}
                />
              </div>
            )}
            {tab === 'RESPOND_TO_QUOTE' && (
              <ClientJobIssueRespondToQuoteForm
                issue={issue}
                updateQuoteOnCompleted={() => {}}
              />
            )}
            {tab === 'REQUEST_EOT' && (
              <ClientJobIssueRequestEOTForm
                issue={issue}
                onCreateCompleted={handleCreateEOTRequestOnCompleted}
              />
            )}
            {tab === 'RESPOND_TO_EOT_REQUEST' && (
              <ClientJobIssueRespondToEOTBlock
                issue={issue}
                updateEotRequestOnCompleted={handleUpdateEotRequestOnCompleted}
              />
            )}
          </div>
        </ClientJobIssueBlock>
      </Col>
    </Row>
  );
};

export default ClientJobIssueControlBlock;
