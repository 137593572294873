import React from 'react';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { useMutation } from '@apollo/client';
import SimpleButton from '../SimpleButton';
import { ClientJobIssueQuoteResponseFormProps } from './types';
import { UPDATE_QUOTE_MUTATION } from './query';

const ClientJobIssueQuoteResponseForm = ({
  issue,
  updateQuoteOnCompleted,
}: ClientJobIssueQuoteResponseFormProps) => {
  const [updateEotRequest] = useMutation(UPDATE_QUOTE_MUTATION);

  const handleUpdateQuoteRequest = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    const { value: status } = event.currentTarget;
    updateEotRequest({ variables: { jobIssueId: issue.id, status } }).then(
      updateQuoteOnCompleted
    );
  };

  return (
    <div className="p-3">
      <SimpleButton
        className="mr-2"
        icon={faCheck}
        value="APPROVED"
        onClick={handleUpdateQuoteRequest}
      >
        Approve
      </SimpleButton>
      <SimpleButton
        icon={faTimes}
        value="REJECTED"
        onClick={handleUpdateQuoteRequest}
      >
        Reject
      </SimpleButton>
    </div>
  );
};

export default ClientJobIssueQuoteResponseForm;
