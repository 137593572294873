import { DefaultTheme } from 'styled-components';
import { JobIssue } from '../types';

const getIssueColor = (theme: DefaultTheme, status: JobIssue['status']) => {
  switch (status) {
    case 'OPEN':
      return theme.color.danger.alpha(0.25).hex();
    case 'CLOSED':
      return theme.color.primary.alpha(0.25).hex();
    default:
      return '';
  }
};

export default getIssueColor;
