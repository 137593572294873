import { gql } from '@apollo/client';

export const MANAGEMENT_TASKS_QUERY = gql`
  query ($clientId: ID!, $managementTaskType: String) {
    managementTaskTypes(clientId: $clientId)
    managementTasks(
      clientId: $clientId
      managementTaskType: $managementTaskType
    ) {
      results {
        id
        type
        status
      }
    }
  }
`;

export const MANAGEMENT_TASK_QUERY = gql`
  query ($managementTaskId: ID!) {
    managementTask(managementTaskId: $managementTaskId) {
      id
      type
      instance {
        ... on ManagementTaskClientSite {
          type
          data {
            id
            location {
              id
              meta
            }
          }
        }
        ... on ManagementTaskJobIssue {
          type
          data {
            id
            status
            issueType
          }
        }
      }
    }
  }
`;
