import { gql } from '@apollo/client';

export const JOB_REPORT_TEMPLATES_QUERY = gql`
  query (
    $clientId: ID!
    $userGroupId: ID
    $jobId: ID
    $jobInstantiationTypeId: ID
  ) {
    jobReportTemplates(
      clientId: $clientId
      userGroupId: $userGroupId
      jobInstantiationTypeId: $jobInstantiationTypeId
    ) {
      results {
        id
        name
        description
        isDefault
      }
    }
    jobReports(clientId: $clientId, jobId: $jobId) {
      results {
        id
        template {
          id
          name
          description
        }
      }
    }
  }
`;
