import { ApolloClient } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import cache from './cache';

const getUri = (token: string) =>
  `${process.env.REACT_APP_WEBSOCKET_PROTOCOL}://${process.env.REACT_APP_API_HOST}/graphql/?token=${token}`;

const createWebsocketClient = (token: string) =>
  new ApolloClient({
    cache,
    link: new WebSocketLink({
      uri: getUri(token),
      options: {
        reconnect: true,
      },
    }),
  });

export default createWebsocketClient;
