import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useClient } from 'lib/hooks';
import { getId } from 'lib/utils';
import { Link } from 'wouter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-duotone-svg-icons/faChevronRight';
import { DateTime } from 'luxon';
import { useLazyQuery } from '@apollo/client';
import { faUserCircle } from '@fortawesome/pro-duotone-svg-icons/faUserCircle';
import { Job, JobVisit } from 'lib/types';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons/faSpinnerThird';
import { faExclamationCircle } from '@fortawesome/pro-duotone-svg-icons/faExclamationCircle';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons/faArrowRight';
import { TableProps, TableRowProps } from './types';
import {
  Wrapper,
  TableRowStyled,
  JobTable,
  JobRow,
  JobVisitRow,
} from './styled';
import TableHeadButton from '../TableHeadButton';
import Table from '../Table';
import SimpleButton from '../SimpleButton';
import JobStatusFlag from '../JobStatusFlag';
import { JOB_VISITS_QUERY, JOBS_QUERY } from './query';
import { Small } from '../Typography';
import JobLink from '../JobLink';

const TableRow = ({
  id,
  jobCount,
  reference,
  name,
  previousJob,
  currentJob,
  nextJob,
  selectedRow,
  selectedInnerRow,
  setSelectedInnerRow,
  onClick,
}: TableRowProps) => {
  const client = useClient();
  const selected = selectedRow === id;
  const [jobs, setJobs] = useState<Job[]>([]);
  const [jobVisits, setJobVisits] = useState<JobVisit[]>([]);

  const [getJobs, { loading: loadingJobs }] = useLazyQuery(JOBS_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setJobs(data.jobs.results);
    },
  });

  useEffect(() => {
    if (selected) {
      getJobs({
        variables: {
          clientId: client.id,
          jobGroupId: id,
          sort: { name: 'dateTimeStart', order: true },
        },
      });
    }
  }, [client.id, getJobs, id, selected]);

  const [getVisits, { loading: loadingVisits }] = useLazyQuery(
    JOB_VISITS_QUERY,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        setJobVisits(data.jobVisits);
      },
    }
  );

  const handleInnerRowOnClick = (
    event: React.MouseEvent<HTMLTableRowElement>
  ) => {
    const innerRowId = getId(event) as string;
    setJobVisits([]);
    setSelectedInnerRow((prevSelectedInnerRow) => {
      if (prevSelectedInnerRow === innerRowId) {
        return '-1';
      }
      getVisits({
        variables: {
          clientId: client.id,
          jobId: innerRowId.split(':')[1],
        },
      });
      return innerRowId;
    });
  };

  return (
    <>
      <TableRowStyled
        className={classNames({ 'border-bottom-0': selected })}
        style={{
          border: selected ? '2px solid #106c12' : 'none',
          borderRadius: selected ? '0.5rem 0.5rem 0 0' : 0,
        }}
        data-id={id}
        onClick={onClick}
      >
        <td className="button-cell">
          <SimpleButton
            icon={faChevronRight}
            iconProps={{ rotation: selected ? 90 : undefined }}
          />
        </td>
        <td className="mono">{reference}</td>
        <td>{jobCount}</td>
        <td
          className={classNames({
            'font-weight-bold': selected,
          })}
        >
          <Link to={`/clients/${client.slug}/job-groups/${reference}`}>
            {name || 'Untitled'}
          </Link>
        </td>
        <td className="mono text-75 d-flex justify-content-between position-relative">
          {previousJob ? <JobLink job={previousJob} /> : 'N/A'}
          <span
            className="flex-grow-1 text-center position-absolute"
            style={{ right: '2rem' }}
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </span>
        </td>
        <td className="mono d-flex justify-content-between position-relative">
          {currentJob ? <JobLink job={currentJob} /> : 'N/A'}
          <span
            className="flex-grow-1 text-center text-75 position-absolute"
            style={{ right: '2rem' }}
          >
            <FontAwesomeIcon icon={faArrowRight} />
          </span>
        </td>
        <td className="mono text-75">
          {nextJob ? <JobLink job={nextJob} /> : 'N/A'}
        </td>
      </TableRowStyled>
      {selected && (
        <tr
          style={{
            border: '2px solid #106c12',
            borderTop: 'none',
            borderRadius: '0 0 0.5rem 0.5rem',
            backgroundColor: 'rgba(0, 0, 0, 0.025)',
          }}
        >
          <td colSpan={4} className="p-0">
            <JobTable
              className="w-100 inner-table"
              flexBases={[5, 5, 10, 13, 37, 30]}
            >
              <thead>
                <tr>
                  <th className="decoration-wrapper">
                    <div className="decoration" />
                  </th>
                  <th />
                  <th>
                    <span>Reference</span>
                  </th>
                  <th>Status</th>
                  <th>Name</th>
                  <th>Dates</th>
                </tr>
              </thead>
              <tbody>
                {jobs.length > 0 ? (
                  jobs.map((innerJob, index) => {
                    const last = index === jobs.length - 1;
                    const innerRowSelected =
                      selectedInnerRow === `inner-${innerJob.id}`;
                    return (
                      <>
                        <JobRow
                          key={`job-${innerJob.id}`}
                          className="d-flex"
                          last={last}
                          selected={innerRowSelected}
                          data-id={`inner:${innerJob.id}`}
                          onClick={handleInnerRowOnClick}
                        >
                          <td>
                            <div className="decoration" />
                          </td>
                          <td>
                            <SimpleButton
                              icon={faChevronRight}
                              iconProps={{
                                rotation: innerRowSelected ? 90 : undefined,
                              }}
                            />
                          </td>
                          <td
                            className="text-75"
                            style={{
                              fontFamily: 'JetBrains Mono',
                              lineHeight: '34px',
                            }}
                          >
                            {innerJob.reference}
                          </td>
                          <td>
                            <span
                              className="mr-2"
                              style={{ lineHeight: '34px' }}
                            >
                              <JobStatusFlag flag={innerJob.status.flag} />
                            </span>
                            <span>{innerJob.status.nameDisplay}</span>
                          </td>
                          <td>
                            <span style={{ lineHeight: '34px' }}>
                              <Link
                                to={`/clients/${client.slug}/jobs/${innerJob.reference}`}
                              >
                                {innerJob.name}
                              </Link>
                            </span>
                          </td>
                          <td
                            style={{
                              fontFamily: 'JetBrains Mono',
                              lineHeight: '34px',
                            }}
                          >
                            {DateTime.fromISO(innerJob.dateTimeStart).toFormat(
                              'dd MMM yyyy'
                            )}
                            {' - '}
                            {DateTime.fromISO(innerJob.dateTimeEnd).toFormat(
                              'dd MMM yyyy'
                            )}
                          </td>
                        </JobRow>
                        {innerRowSelected && (
                          <tr className="inner-row">
                            <td colSpan={4} className="p-0">
                              <table className="w-100">
                                <tbody>
                                  <JobVisitRow
                                    last={false}
                                    style={{ height: 32 }}
                                    className="border-0"
                                  >
                                    <td className="py-0">
                                      {!last && <div className="decoration" />}
                                    </td>
                                    <td className="py-0 ">
                                      <div className="second-decoration border-bottom-0 rounded-0" />
                                    </td>
                                    <td style={{ lineHeight: 0 }}>
                                      <Small>Visits</Small>
                                    </td>
                                    <td style={{ lineHeight: 0 }}>
                                      {jobVisits.length > 0 && (
                                        <Small>Assignee</Small>
                                      )}
                                    </td>
                                    <td style={{ lineHeight: 0 }}>
                                      {jobVisits.length > 0 && (
                                        <Small>Dates</Small>
                                      )}
                                    </td>
                                    <td />
                                  </JobVisitRow>
                                  {jobVisits.length > 0 ? (
                                    jobVisits.map((jobVisit, innerIndex) => {
                                      const lastInner =
                                        innerIndex === jobVisits.length - 1;
                                      return (
                                        <JobVisitRow
                                          key={`visit-${jobVisit.id}`}
                                          last={lastInner}
                                        >
                                          <td className="py-0">
                                            {!last && (
                                              <div className="decoration" />
                                            )}
                                          </td>
                                          <td className="py-0">
                                            <div className="second-decoration" />
                                          </td>
                                          <td>
                                            <span
                                              style={{ lineHeight: '34px' }}
                                            >
                                              {jobVisit.label || 'Untitled'}
                                            </span>
                                          </td>
                                          <td>
                                            {jobVisit.user ? (
                                              <div>
                                                <FontAwesomeIcon
                                                  className="mr-1"
                                                  icon={faUserCircle}
                                                />
                                                <span
                                                  style={{ lineHeight: '34px' }}
                                                >
                                                  {jobVisit.user.name}
                                                </span>
                                              </div>
                                            ) : (
                                              <span
                                                style={{ lineHeight: '34px' }}
                                              >
                                                <FontAwesomeIcon
                                                  icon={faExclamationCircle}
                                                  className="mr-1"
                                                  fixedWidth
                                                />
                                                Unassigned
                                              </span>
                                            )}
                                          </td>
                                          <td
                                            className="mono"
                                            style={{
                                              lineHeight: '34px',
                                            }}
                                          >
                                            {DateTime.fromISO(
                                              jobVisit.dateTimeStart
                                            ).toFormat('HH:mm dd MMM yyyy')}
                                            {' - '}
                                            {DateTime.fromISO(
                                              jobVisit.dateTimeEnd
                                            ).toFormat('HH:mm dd MMM yyyy')}
                                          </td>
                                          <td />
                                        </JobVisitRow>
                                      );
                                    })
                                  ) : (
                                    <JobVisitRow last>
                                      <td className="py-0">
                                        {!last && (
                                          <div className="decoration" />
                                        )}
                                      </td>
                                      <td className="py-0">
                                        <div className="second-decoration" />
                                      </td>
                                      <td>
                                        {loadingVisits ? (
                                          <span style={{ lineHeight: '34px' }}>
                                            <FontAwesomeIcon
                                              icon={faSpinnerThird}
                                              spin
                                              fixedWidth
                                            />
                                          </span>
                                        ) : (
                                          <span
                                            className="text-75"
                                            style={{ lineHeight: '34px' }}
                                          >
                                            No visits
                                          </span>
                                        )}
                                      </td>
                                      <td />
                                      <td />
                                      <td />
                                    </JobVisitRow>
                                  )}
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        )}
                      </>
                    );
                  })
                ) : (
                  <JobRow last selected={false}>
                    <td className="py-0">
                      <div className="decoration" />
                    </td>
                    <td>
                      {loadingJobs ? (
                        <span style={{ lineHeight: '34px' }}>
                          <FontAwesomeIcon
                            icon={faSpinnerThird}
                            spin
                            fixedWidth
                          />
                        </span>
                      ) : (
                        <span
                          className="text-75"
                          style={{ lineHeight: '34px' }}
                        >
                          No jobs
                        </span>
                      )}
                    </td>
                    <td />
                    <td />
                    <td />
                    <td />
                  </JobRow>
                )}
              </tbody>
            </JobTable>
          </td>
        </tr>
      )}
    </>
  );
};

const ClientJobsPPMTable = ({ rows, sortState, sort }: TableProps) => {
  const [selectedRow, setSelectedRow] = useState('-1');
  const [selectedInnerRow, setSelectedInnerRow] = useState('-1');

  const handleRowOnClick = (event: React.MouseEvent<HTMLTableRowElement>) => {
    const id = getId(event) as string;
    setSelectedRow(id === selectedRow ? '-1' : id);
    setSelectedInnerRow('-1');
  };

  return (
    <Wrapper>
      <Table flexBases={[5, 10, 10, 33, 14, 14, 14]} className="w-100">
        <thead>
          <tr>
            <th />
            <th>
              <TableHeadButton
                active={sortState.activeName === 'reference'}
                order={sortState.columns.reference.order}
                onClick={() => sort('reference')}
              >
                Reference
              </TableHeadButton>
            </th>
            <th>
              <TableHeadButton
                active={sortState.activeName === 'jobs'}
                order={sortState.columns.jobs.order}
                onClick={() => sort('jobs')}
              >
                Jobs
              </TableHeadButton>
            </th>
            <th>
              <TableHeadButton
                active={sortState.activeName === 'name'}
                order={sortState.columns.name.order}
                onClick={() => sort('name')}
              >
                Name
              </TableHeadButton>
            </th>
            <th>Previous job</th>
            <th>Current job</th>
            <th>Next job</th>
          </tr>
        </thead>
        <tbody>
          {rows?.map((result) => (
            <TableRow
              key={`job-group-${result.id}`}
              {...result}
              selectedRow={selectedRow}
              selectedInnerRow={selectedInnerRow}
              setSelectedInnerRow={setSelectedInnerRow}
              onClick={handleRowOnClick}
            />
          ))}
        </tbody>
      </Table>
    </Wrapper>
  );
};

export default ClientJobsPPMTable;
