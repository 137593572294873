import { gql } from '@apollo/client';

export const IMMEDIATE_ACTION_JOBS_QUERY = gql`
  query ($clientId: ID!, $page: Int, $size: Int) {
    immediateActionJobs(clientId: $clientId, page: $page, size: $size) {
      results {
        id
        reference
        name
        status {
          id
          name
          nameDisplay
          flag {
            color
          }
        }
        tags {
          id
          name
          nameDisplay
          flag {
            id
            name
            nameDisplay
            icon
          }
        }
      }
      count
    }
  }
`;
