import { gql } from '@apollo/client';

export const JOB_QUERY = gql`
  query ($reference: String) {
    job(jobReference: $reference) {
      id
      reference
      instantiated
      name
      description
      dateTimeCreated
      dateTimeStart
      dateTimeEnd
      targetDateTimeStart
      targetDateTimeStartMet
      targetDateTimeEnd
      originalTargetDateTimeStart
      actualDateTimeStart
      actualDateTimeEnd
      dateTimeStartDiff
      dateTimeEndDiff
      viewDateTimeStart
      viewDateTimeEnd
      meta
      rawMeta
      positionInJobGroup
      visitCount
      instantiationType {
        name
      }
      group {
        id
        reference
      }
      previousInGroup {
        dateTimeEnd
      }
      priority {
        id
        code
        color
        responseName
        completionName
      }
      workType
      status {
        id
        name
        nameDisplay
        flag {
          id
          color
          name
        }
      }
      client {
        id
        slug
      }
      site {
        id
        name
        location {
          id
          address
          point {
            longitude
            latitude
          }
        }
        client {
          id
          slug
        }
      }
      assignees {
        id
        name
      }
      visits {
        id
        dateTimeStart
        dateTimeEnd
        label
        number
        status {
          nameDisplay
          color
        }
        user {
          id
          name
        }
      }
      logBlocks {
        id
        status
        type
        dateTime
        instanceContentType
        data
        meta
        context
        integration
        children {
          id
          status
          type
          dateTime
          instanceContentType
          data
          meta
          context
        }
      }
      activeEotRequest {
        id
        dateTimeNew
      }
      reports {
        id
        name
        status
        template {
          id
          name
        }
        versions {
          id
          versionNumber
        }
      }
      reportCount
      issues {
        id
        status
      }
      issueCount(status: "OPEN")
      parent {
        id
        reference
      }
      visitsInView: visits(fromViewDateTimeStart: true, isAssigned: true) {
        id
        user {
          id
        }
      }
      chat {
        id
        messages {
          id
        }
      }
    }
  }
`;

export const JOB_SUBSCRIPTION = gql`
  subscription ($jobId: ID, $sessionId: ID) {
    jobSubscription(jobId: $jobId, sessionId: $sessionId) {
      sessionId
      type
      action
      job {
        id
        name
        client {
          id
          slug
        }
        site {
          id
          name
          location {
            id
            meta
            point {
              longitude
              latitude
            }
          }
        }
        logBlocks {
          id
          status
          type
          dateTime
          instanceContentType
          data
          meta
          children {
            id
            status
            type
            dateTime
            instanceContentType
            data
            meta
          }
        }
      }
      logBlock {
        id
        status
        type
        dateTime
        instanceContentType
        data
        meta
        parent {
          id
        }
        previous {
          id
        }
        children {
          id
          status
          type
          dateTime
          instanceContentType
          data
          meta
        }
      }
      logItem {
        id
        status
        type
        dateTime
        instanceContentType
        data
        meta
        parent {
          id
        }
        previous {
          id
        }
        children {
          id
          status
          type
          dateTime
          instanceContentType
          data
          meta
        }
      }
      report: jobReport {
        id
        name
        versions {
          id
          versionNumber
          pdfUrl
          editUrl
          pageCount
          pages {
            id
            pageNumber
            status
          }
        }
      }
    }
  }
`;
