import styled from 'styled-components';
import CardWrapper from '../CardWrapper';
import { JobCardWrapperProps } from './types';

export const JobCardWrapper = styled(CardWrapper)<JobCardWrapperProps>`
  transition: 150ms;

  ${({ theme, isCurrent }) => {
    if (isCurrent) {
      return `
        border: 2px solid ${theme.color.primary.hex()};
      `;
    }
    return `
      opacity: 0.75;
    `;
  }};

  &:hover {
    cursor: pointer;
    transition: opacity 50ms;
    opacity: 1;
  }
`;
