const divide = (numerator: number, denominator: number) => {
  const quotient = Math.floor(numerator / denominator);
  const remainder = numerator % denominator;
  return [quotient, remainder];
};

const pluralize = (label: string, value: number) =>
  value === 1 ? label : `${label}s`;

const parseDuration = (seconds: number, units?: string[]) => {
  const [months, monthsRemainder] = divide(seconds, 30 * 24 * 60 * 60);
  const [days, daysRemainder] = divide(monthsRemainder, 24 * 60 * 60);
  const [hours, hoursRemainder] = divide(daysRemainder, 60 * 60);
  const [minutes, minutesRemainder] = divide(hoursRemainder, 60);
  const deltas = [
    { label: pluralize('month', months), value: months },
    { label: pluralize('day', days), value: days },
    { label: pluralize('hour', hours), value: hours },
    { label: pluralize('minute', minutes), value: minutes },
    { label: pluralize('second', minutesRemainder), value: minutesRemainder },
  ];
  return deltas.filter(
    (delta) =>
      delta.value > 0 &&
      (units
        ? units.includes(delta.label) ||
          units.includes(pluralize(delta.label, 2))
        : true)
  );
};

export default parseDuration;
