import { gql } from '@apollo/client';

export const USER_GROUPS_QUERY = gql`
  query ($clientId: ID!, $searchTerm: String) {
    userGroups(clientId: $clientId, searchTerm: $searchTerm) {
      id
      name
      namePlural
      users {
        id
        name
      }
    }
  }
`;

export const USER_GROUP_QUERY = gql`
  query ($userGroupId: ID!) {
    userGroup(userGroupId: $userGroupId) {
      id
      name
      namePlural
      users {
        id
        name
      }
    }
  }
`;
