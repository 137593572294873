import { gql } from '@apollo/client';

export const SITES_QUERY = gql`
  query ($clientId: ID!) {
    sites: clientSites(clientId: $clientId) {
      results {
        id
        name
        location {
          address
          meta
          point {
            longitude
            latitude
          }
        }
        buildings {
          id
        }
      }
      polygon
      centroid {
        longitude
        latitude
      }
    }
  }
`;
