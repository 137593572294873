import React, { Reducer, useEffect, useReducer } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Link, Switch, Route, useLocation } from 'wouter';
import { AnyAction } from 'redux';
import {
  useCallbackRef,
  useClient,
  useSessionId,
  useWebsocketUri,
} from 'lib/hooks';
import { routeMatcher } from 'lib/utils';
import { useTheme } from 'styled-components';
import { faPenAlt } from '@fortawesome/pro-duotone-svg-icons/faPenAlt';
import { faExclamationCircle } from '@fortawesome/pro-duotone-svg-icons/faExclamationCircle';
import { faHome } from '@fortawesome/pro-solid-svg-icons/faHome';
import { faFileContract } from '@fortawesome/pro-duotone-svg-icons/faFileContract';
import { faList } from '@fortawesome/pro-duotone-svg-icons/faList';
import { faMapMarkedAlt } from '@fortawesome/pro-duotone-svg-icons/faMapMarkedAlt';
import { useTitle } from 'react-use';
import { faListUl } from '@fortawesome/pro-duotone-svg-icons';
import ClientJobGroupButton from '../ClientJobGroupButton';
import { JOB_QUERY } from './query';
import CardWrapper from '../CardWrapper';
import {
  ClientJobProps,
  ClientJobState,
  JobQueryData,
  JobQueryVariables,
} from './types';
import reducer, {
  initialState,
  ReducerContext,
  jobQueryOnCompleted,
  jobSubscriptionOnUpdate,
  jobSubscriptionHistoryOnCreate,
  jobSubscriptionHistoryOnUpdate,
  jobSubscriptionReportOnCreate,
  jobSubscriptionReportOnUpdate,
} from './reducer';
import SimpleButton from '../SimpleButton';
import ClientJobLogs from '../ClientJobLogs';
import ClientJobLocation from '../ClientJobMap';
import ClientJobHome from '../ClientJobHome';
import ClientJobIssues from '../ClientJobIssues';
import Figure from '../Figure';
import ClientJobReports from '../ClientJobReports';
import ClientJobReport from '../ClientJobReport';
import ClientJobBreadcrumb from '../ClientJobBreadcrumb';
import ClientJobInstantiation from '../ClientJobInstantiation';
import SimpleButtonWithWindow from '../SimpleButtonWithWindow';
import PortalTopbarExtension from '../PortalTopbarExtension';
import SimpleButtonReactive from '../SimpleButtonReactive';
import ClientJobIssue from '../ClientJobIssue';
import { JobTabBarExtensionContext } from './context';
import ClientJobChatWindow from '../ClientJobChatWindow';
import ClientJobHistory from '../ClientJobHistory';

const ClientJob = ({ params }: ClientJobProps) => {
  const [state, dispatch] = useReducer<Reducer<ClientJobState, AnyAction>>(
    reducer,
    initialState
  );
  const { job } = state;

  useTitle(job?.reference ?? 'McHugh');

  const [location, setLocation] = useLocation();
  const [getJob, { loading }] = useLazyQuery<JobQueryData, JobQueryVariables>(
    JOB_QUERY,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (data.job.reference !== params?.reference) {
          setLocation(location.replace(params?.reference, data.job.reference), {
            replace: true,
          });
        }
        dispatch(jobQueryOnCompleted(data));
      },
    }
  );

  useEffect(() => {
    if (params?.reference === job?.reference) return;
    getJob({ variables: { reference: params?.reference } });
  }, [getJob, job?.reference, params?.reference]);

  const sessionId = useSessionId();
  const websocketUri = useWebsocketUri('job/', { jobId: job?.id });
  useEffect(() => {
    if (!job) return () => {};
    const websocket = new WebSocket(websocketUri);
    websocket.onmessage = (rawResponse) => {
      const response = {
        data: JSON.parse(rawResponse.data),
      };
      if (!response.data) return;
      const {
        sessionId: sessionIdUsedForUpdate,
        type,
        action,
      } = response.data.jobSubscription;
      switch (type) {
        case 'JOB':
          switch (action) {
            case 'UPDATE':
              if (sessionId !== sessionIdUsedForUpdate) {
                dispatch(jobSubscriptionOnUpdate(response.data));
              }
              break;
            default:
              throw Error(`Invalid action: ${action}`);
          }
          break;
        case 'HISTORY': {
          switch (action) {
            case 'CREATE':
              if (sessionId !== sessionIdUsedForUpdate) {
                dispatch(jobSubscriptionHistoryOnCreate(response.data));
              }
              break;
            case 'UPDATE':
              dispatch(jobSubscriptionHistoryOnUpdate(response.data));
              break;
            default:
              throw Error(`Invalid action: ${action}`);
          }
          break;
        }
        case 'REPORT':
          switch (action) {
            case 'CREATE':
              if (sessionId !== sessionIdUsedForUpdate) {
                dispatch(jobSubscriptionReportOnCreate(response.data));
              }
              break;
            case 'UPDATE':
              if (sessionId !== sessionIdUsedForUpdate) {
                dispatch(jobSubscriptionReportOnUpdate(response.data));
              }
              break;
            default:
              throw Error(`Invalid action: ${action}`);
          }
          break;
        default:
          throw Error(`Invalid type: ${type}`);
      }
    };
    return () => {
      websocket.close();
    };
  }, [job, sessionId, websocketUri]);

  const theme = useTheme();
  const client = useClient();

  const [element, elementRef] = useCallbackRef<HTMLDivElement>();

  if (!job) return null;

  if (!job?.instantiated) {
    return (
      <ReducerContext.Provider value={[state, dispatch]}>
        <ClientJobInstantiation />
      </ReducerContext.Provider>
    );
  }

  return (
    <ReducerContext.Provider value={[state, dispatch]}>
      <ClientJobBreadcrumb loading={loading} />
      <PortalTopbarExtension>
        <CardWrapper
          className="px-4 py-2 rounded-0"
          style={{ borderLeft: theme.border, borderBottom: theme.border }}
        >
          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <Link to={`/clients/${client.slug}/jobs/${job?.reference}`}>
                <SimpleButton
                  value="visits"
                  icon={faHome}
                  inButtonToolbar
                  active={
                    routeMatcher('/clients/:slug/jobs/:reference', location)[0]
                  }
                >
                  Job home
                </SimpleButton>
              </Link>
              <Link
                to={`/clients/${client.slug}/jobs/${job?.reference}/reports`}
              >
                <SimpleButtonReactive
                  isReactive={!job.initial}
                  reactTo={job.reportCount}
                  value="reports"
                  icon={faFileContract}
                  inButtonToolbar
                  active={
                    routeMatcher(
                      '/clients/:slug/jobs/:reference/reports/:rest*',
                      location
                    )[0]
                  }
                  windowChildren={() => <div className="p-3">New report</div>}
                >
                  Reports <Figure value={job.reportCount} />
                </SimpleButtonReactive>
              </Link>
              <Link
                to={`/clients/${client.slug}/jobs/${job?.reference}/issues`}
              >
                <SimpleButtonReactive
                  isReactive={!job.initial}
                  reactTo={job.issueCount}
                  value="reports"
                  icon={faExclamationCircle}
                  inButtonToolbar
                  active={
                    routeMatcher(
                      '/clients/:slug/jobs/:reference/issues/:rest*',
                      location
                    )[0]
                  }
                  windowChildren={() => <div className="p-3">New issue</div>}
                >
                  Issues <Figure value={job.issueCount} />
                </SimpleButtonReactive>
              </Link>
              <Link to={`/clients/${client.slug}/jobs/${job?.reference}/logs`}>
                <SimpleButton
                  value="reports"
                  icon={faList}
                  inButtonToolbar
                  active={
                    routeMatcher(
                      '/clients/:slug/jobs/:reference/logs/:rest*',
                      location
                    )[0]
                  }
                >
                  Logs
                </SimpleButton>
              </Link>
              <Link to={`/clients/${client.slug}/jobs/${job?.reference}/map`}>
                <SimpleButton
                  value="reports"
                  icon={faMapMarkedAlt}
                  inButtonToolbar
                  active={
                    routeMatcher(
                      '/clients/:slug/jobs/:reference/map',
                      location
                    )[0]
                  }
                >
                  Map
                </SimpleButton>
              </Link>
              <div ref={elementRef} />
            </div>
            <div>
              <Switch>
                <Route path={`/clients/${client.slug}/jobs/:reference`}>
                  <></>
                </Route>
                <Route path={`/clients/${client.slug}/jobs/:reference/logs`}>
                  <></>
                </Route>
                <Route path={`/clients/${client.slug}/jobs/:reference/:rest*`}>
                  <SimpleButtonWithWindow
                    icon={faListUl}
                    inButtonToolbar
                    windowChildren={() => (
                      <div style={{ height: 400 }}>
                        <ClientJobHistory />
                      </div>
                    )}
                  >
                    History
                  </SimpleButtonWithWindow>
                </Route>
              </Switch>
              <ClientJobChatWindow />
              <ClientJobGroupButton job={job} />
              <SimpleButton icon={faPenAlt} inButtonToolbar>
                Edit job
              </SimpleButton>
            </div>
          </div>
        </CardWrapper>
      </PortalTopbarExtension>
      <JobTabBarExtensionContext.Provider value={element}>
        <Switch>
          <Route
            path="/clients/:slug/jobs/:reference"
            component={ClientJobHome}
          />
          <Route
            path="/clients/:slug/jobs/:reference/reports"
            component={ClientJobReports}
          />
          <Route
            path="/clients/:slug/jobs/:reference/reports/:reportNumber"
            component={ClientJobReport}
          />
          <Route
            path="/clients/:slug/jobs/:reference/issues"
            component={ClientJobIssues}
          />
          <Route
            path="/clients/:slug/jobs/:reference/issues/:issueNumber"
            component={ClientJobIssue}
          />
          <Route
            path="/clients/:slug/jobs/:reference/logs"
            component={ClientJobLogs}
          />
          <Route
            path="/clients/:slug/jobs/:reference/map"
            component={ClientJobLocation}
          />
        </Switch>
      </JobTabBarExtensionContext.Provider>
    </ReducerContext.Provider>
  );
};

export default ClientJob;
