import { gql } from '@apollo/client';

export const CREATE_JOB_MUTATION = gql`
  mutation(
    $clientId: ID
    $name: String
    $siteId: ID
    $visits: [JobVisitInputType]
  ) {
    createJob(
      clientId: $clientId
      name: $name
      siteId: $siteId
      visits: $visits
    ) {
      id
    }
  }
`;
