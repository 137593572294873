import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import {
  ResourceModel,
  SchedulerEventModel,
} from '@bryntum/schedulerpro/schedulerpro.umd.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/pro-duotone-svg-icons/faUserCircle';
import chroma from 'chroma-js';
import { Link } from 'wouter';
import { Client, JobVisit } from '../../lib/types';
import { parseDateTime } from '../../lib/utils';
import JobStatusFlag from '../JobStatusFlag';
import UserInitials from '../UserInitials';

export const renderColumn = ({ record }: { record: ResourceModel }) => {
  const eventsInSeries = record.events.length;
  return (
    <div className="d-flex">
      <div className="center-vertically">
        <UserInitials
          className="mr-2"
          style={{ backgroundColor: record.eventColor }}
          user={{ initials: record.initials }}
        />
      </div>
      <div className="center-vertically">
        <p
          className={classNames('mb-0', {
            'font-weight-bold': eventsInSeries > 0,
          })}
        >
          {record.name}
        </p>
        {eventsInSeries > 0 && (
          <p className="mb-0 text-50" style={{ lineHeight: '16px' }}>
            <small>
              <span className="mono">{eventsInSeries}</span> visit
              {eventsInSeries === 1 ? '' : 's'}
            </small>
          </p>
        )}
      </div>
    </div>
  );
};

export const renderEvent = ({
  eventRecord,
  resourceRecord,
}: {
  eventRecord: SchedulerEventModel;
  resourceRecord: ResourceModel;
}) => {
  return renderToStaticMarkup(
    <div
      id={`job-visit-scheduler-visit-${eventRecord.id}`}
      className="d-flex flex-column justify-content-between"
      style={{
        color:
          chroma(eventRecord.eventColor).hex() === chroma('white').hex()
            ? 'black'
            : '',
      }}
    >
      <div style={{ lineHeight: '14px', opacity: 0.75 }}>
        <small className="mono">
          {DateTime.fromJSDate(eventRecord.startDate as Date).toLocaleString(
            DateTime.TIME_24_SIMPLE
          )}
          {' - '}
          {DateTime.fromJSDate(eventRecord.endDate as Date).toLocaleString(
            DateTime.TIME_24_SIMPLE
          )}
        </small>
        <small className="ml-2">
          <FontAwesomeIcon className="mr-1" icon={faUserCircle} fixedWidth />
          {resourceRecord.name}
        </small>
      </div>
      <div
        className="font-weight-bold"
        style={{ lineHeight: '16px', fontSize: 12 }}
      >
        {eventRecord.name}
      </div>
      <div style={{ lineHeight: '14px', opacity: 0.75, marginTop: 2 }}>
        <span className="mono">{(eventRecord as any)?.job?.reference}</span>
      </div>
    </div>
  );
};

export const renderTooltip = (
  client: Client,
  visit: JobVisit,
  eventRecord: SchedulerEventModel
) => {
  if (!visit.job) return '';
  return renderToStaticMarkup(
    <div>
      <div>
        <p className="mb-0">
          Start:{' '}
          <span className="mono">{parseDateTime(visit.dateTimeStart)}</span>
        </p>
        <p>
          End: <span className="mono">{parseDateTime(visit.dateTimeEnd)}</span>
        </p>
      </div>
      <div>
        <span className="mono mr-1">{visit.job.reference}</span>
        <JobStatusFlag className="mr-1" flag={visit.job.status.flag} />
        {visit.job.status.nameDisplay}
        <div>
          <Link
            to={`/clients/${client.slug}/jobs/${visit.job.reference}`}
            target="_blank"
            rel="noreferrer noopener"
          >
            {visit.job.name}
          </Link>
        </div>
        <div>{visit.job.site.name}</div>
      </div>
      <div>{(eventRecord as any).dragValidationText}</div>
    </div>
  );
};
