import React, { useState } from 'react';
import { Link } from 'wouter';
import { faMapMarked } from '@fortawesome/pro-duotone-svg-icons/faMapMarked';
import SimpleButton from '../SimpleButton';
import ClientSitesMap from '../ClientSitesMap';
import { useReducerContext } from '../ClientJob/reducer';
import { Wrapper } from './styled';
import { ClientJobHomeMapProps } from './types';

const ClientJobHomeMap = ({ overlay = false }: ClientJobHomeMapProps) => {
  const [{ job }] = useReducerContext();

  const [viewState, setViewState] = useState({
    longitude: 0,
    latitude: 0,
    ...job?.site?.location?.point,
    zoom: 15,
    pitch: 45,
    bearing: -20,
  });

  const handleOnViewStateChange = ({ viewState: newViewState }: any) => {
    setViewState(newViewState);
  };

  if (!job) return null;
  return (
    <Wrapper className="h-100">
      {overlay && (
        <div className="map-label shadow">
          <div className="d-flex">
            <h5 className="mb-0 mr-4" style={{ lineHeight: '34px' }}>
              Location
            </h5>
            <Link to={`/clients/${job.client.slug}/jobs/${job.reference}/map`}>
              <small>
                <SimpleButton icon={faMapMarked} hoverStyles={false}>
                  View full map
                </SimpleButton>
              </small>
            </Link>
          </div>
          <p className="text-75 mb-0">{job.site.location.address}</p>
        </div>
      )}
      <div className="h-100">
        <ClientSitesMap
          sites={[job.site]}
          viewState={viewState}
          onViewStateChange={handleOnViewStateChange}
          height="100%"
        />
      </div>
    </Wrapper>
  );
};

export default ClientJobHomeMap;
