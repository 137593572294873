import React, { useRef } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { JobReport, JobReportTemplate, JobVisit } from 'lib/types';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faHandPointer } from '@fortawesome/pro-light-svg-icons/faHandPointer';
import { useTheme } from 'styled-components';
import { getId } from 'lib/utils';
import { Small } from '../Typography';
import SimpleButton from '../SimpleButton';
import JobVisitCard from '../JobVisitCard';
import Checkbox from '../Checkbox';
import {
  selectJobVisit,
  setSchedulerReadOnly,
  setSchedulerSelectMode,
  useReducerContext,
} from '../ClientJob/reducer';

const JobVisitSchedulerReportsField = ({
  input,
  setOverlay,
}: FieldRenderProps<{ parent: JobVisit; reports: JobReport[] }>) => {
  const theme = useTheme();
  const [{ selectedJobVisit, scheduler }, dispatch] = useReducerContext();

  const handleEnableSelectVisitMode = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    dispatch(setSchedulerReadOnly(true));
    dispatch(setSchedulerSelectMode('parent'));
    setOverlay(false);
  };

  const handleOnDeselect = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    dispatch(selectJobVisit(undefined));
  };

  const jobVisitCardRef = useRef<HTMLDivElement>(null);

  const toggleReportSelection = (id: JobReportTemplate['id']) => {
    if (!selectedJobVisit) return;
    const report = selectedJobVisit.reports.find(
      (innerReport) => innerReport.id === id
    );
    if (!report) return;
    if (input.value.reports.includes(report)) {
      input.onChange({
        ...input.value,
        reports: input.value.reports.filter(
          (innerReport) => innerReport.id !== id
        ),
      });
    } else {
      input.onChange({
        ...input.value,
        reports: [...input.value.reports, report],
      });
    }
  };

  const handleReportOnToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = getId(event);
    if (!id) return;
    toggleReportSelection(id);
  };

  return (
    <>
      <div>
        {selectedJobVisit ? (
          <div className="d-flex justify-content-between">
            <Small style={{ lineHeight: '34px' }}>Selected visit</Small>
            <SimpleButton
              hoverStyles={false}
              icon={faTimes}
              onClick={handleOnDeselect}
            >
              Deselect
            </SimpleButton>
          </div>
        ) : (
          <div>
            <Small>Add to existing report</Small>
            <small>
              <p>Select a visit to add to a report</p>
            </small>
          </div>
        )}
        <div ref={jobVisitCardRef}>
          {selectedJobVisit && (
            <JobVisitCard
              className="mb-4"
              style={{
                border: `2px solid ${theme.color.primary.hex()}`,
              }}
              jobVisit={selectedJobVisit}
            >
              <div className="mt-3">
                <div className="mb-2">
                  <Small>Reports</Small>
                </div>
                {selectedJobVisit.reports.length > 0 ? (
                  selectedJobVisit.reports.map((report) => (
                    <div key={report.id}>
                      <Checkbox
                        label={report.template.name}
                        className="ml-1"
                        checked={input.value.reports.includes(report)}
                        data-id={report.id}
                        onChange={handleReportOnToggle}
                      />
                    </div>
                  ))
                ) : (
                  <div>This visit has no reports</div>
                )}
              </div>
            </JobVisitCard>
          )}
        </div>
        <div>
          {scheduler.selectMode !== 'parent' && (
            <SimpleButton
              active
              icon={faHandPointer}
              onClick={handleEnableSelectVisitMode}
            >
              Select job visit
            </SimpleButton>
          )}
        </div>
      </div>
    </>
  );
};

export default JobVisitSchedulerReportsField;
