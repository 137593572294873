import { gql } from '@apollo/client';

export const USERS_QUERY = gql`
  query ($searchTerm: String, $userGroupId: ID) {
    users(searchTerm: $searchTerm, userGroupId: $userGroupId) {
      id
      name
    }
  }
`;

export const REMOVE_USER_MUTATION = gql`
  mutation ($userGroupId: ID!, $userId: ID!) {
    removeUserFromUserGroup(userGroupId: $userGroupId, userId: $userId) {
      user {
        id
      }
    }
  }
`;
