import { useState } from 'react';

type Options = {
  debounce?: boolean;
  debounceDuration?: number;
  emitTimeoutId?: boolean;
};

type Callback = (...args: any) => void;

type ReturnFunction = Callback;

const defaultOptions = {
  debounce: true,
  debounceDuration: 1000,
  emitTimeoutId: false,
};

function useDebounceCallback(
  callback: Callback,
  options?: {
    debounce?: boolean;
    debounceDuration?: number;
    emitTimeoutId?: false;
  }
): ReturnFunction;

function useDebounceCallback(
  callback: Callback,
  options: Options = defaultOptions
) {
  const [saveTimeout, setSaveTimeout] = useState<NodeJS.Timeout>();

  return (...args: any) => {
    if (saveTimeout) {
      clearTimeout(saveTimeout);
    }
    setSaveTimeout(
      setTimeout(() => callback(...args), options.debounceDuration)
    );
  };
}

export default useDebounceCallback;
