import styled from 'styled-components';
import { Small } from '../Typography';
import { WeekdayProps, DayProps } from './types';

export const Weekday = styled(Small)<WeekdayProps>`
  transition: color 100ms;
  color: ${({ theme, hovered }) =>
    theme.color.cardText.alpha(hovered ? 1 : 0.5).hex()};
`;

export const Week = styled.div``;

export const Day = styled.button<DayProps>`
  width: 32px;
  height: 32px;
  padding: 0;
  border: none;
  border-bottom: 2px solid transparent;
  border-radius: 0.25rem;
  color: ${({ theme, active }) =>
    active ? theme.color.contentBackground.hex() : theme.color.cardText.hex()};
  background-color: ${({ theme, active }) =>
    active ? theme.color.cardText.hex() : theme.color.contentBackground.hex()};

  transition: background-color 150ms;

  &:hover {
    transition: background-color 50ms;
    background-color: ${({ theme, active }) =>
      active
        ? theme.color.cardText.hex()
        : theme.color.contentBackground.alpha(0.5).hex()};
  }

  ${({ theme, count, maxCount }) => {
    if (!count || !maxCount) return '';
    return `
      border-bottom-color: ${theme.color.primary.alpha(count / maxCount).hex()}
    `;
  }}
`;
