import styled from 'styled-components';

export const BaseClientJobIssueBlockWrapper = styled.div`
  div.line-decoration {
    width: 100%;
    height: 2rem;
    margin-left: 3rem;
    border-left: ${({ theme }) => theme.border};
    border-left-color: ${({ theme }) => theme.color.cardBackground.hex()};
    border-left-width: 2px;
  }
`;

export const ClientJobIssueMarkerWrapper = styled.div`
  position: relative;

  div.icon-decoration {
    margin-left: 3rem;
    transform: translateX(-50%);
    background-color: ${({ theme }) => theme.color.cardBackground.hex()};
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }

  div.line-decoration {
    width: 100%;
    height: 2rem;
    margin-left: 3rem;
    border-left: ${({ theme }) => theme.border};
    border-left-color: ${({ theme }) => theme.color.cardBackground.hex()};
    border-left-width: 2px;
  }
`;
