import React, { useState } from 'react';
import { useClient, useSetTaskbarData, useTable } from 'lib/hooks';
import { useQuery } from '@apollo/client';
import { JobGroup } from 'lib/types';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
import { useTheme } from 'styled-components';
import { JOB_GROUPS_QUERY } from './query';
import ClientJobsPPMTable from '../ClientJobsPPMTable';
import { TableHeadNames } from '../ClientJobsPPMTable/types';
import { JobGroupsQueryData, JobGroupsQueryVariables } from './types';
import CardWrapper from '../CardWrapper';
import ClientJobsPPMTableFilter from '../ClientJobsPPMTableFilter';
import Button from '../Button';
import PortalBreadcrumb from '../PortalBreadcrumb';
import TablePageIndicator from '../TablePageIndicator';

const ClientJobsPPM = () => {
  const client = useClient();

  useSetTaskbarData({
    type: 'JOBS',
    label: `${client.name} PPM series`,
    path: `/clients/${client.slug}/jobs/ppm`,
  });

  const [jobGroups, setJobGroups] = useState<{
    results: JobGroup[];
    count: number;
  }>({
    count: 0,
    results: [],
  });
  const { refetch } = useQuery<JobGroupsQueryData, JobGroupsQueryVariables>(
    JOB_GROUPS_QUERY,
    {
      fetchPolicy: 'no-cache',
      variables: {
        clientId: client.id,
        page: 1,
        sort: { name: 'reference', order: false },
      },
      onCompleted: (data) => {
        setJobGroups(data.jobGroups);
      },
    }
  );

  const tableHeadNames: TableHeadNames = [
    'reference',
    'jobs',
    'name',
    'site',
    'dateTimeStart',
    'dateTimeEnd',
  ];

  const {
    sortState,
    page,
    refetching,
    handleOnSort,
    searchInputProps,
    handleChangePage,
  } = useTable(tableHeadNames, refetch, {
    defaultActiveName: 'reference',
    sortOnCompleted: (response) => {
      setJobGroups(response.data.jobGroups);
    },
    searchOnCompleted: (response) => {
      setJobGroups(response.data.jobGroups);
    },
    changePageCompleted: (response) => {
      setJobGroups(response.data.jobGroups);
    },
  });

  const theme = useTheme();
  return (
    <>
      <PortalBreadcrumb>
        <h4 className="text-capitalize">PPM Jobs</h4>
      </PortalBreadcrumb>
      <div className="h-100">
        <CardWrapper className="h-100 px-0 py-3">
          <ClientJobsPPMTableFilter
            jobGroups={jobGroups}
            refetching={refetching}
            searchInputProps={searchInputProps}
          />
          <ClientJobsPPMTable
            rows={jobGroups?.results}
            sortState={sortState}
            sort={handleOnSort}
          />
          <div
            className="d-flex justify-content-between px-3 pt-3"
            style={{ borderTop: theme.border }}
          >
            <div className="d-flex flex-column justify-content-center">
              <TablePageIndicator page={page} count={jobGroups.count} />
            </div>
            <div>
              <Button
                className="mr-3"
                icon={faChevronLeft}
                iconSide="left"
                disabled={page === 1}
                onClick={() => handleChangePage(-1)}
              >
                Previous page
              </Button>
              <Button
                disabled={jobGroups?.count <= 10 || false}
                icon={faChevronRight}
                iconSide="right"
                onClick={() => handleChangePage(1)}
              >
                Next page
              </Button>
            </div>
          </div>
        </CardWrapper>
      </div>
    </>
  );
};

export default ClientJobsPPM;
