import React, { Dispatch, useContext } from 'react';
import { AnyAction } from 'redux';
import { Descendant } from 'slate';
import { Chat, ChatMessage, User } from 'lib/types';
import {
  ChatQueryData,
  CreateChatMessageMutationData,
} from '../CommunicationsChat/types';
import {
  CreateChatMutationData,
  InitialQueryData,
  State,
  WebsocketEventData,
} from './types';

export const initialState: State = {
  chats: [],
  chat: null,
  input: {
    readOnly: false,
    value: [{ type: 'paragraph', children: [{ text: '' }] }],
  },
};

export const ReducerContext = React.createContext<[State, Dispatch<AnyAction>]>(
  [initialState, () => {}]
);

export const useReducerContext = () => useContext(ReducerContext);

export const initialQueryOnCompleted = (responseData: InitialQueryData) => ({
  type: 'INITIAL_QUERY_DATA_ON_COMPLETED',
  chats: responseData.chats.chats,
  chat: responseData.chats.jobChat
    ? { ...responseData.chats.jobChat, skip: true }
    : null,
});

export const inputOnChange = (newValue: Descendant[]) => ({
  type: 'INPUT_ON_CHANGE',
  newValue,
});

export const onCreateChat = () => ({
  type: 'ON_CREATE_CHAT',
});

export const createChatMutationOnCompleted = (
  responseData: CreateChatMutationData
) => ({
  type: 'CREATE_CHAT_MUTATION_ON_COMPLETED',
  chat: responseData.createChat.chat,
});

export const onCreateChatMessage = (
  chatMessage: Pick<
    ChatMessage,
    'tempId' | 'body' | 'isPrimary' | 'device' | 'dateTime'
  >
) => ({
  type: 'ON_CREATE_CHAT_MESSAGE',
  chatMessage,
});

export const clearInput = () => ({
  type: 'CLEAR_INPUT',
});

export const createChatMessageMutationOnCompleted = (
  responseData: CreateChatMessageMutationData
) => ({
  type: 'CREATE_CHAT_MESSAGE_MUTATION_ON_COMPLETED',
  chatMessage: responseData.createChatMessage.chatMessage,
});

export const onWebsocketEvent = (
  eventData: WebsocketEventData,
  sessionId: string,
  user: User
) => ({
  type: 'ON_WEB_SOCKET_EVENT',
  ignore: eventData.chatsSubscription.sessionId === sessionId,
  isPrimary: user.id === (eventData.chatsSubscription.chat.user as string),
  chatMessage: eventData.chatsSubscription.chat,
});

export const chatQueryOnCompleted = (responseData: ChatQueryData) => ({
  type: 'CHAT_QUERY_ON_COMPLETED',
  chat: responseData.chat,
});

export const setChat = (chatId: Chat['id']) => ({
  type: 'SET_CHAT',
  chatId,
});

const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case 'INITIAL_QUERY_DATA_ON_COMPLETED': {
      const thisAction = action as ReturnType<typeof initialQueryOnCompleted>;
      return {
        ...state,
        chats: thisAction.chats,
        chat: thisAction.chat,
      };
    }

    case 'INPUT_ON_CHANGE': {
      const thisAction = action as ReturnType<typeof inputOnChange>;
      return {
        ...state,
        input: {
          ...state.input,
          value: thisAction.newValue,
        },
      };
    }

    case 'CREATE_CHAT_MUTATION_ON_COMPLETED': {
      const thisAction = action as ReturnType<
        typeof createChatMutationOnCompleted
      >;
      return {
        ...state,
        chats: [...state.chats, thisAction.chat],
        chat: thisAction.chat,
      };
    }

    case 'ON_CREATE_CHAT_MESSAGE': {
      if (!state.chat) return state;
      const thisAction = action as ReturnType<typeof onCreateChatMessage>;
      return {
        ...state,
        chat: {
          ...state.chat,
          messages: [
            ...state.chat.messages,
            thisAction.chatMessage as ChatMessage,
          ],
        },
        input: {
          ...state.input,
          value: initialState.input.value,
        },
      };
    }

    case 'CLEAR_INPUT':
      if (!state.input.value) return state;
      return {
        ...state,
        input: {
          ...state.input,
          value: initialState.input.value,
        },
      };

    case 'CREATE_CHAT_MESSAGE_MUTATION_ON_COMPLETED': {
      if (!state.chat) return state;
      const thisAction = action as ReturnType<
        typeof createChatMessageMutationOnCompleted
      >;
      return {
        ...state,
        chat: {
          ...state.chat,
          messages: state.chat.messages.map((chatMessage) =>
            chatMessage.id === thisAction.chatMessage.tempId
              ? { ...chatMessage, ...thisAction.chatMessage }
              : chatMessage
          ),
        },
      };
    }

    case 'ON_WEB_SOCKET_EVENT': {
      const thisAction = action as ReturnType<typeof onWebsocketEvent>;
      if (!state.chat || thisAction.ignore) return state;
      return {
        ...state,
        chat: {
          ...state.chat,
          messages: [
            ...state.chat.messages,
            { ...thisAction.chatMessage, isPrimary: thisAction.isPrimary },
          ],
        },
      };
    }

    case 'CHAT_QUERY_ON_COMPLETED': {
      const thisAction = action as ReturnType<typeof chatQueryOnCompleted>;
      return {
        ...state,
        chat: thisAction.chat,
      };
    }

    case 'SET_CHAT': {
      const thisAction = action as ReturnType<typeof setChat>;
      return {
        ...state,
        chat: state.chats.find((chat) => chat.id === thisAction.chatId) ?? null,
      };
    }

    default:
      return state;
  }
};

export default reducer;
