import { gql } from '@apollo/client';

export const INITIAL_QUERY = gql`
  query {
    clients {
      id
      name
      slug
      immediateActionCount
      jobStatusFlags {
        id
        name
        color
        icon
        count
      }
    }
    user {
      id
      name
      initials
      themeMode
      isConfigured
      websocketToken {
        token
      }
      taskbar {
        tabs
        isVisible
      }
    }
    notificationCount
  }
`;
