import React, { useState } from 'react';
import { createEditor } from 'slate';
import { Slate, Editable, withReact, ReactEditor } from 'slate-react';
import { withHistory } from 'slate-history';
import { SlateEditorProps } from './types';

const SlateEditor = ({
  value,
  onChange,
  editor: externalEditor,
  ...rest
}: SlateEditorProps) => {
  const [editor] = useState<ReactEditor>(() =>
    withHistory(withReact(createEditor() as ReactEditor))
  );

  return (
    <Slate editor={externalEditor ?? editor} value={value} onChange={onChange}>
      <Editable placeholder="Aa" className="h-100" {...rest} />
    </Slate>
  );
};

export default SlateEditor;
