import classNames from 'classnames';
import { DateTime } from 'luxon';
import React from 'react';
import { useTheme } from 'styled-components';
import { parseDateTime } from '../../lib/utils';
import SlateViewer from '../SlateViewer';
import CommunicationsChatMessageAttachmentRouter from './attachments';
import { CommunicationsChatMessageProps } from './types';
import { Wrapper } from './styled';

const CommunicationsChatMessage = ({
  message,
  prevMessage,
  nextMessage,
  index,
  last,
}: CommunicationsChatMessageProps) => {
  const theme = useTheme();

  const dateMarker =
    index === 0 ||
    (prevMessage &&
      DateTime.fromISO(message.dateTime) >
        DateTime.fromISO(prevMessage.dateTime).plus({ minutes: 5 }));

  const nextDateMarker =
    nextMessage &&
    DateTime.fromISO(nextMessage.dateTime) >
      DateTime.fromISO(message.dateTime).plus({ minutes: 5 });

  const getStyle = () => {
    let style: Record<string, React.CSSProperties> = {
      wrapper: {
        marginTop: '0',
        marginBottom: '0.6rem',
      },
      body: {
        borderTopLeftRadius: '',
        borderTopRightRadius: '',
        borderBottomLeftRadius: '',
        borderBottomRightRadius: '',
        marginTop: '',
      },
    };

    const radius = '0.25rem';

    if (
      index !== 0 &&
      message.isPrimary === prevMessage.isPrimary &&
      !dateMarker
    ) {
      if (message.isPrimary) {
        style = {
          ...style,
          body: {
            ...style.body,
            borderTopRightRadius: radius,
          },
        };
      } else {
        style = {
          ...style,
          body: {
            ...style.body,
            borderTopLeftRadius: radius,
          },
        };
      }
    }

    if (
      !last &&
      message.isPrimary === nextMessage.isPrimary &&
      !nextDateMarker
    ) {
      style = {
        ...style,
        wrapper: {
          ...style.wrapper,
          marginBottom: '0.15rem',
        },
      };
      if (message.isPrimary) {
        style = {
          ...style,
          body: {
            ...style.body,
            borderBottomRightRadius: radius,
          },
        };
      } else {
        style = {
          ...style,
          body: {
            ...style.body,
            borderBottomLeftRadius: radius,
          },
        };
      }
    }

    if ((message.attachments ?? []).length > 0) {
      style = {
        ...style,
        body: {
          ...style.body,
          borderBottomLeftRadius: radius,
          borderBottomRightRadius: radius,
        },
      };
    }

    return style;
  };

  const style = getStyle();

  return (
    <>
      {dateMarker && (
        <div
          className={classNames('text-center', { 'mt-4': index !== 0 })}
          style={{ fontSize: '0.8rem' }}
        >
          <small
            style={{
              color: theme.color.cardText.alpha(0.5).hex(),
            }}
          >
            {parseDateTime(
              message.dateTime,
              DateTime.fromISO(message.dateTime)
                .startOf('day')
                .equals(DateTime.local().startOf('day'))
                ? 'HH:mm'
                : 'HH:mm dd MMM yyyy'
            )}
          </small>
        </div>
      )}
      <div
        className={classNames('d-flex', {
          'justify-content-start': !message.isPrimary,
          'justify-content-end': message.isPrimary,
        })}
        style={style.wrapper}
      >
        <div style={{ maxWidth: '80%' }}>
          <Wrapper isPrimary={message.isPrimary} style={style.body}>
            {message.device === 'WEB' && <SlateViewer value={message.body} />}
            {message.device === 'MOBILE' && <div>{message.body}</div>}
          </Wrapper>
          {(message.attachments ?? []).map((chatMessageAttachment) => (
            <CommunicationsChatMessageAttachmentRouter
              key={chatMessageAttachment.id}
              chatMessageAttachment={chatMessageAttachment}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default CommunicationsChatMessage;
