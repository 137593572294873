import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useJobTabBarExtension } from '../ClientJob/context';

const ClientJobTabBarExtension = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  const element = useJobTabBarExtension();
  const [canMount, setCanMount] = useState(false);
  useEffect(() => {
    if (!element) return;
    setCanMount(true);
  }, [element]);
  if (!canMount) return null;
  return ReactDOM.createPortal(children, element as HTMLDivElement);
};

export default ClientJobTabBarExtension;
