import { useSelector } from 'react-redux';
import useClientSlug from './useClientSlug';
import { State } from '../redux/types';
import { Client } from '../types';

const useClient = (): Client => {
  const clientSlug = useClientSlug();
  const { clients } = useSelector((state: State) => state.clients);
  return clients.find((client) => client.slug === clientSlug) as Client;
};

export default useClient;
