import React from 'react';
import { Link, useLocation } from 'wouter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons/faSpinnerThird';
import { faFlag as faFlagDuotone } from '@fortawesome/pro-duotone-svg-icons/faFlag';
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import { useClient } from 'lib/hooks';
import { faGlobeEurope } from '@fortawesome/pro-duotone-svg-icons/faGlobeEurope';
import Select from '../Select';
import TableFilterDescription from '../ClientJobsAllTableFilterDescription';
import Button from '../Button';
import SearchBar from '../SearchBar';
import { ClientJobsAllTableFilterProps } from './types';
import ButtonMulti from '../ButtonMulti';

const ClientJobsAllTableFilter = ({
  jobs,
  jobStatuses,
  refetching,
  activeFlagName,
  nextFlagName,
  activeStatus,
  flagNameOnChange,
  statusOnChange,
  searchBarProps,
}: ClientJobsAllTableFilterProps) => {
  const client = useClient();
  const [, setLocation] = useLocation();

  const handleCreateReactiveJobOnClick = () => {
    setLocation(`/clients/${client?.slug}/jobs/create-reactive`);
  };

  const handleCreatePPMJobOnClick = () => {
    setLocation(`/clients/${client?.slug}/jobs/create-ppm`);
  };

  return (
    <div>
      <div className="d-flex px-3">
        <div className="flex-grow-1">
          <p className="mb-2 text-50">Search</p>
          <SearchBar
            style={{
              borderColor: searchBarProps.value !== '' ? '#106c12' : '',
            }}
            placeholder={`Search through ${activeFlagName.toLowerCase()} jobs...`}
            {...searchBarProps}
          />
        </div>
        <div>
          <p className="mb-2 text-50">Filter by flag</p>
          <Button
            className="mr-3"
            type="button"
            icon={faGlobeEurope}
            loading={refetching && nextFlagName === 'all'}
            active={activeFlagName === 'all'}
            onClick={() => flagNameOnChange('all')}
          >
            All flags
          </Button>
          <Button
            className="mr-3"
            icon={faFlagDuotone}
            iconProps={{ className: 'mr-2' }}
            loading={refetching && nextFlagName === 'BLUE'}
            flagType="BLUE"
            type="button"
            active={activeFlagName === 'BLUE'}
            onClick={() => flagNameOnChange('BLUE')}
          >
            Blue
          </Button>
          <Button
            className="mr-3"
            icon={faFlagDuotone}
            iconProps={{ className: 'mr-2' }}
            loading={refetching && nextFlagName === 'GREEN'}
            flagType="GREEN"
            type="button"
            active={activeFlagName === 'GREEN'}
            onClick={() => flagNameOnChange('GREEN')}
          >
            Green
          </Button>
          <Button
            className="mr-3"
            flagType="AMBER"
            type="button"
            active={activeFlagName === 'AMBER'}
            onClick={() => flagNameOnChange('AMBER')}
          >
            <FontAwesomeIcon
              className="icon"
              icon={
                refetching && nextFlagName === 'AMBER'
                  ? faSpinnerThird
                  : faFlagDuotone
              }
              spin={refetching && nextFlagName === 'AMBER'}
              fixedWidth
            />
            Amber
          </Button>
          <Button
            className="mr-3"
            icon={faFlagDuotone}
            iconProps={{ className: 'mr-2' }}
            loading={refetching && nextFlagName === 'RED'}
            flagType="RED"
            type="button"
            active={activeFlagName === 'RED'}
            onClick={() => flagNameOnChange('RED')}
          >
            Red
          </Button>
          <Button
            className="mr-3"
            flagType="WHITE"
            type="button"
            active={activeFlagName === 'WHITE'}
            onClick={() => flagNameOnChange('WHITE')}
          >
            <FontAwesomeIcon
              className="icon"
              icon={
                refetching && nextFlagName === 'WHITE'
                  ? faSpinnerThird
                  : faFlagDuotone
              }
              spin={refetching && nextFlagName === 'WHITE'}
              fixedWidth
            />
            White
          </Button>
        </div>
        <div className="flex-grow-1 mr-3">
          <p className="mb-2 text-50">Filter by status</p>
          <Select
            isClearable
            isLoading={refetching}
            active={!!activeStatus}
            options={jobStatuses.map((jobStatus) => ({
              label: jobStatus.nameDisplay,
              value: jobStatus.id,
            }))}
            value={
              activeStatus
                ? { label: activeStatus.nameDisplay, value: activeStatus.id }
                : null
            }
            placeholder="Filter by status..."
            onChange={statusOnChange}
          />
        </div>
        <div style={{ marginTop: '2rem' }}>
          <Link href={`/clients/${client.slug}/jobs/create`}>
            <ButtonMulti
              label="Create"
              icon={faPlus}
              options={[
                {
                  label: 'PPM job',
                  icon: faPlus,
                  onClick: handleCreatePPMJobOnClick,
                },
                {
                  label: 'Reactive job',
                  icon: faPlus,
                  onClick: handleCreateReactiveJobOnClick,
                },
              ]}
            />
          </Link>
        </div>
      </div>
      <div className="text-50 px-3 my-3">
        <TableFilterDescription
          count={jobs?.count || 0}
          searchTerm={searchBarProps.value}
          activeFlagName={activeFlagName}
          activeStatusName={activeStatus?.nameDisplay}
        />
      </div>
    </div>
  );
};

export default ClientJobsAllTableFilter;
