import styled from 'styled-components';
import CardWrapper from '../CardWrapper';
import { InstantiationStageWrapperProps } from './types';

export const InstantiationStageWrapper = styled.div<InstantiationStageWrapperProps>`
  border-left: 2px solid
    ${({ theme, last }) =>
      last ? 'transparent' : theme.color.cardBackground.alpha(0.75).hex()};
  padding-bottom: 3rem;

  div.instantiation-stage-number-icon {
    position: absolute;
    width: 32px;
    height: 32px;
    left: -3px;

    div {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-color: ${({ theme }) => theme.color.cardBackground.hex()};
    }

    span {
      position: absolute;
      display: block;
      top: 52%;
      width: 100%;
      text-align: center;
      font-size: 16px;
      transform: translateY(-50%);
      color: ${({ theme }) => theme.color.cardText.hex()};
      z-index: 5;
    }
  }

  h5.instantiation-stage-title {
    color: ${({ theme }) => theme.color.cardText.alpha(0.75).hex()};
    line-height: 32px;
    margin-left: 2rem;
  }
`;

export const InstantiationStageInner = styled(CardWrapper)`
  margin-left: 2rem;
`;

export const InstantiationQuestionWrapper = styled(
  InstantiationStageWrapper
)<any>``;

export const InstantiationDetailsWrapper = styled(
  InstantiationStageWrapper
)<any>`
  border-left: none;

  h5 {
    margin-left: 0;
  }
`;
