import React from 'react';
import { msalApplication } from 'lib/msal';
import { useLocation } from 'wouter';
import CardWrapper from '../CardWrapper';
import SimpleButton from '../SimpleButton';

const Profile = () => {
  const [, setLocation] = useLocation();
  const handleOnLogout = () => {
    msalApplication.logout();
    setLocation('/login');
  };
  return (
    <div>
      <CardWrapper>
        <SimpleButton onClick={handleOnLogout}>Logout</SimpleButton>
      </CardWrapper>
    </div>
  );
};

export default Profile;
