import { createStore, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import {
  layoutReducer,
  clientsReducer,
  themeReducer,
  userReducer,
  errorsReducer,
  toastReducer,
  notificationsReducer,
  todosReducer,
  utilsReducer,
} from './reducer';
import { Client, User } from '../types';
import { darkTheme, lightTheme } from '../theme';

const makeStore = (preloadedState: {
  clients: Client[];
  themeMode: 'LIGHT' | 'DARK';
  user: User;
  notificationCount: number;
}) => {
  return createStore(
    combineReducers({
      layout: layoutReducer,
      clients: clientsReducer,
      theme: themeReducer,
      user: userReducer,
      errors: errorsReducer,
      toast: toastReducer,
      notifications: notificationsReducer,
      todos: todosReducer,
      utils: utilsReducer,
    }),
    {
      clients: {
        clients: preloadedState.clients,
        client: null,
        isOpen: false,
      },
      theme: {
        mode: preloadedState.themeMode,
        ...(preloadedState.themeMode === 'LIGHT' ? lightTheme : darkTheme),
      },
      user: preloadedState.user,
      notifications: {
        notifications: [],
        notificationCount: preloadedState.notificationCount,
        isOpen: false,
        opened: false,
      },
    },
    composeWithDevTools()
  );
};

export default makeStore;
