import React from 'react';
import { WindowWrapper } from './styled';
import Portal from '../Portal';
import { WindowProps } from './types';

const Window = ({
  innerRef,
  children,
  position = 'center',
  onMouseEnter,
  onMouseLeave,
  ...rest
}: WindowProps) => {
  return (
    <Portal>
      <WindowWrapper
        {...rest}
        ref={innerRef}
        position={position}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div className="inner shadow h-100">{children}</div>
      </WindowWrapper>
    </Portal>
  );
};

export default Window;
