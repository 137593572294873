import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  ProjectModel,
  SchedulerEventModel,
  SchedulerPro,
  SchedulerProConfig,
} from '@bryntum/schedulerpro/schedulerpro.umd.js';
import { DateTime } from 'luxon';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { BryntumSchedulerPro } from '@bryntum/schedulerpro-react';
import { batch } from 'react-redux';
import { Col, Row } from 'reactstrap';
import { useTheme } from 'styled-components';
import { useClient } from 'lib/hooks';
import { JobGroupMultiAssignPlacement, User } from 'lib/types';
import {
  analyseJobVisitPlacementQueryOnCompleted,
  analyseUpdatedJobVisitPlacementQueryOnCompleted,
  resetMultiAssignment,
  schedulerInitialQueryOnCompleted,
  toggleLockJobVisitPlacement,
  updateGeneratedPlacement,
  useReducerContext,
} from '../ClientJobGroup/reducer';
import ClientJobGroupSchedulerMultiAssignHelper from '../ClientJobGroupSchedulerMultiAssignHelper';
import {
  ANALYSE_JOB_VISIT_PLACEMENT_QUERY,
  ANALYSE_UPDATED_JOB_VISIT_PLACEMENT_QUERY,
} from '../ClientJobGroupSchedulerMultiAssignHelper/query';
import {
  AnalyseJobVisitPlacementQueryData,
  AnalyseJobVisitPlacementQueryVariables,
  AnalyseUpdatedJobVisitPlacementQueryData,
  AnalyseUpdatedJobVisitPlacementQueryVariables,
} from '../ClientJobGroupSchedulerMultiAssignHelper/types';
import JobVisitSchedulerControls from '../JobVisitSchedulerControls';
import { CANCEL_MULTI_ASSIGNMENT_MUTATION, INITIAL_QUERY } from './query';
import { renderColumn, renderEvent } from './renderers';
import { InitialQueryData, InitialQueryVariables } from './types';
import {
  schedulerConfig,
  addResources,
  addDeadlines,
  addAssignedVisits,
  fetchMoreVisitsOnCompleted,
  addPlacements,
} from './utils';

const ClientJobGroupScheduler = () => {
  const theme = useTheme();
  const client = useClient();
  const [{ jobGroup, selectedJob, futurePlacementCount, scheduler }, dispatch] =
    useReducerContext();

  const [users, setUsers] = useState<User[]>([]);

  const schedulerRef = useRef<{ instance: SchedulerPro }>(null);
  const [schedulerProject] = useState(new ProjectModel());

  const [currentEventRecord, setCurrentEventRecord] =
    useState<SchedulerEventModel | null>(null);

  useEffect(() => {
    if (!schedulerRef.current) return;
    const canEdit = !!jobGroup?.activeMultiAssignPlacementBatch;
    schedulerRef.current.instance.features.eventDragCreate.disabled = !canEdit;
    schedulerRef.current.instance.features.taskEdit.disabled = !canEdit;
  }, [jobGroup?.activeMultiAssignPlacementBatch]);

  const initialViewDateTimeStart = useMemo(() => {
    if (!selectedJob) return DateTime.local();
    return DateTime.fromISO(selectedJob.viewDateTimeStart);
  }, [selectedJob]);

  const initialViewDateTimeEnd = useMemo(() => {
    if (!selectedJob) return DateTime.local();
    return DateTime.fromISO(selectedJob.viewDateTimeEnd);
  }, [selectedJob]);

  useEffect(() => {
    if (!schedulerRef.current) return;
    schedulerRef.current.instance.timeAxis.setTimeSpan(
      initialViewDateTimeStart.toJSDate(),
      initialViewDateTimeEnd.toJSDate()
    );
  }, [initialViewDateTimeStart, initialViewDateTimeEnd]);

  const { fetchMore } = useQuery<InitialQueryData, InitialQueryVariables>(
    INITIAL_QUERY,
    {
      fetchPolicy: 'no-cache',
      variables: {
        clientId: client.id,
        jobGroupId: jobGroup?.id,
        dateTimeStart: initialViewDateTimeStart.toISO(),
        dateTimeEnd: initialViewDateTimeEnd.toISO(),
      },
      onCompleted: (data) => {
        if (!schedulerRef.current) return;
        dispatch(schedulerInitialQueryOnCompleted(data));
        setUsers(data.userGroups.map((userGroup) => userGroup.users).flat());
        addResources(data.userGroups, schedulerProject, theme);
        if (jobGroup && selectedJob) {
          addDeadlines(
            selectedJob,
            initialViewDateTimeStart,
            DateTime.fromISO(selectedJob.targetDateTimeEnd),
            DateTime.fromISO(selectedJob.targetDateTimeStart),
            schedulerProject
          );
          addAssignedVisits(
            data.jobVisits,
            jobGroup,
            selectedJob,
            schedulerProject
          );
        }
        if (data.jobGroup.activeMultiAssignPlacementBatch) {
          setCurrentEventRecord(
            addPlacements(
              data.jobGroup.activeMultiAssignPlacementBatch.placements,
              false,
              schedulerProject
            )?.[0] ?? null
          );
        }
      },
    }
  );

  const handleOnForward = (unit: string) => {
    if (!schedulerRef.current) return;
    schedulerRef.current?.instance.shift(1, unit);
  };

  const handleOnBackward = (unit: string) => {
    if (!schedulerRef.current) return;
    schedulerRef.current?.instance.shift(-1, unit);
  };

  const handleOnTimeAxisChange = useCallback(
    ({ config }: { config: SchedulerProConfig }) => {
      const newDateTimeStart = DateTime.fromJSDate(config.startDate as Date);
      const newDateTimeEnd = DateTime.fromJSDate(config.endDate as Date);
      fetchMore({
        variables: {
          dateTimeStart: newDateTimeStart.toISO(),
          dateTimeEnd: newDateTimeEnd.toISO(),
        },
      }).then((response) => {
        if (!selectedJob) return;
        fetchMoreVisitsOnCompleted(response, selectedJob, schedulerProject);
      });
    },
    [fetchMore, selectedJob, schedulerProject]
  );

  const [analyseJobVisitPlacement, { loading }] = useLazyQuery<
    AnalyseJobVisitPlacementQueryData,
    AnalyseJobVisitPlacementQueryVariables
  >(ANALYSE_JOB_VISIT_PLACEMENT_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (!schedulerRef.current) return;
      dispatch(analyseJobVisitPlacementQueryOnCompleted(data));
      if (currentEventRecord) {
        schedulerRef.current.instance.eventStore.remove(currentEventRecord);
      }
      setCurrentEventRecord(
        addPlacements(
          data.analyseJobVisitPlacement,
          true,
          schedulerProject
        )?.[0] ?? null
      );
    },
  });

  const handleOnBeforeTaskEdit = ({
    taskRecord,
  }: {
    taskRecord: SchedulerEventModel;
  }) => {
    if (!schedulerRef.current) return false;
    if (!jobGroup || !selectedJob) return false;
    if (scheduler.readOnly) return false;
    if (currentEventRecord) return false;
    setCurrentEventRecord(taskRecord);
    analyseJobVisitPlacement({
      variables: {
        jobGroupId: jobGroup.id,
        jobVisitPlacement: {
          jobId: selectedJob.id,
          dateTimeStart: DateTime.fromJSDate(
            taskRecord.startDate as Date
          ).toISO(),
          dateTimeEnd: DateTime.fromJSDate(taskRecord.endDate as Date).toISO(),
          userId: taskRecord.resourceId as string,
        },
        futurePlacementCount,
      },
    });
    return false;
  };

  const updateOriginal = (eventRecord: SchedulerEventModel) => {
    if (!jobGroup) return;
    const { job: placementJob } = (eventRecord as any).data;
    analyseJobVisitPlacement({
      variables: {
        jobGroupId: jobGroup.id,
        jobVisitPlacement: {
          jobId: placementJob.id,
          dateTimeStart: DateTime.fromJSDate(
            eventRecord.startDate as Date
          ).toISO(),
          dateTimeEnd: DateTime.fromJSDate(eventRecord.endDate as Date).toISO(),
          userId: eventRecord.resourceId as string,
        },
        futurePlacementCount,
      },
    });
  };

  const apolloClient = useApolloClient();
  const updateGenerated = async (eventRecord: SchedulerEventModel) => {
    const { id, job: placementJob } = (eventRecord as any).data;
    if (!jobGroup || !selectedJob) return;

    batch(() => {
      dispatch(toggleLockJobVisitPlacement(id, true));
      dispatch(
        updateGeneratedPlacement(id, {
          dateTimeStart: DateTime.fromJSDate(
            eventRecord.startDate as Date
          ).toISO(),
          dateTimeEnd: DateTime.fromJSDate(eventRecord.endDate as Date).toISO(),
          user: users.find(
            (user) => user.id === eventRecord.resourceId
          ) as User,
        })
      );
    });

    const { data } = await apolloClient.query<
      AnalyseUpdatedJobVisitPlacementQueryData,
      AnalyseUpdatedJobVisitPlacementQueryVariables
    >({
      query: ANALYSE_UPDATED_JOB_VISIT_PLACEMENT_QUERY,
      variables: {
        jobVisitPlacement: {
          id,
          jobId: placementJob.id,
          dateTimeStart: DateTime.fromJSDate(
            eventRecord.startDate as Date
          ).toISO(),
          dateTimeEnd: DateTime.fromJSDate(eventRecord.endDate as Date).toISO(),
          userId: eventRecord.resourceId as string,
        },
      },
    });

    dispatch(analyseUpdatedJobVisitPlacementQueryOnCompleted(id, data));
  };

  const handleOnAfterEventDrop = ({
    draggedRecords,
    valid,
  }: {
    draggedRecords: SchedulerEventModel[];
    valid: boolean;
  }) => {
    if (!valid) return;
    const [draggedEvent] = draggedRecords;
    if (draggedEvent.isPhantom) {
      draggedEvent.set('original', true);
      setCurrentEventRecord(null);
      setCurrentEventRecord(draggedEvent);
    }
    if ((draggedEvent as any).data.generated) {
      updateGenerated(draggedEvent);
    } else if ((draggedEvent as any).data.isOriginal) {
      updateOriginal(draggedEvent);
    }
  };

  const handleOnEventResizeEnd = ({
    changed,
    eventRecord,
  }: {
    changed: boolean;
    eventRecord: SchedulerEventModel;
  }) => {
    if (!changed) return;
    if (eventRecord.isPhantom) {
      setCurrentEventRecord(null);
      setCurrentEventRecord(eventRecord);
    }
    if ((eventRecord as any).data.generated) {
      updateGenerated(eventRecord);
    } else if ((eventRecord as any).data.isOriginal) {
      updateOriginal(eventRecord);
    }
  };

  const [currentGeneratedJobVisit, setCurrentGeneratedJobVisit] =
    useState<null | JobGroupMultiAssignPlacement>(null);

  const handleGeneratedJobVisitOnClick = (
    placement: JobGroupMultiAssignPlacement
  ) => {
    if (!schedulerRef.current) return;
    setCurrentGeneratedJobVisit(placement);
    schedulerRef.current.instance.timeAxis.setTimeSpan(
      DateTime.fromISO(placement.dateTimeStart).startOf('day').toJSDate(),
      DateTime.fromISO(placement.dateTimeEnd).endOf('day').toJSDate()
    );
    const record = schedulerRef.current.instance.eventStore.getById(
      placement.id
    );
    if (record) {
      schedulerRef.current.instance.scrollEventIntoView(
        record as SchedulerEventModel,
        { highlight: true, edgeOffset: 300 }
      );
      addDeadlines(
        placement.job,
        initialViewDateTimeStart,
        DateTime.fromISO(placement.job.targetDateTimeEnd),
        DateTime.fromISO(placement.job.targetDateTimeStart),
        schedulerProject
      );
    }
  };

  const [cancelMultiAssignment] = useMutation(CANCEL_MULTI_ASSIGNMENT_MUTATION);

  const handleMultiAssignmentOnCancel = async () => {
    if (!schedulerRef.current || !jobGroup) return;
    if (currentEventRecord) {
      schedulerRef.current.instance.eventStore.remove(currentEventRecord.id);
    }
    setCurrentEventRecord(null);
    const { data } = await cancelMultiAssignment({
      variables: { jobGroupId: jobGroup.id },
    });
    if (data) {
      dispatch(resetMultiAssignment());
    }
  };

  if (!jobGroup) return null;
  return (
    <div className="h-100 d-flex flex-column">
      <JobVisitSchedulerControls
        schedulerRef={schedulerRef}
        schedulerProject={schedulerProject}
        initialViewDateTimeStart={initialViewDateTimeStart}
        initialViewDateTimeEnd={initialViewDateTimeEnd}
        showUnassignedGrid={false}
        expanded={false}
        currentTime={false}
        onForward={handleOnForward}
        onBackward={handleOnBackward}
        resetView={() => {}}
        toggleFilter={() => {}}
        toggleCurrentTime={() => {}}
        toggleExpanded={() => {}}
      />
      <Row
        className="flex-grow-1"
        style={{
          minHeight: 0,
          borderTop: theme.border,
          borderRadius: '0 0 0.25rem 0.25rem',
          overflow: 'hidden',
        }}
        noGutters
      >
        <Col className="h-100" xs={9}>
          <BryntumSchedulerPro
            ref={schedulerRef}
            {...schedulerConfig}
            onTimeAxisChange={handleOnTimeAxisChange}
            onBeforeTaskEdit={handleOnBeforeTaskEdit}
            onAfterEventDrop={handleOnAfterEventDrop}
            onEventResizeEnd={handleOnEventResizeEnd}
            rowHeight={80}
            eventRenderer={renderEvent}
            project={schedulerProject}
            sortFeature="name"
            groupFeature="category"
            columns={[
              {
                text: 'Name',
                field: 'name',
                width: 240,
                renderer: renderColumn,
              },
            ]}
            stripeFeature
            timeRangesFeature
            resourceTimeRangesFeature
            createEventOnDblClick={false}
          />
        </Col>
        {schedulerRef.current && (
          <Col xs={3} className="h-100" style={{ borderLeft: theme.border }}>
            <ClientJobGroupSchedulerMultiAssignHelper
              schedulerRef={schedulerRef.current.instance}
              loading={loading}
              currentEventRecord={currentEventRecord}
              setCurrentEventRecord={setCurrentEventRecord}
              currentGeneratedJobVisit={currentGeneratedJobVisit}
              generatedJobVisitOnClick={handleGeneratedJobVisitOnClick}
              onCancel={handleMultiAssignmentOnCancel}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default ClientJobGroupScheduler;
