import React, { Reducer, useReducer } from 'react';
import { AnyAction } from 'redux';
import { Col, Row } from 'reactstrap';
import { useLazyQuery, useQuery } from '@apollo/client';
import { MANAGEMENT_TASK_QUERY, MANAGEMENT_TASKS_QUERY } from './query';
import {
  ManagementTaskQueryData,
  ManagementTaskQueryVariables,
  ManagementTasksQueryData,
  ManagementTasksQueryVariables,
  State,
} from './types';
import reducer, {
  initialState,
  ReducerContext,
  managementTasksQueryOnCompleted,
  managementTasksRefetchOnCompleted,
  managementTaskQueryOnCompleted,
} from './reducer';
import { useClient } from 'lib/hooks';
import SimpleButton from '../SimpleButton';
import ClientSettingsManagementSite from '../ClientSettingsManagementSite';

const ClientSettingsManagement = () => {
  const client = useClient();

  const [state, dispatch] = useReducer<Reducer<State, AnyAction>>(
    reducer,
    initialState
  );

  const {
    managementTaskTypes,
    managementTaskType,
    managementTasks,
    managementTask,
  } = state;

  const { refetch } = useQuery<
    ManagementTasksQueryData,
    ManagementTasksQueryVariables
  >(MANAGEMENT_TASKS_QUERY, {
    variables: { clientId: client.id },
    onCompleted: (data) => {
      dispatch(managementTasksQueryOnCompleted(data));
    },
  });

  const handleManagementTaskTypeOnClick = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    const { value } = event.currentTarget;
    const { data } = await refetch({
      managementTaskType: value === 'ALL' ? undefined : value,
    });
    dispatch(managementTasksRefetchOnCompleted(value, data));
  };

  const [getManagementTask] = useLazyQuery<
    ManagementTaskQueryData,
    ManagementTaskQueryVariables
  >(MANAGEMENT_TASK_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      dispatch(managementTaskQueryOnCompleted(data));
    },
  });

  const handleManagementTaskOnClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    const { value } = event.currentTarget;
    getManagementTask({ variables: { managementTaskId: value } });
  };

  const renderManager = () => {
    if (!managementTask) return null;
    switch (managementTaskType) {
      case 'ENTER_SITE_LOCATION':
        return <ClientSettingsManagementSite />;
      default:
        return null;
    }
  };

  return (
    <ReducerContext.Provider value={[state, dispatch]}>
      <Row>
        <Col xs={3}>
          <div>
            <div>
              <SimpleButton
                value="ALL"
                onClick={handleManagementTaskTypeOnClick}
              >
                All management tasks
              </SimpleButton>
            </div>
            {managementTaskTypes.map((managementTaskType) => (
              <div key={managementTaskType}>
                <SimpleButton
                  className="text-capitalize"
                  value={managementTaskType}
                  onClick={handleManagementTaskTypeOnClick}
                >
                  {managementTaskType.replace(/_/g, ' ').toLowerCase()}
                </SimpleButton>
              </div>
            ))}
          </div>
        </Col>
        <Col xs={3}>
          <div>
            {managementTasks.results.map((managementTask) => (
              <div key={managementTask.id}>
                <SimpleButton
                  value={managementTask.id}
                  onClick={handleManagementTaskOnClick}
                >
                  {managementTask.type}
                </SimpleButton>
              </div>
            ))}
          </div>
        </Col>
        <Col xs={6}>
          <div>{renderManager()}</div>
        </Col>
      </Row>
    </ReducerContext.Provider>
  );
};

export default ClientSettingsManagement;
