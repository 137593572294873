import { gql } from '@apollo/client';

export const INITIAL_QUERY = gql`
  query ($clientId: ID!, $searchTerm: String) {
    userGroups(clientId: $clientId, searchTerm: $searchTerm) {
      id
      name
      namePlural
    }
    userGroup: firstUserGroup(clientId: $clientId) {
      id
      name
      namePlural
      users {
        id
        name
      }
    }
  }
`;
