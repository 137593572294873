import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { Input } from './styled';

const IntegerField = ({ input, ...props }: FieldRenderProps<number>) => {
  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    input.onChange(parseInt(value, 10));
  };

  return (
    <Input type="number" {...props} {...input} onChange={handleOnChange} />
  );
};

export default IntegerField;
