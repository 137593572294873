import styled from 'styled-components';
import { InnerProps, PageProps } from './types';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  overflow-x: hidden;
`;

export const Inner = styled.div<InnerProps>`
  //position: absolute;
  top: 0;
  transform: translateX(
    ${({ pageCount, page }) => (-(page - 1) * 100) / pageCount}%
  );
  width: ${({ pageCount }) => 100 * pageCount}%;
  transition: transform 200ms ease-out;
`;

export const Page = styled.div<PageProps>`
  flex: 1 1 0px;
  opacity: ${({ currentPage, page }) => (currentPage === page ? 1 : 0)};
  transition: opacity 100ms ease-out;
`;
