import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useScroll } from 'lib/hooks';
import { HighlightProps } from './types';
import { Highlighter } from './styled';
import Portal from '../Portal';

const Highlight = ({ children, active }: HighlightProps) => {
  const childrenRef = useRef<HTMLDivElement>();
  const [canMount, setCanMount] = useState(false);

  useEffect(() => {
    if (!childrenRef.current) return;
    setCanMount(true);
  }, []);

  useScroll();

  const rect = childrenRef?.current?.getBoundingClientRect();

  return (
    <div className="h-100">
      {active && canMount && (
        <Portal>
          <Highlighter
            preserveAspectRatio="none"
            viewBox={`0 0 ${window.innerWidth} ${window.innerHeight}`}
          >
            <mask id="highlighter-mask">
              <polygon
                points={[
                  [0, 0],
                  [window.innerWidth, 0],
                  [window.innerWidth, rect?.top],
                  [0, rect?.top],
                ].join(', ')}
              />
            </mask>

            <polygon mask="url(#highlighter-mask)" />

            <polygon
              points={[
                [0, 0],
                [window.innerWidth, 0],
                [window.innerWidth, rect?.top],
                [0, rect?.top],
              ].join(', ')}
            />
            <polygon
              points={[
                [0, rect?.top],
                [rect?.left, rect?.top],
                [rect?.left, rect?.bottom],
                [0, rect?.bottom],
              ].join(', ')}
            />
            <polygon
              points={[
                [rect?.right, rect?.top],
                [window.innerWidth, rect?.top],
                [window.innerWidth, rect?.bottom],
                [rect?.right, rect?.bottom],
              ].join(', ')}
            />
            <polygon
              points={[
                [0, rect?.bottom],
                [window.innerWidth, rect?.bottom],
                [window.innerWidth, window.innerHeight],
                [0, window.innerHeight],
              ].join(', ')}
            />
          </Highlighter>
        </Portal>
      )}
      {React.cloneElement(children as ReactElement, {
        ref: (ref: React.RefObject<HTMLDivElement>) => {
          // @ts-ignore
          childrenRef.current = ref;
        },
      })}
    </div>
  );
};

export default Highlight;
