import { useTheme } from 'styled-components';
import { JobReport } from '../types';
import getReportColor from '../utils/getReportColor';

const useReportColor = (status: JobReport['status']) => {
  const theme = useTheme();
  return getReportColor(theme, status);
};

export default useReportColor;
