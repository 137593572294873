import React, { useState } from 'react';
import { faPaperclip } from '@fortawesome/pro-duotone-svg-icons/faPaperclip';
import { createEditor, Descendant, Editor, Transforms } from 'slate';
import { serialize } from 'lib/slate';
import { withHistory } from 'slate-history';
import { ReactEditor, withReact } from 'slate-react';
import {
  inputOnChange,
  useReducerContext,
} from '../CommunicationChats/reducer';
import SimpleButton from '../SimpleButton';
import SlateEditor from '../SlateEditor';
import { CommunicationsChatInputProps } from './types';
import { Wrapper } from './styled';

const defaultValue: Descendant[] = [
  { type: 'paragraph', children: [{ text: '' }] },
];

const CommunicationsChatInput = ({
  onCommit,
}: CommunicationsChatInputProps) => {
  const [{ input }, dispatch] = useReducerContext();

  const handleOnChange = (newValue: Descendant[]) => {
    dispatch(inputOnChange(newValue));
  };

  const [editor] = useState<ReactEditor>(() =>
    withHistory(withReact(createEditor() as ReactEditor))
  );

  const handleOnKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (input.value && serialize(input.value)) onCommit();
      Transforms.delete(editor as Editor, {
        at: {
          anchor: Editor.start(editor as Editor, []),
          focus: Editor.end(editor as Editor, []),
        },
      });
    }
  };

  return (
    <div className="w-100 d-flex">
      <Wrapper className="mr-2 flex-grow-1">
        <SlateEditor
          readOnly={input.readOnly}
          value={input.value || defaultValue}
          editor={editor}
          onChange={handleOnChange}
          onKeyPress={handleOnKeyPress}
        />
      </Wrapper>
      <div>
        <SimpleButton active icon={faPaperclip} />
      </div>
    </div>
  );
};

export default CommunicationsChatInput;
