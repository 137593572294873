import styled from 'styled-components';

export const TemplateEditorHeader = styled.div`
  margin-left: 40px;
  background-color: ${({ theme }) =>
    theme.color.cardBackground.darken(0.25).hex()};
  padding: 0.5rem 1rem;
  border-radius: 0.25rem 0.25rem 0 0;
  border-bottom: ${({ theme }) => theme.border};
  color: ${({ theme }) => theme.color.cardText.alpha(0.75).hex()};
`;

export const TemplateBlockWrapper = styled.div`
  padding: 0.25rem;
  color: ${({ theme }) => theme.color.cardText.hex()};
  background-color: ${({ theme }) => theme.color.cardBackground.hex()};
  border: ${({ theme }) => theme.border};
  border-top: none;
  cursor: cell;

  p {
    margin-bottom: 0;
  }

  div.decoration {
    position: absolute;
    top: 43px;
    left: 22px;
    width: 2px;
    height: calc(100% - 43px);
    background-color: ${({ theme }) =>
      theme.color.cardBackground.darken(0.125).hex()};
    z-index: 1;
  }
`;

export const TemplateBlockControlsWrapper = styled.div`
  position: relative;
  padding: 0.25rem;
  background-color: ${({ theme }) =>
    theme.color.cardBackground.darken(0.25).hex()};
  z-index: 2;
  border-radius: 0 0 0 0.25rem;
`;

export const TemplateBlockLogicWrapper = styled.div`
  padding: 0.25rem;
  background-color: ${({ theme }) =>
    theme.color.cardBackground.darken(0.25).hex()};
  margin-left: 40px;
`;

export const ControlsWrapper = styled.div`
  position: absolute;
  right: 100%;
  border: ${({ theme }) => theme.color.cardBackground.hex()};
`;

export const TemplateEditorFooter = styled.div`
  margin-left: 40px;
  background-color: ${({ theme }) =>
    theme.color.cardBackground.darken(0.25).hex()};
  padding: 0.5rem 1rem;
  border-radius: 0 0 0.25rem 0.25rem;
  border-top: ${({ theme }) => theme.border};
`;
