import styled from 'styled-components';
import { CheckboxButtonProps } from './types';

export const CheckboxWrapper = styled.label`
  position: relative;
  display: flex;
  cursor: pointer;
  height: 20px;
`;

export const InputStyled = styled.input`
  display: none;
`;

export const CheckboxButton = styled.span<CheckboxButtonProps>`
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2px solid
    ${({ theme, checked }) =>
      checked
        ? theme.color.primary.hex()
        : theme.color.contentBackground.hex()};
  text-align: center;
  background-color: ${({ theme, checked }) =>
    checked
      ? theme.color.primary.hex()
      : theme.color.contentBackground.brighten(0.15).hex()};
  user-select: none;

  &:hover {
    background-color: ${({ theme, checked }) =>
      checked ? theme.color.primary.hex() : 'rgba(0, 0, 0, 0.1)'};
  }

  svg {
    position: absolute;
    color: white;
    opacity: ${({ checked }) => (checked ? 1 : 0)};
    width: 14px !important;
    height: 14px;
    top: 2px;
    left: 1px;
  }
`;
