import React from 'react';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/pro-duotone-svg-icons/faUserCircle';
import { Wrapper } from './styled';
import { JobVisitCardProps } from './types';

const JobVisitCard = ({
  children,
  innerRef,
  jobVisit,
  ...rest
}: JobVisitCardProps) => {
  return (
    <Wrapper ref={innerRef} {...rest}>
      <div>
        <small className="mono">
          {DateTime.fromISO(jobVisit.dateTimeStart).toLocaleString(
            DateTime.TIME_24_SIMPLE
          )}
          {' - '}
          {DateTime.fromISO(jobVisit.dateTimeEnd).toLocaleString(
            DateTime.TIME_24_SIMPLE
          )}
        </small>
        <small className="ml-2">
          <FontAwesomeIcon className="mr-1" icon={faUserCircle} fixedWidth />
          {jobVisit.user.name}
        </small>
      </div>
      <div>
        <strong>
          {jobVisit.number ? (
            <>
              <span className="mono">#{jobVisit.number}</span>{' '}
              {jobVisit.label || 'New visit'}
            </>
          ) : (
            jobVisit.label || 'New visit'
          )}
        </strong>
      </div>
      <div>{children}</div>
    </Wrapper>
  );
};

export default JobVisitCard;
