import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import { Form, Field } from 'react-final-form';
import { useClient } from 'lib/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import { useMutation } from '@apollo/client';
import { useLocation } from 'wouter';
import { faMapMarkerAlt } from '@fortawesome/pro-duotone-svg-icons/faMapMarkerAlt';
import CardWrapper from '../CardWrapper';
import CharField from '../CharField';
import JobSiteField from '../JobSiteField';
import PortalBreadcrumb from '../PortalBreadcrumb';
import Button from '../Button';
import ClientSitesMap from '../ClientSitesMap';
import { CREATE_JOB_MUTATION } from './query';
import {
  CreateJobFormValues,
  CreateJobMutationData,
  CreateJobMutationVariables,
} from './types';
import IntegerField from '../IntegerField';
import { Small } from '../Typography';
import SelectField from '../SelectField';

const frequencyIntervalInitialValue = { value: 'month', label: 'month' };

const ClientJobsCreate = () => {
  const client = useClient();

  const [, setLocation] = useLocation();

  const [createJob] = useMutation<
    CreateJobMutationData,
    CreateJobMutationVariables
  >(CREATE_JOB_MUTATION);

  const handleOnSubmit = (values: CreateJobFormValues) => {
    if (!values.site) return;
    createJob({
      variables: {
        clientId: client.id,
        name: values.name,
        siteId: values.site.id,
        visits: values.visits,
      },
    }).then((response) => {
      if (!response.data) return;
      const { job: createdJob } = response.data?.createJob;
      setLocation(`/clients/${client.slug}/jobs/${createdJob.id}`);
    });
  };

  const [viewState, setViewState] = useState({
    longitude: -0.115,
    latitude: 51.5074,
    zoom: 6.5,
    pitch: 45,
    bearing: -10,
  });

  const handleOnViewStateChange = ({ viewState: newViewState }: any) => {
    setViewState(newViewState);
  };

  return (
    <>
      <PortalBreadcrumb>
        <h4>Create PPM Job</h4>
      </PortalBreadcrumb>
      <div>
        <Form<CreateJobFormValues>
          initialValues={{
            frequencyPerInterval: 1,
            frequencyInterval: frequencyIntervalInitialValue,
            period: 12,
          }}
          validate={(values) => {
            const errors: { name?: string; site?: string } = {};
            if (!values.name) errors.name = 'Please enter a name';
            if (!values.site) errors.site = 'Please choose a site';
            if (values.frequencyPerInterval <= 0)
              errors.site = 'Please choose a frequency greater than 0';
            if (values.period <= 0)
              errors.site = 'Please choose a period greater than 0';
            return errors;
          }}
          onSubmit={handleOnSubmit}
        >
          {({ handleSubmit, values, valid }) => (
            <form onSubmit={handleSubmit}>
              <div
                className="mb-5 d-flex flex-column"
                style={{ minHeight: 350 }}
              >
                <Row className="h-100 flex-grow-1">
                  <Col>
                    <CardWrapper className="h-100 d-flex flex-column justify-content-between">
                      <div>
                        <h5>Job information</h5>
                        <div>
                          <div className="mb-3">
                            <Field name="name" component={CharField} />
                          </div>
                          <div className="mb-3">
                            <Field
                              name="site"
                              component={JobSiteField}
                              parse={(value) => {
                                setViewState((prevViewState) => ({
                                  ...prevViewState,
                                  longitude: value.location.point.longitude,
                                  latitude: value.location.point.latitude,
                                }));
                                return value;
                              }}
                            />
                          </div>
                          <div className="mb-3">
                            <div>
                              <Small className="ml-2">Frequency</Small>
                            </div>
                            <div className="d-flex">
                              <Field
                                name="frequencyPerInterval"
                                style={{ width: 128 }}
                                component={IntegerField}
                                min={1}
                              />
                              <span
                                className="mx-2"
                                style={{ lineHeight: '38px' }}
                              >
                                planned maintenance job
                                {values.frequencyPerInterval === 1
                                  ? ''
                                  : 's'}{' '}
                                per
                              </span>
                              <div className="flex-grow-1">
                                <Field
                                  name="frequencyInterval"
                                  noLabel
                                  menuPortalTarget={document.body}
                                  component={SelectField}
                                  options={[
                                    { value: 'month', label: 'month' },
                                    { value: 'week', label: 'week' },
                                  ]}
                                />
                              </div>
                              <span
                                className="mx-2"
                                style={{ lineHeight: '38px' }}
                              >
                                for
                              </span>
                              <Field
                                name="period"
                                style={{ width: 128 }}
                                component={IntegerField}
                                min={1}
                              />
                              <span
                                className="mx-2"
                                style={{ lineHeight: '38px' }}
                              >
                                month
                                {values.period === 1 ? '' : 's'}.
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end">
                        <Button color="green" icon={faPlus} disabled={!valid}>
                          Create PPM job
                        </Button>
                      </div>
                    </CardWrapper>
                  </Col>
                  <Col>
                    <CardWrapper className="h-100">
                      <h5>Job location</h5>
                      {values.site ? (
                        <ClientSitesMap
                          sites={[values.site]}
                          viewState={viewState}
                          onViewStateChange={handleOnViewStateChange}
                          height="100%"
                        />
                      ) : (
                        <div className="d-flex flex-column justify-content-center h-100">
                          <div className="text-50 text-center">
                            <FontAwesomeIcon icon={faMapMarkerAlt} size="3x" />
                            <p className="mt-3">
                              When you select a site the location will be shown
                              on a map.
                            </p>
                          </div>
                        </div>
                      )}
                    </CardWrapper>
                  </Col>
                </Row>
              </div>
            </form>
          )}
        </Form>
      </div>
    </>
  );
};

export default ClientJobsCreate;
