import { useMutation } from '@apollo/client';
import {
  SchedulerEventModel,
  SchedulerPro,
} from '@bryntum/schedulerpro/schedulerpro.umd.js';
import { faVolume } from '@fortawesome/pro-duotone-svg-icons/faVolume';
import { faVolumeMute } from '@fortawesome/pro-duotone-svg-icons/faVolumeMute';
import React, { useMemo } from 'react';
import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons/faCheckCircle';
import { faEye } from '@fortawesome/pro-duotone-svg-icons/faEye';
import { faLock } from '@fortawesome/pro-duotone-svg-icons/faLock';
import { faLockOpen } from '@fortawesome/pro-duotone-svg-icons/faLockOpen';
import { faUserCircle } from '@fortawesome/pro-duotone-svg-icons/faUserCircle';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faTimesCircle } from '@fortawesome/pro-regular-svg-icons/faTimesCircle';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { parseDateTime } from 'lib/utils';
import { Form, Field } from 'react-final-form';
import { useTheme } from 'styled-components';
import { JobReportTemplate } from '../../lib/types';
import {
  commitMultiAssigmentMutationOnCompleted,
  setFuturePlacementCount,
  setMultiAssignHelperMode,
  startMultiAssignmentMutationOnCompleted,
  toggleLockJobVisitPlacement,
  toggleMuteJobVisitPlacement,
  useReducerContext,
} from '../ClientJobGroup/reducer';
import { addAssignedVisits } from '../ClientJobGroupScheduler/utils';
import Loading from '../Loading';
import ReportsField from '../ReportsField';
import ScrollDiv from '../ScrollDiv';
import { Small } from '../Typography';
import SimpleButton from '../SimpleButton';
import SimpleConfirmButton from '../SimpleConfirmButton';
import {
  COMMIT_MULTI_ASSIGNMENT_MUTATION,
  START_MULTI_ASSIGNMENT_MUTATION,
  TOGGLE_LOCK_JOB_VISIT_PLACEMENT_MUTATION,
  TOGGLE_MUTE_JOB_VISIT_PLACEMENT_MUTATION,
} from './query';
import {
  ClientJobGroupSchedulerMultiAssignHelperProps,
  CommitMultiAssignmentMutationData,
  CommitMultiAssignmentMutationVariables,
  StartMultiAssignmentMutationData,
  StartMultiAssignmentMutationVariables,
} from './types';
import { Input } from '../IntegerField/styled';

const CommittedMultiAssignments = ({ currentGeneratedJobVisit }: any) => {
  const theme = useTheme();
  const [{ jobGroup }, dispatch] = useReducerContext();

  const getBorderColor = (selected: boolean) => {
    if (selected) return theme.color.primary.hex();
    return 'transparent';
  };

  const handleGoBackOnClick = () => {
    dispatch(setMultiAssignHelperMode('GENERATED'));
  };

  const batches = jobGroup?.multiAssignPlacementBatches ?? [];

  if (!jobGroup) return null;
  return (
    <div className="h-100">
      <div className="d-flex mb-2 justify-content-between">
        <div style={{ fontSize: '0.9rem' }}>
          <p className="mb-0">Committed</p>
          <small className="text-75">
            Generated visits will be based on this selection
          </small>
        </div>
        <div>
          <SimpleButton icon={faTimes} onClick={handleGoBackOnClick}>
            Close
          </SimpleButton>
        </div>
      </div>
      <ScrollDiv className="flex-grow-1 pr-3" style={{ overflowY: 'scroll' }}>
        {batches.map((batch) => (
          <div key={batch.id}>
            {batch.placements.map((placement, index) => {
              const last = index === batch.placements.length - 1;
              const selected =
                !!currentGeneratedJobVisit &&
                currentGeneratedJobVisit.job.id === placement.job.id;
              return (
                <SimpleButton
                  as="div"
                  active={selected}
                  key={placement.job.id}
                  backgroundColor={theme.color.contentBackground
                    .alpha(0.5)
                    .hex()}
                  className={classNames(
                    'position-relative d-block w-100 h-auto text-left',
                    {
                      'mb-2': !last,
                    }
                  )}
                  style={{
                    border: '1px solid transparent',
                    borderColor: getBorderColor(selected),
                    fontSize: '0.8rem',
                  }}
                >
                  <div style={{ opacity: placement.isMuted ? 0.25 : 1 }}>
                    <div className="mb-2">
                      <small>
                        <span className="mono mr-2 text-75">
                          {placement.job.reference}
                        </span>
                        <span className="text-75">
                          <FontAwesomeIcon
                            className="mr-2"
                            icon={faCheckCircle}
                            color={theme.color.primary.hex()}
                          />
                          Committed
                        </span>
                      </small>
                    </div>
                    <div className="d-flex mb-2">
                      <div className="mr-2 text-75">
                        <p className="mb-0">Start:</p>
                        <p className="mb-0">End:</p>
                      </div>
                      <div>
                        <p className="mono mb-0">
                          {parseDateTime(
                            placement.dateTimeStart,
                            'HH:mm EEEE dd MMM yyyy'
                          )}
                        </p>
                        <p className="mono mb-0">
                          {parseDateTime(
                            placement.dateTimeEnd,
                            'HH:mm EEEE dd MMM yyyy'
                          )}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div key={placement.user.id} className="mr-2 text-nowrap">
                        <FontAwesomeIcon
                          className="mr-1"
                          icon={faUserCircle}
                          fixedWidth
                        />
                        <span>{placement.user.name}</span>
                      </div>
                    </div>
                  </div>
                </SimpleButton>
              );
            })}
          </div>
        ))}
      </ScrollDiv>
    </div>
  );
};

const GeneratedMultiAssignments = ({
  loading,
  schedulerRef,
  currentEventRecord,
  setCurrentEventRecord,
  currentGeneratedJobVisit,
  generatedJobVisitOnClick,
  onCancel,
}: {
  loading: boolean;
  schedulerRef: SchedulerPro;
  currentEventRecord: SchedulerEventModel | null;
  setCurrentEventRecord: any;
  currentGeneratedJobVisit: any;
  generatedJobVisitOnClick: any;
  onCancel: any;
}) => {
  const theme = useTheme();
  const [{ jobGroup, selectedJob, futurePlacementCount }, dispatch] =
    useReducerContext();

  const [startMultiAssignment] = useMutation<
    StartMultiAssignmentMutationData,
    StartMultiAssignmentMutationVariables
  >(START_MULTI_ASSIGNMENT_MUTATION);

  const handleBeginOnClick = async () => {
    if (!jobGroup || !selectedJob) return;
    const { data } = await startMultiAssignment({
      variables: { jobGroupId: jobGroup.id, startJobId: selectedJob.id },
    });
    if (data) {
      dispatch(startMultiAssignmentMutationOnCompleted(data));
    }
  };

  const placements = useMemo(
    () => jobGroup?.activeMultiAssignPlacementBatch?.placements ?? [],
    [jobGroup?.activeMultiAssignPlacementBatch?.placements]
  );

  const [toggleLock] = useMutation(TOGGLE_LOCK_JOB_VISIT_PLACEMENT_MUTATION);

  const handleOnLock = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { value: placementId } = event.currentTarget;
    dispatch(toggleLockJobVisitPlacement(placementId));
    toggleLock({ variables: { jobVisitPlacementId: placementId } });
  };

  const [toggleMute] = useMutation(TOGGLE_MUTE_JOB_VISIT_PLACEMENT_MUTATION);

  const handleOnMute = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { value: placementId } = event.currentTarget;
    dispatch(toggleMuteJobVisitPlacement(placementId));
    toggleMute({ variables: { jobVisitPlacementId: placementId } });
  };

  const [commitMultiAssignment] = useMutation<
    CommitMultiAssignmentMutationData,
    CommitMultiAssignmentMutationVariables
  >(COMMIT_MULTI_ASSIGNMENT_MUTATION);

  const handleOnSubmit = async (values: any) => {
    if (!jobGroup || !selectedJob) return;
    const { data } = await commitMultiAssignment({
      variables: {
        jobGroupId: jobGroup.id,
        reportTemplateIds: values.createNewReports.templates.map(
          (template: JobReportTemplate) => template.id
        ),
      },
    });
    if (data) {
      dispatch(commitMultiAssigmentMutationOnCompleted(data));
      setCurrentEventRecord(null);
      schedulerRef.eventStore.remove(
        data.commitMultiAssignment.multiAssignmentBatch.placements.map(
          (placement) => placement.id
        )
      );
      addAssignedVisits(
        data.commitMultiAssignment.jobVisits,
        jobGroup,
        selectedJob,
        schedulerRef.project as any
      );
    }
  };

  const handleFuturePlacementCountOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value: valueString } = event.currentTarget;
    dispatch(setFuturePlacementCount(parseInt(valueString, 10)));
  };

  const getBorderColor = (selected: boolean, locked: boolean) => {
    if (selected) return theme.color.primary.hex();
    if (locked) return theme.color.warning.hex();
    return 'transparent';
  };

  const originalPlacement = placements.find(
    (placement) => placement.isOriginal && !placement.isCommitted
  );

  if (!jobGroup || !selectedJob) return null;

  if (!currentEventRecord) {
    if (!jobGroup.activeMultiAssignPlacementBatch) {
      return (
        <div>
          <div className="mb-3">Press the button below to get started</div>
          <div className="d-flex mb-3">
            <div className="mr-4">
              <Small>Selected job</Small>
              <div className="mono" style={{ lineHeight: '38px' }}>
                {selectedJob.reference}
              </div>
            </div>
            <div>
              <Small>Generate</Small>
              <div>
                <Input
                  style={{ width: 80 }}
                  type="number"
                  value={futurePlacementCount}
                  onChange={handleFuturePlacementCountOnChange}
                />
              </div>
            </div>
          </div>
          <SimpleButton active onClick={handleBeginOnClick}>
            Begin multi assignment
          </SimpleButton>
        </div>
      );
    }

    return (
      <div>
        <div className="mb-3">
          Now create a job visit by dragging on the scheduler
        </div>
        <SimpleButton active icon={faTimes} onClick={onCancel}>
          Cancel multi assignment
        </SimpleButton>
      </div>
    );
  }

  return (
    <>
      <div className="mb-2">
        <div
          className="d-flex justify-content-between mb-2"
          style={{ fontSize: '0.9rem' }}
        >
          <div>
            <p className="mb-0">Original selection</p>
            <small className="text-75">
              Generated visits will be based on this selection
            </small>
          </div>
          <div>
            <SimpleConfirmButton icon={faTimes} onClick={onCancel}>
              Delete
            </SimpleConfirmButton>
          </div>
        </div>
        {originalPlacement && (
          <SimpleButton
            backgroundColor={theme.color.contentBackground.alpha(0.5).hex()}
            className="d-block w-100 h-auto text-left mb-2"
            style={{
              border: '1px solid transparent',
              fontSize: '0.8rem',
            }}
            onClick={() => generatedJobVisitOnClick(originalPlacement)}
          >
            <div className="mb-2">
              <small>
                <span className="mono mr-2 text-75">
                  {originalPlacement.job.reference}
                </span>
              </small>
            </div>
            <div className="d-flex mb-2">
              <div className="mr-2 text-75">
                <p className="mb-0">Start:</p>
                <p className="mb-0">End:</p>
              </div>
              <div>
                <p className="mono mb-0">
                  {parseDateTime(
                    originalPlacement.dateTimeStart,
                    'HH:mm EEEE dd MMM yyyy'
                  )}
                </p>
                <p className="mono mb-0">
                  {parseDateTime(
                    originalPlacement.dateTimeEnd,
                    'HH:mm EEEE dd MMM yyyy'
                  )}
                </p>
              </div>
            </div>
            <div>
              <div key={originalPlacement.user.id} className="mr-2 text-nowrap">
                <FontAwesomeIcon
                  className="mr-1"
                  icon={faUserCircle}
                  fixedWidth
                />
                <span>{originalPlacement.user.name}</span>
              </div>
            </div>
          </SimpleButton>
        )}
      </div>
      <div className="d-flex justify-content-between mb-2">
        <div className="text-nowrap" style={{ fontSize: '0.9rem' }}>
          <p className="mb-0">Generat{loading ? 'ing' : 'ed'} job visits</p>
          {loading ? (
            <small>
              <Loading /> gathering results...
            </small>
          ) : (
            <small className="text-75">
              {placements.length - 1} visit
              {placements.length - 1 === 1 ? '' : 's'} have been generated
            </small>
          )}
        </div>
      </div>
      <ScrollDiv
        className="flex-grow-1 pr-3"
        style={{ overflowY: 'scroll', minHeight: 0 }}
      >
        <div className="mb-3">
          {placements
            .filter(
              (placement) => !placement.isOriginal && !placement.isCommitted
            )
            .map((placement, index) => {
              const last = index === placements.length - 1;
              const selected =
                !!currentGeneratedJobVisit &&
                currentGeneratedJobVisit.job.id === placement.job.id;
              return (
                <SimpleButton
                  as="div"
                  active={selected}
                  key={placement.job.id}
                  backgroundColor={theme.color.contentBackground
                    .alpha(0.5)
                    .hex()}
                  className={classNames(
                    'position-relative d-block w-100 h-auto text-left',
                    {
                      'mb-2': !last,
                    }
                  )}
                  style={{
                    border: '1px solid transparent',
                    borderColor: getBorderColor(selected, placement.isLocked),
                    fontSize: '0.8rem',
                  }}
                >
                  <div
                    style={{
                      position: 'absolute',
                      right: 0,
                      top: 0,
                      zIndex: 100,
                    }}
                  >
                    <SimpleButton
                      icon={faEye}
                      onClick={() => generatedJobVisitOnClick(placement)}
                    >
                      View
                    </SimpleButton>
                    <SimpleButton
                      icon={placement.isLocked ? faLock : faLockOpen}
                      disabled={placement.isMuted}
                      value={placement.id}
                      onClick={handleOnLock}
                    >
                      {placement.isLocked ? 'Locked' : 'Unlocked'}
                    </SimpleButton>
                    <SimpleButton
                      icon={placement.isMuted ? faVolumeMute : faVolume}
                      value={placement.id}
                      onClick={handleOnMute}
                    >
                      {placement.isMuted ? 'Unmute' : 'Mute'}
                    </SimpleButton>
                  </div>
                  <div style={{ opacity: placement.isMuted ? 0.25 : 1 }}>
                    <div className="mb-2">
                      <small>
                        <span className="mono mr-2 text-75">
                          {placement.job.reference}
                        </span>
                        {placement.isFeasible ? (
                          <span className="text-75">
                            <FontAwesomeIcon
                              className="mr-2"
                              icon={faCheckCircle}
                              color={theme.color.primary.hex()}
                            />
                            Feasible
                          </span>
                        ) : (
                          <span className="text-75">
                            <FontAwesomeIcon
                              className="mr-2"
                              icon={faTimesCircle}
                              color={theme.color.warning.hex()}
                            />
                            Has issues
                          </span>
                        )}
                      </small>
                    </div>
                    <div className="d-flex mb-2">
                      <div className="mr-2 text-75">
                        <p className="mb-0">Start:</p>
                        <p className="mb-0">End:</p>
                      </div>
                      <div>
                        <p className="mono mb-0">
                          {parseDateTime(
                            placement.dateTimeStart,
                            'HH:mm EEEE dd MMM yyyy'
                          )}
                        </p>
                        <p className="mono mb-0">
                          {parseDateTime(
                            placement.dateTimeEnd,
                            'HH:mm EEEE dd MMM yyyy'
                          )}
                        </p>
                      </div>
                    </div>
                    <div>
                      <div key={placement.user.id} className="mr-2 text-nowrap">
                        <FontAwesomeIcon
                          className="mr-1"
                          icon={faUserCircle}
                          fixedWidth
                        />
                        <span>{placement.user.name}</span>
                      </div>
                    </div>
                  </div>
                </SimpleButton>
              );
            })}
        </div>
        {placements.length > 0 && (
          <div>
            <Form
              initialValues={{
                createNewReports: {
                  templates: [],
                  reports: [],
                },
              }}
              onSubmit={handleOnSubmit}
            >
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  {currentEventRecord.resource && (
                    <Field
                      name="createNewReports"
                      component={ReportsField}
                      jobId={selectedJob.id}
                      userGroupId={
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        currentEventRecord.resource.userGroup.id
                      }
                    />
                  )}
                  <div className="d-flex justify-content-end">
                    <SimpleButton icon={faCheck}>Commit</SimpleButton>
                  </div>
                </form>
              )}
            </Form>
          </div>
        )}
      </ScrollDiv>
    </>
  );
};

const ClientJobGroupSchedulerMultiAssignHelper = ({
  schedulerRef,
  loading,
  currentEventRecord,
  setCurrentEventRecord,
  currentGeneratedJobVisit,
  generatedJobVisitOnClick,
  onCancel,
}: ClientJobGroupSchedulerMultiAssignHelperProps) => {
  const [{ jobGroup, multiAssignHelper }] = useReducerContext();

  if (!jobGroup) return null;
  return (
    <div className="p-3 h-100 d-flex flex-column">
      {multiAssignHelper.mode === 'COMMITTED' && (
        <div className="mb-2">
          <CommittedMultiAssignments
            currentEventRecord={currentEventRecord}
            currentGeneratedJobVisit={currentGeneratedJobVisit}
          />
        </div>
      )}
      {multiAssignHelper.mode === 'GENERATED' && (
        <GeneratedMultiAssignments
          loading={loading}
          schedulerRef={schedulerRef}
          currentEventRecord={currentEventRecord}
          setCurrentEventRecord={setCurrentEventRecord}
          currentGeneratedJobVisit={currentGeneratedJobVisit}
          generatedJobVisitOnClick={generatedJobVisitOnClick}
          onCancel={onCancel}
        />
      )}
    </div>
  );
};

export default ClientJobGroupSchedulerMultiAssignHelper;
