import React from 'react';
import { useTableRowOnClick } from 'lib/hooks';
import Table from '../Table';
import { clearUser, useReducerContext } from '../People/reducer';
import PeopleTableFilter from '../PeopleTableFilter';

const PeopleTable = () => {
  const [{ users }, dispatch] = useReducerContext();

  const handleTableRowOnClick = useTableRowOnClick(
    (id) => `/people/${id}`,
    () => {
      dispatch(clearUser());
    }
  );

  return (
    <div style={{ height: 'calc(100% - 70px)' }}>
      <PeopleTableFilter />
      <Table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Configured</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id} data-id={user.id} onClick={handleTableRowOnClick}>
              <td>{user.name}</td>
              <td>{user.email}</td>
              <td>{user.isConfigured ? 'Yes' : 'No'}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default PeopleTable;
