import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'lib/redux/types';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { useDelay } from 'lib/hooks';
import { Wrapper } from './styled';
import SimpleButton from '../SimpleButton';

const ToastNotification = ({ notification, top }: any) => {
  const dispatch = useDispatch();

  const [mounted, setMounted] = useState(false);
  const mountedDelayed = useDelay(mounted, { delay: 10 });
  const visibleDelayed = useDelay(notification.visible, { delay: 1000 });
  useEffect(() => {
    if (!mounted) setMounted(true);
  }, [mounted]);

  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();

  useEffect(() => {
    setTimeoutId(
      setTimeout(() => {
        if (!notification.visible) return;
        dispatch({ type: 'REMOVE_NOTIFICATION', payload: notification.id });
      }, 5000)
    );
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [dispatch, notification.visible]);

  const handleRemoveOnClick = () => {
    if (timeoutId) clearTimeout(timeoutId);
    dispatch({ type: 'REMOVE_NOTIFICATION', payload: notification.id });
  };

  if (!notification.visible && !visibleDelayed) return null;
  return (
    <div
      style={{ top }}
      className={classNames('d-flex toast-inner', {
        mounted: mountedDelayed,
        visible: notification.visible,
      })}
    >
      <FontAwesomeIcon
        className="m-3 mt-4"
        size="2x"
        icon={faExclamationTriangle}
      />
      <div className="flex-grow-1 py-3">
        <p className="mb-0">{notification.message}</p>
      </div>
      <SimpleButton
        className="mr-2"
        style={{ marginTop: 12 }}
        icon={faTimes}
        hoverStyles={false}
        onClick={handleRemoveOnClick}
      />
    </div>
  );
};

const Toast = () => {
  const { notifications } = useSelector((state: State) => state.toast);

  return (
    <Wrapper>
      {notifications.map((notification) => {
        const top =
          notifications
            .filter((innerNotification) => innerNotification.visible)
            .indexOf(notification) * 116;
        return (
          <ToastNotification
            // eslint-disable-next-line react/no-array-index-key
            key={notification.id}
            notification={notification}
            top={top}
          />
        );
      })}
    </Wrapper>
  );
};

export default Toast;
