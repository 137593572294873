import { IconProp } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons/faSpinnerThird';
import { ButtonStyled } from './styled';
import { ButtonProps, IconProps } from './types';

const Icon = ({ icon, className, loading, ...rest }: IconProps) =>
  loading ? (
    <FontAwesomeIcon
      className={classNames(className, 'icon')}
      icon={faSpinnerThird as IconProp}
      spin
      fixedWidth
      {...rest}
    />
  ) : (
    <FontAwesomeIcon
      className={classNames(className, 'icon')}
      icon={icon}
      fixedWidth
      {...rest}
    />
  );

const Button = ({
  children,
  active,
  icon,
  iconSide = 'left',
  iconProps,
  flagType,
  loading,
  disabled,
  ...rest
}: ButtonProps) => {
  return (
    <ButtonStyled
      active={active}
      flagType={flagType}
      disabled={loading || disabled}
      {...rest}
    >
      <span className="inner">
        {icon && iconSide === 'left' && (
          <Icon
            className={iconProps?.className || 'mr-1'}
            icon={icon}
            loading={loading}
            {...iconProps}
          />
        )}
        {children && (
          <span
            className={classNames({
              'pr-2': !!icon && iconSide === 'left',
              'pl-2': !!icon && iconSide === 'right',
            })}
          >
            {children}
          </span>
        )}
        {icon && iconSide === 'right' && (
          <Icon
            className={iconProps?.className || 'ml-1 mr-0'}
            icon={icon}
            loading={loading}
            {...iconProps}
          />
        )}
      </span>
    </ButtonStyled>
  );
};

export default Button;
