import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/pro-duotone-svg-icons/faChevronUp';
import { TableHeadButtonStyled } from './styled';
import { TableHeadButtonProps } from './types';

const TableHeadButton = ({
  children,
  active,
  order,
  onClick,
}: React.PropsWithChildren<TableHeadButtonProps>) => (
  <TableHeadButtonStyled active={active} onClick={onClick}>
    {children}
    <FontAwesomeIcon
      className="icon ml-1"
      icon={order ? faChevronUp : faChevronDown}
      fixedWidth
    />
  </TableHeadButtonStyled>
);

export default TableHeadButton;
