import React from 'react';
import inflection from 'inflection';
import { FieldRenderProps } from 'react-final-form';
import { Small } from '../Typography';
import { Input, Label } from './styled';

const CharField = ({
  input,
  meta,
  placeholder,
  label,
  noLabel,
  innerRef,
  optional,
  errorOnSubmit = false,
  ...rest
}: FieldRenderProps<string>) => {
  const hasError =
    (errorOnSubmit ? !!meta.submitFailed : !!meta.touched) && !!meta.error;
  return (
    <div>
      {!noLabel && (
        <Label
          htmlFor={input.name}
          className="d-inline-flex w-100 justify-content-between"
        >
          <Small>
            {label || input.name}
            {optional && ' (optional)'}
          </Small>
          {hasError && (
            <Small className="text-danger" capitalize={false}>
              {meta.error}
            </Small>
          )}
        </Label>
      )}
      <Input
        ref={innerRef}
        id={input.name}
        placeholder={placeholder || inflection.capitalize(label || input.name)}
        hasError={hasError}
        {...input}
        {...rest}
      />
    </div>
  );
};

export default CharField;
