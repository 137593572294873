import React, { Dispatch, SetStateAction, useState } from 'react';
import { ApolloQueryResult } from '@apollo/client/core/types';
import { OperationVariables } from '@apollo/client';

export type SearchRefetchHookReturn = [
  {
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onClear: () => void;
    setSearchTerm: Dispatch<SetStateAction<string>>;
  },
  { loading: boolean }
];

const useSearchRefetch = <TData = any, TVariables = OperationVariables>(
  refetch: (variables?: Partial<any>) => Promise<ApolloQueryResult<TData>>,
  {
    variables,
    onCompleted,
  }: {
    variables?: Partial<Omit<TVariables, 'searchTerm'>>;
    onCompleted: (response: ApolloQueryResult<TData>) => void;
  }
): SearchRefetchHookReturn => {
  const [searchTerm, setSearchTerm] = useState('');
  const [saveTimeout, setSaveTimeout] = useState<NodeJS.Timeout>();
  const [refetching, setRefetching] = useState(false);

  const handleSearchTermOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value: newSearchTerm } = event.target;
    setSearchTerm(newSearchTerm);
    if (saveTimeout) {
      clearTimeout(saveTimeout);
    }
    setSaveTimeout(
      setTimeout(() => {
        setRefetching(true);
        refetch({ ...variables, searchTerm: newSearchTerm }).then(
          (response) => {
            setRefetching(false);
            return onCompleted(response);
          }
        );
      }, 250)
    );
  };

  const handleSearchTermOnClear = () => {
    setSearchTerm('');
    refetch({ ...variables, searchTerm: '' }).then((response) => {
      setRefetching(false);
      return onCompleted(response);
    });
  };

  return [
    {
      value: searchTerm,
      onChange: handleSearchTermOnChange,
      onClear: handleSearchTermOnClear,
      setSearchTerm,
    },
    { loading: refetching },
  ];
};

export default useSearchRefetch;
