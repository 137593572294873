import styled from 'styled-components';

export const Wrapper = styled.div`
  display: inline-block;
  width: 3rem;
  background-color: ${({ theme }) => theme.color.contentBackground.hex()};
  text-align: center;
  margin-right: 1rem;
  border-radius: 0.5rem;
  padding: 2px 0;
`;
