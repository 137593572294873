import React, { Component } from 'react';
import {
  DragHelper,
  DateHelper,
  DomHelper,
  DragHelperConfig,
  Rectangle,
  SchedulerEventModel,
  Grid,
  EventStore,
  // eslint-disable-next-line import/extensions
} from '@bryntum/schedulerpro/schedulerpro.umd.js';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line import/extensions
import { GridConfig } from '@bryntum/grid/grid.umd.js';
import { DateTime } from 'luxon';
import { renderToStaticMarkup } from 'react-dom/server';
import { UnassignedGridComponentProps } from './types';

export class Drag extends DragHelper {
  static get defaultConfig() {
    return {
      cloneTarget: true,
      mode: 'translateXY',
      dropTargetSelector: '.b-timeline-subgrid',
      targetSelector: '.b-grid-row',
    };
  }

  construct(config: DragHelperConfig) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    super.construct(config);
    this.on({
      dragstart: this.onTaskDragStart,
      drag: this.onTaskDrag,
      drop: this.onTaskDrop,
      thisObj: this,
    });
  }

  onTaskDragStart({ context }: any) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { grid, schedule } = this;
    if (!grid || !schedule) return;
    const mouseX = context.clientX;
    const proxy = context.element;
    const task = grid.getRecordFromElement(
      context.grabbed
    ) as SchedulerEventModel;
    const newWidth = schedule.timeAxisViewModel.getDistanceForDuration(
      task.durationMS
    );
    context.task = task;
    proxy.classList.remove('b-grid-row');
    proxy.classList.add('b-sch-event');
    proxy.classList.add('b-unassigned-class');
    proxy.classList.add(`b-${schedule.mode}`);
    proxy.innerHTML = renderToStaticMarkup(
      <div className="p-2">
        <div style={{ height: 16 }}>
          <small>Unassigned</small>
        </div>
        <div>
          <span style={{ fontSize: 14 }}>
            {task.name === 'New event' ? 'New Visit' : task.name}
          </span>
        </div>
      </div>
    );
    if (context.grabbed.offsetWidth > newWidth) {
      const proxyRect = Rectangle.from(context.grabbed);
      if (mouseX > proxyRect.x + newWidth - 20) {
        context.newX = mouseX - newWidth / 2;
        DomHelper.setTranslateX(proxy, context.newX);
      }
      proxy.style.width = `${newWidth}px`;
      schedule.element.classList.add('b-dragging-event');
    }
  }

  onTaskDrag({ context }: any) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { schedule } = this;
    if (!schedule) return;
    const { task } = context;
    const coordinate = DomHelper[
      `getTranslate${schedule.isHorizontal ? 'X' : 'Y'}` as
        | 'getTranslateX'
        | 'getTranslateY'
    ](context.element);
    const startDate = schedule.getDateFromCoordinate(
      coordinate,
      'round',
      false
    );
    const endDate =
      startDate && DateHelper.add(startDate, task.duration, task.durationUnit);
    const resource =
      context.target && schedule.resolveResourceRecord(context.target);

    // eslint-disable-next-line no-bitwise
    context.valid &=
      startDate &&
      resource &&
      (schedule.allowOverlap ||
        schedule.isDateRangeAvailable(startDate, endDate, null, resource));
    context.resource = resource;
  }

  onTaskDrop({ context }: any) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { grid, schedule } = this;
    if (!grid || !schedule) return;
    const { task, target } = context;
    if (context.valid && target) {
      const date = schedule.getDateFromCoordinate(
        DomHelper.getTranslateX(context.element),
        'round',
        false
      );
      if (date) {
        (grid.store as any).remove(task);
        task.setStartDate(date, true);
        task.setEndDate(
          DateTime.fromJSDate(date).plus({ hour: 1 }).toJSDate(),
          true
        );
        task.resource = context.resource;
        schedule.eventStore.add(task);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.updateEvent(task);
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.context.finalize();
    } else {
      this.abort();
    }
    schedule.element.classList.remove('b-dragging-event');
  }
}

export class UnassignedGrid extends Grid {
  eventStore: EventStore | undefined;

  static get $name() {
    return 'UnassignedGrid';
  }

  static get defaultConfig() {
    return {
      columns: [
        {
          text: 'Unassigned tasks',
          flex: 1,
          field: 'name',
          htmlEncode: false,
        },
      ],
    };
  }

  construct(
    config: GridConfig & { eventStore: EventStore; store: EventStore }
  ) {
    super.construct(config);
    // (this.eventStore as EventStore).on({
    //   update: ({ record }: any) => {
    //     if (!record.resourceId) {
    //       this.eventStore?.remove(record);
    //       (this.store as Store)?.add(record);
    //     }
    //   },
    //   thisObj: this,
    // });
  }
}

export class UnassignedGridComponent extends Component<UnassignedGridComponentProps> {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  unassignedGrid: UnassignedGrid;

  componentDidMount() {
    const { eventStore, store } = this.props;
    this.unassignedGrid = new UnassignedGrid({
      appendTo: 'unassigned-container',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      eventStore,
      store,
    });
  }

  render() {
    return <div id="unassigned-container" className="h-100" />;
  }
}
