import styled from 'styled-components';
import { BaseClientJobIssueBlockWrapper } from '../ClientJobIssue/styled';

export const ClientJobIssueBlockWrapper = styled(
  BaseClientJobIssueBlockWrapper
)`
  position: relative;
  z-index: 1;

  div.user-decoration {
    position: absolute;
    right: 100%;
    width: 60px;

    div.user-decoration-icon {
      position: absolute;
      left: 0;
      background-color: ${({ theme }) => theme.color.cardBackground.hex()};
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }

    div.user-decoration-arrow {
      position: absolute;
      right: 0;
      top: 16px;
      width: 12px;
      height: 12px;
      background-color: ${({ theme }) => theme.color.cardBackground.hex()};
      transform: translateX(50%) rotate(45deg);
      border-bottom-left-radius: 0.15rem;
      z-index: 0;
    }
  }

  div.block-header {
    position: relative;
    height: 40px;
    border-bottom: ${({ theme }) => theme.border};
    z-index: 1;
  }
`;
