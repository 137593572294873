import { gql } from '@apollo/client';

export const INITIAL_QUERY = gql`
  query (
    $clientId: ID!
    $priorityId: ID
    $includePriorities: Boolean!
    $sort: Sort
    $page: Int
    $searchTerm: String
  ) {
    lateCompletionJobs(
      clientId: $clientId
      jobPriorityId: $priorityId
      sort: $sort
      page: $page
      searchTerm: $searchTerm
    ) {
      results {
        id
        reference
        name
        dateTimeStart
        dateTimeEnd
        targetDateTimeEnd
        actualDateTimeEnd
        dateTimeEndDiff
        priority {
          id
          code
          color
          responseName
          completionName
        }
      }
      count
    }
    jobPriorities(clientId: $clientId) @include(if: $includePriorities) {
      id
      code
      color
      responseName
      completionName
    }
  }
`;

export const JOBS_QUERY = gql`
  query ($clientId: ID!, $priorityId: ID, $sort: Sort, $searchTerm: String) {
    lateCompletionJobs(
      clientId: $clientId
      jobPriorityId: $priorityId
      sort: $sort
      searchTerm: $searchTerm
    ) {
      results {
        id
        reference
        name
        dateTimeStart
        dateTimeEnd
        priority {
          id
          code
          responseName
          responseDuration
        }
      }
      count
    }
  }
`;
