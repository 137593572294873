import { gql } from '@apollo/client';

export const USERS_QUERY = gql`
  query ($searchTerm: String) {
    users(searchTerm: $searchTerm) {
      id
      name
    }
  }
`;

export const ADD_USER_MUTATION = gql`
  mutation ($userGroupId: ID!, $userId: ID!) {
    addUserToUserGroup(userGroupId: $userGroupId, userId: $userId) {
      user {
        id
        name
      }
    }
  }
`;
