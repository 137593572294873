import React, { useState } from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import { User } from 'lib/types';
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import { faUserCheck } from '@fortawesome/pro-light-svg-icons/faUserCheck';
import classNames from 'classnames';
import { ApolloQueryResult } from '@apollo/client/core/types';
import { useTable } from 'lib/hooks';
import SidePanel from '../SidePanel';
import { SidePanelComponentProps } from '../SidePanel/types';
import {
  addUserMutationOnCompleted,
  useReducerContext,
} from '../ClientPeople/reducer';
import { ADD_USER_MUTATION, USERS_QUERY } from './query';
import Table from '../Table';
import SearchBar from '../SearchBar';
import SimpleButton from '../SimpleButton';
import { AddUserMutationData, AddUserMutationVariables } from './types';

const ClientPeopleGroupUsersSidePanel = ({
  isOpen,
  toggle,
}: SidePanelComponentProps<any>) => {
  const [{ userGroup }, dispatch] = useReducerContext();
  const [users, setUsers] = useState<User[]>([]);

  const [getUsers, { refetch }] = useLazyQuery(USERS_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setUsers(data.users);
    },
  });

  const { searchInputProps } = useTable(
    ['name'],
    refetch as (variables?: Partial<any>) => Promise<ApolloQueryResult<any>>,
    {
      defaultActiveName: 'name',
      sortOnCompleted: () => {},
      searchOnCompleted: (response) => {
        if (!response.data) return;
        setUsers(response.data.users);
      },
      changePageCompleted: () => {},
    }
  );

  const handleOnOpening = () => {
    getUsers();
  };

  const [addUser, { loading: adding }] = useMutation<
    AddUserMutationData,
    AddUserMutationVariables
  >(ADD_USER_MUTATION);

  const handleOnAdd = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!userGroup) return;
    const { value: userId } = event.currentTarget;
    addUser({ variables: { userGroupId: userGroup.id, userId } }).then(
      (response) => {
        if (!response.data) return;
        dispatch(addUserMutationOnCompleted(response.data));
      }
    );
  };

  if (!userGroup) return null;
  return (
    <SidePanel
      title={`Add users to ${userGroup.namePlural.toLowerCase()}`}
      isOpen={isOpen}
      className="overflow-hidden"
      style={{ height: 'calc(100% - 2rem)' }}
      wrapperClassName="flex-grow-1"
      toggle={toggle}
      onOpening={handleOnOpening}
    >
      <div className="h-100 d-flex flex-column">
        <div className="m-3">
          <SearchBar
            {...searchInputProps}
            wrapperClassName="mr-0"
            placeholder="Search for users to add..."
          />
        </div>
        <Table className="flex-grow-1" style={{ minHeight: 0 }}>
          <thead>
            <tr>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => {
              const userInGroup = userGroup.users
                .map((innerUser) => innerUser.id)
                .includes(user.id);
              return (
                <tr key={user.id}>
                  <td className={classNames({ 'text-75': userInGroup })}>
                    {user.name}
                  </td>
                  <td
                    className={classNames(
                      'table-cell-with-button d-flex justify-content-end pr-3',
                      { 'show-on-hover': !userInGroup }
                    )}
                  >
                    {userInGroup ? (
                      <SimpleButton icon={faUserCheck} value={user.id}>
                        Added
                      </SimpleButton>
                    ) : (
                      <SimpleButton
                        icon={faPlus}
                        loading={adding}
                        value={user.id}
                        onClick={handleOnAdd}
                      >
                        Add
                      </SimpleButton>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </SidePanel>
  );
};

export default ClientPeopleGroupUsersSidePanel;
