import { gql } from '@apollo/client';

export const JOB_ISSUE_QUERY = gql`
  query ($jobId: ID!, $jobIssueNumber: Int!) {
    jobIssue(jobId: $jobId, jobIssueNumber: $jobIssueNumber) {
      id
      number
      status
      issueType
      issueTypeDisplay
      controls
      openedBy {
        id
        type
        context
        meta
        data
        dateTime
        user {
          id
          initials
          name
        }
      }
      closedBy {
        id
        type
        context
        meta
        data
        dateTime
        user {
          id
          initials
          name
        }
      }
      transferredTo {
        id
        number
        issueType
        issueTypeDisplay
        job {
          id
          reference
        }
      }
      worstLogBlock {
        id
        type
        context
        meta
        data
        dateTime
        user {
          id
          initials
          name
        }
      }
      blocks {
        ... on JobIssueBlockLogItem {
          type
          item {
            id
            type
            opensIssue
            closesIssue
            context
            meta
            data
            dateTime
            links {
              id
              reason
            }
            logItemUser: user {
              id
              initials
              name
            }
          }
        }
        ... on JobIssueBlockEOTRequest {
          type
          item {
            id
            dateTimeCreated
            dateTimeOld
            dateTimeNew
            dateTimeResponded
            requestType
            status
            message
            eotRequestUser: user {
              id
              initials
              name
            }
          }
        }
        ... on JobIssueBlockMessage {
          type
          item {
            id
            dateTimeCreated
            body
            messageUser: user {
              id
              initials
              name
            }
          }
        }
      }
    }
  }
`;
