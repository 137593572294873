import { gql } from '@apollo/client';

export const JOB_VISIT_WORK_TYPE_QUERY = gql`
  query ($clientId: ID!) {
    jobVisitWorkTypes(clientId: $clientId) {
      id
      name
      nameDisplay
    }
  }
`;
