import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag as faFlagDuotone } from '@fortawesome/pro-duotone-svg-icons/faFlag';
import { faFlag as faFlagRegular } from '@fortawesome/pro-regular-svg-icons/faFlag';
import { DefaultTheme, useTheme } from 'styled-components';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { JobStatusFlag as JobStatusFlagType } from 'lib/types';
import { JobStatusFlagProps } from './types';

const getIcon = (theme: DefaultTheme, flag: JobStatusFlagType) => {
  if (!flag) return faFlagDuotone;
  switch (flag.icon) {
    case 'faExclamationCircle':
      return faExclamationCircle;
    case 'faFlagDuotone':
      return faFlagDuotone;
    case 'faFlagRegular':
      return faFlagRegular;
    case 'faCheckCircle':
      return faCheckCircle;
    default:
      return faFlagDuotone;
  }
};

const JobStatusFlag = ({
  flag,
  fixedWidth = true,
  ...rest
}: JobStatusFlagProps) => {
  const theme = useTheme();
  const icon = getIcon(theme, flag);
  return (
    <FontAwesomeIcon
      {...rest}
      icon={icon}
      color={flag?.color ?? '#00ffff'}
      fixedWidth={fixedWidth}
    />
  );
};

export default JobStatusFlag;
