import { gql } from '@apollo/client';

export const JOBS_QUERY = gql`
  query (
    $clientId: ID!
    $jobStatusId: ID
    $page: Int
    $flagName: String
    $includeInstantiationSuggestion: Boolean!
    $searchTerm: String
    $sort: Sort
  ) {
    jobs(
      clientId: $clientId
      jobStatusId: $jobStatusId
      flagName: $flagName
      searchTerm: $searchTerm
      page: $page
      sort: $sort
    ) {
      results {
        id
        name
        reference
        dateTimeStart
        dateTimeEnd
        status {
          id
          name
          nameDisplay
          flag {
            id
            name
            nameDisplay
            color
            icon
          }
        }
        tags {
          id
          name
          nameDisplay
          flag {
            id
            name
            nameDisplay
            color
            icon
          }
        }
        site {
          id
          name
          location {
            point {
              longitude
              latitude
            }
            meta
          }
        }
        assignees {
          id
          name
        }
        instantiationType @include(if: $includeInstantiationSuggestion) {
          name
        }
      }
      count
    }
    jobStatuses(clientId: $clientId, jobStatusFlagName: $flagName) {
      id
      nameDisplay
      flag {
        id
        nameDisplay
        color
        icon
      }
    }
  }
`;

export const ADDITIONAL_JOB_INFORMATION_QUERY = gql`
  query ($jobId: ID) {
    job(jobId: $jobId) {
      id
      logBlocks {
        id
        status
        type
        dateTime
        instanceContentType
        data
        meta
        children {
          id
          status
          type
          dateTime
          instanceContentType
          data
          meta
        }
      }
    }
  }
`;
