import { DefaultParams, MatcherFn } from 'wouter';
import routeMatcher from './routeMatcher';

const multiPathRouteMatcher: MatcherFn = (patterns, path) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const pattern of [patterns].flat()) {
    const [match, params] = routeMatcher(pattern, path);
    if (match) return [match, params] as [false, null] | [true, DefaultParams];
  }

  return [false, null];
};

export default multiPathRouteMatcher;
