import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import JobStatus from 'components/JobStatus';
import JobIssuePill from 'components/JobIssuePill';
import JobReportPill from 'components/JobReportPill';
import { JobIssue, JobReport, LogItem } from '../types';
import { parseDateTime } from '../utils';

export const getLogText = (logItem: LogItem) => {
  const error = logItem.status === 'ERROR';
  const participle = logItem.status === 'INITIATED' || error;
  let inner;
  switch (logItem.type) {
    case 'PREPOPULATE_JOB':
      inner = <>Prepopulate job from data</>;
      break;
    case 'RECEIVE_JOB':
      inner = <>Received job from {logItem.integration}</>;
      break;
    case 'RECEIVE_JOB_UPDATE':
      inner = <>Received job update from {logItem.integration}</>;
      break;
    case 'INSTANTIATE_JOB':
      inner = <>Accepted job</>;
      break;
    case 'CREATE_JOB_VISIT':
      if (!logItem.data) {
        inner = <>Visit created but not assigned</>;
      } else {
        inner = (
          <>
            Visit assigned to <strong>{logItem.data.user.name}</strong>
          </>
        );
      }
      break;
    case 'UPDATE_JOB_VISIT':
      inner = (
        <>
          Updated visit assigned to{' '}
          <strong>
            {logItem?.meta?.type === 'REASSIGNED'
              ? logItem?.meta?.from.name
              : logItem?.data?.user?.name}
          </strong>
        </>
      );
      break;
    case 'DELETE_JOB_VISIT':
      inner = (
        <>
          Deleted visit assigned to <strong>{logItem.data.user.name}</strong>
        </>
      );
      break;
    case 'CREATE_JOB_REPORT':
      inner = (
        <>
          Created job report{' '}
          <JobReportPill
            jobReport={logItem.data as JobReport}
            statusOverride="SCHEDULED"
          />
        </>
      );
      break;
    case 'UPDATE_JOB_REPORT':
      inner = (
        <>
          Updated job report{' '}
          <JobReportPill
            jobReport={logItem.data as JobReport}
            statusOverride="COMPLETED"
          />
        </>
      );
      break;
    case 'DELETE_JOB_REPORT':
      inner = (
        <>
          Deleted job report{' '}
          <JobReportPill jobReport={logItem.meta as JobReport} />
        </>
      );
      break;
    case 'START_JOB_REPORT':
      inner = (
        <>
          {logItem.data.startedBy.name} started{' '}
          <JobReportPill
            jobReport={logItem.data as JobReport}
            statusOverride="STARTED"
          />
        </>
      );
      break;
    case 'COMPLETE_JOB_REPORT':
      inner = (
        <>
          {logItem.data.startedBy.name} completed job report{' '}
          <JobReportPill
            jobReport={logItem.data as JobReport}
            statusOverride="COMPLETED"
          />
        </>
      );
      break;
    case 'CREATE_GEOOP_JOB':
      inner = (
        <>
          {error && 'error '}
          {participle ? 'creating' : 'created'} job in GeoOp
        </>
      );
      break;
    case 'CREATE_GEOOP_VISIT':
      inner = (
        <>
          {error && 'error '}
          {participle ? 'creating' : 'created'} visit in GeoOp
        </>
      );
      break;
    case 'UPDATE_GEOOP_VISIT':
      inner = (
        <>
          {error && 'error '}
          {participle ? 'syncing' : 'synced'} visit update with GeoOp
        </>
      );
      break;
    case 'DELETE_GEOOP_VISIT':
      inner = (
        <>
          {error && 'error '}
          {participle ? 'deleting' : 'deleted'} visit in GeoOp
        </>
      );
      break;
    case 'CREATE_IAUDITOR_INSPECTION':
      inner = (
        <>{participle ? 'Initialising' : 'Initialised'} report in iAuditor</>
      );
      break;
    case 'ADD_USER_TO_IAUDITOR_INSPECTION':
      inner = (
        <>
          {participle ? 'Adding' : 'Added'}{' '}
          {logItem.meta?.name ? <strong>{logItem.meta?.name}</strong> : 'user'}{' '}
          to report in iAuditor
        </>
      );
      break;
    case 'ADD_USER_TO_USER_GROUP':
      inner = (
        <>
          Added <strong>{logItem.data.name}</strong> to{' '}
          <strong>{logItem.context.namePlural}</strong>
        </>
      );
      break;
    case 'MOVE_USER_TO_USER_GROUP':
      inner = (
        <>
          Moved <strong>{logItem.meta.user.name}</strong> to{' '}
          <strong>{logItem.meta.to.namePlural}</strong>
        </>
      );
      break;
    case 'REMOVE_USER_FROM_USER_GROUP':
      inner = (
        <>
          Removed <strong>{logItem.data.name}</strong> from{' '}
          <strong>{logItem.context.namePlural}</strong>
        </>
      );
      break;
    case 'UPLOAD_JOB_QUOTE':
      inner = <>Quote uploaded</>;
      break;
    case 'UPDATE_JOB_QUOTE':
      inner = <>Quote {logItem.data.statusDisplay.toLowerCase()}</>;
      break;
    case 'CREATE_ISSUE_DUE_TO_RESPONSE':
      inner = <>Created issue</>;
      break;
    case 'CREATE_EOT_REQUEST':
      inner = (
        <>
          EOT requested (to:{' '}
          <span className="mono">
            {parseDateTime(logItem.data.dateTimeNew)}
          </span>
          )
        </>
      );
      break;
    case 'UPDATE_EOT_REQUEST':
      inner = <>EOT request {logItem.data.statusDisplay.toLowerCase()}</>;
      break;
    case 'COMPLETE_REPORT':
      inner = (
        <>
          Report <span className="mono">#1</span> completed
        </>
      );
      break;
    case 'EXPORT_IAUDITOR_REPORT':
      inner = <>Report PDF exported and downloaded</>;
      break;
    case 'SET_JOB_PARENT':
      inner = (
        <>
          Merged job <span className="mono">{logItem.context.reference}</span>{' '}
          with <span className="mono">{logItem.meta.reference}</span>
        </>
      );
      break;
    case 'SET_JOB_STATUS':
      inner = (
        <>
          Job status set to{' '}
          <JobStatus
            className="ml-1 font-weight-bold"
            status={(logItem.meta || logItem.data).to || logItem.data}
          />
        </>
      );
      break;
    case 'RECEIVE_TECHFORGE_INSTRUCTION':
      inner = (
        <>
          Received TechForge Instruction{' '}
          <span className="mono">{logItem.data.code}</span>
        </>
      );
      break;
    case 'OPEN_JOB_ISSUE':
      inner = (
        <>
          Opened issue
          <JobIssuePill
            className="ml-2"
            jobIssue={logItem.data as JobIssue}
            statusOverride="OPEN"
          />
        </>
      );
      break;
    case 'CLOSE_JOB_ISSUE':
      inner = (
        <>
          Closed issue
          <JobIssuePill
            className="ml-2"
            jobIssue={logItem.data as JobIssue}
            statusOverride="CLOSED"
          />
        </>
      );
      break;
    case 'REOPEN_JOB_ISSUE':
      inner = (
        <>
          Reopened issue{' '}
          <JobIssuePill
            className="ml-2"
            jobIssue={logItem.data as JobIssue}
            statusOverride="OPEN"
          />
        </>
      );
      break;
    case 'NOTIFY_USER':
      inner = <>Notify user</>;
      break;
    case 'APPLY_PREPOPULATED_STATUS':
      inner = <>Applied prepopulated status</>;
      break;
    case 'APPROVE_JOB':
      inner = (
        <>
          <FontAwesomeIcon
            className="mr-1"
            icon={faCheckCircle}
            fixedWidth
            style={{ color: '#106c12' }}
          />
          Client approved job
        </>
      );
      break;
    case 'SET_JOB_VISIT_STATUS':
      inner = (
        <>
          Set job visit status to <strong>{logItem.meta.to.nameDisplay}</strong>
        </>
      );
      break;
    case 'START_JOURNEY_TO_JOB_VISIT':
      inner = (
        <>
          <strong>{logItem.data.user.name}</strong> started his journey to job
          visit
        </>
      );
      break;
    case 'START_JOB_VISIT':
      inner = (
        <>
          <strong>{logItem.data.user.name}</strong> started job visit
        </>
      );
      break;
    case 'PLACE_JOB_VISIT_ON_HOLD':
      inner = (
        <>
          Placed <strong>{logItem.data.user.name}</strong>&#39;s current job
          visit on hold
        </>
      );
      break;
    case 'CONTINUE_JOB_VISIT':
      inner = (
        <>
          <strong>{logItem.data.user.name}</strong> continued job visit
        </>
      );
      break;
    case 'COMPLETE_JOB_VISIT':
      inner = (
        <>
          <strong>{logItem.data.user.name}</strong> completed job visit
        </>
      );
      break;
    case 'CREATE_JOB_REPORT_VERSION':
      inner = <>Created job report version ready for approval</>;
      break;
    case 'SEND_REPORT_TO_CLIENT':
      inner = <>Submitted job report to {logItem.integration}</>;
      break;
    case 'REQUEST_ASSISTANCE':
      inner = (
        <>
          <strong>{logItem.data.helpRequest.requester.name}</strong> requested{' '}
          <strong>{logItem.data.user.name}</strong> to provide assistance on
          visit <strong>{logItem.data.helpRequest.visit.label}</strong>
        </>
      );
      break;
    default:
      inner = null;
  }
  return (
    <span className={classNames('log-item-text pr-4', { error })}>{inner}</span>
  );
};

export default getLogText;
