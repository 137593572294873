import { gql } from '@apollo/client';

export const SETTINGS_QUERY = gql`
  query {
    user {
      id
      name
      themeMode
    }
  }
`;

export const UPDATE_USER_MUTATION = gql`
  mutation ($data: JSONScalar) {
    updateUser(data: $data) {
      user {
        id
        name
        themeMode
      }
    }
  }
`;
