import styled from 'styled-components';

export const ClientJobReportCardHeader = styled.div`
  border-bottom: ${({ theme }) => theme.border};
  //padding-bottom: 1px;
  a {
    color: ${({ theme }) => theme.color.cardText.hex()};
  }

  p {
    margin-bottom: 0;
    line-height: 32px;
  }
`;
