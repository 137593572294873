import styled from 'styled-components';

export const JobOfInterest = styled.div`
  transition: background-color 150ms;

  &:hover {
    cursor: pointer;
    transition: background-color 50ms;
    background-color: ${({ theme }) => theme.color.contentBackground.hex()};
  }
`;
