import { batch, useDispatch } from 'react-redux';
import { GraphQLError } from 'graphql';

// TODO: This currently only captures the first error.

const useCaptureServerError = () => {
  const dispatch = useDispatch();
  return (error: GraphQLError & { type?: string }) => {
    batch(() => {
      dispatch({ type: 'SET_ERROR', payload: error });
      if (error.type === 'INTEGRATION_INSTANCE_ACTIVE_ERROR') {
        dispatch({ type: 'ADD_WARNING', payload: error.message });
      } else {
        dispatch({ type: 'SET_ERROR_WINDOW_IS_OPEN', payload: true });
      }
    });
  };
};

export default useCaptureServerError;
