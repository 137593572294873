import styled from 'styled-components';
import SimpleButton from '../SimpleButton';

export const Wrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.color.cardBackground.hex()};
  border-top: ${({ theme }) => theme.border};
  border-left: ${({ theme }) => theme.border};
  margin-left: 80px;
  padding-left: 1.5rem;
`;

export const Tab = styled(SimpleButton)`
  margin-right: 0 !important;
  border-radius: 0;
  border-bottom: 2px solid
    ${({ theme, active }) =>
      active ? theme.color.cardText.alpha(0.25).hex() : 'transparent'};
  color: ${({ theme, active }) =>
    theme.color.cardText.alpha(active ? 1 : 0.75).hex()};
`;
