import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/pro-duotone-svg-icons/faUserCircle';
import { getLogText } from 'lib/logs';
import { faRobot } from '@fortawesome/pro-regular-svg-icons/faRobot';
import { parseDateTime } from 'lib/utils';
import CardWrapper from '../CardWrapper';
import { LogBlockDescriptionProps, LogBlockSummaryProps } from './types';

const LogBlockDescription = ({ logBlock }: LogBlockDescriptionProps) => {
  let inner;
  switch (logBlock.type) {
    case 'CREATE_JOB_VISIT':
    case 'UPDATE_JOB_VISIT':
      inner = (
        <table>
          <tbody>
            <tr>
              <td className="text-75">Start:</td>
              <td className="mono">
                {parseDateTime(logBlock.data.dateTimeStart)}
              </td>
            </tr>
            <tr>
              <td className="text-75">End:</td>
              <td className="mono">
                {parseDateTime(logBlock.data.dateTimeEnd)}
              </td>
            </tr>
          </tbody>
        </table>
      );
      break;
    default:
      inner = <></>;
  }
  return <small>{inner}</small>;
};

const LogBlockSummary = ({ logBlock, ...rest }: LogBlockSummaryProps) => {
  return (
    <CardWrapper className="py-2 px-3" invert>
      <div className="d-flex justify-content-between text-75">
        {logBlock.user ? (
          <small className="mr-4">
            <FontAwesomeIcon className="mr-1" icon={faUserCircle} />
            {logBlock.user.name}
          </small>
        ) : (
          <small className="mr-4">
            <FontAwesomeIcon className="mr-1" icon={faRobot} />
            Automated
          </small>
        )}
        <small className="mono">{parseDateTime(logBlock.dateTime)}</small>
      </div>
      <p className="mb-0">{getLogText(logBlock)}</p>
      <LogBlockDescription logBlock={logBlock} />
    </CardWrapper>
  );
};

export default LogBlockSummary;
