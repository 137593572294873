import styled from 'styled-components';
import { TableHeadButtonStyledProps } from './types';

// eslint-disable-next-line import/prefer-default-export
export const TableHeadButtonStyled = styled.button<TableHeadButtonStyledProps>`
  border: none;
  background-color: transparent;
  padding: 0;
  font-weight: bold;
  color: ${({ theme }) => theme.color.cardText.hex()};

  .icon {
    color: ${({ theme, active }) =>
      active
        ? theme.color.cardText.hex()
        : theme.color.cardText.alpha(0.25).hex()};
  }

  &:focus {
    outline: none;
  }
`;
