import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons/faTimesCircle';
import { faLongArrowRight } from '@fortawesome/pro-light-svg-icons/faLongArrowRight';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons/faCheckCircle';
import { parseDateTime } from 'lib/utils';
import Duration from '../Duration';
import { Small } from '../Typography';
import { useReducerContext } from '../ClientJob/reducer';
import { ClientJobIssueCardBodyProps } from '../ClientJobIssues/types';

const ClientJobIssueCardBody = ({
  issueType,
  status,
  closedBy,
  worstLogBlock,
  job: outerJob,
}: ClientJobIssueCardBodyProps) => {
  const [{ job: innerJob }] = useReducerContext();
  const job = innerJob ?? outerJob;
  if (!job) return null;
  let inner;
  switch (issueType) {
    case 'MISSED_RESPONSE': {
      let corrected;
      switch (closedBy?.type) {
        case 'UPDATE_JOB_VISIT':
          corrected = (
            <>
              <Small>Corrected response</Small>
              <p className="mb-0 mono">
                <FontAwesomeIcon
                  className="mr-1 text-primary"
                  icon={faCheckCircle}
                />
                {parseDateTime(closedBy.data.dateTimeStart)}
              </p>
            </>
          );
          break;
        case 'UPDATE_EOT_REQUEST':
          corrected = (
            <>
              <Small>New target response</Small>
              <p className="mb-0 mono">
                <FontAwesomeIcon
                  className="mr-1 text-primary"
                  icon={faCheckCircle}
                />
                {parseDateTime(closedBy.data.dateTimeNew)}
              </p>
            </>
          );
          break;
        default:
          corrected = null;
      }

      inner = (
        <div>
          <div className="mb-3">
            The target response time for this job was missed.
          </div>
          <div className="d-flex">
            <div
              className={classNames('mr-4', { 'text-75': status === 'CLOSED' })}
            >
              <Small>Target response</Small>
              <p className="mb-0 mono">
                {parseDateTime(job.originalTargetDateTimeStart)}
              </p>
            </div>
            <div
              className={classNames('mr-4', { 'text-75': status === 'CLOSED' })}
            >
              <Small>Actual response</Small>
              <p className="mb-0 mono">
                <FontAwesomeIcon
                  className="mr-1 text-danger"
                  icon={faTimesCircle}
                />
                {parseDateTime(worstLogBlock?.data?.dateTimeStart)}
              </p>
            </div>
            <div
              className={classNames({
                'text-75': status === 'CLOSED',
              })}
            >
              <Small>Late by</Small>
              <p className="mb-0 mono">
                <Duration
                  dateTimeStart={job.targetDateTimeStart}
                  dateTimeEnd={worstLogBlock?.data?.dateTimeStart}
                />
              </p>
            </div>
            {status === 'CLOSED' && (
              <div className="d-flex">
                <div className="ml-2 mr-3">
                  <FontAwesomeIcon icon={faLongArrowRight} />
                </div>
                <div>{corrected}</div>
              </div>
            )}
          </div>
        </div>
      );
      break;
    }
    case 'REQUIRES_QUOTE':
      inner = (
        <div className="d-flex">
          This job requires the submission of a quote. Does the quote have to be
          submitted before the target completion date?
        </div>
      );
      break;
    default:
      inner = <></>;
  }
  return inner;
};

export default ClientJobIssueCardBody;
