import React from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import {
  JobVisit,
  VisitOnChange,
  VisitOnCreate,
  VisitOnDelete,
} from 'lib/types';
import { DateTime } from 'luxon';
import { useSessionId } from 'lib/hooks';
import {
  CREATE_JOB_VISIT_MUTATION,
  DELETE_JOB_VISIT_MUTATION,
  JOB_VISIT_QUERY,
  UPDATE_JOB_VISIT_MUTATION,
} from './query';
import {
  ClientJobSchedulerProps,
  CreateJobVisitMutationData,
  CreateJobVisitMutationVariables,
  DeleteJobVisitMutationData,
  DeleteJobVisitMutationVariables,
  UpdateJobVisitMutationData,
  UpdateJobVisitMutationVariables,
} from './types';
import {
  useReducerContext,
  createJobVisitOnCompleted,
  updateJobVisitOnCompleted,
  deleteJobVisitOnCompleted,
  selectJobVisit,
  setSchedulerTimeAxis,
} from '../ClientJob/reducer';
import JobVisitScheduler from '../JobVisitScheduler';
import Highlight from '../Highlight';

const ClientJobScheduler = ({
  viewDefault,
  visitSelectOffset,
  visitOnSelect,
}: ClientJobSchedulerProps) => {
  const [{ job, scheduler }, dispatch] = useReducerContext();
  const [createJobVisit] = useMutation<
    CreateJobVisitMutationData,
    CreateJobVisitMutationVariables
  >(CREATE_JOB_VISIT_MUTATION);

  const sessionId = useSessionId();

  const handleVisitOnCreate: VisitOnCreate<CreateJobVisitMutationData> = ([
    visit,
  ]) => {
    return createJobVisit({
      fetchPolicy: 'no-cache',
      variables: {
        sessionId,
        clientId: job?.client?.id,
        jobId: job?.id,
        data: {
          label: visit.label,
          dateTimeStart: visit.dateTimeStart,
          dateTimeEnd: visit.dateTimeEnd,
          userId: visit?.userId,
          reportTemplates: visit.reportTemplates ?? [],
          reports: visit.reports ?? [],
          workTypes: visit.workTypes ?? [],
        },
      },
    }).then((response) => {
      if (!response.data) return null;
      dispatch(createJobVisitOnCompleted(response.data));
      return [response.data.createJobVisit.jobVisit, response.data];
    });
  };

  const [updateJobVisit] = useMutation<
    UpdateJobVisitMutationData,
    UpdateJobVisitMutationVariables
  >(UPDATE_JOB_VISIT_MUTATION);

  const handleVisitOnChange: VisitOnChange<UpdateJobVisitMutationData> = (
    visit
  ) => {
    return updateJobVisit({
      variables: {
        sessionId,
        clientId: job?.client?.id,
        jobVisitId: visit.id,
        data: {
          label: visit.label,
          dateTimeStart: visit.dateTimeStart,
          dateTimeEnd: visit.dateTimeEnd,
          userId: visit.userId || null,
          reportTemplates: visit.reportTemplates ?? [],
          workTypes: visit.workTypes ?? [],
        },
      },
    }).then((response) => {
      if (!response.data) return null;
      dispatch(updateJobVisitOnCompleted(response.data));
      return [response.data.updateJobVisit.jobVisit, response.data];
    });
  };

  const [deleteJobVisit] = useMutation<
    DeleteJobVisitMutationData,
    DeleteJobVisitMutationVariables
  >(DELETE_JOB_VISIT_MUTATION);

  const handleVisitOnRemove: VisitOnDelete<DeleteJobVisitMutationData> = (
    id: JobVisit['id']
  ) => {
    return deleteJobVisit({
      fetchPolicy: 'no-cache',
      variables: {
        jobVisitId: id,
        clientId: job?.client?.id,
        sessionId,
      },
    }).then((response) => {
      if (!response.data) return null;
      dispatch(deleteJobVisitOnCompleted(response.data));
      return [response.data.deleteJobVisit.deletedId, response.data];
    });
  };

  const apolloClient = useApolloClient();
  const handleVisitOnSelect = (id: JobVisit['id']) => {
    return apolloClient
      .query({
        query: JOB_VISIT_QUERY,
        fetchPolicy: 'no-cache',
        variables: { id },
      })
      .then((response) => {
        const { jobVisit: visit } = response.data;
        dispatch(selectJobVisit(visit));
        if (visitOnSelect) return visitOnSelect(visit);
        return visit;
      });
  };

  const handleTimeAxisOnChange = (timeAxis: {
    dateTimeStart: DateTime;
    dateTimeEnd: DateTime;
  }) => {
    dispatch(setSchedulerTimeAxis(timeAxis));
  };

  if (!job) return null;
  return (
    <Highlight active={scheduler.selectMode === 'parent'}>
      <div className="h-100">
        <JobVisitScheduler
          job={job}
          readOnly={scheduler.readOnly}
          selectMode={scheduler.selectMode}
          viewDefault={viewDefault}
          visitSelectOffset={visitSelectOffset}
          visitOnCreate={handleVisitOnCreate}
          visitOnChange={handleVisitOnChange}
          visitOnRemove={handleVisitOnRemove}
          visitOnSelect={handleVisitOnSelect}
          timeAxisOnChange={handleTimeAxisOnChange}
        />
      </div>
    </Highlight>
  );
};

export default ClientJobScheduler;
