import styled from 'styled-components';

export const NestableWrapper = styled.div`
  position: relative;

  &.is-drag-active {
  }
`;

export const NestableList = styled.ol`
  margin: 0;
  padding: 0 0 0 40px;
  list-style-type: none;
`;

export const NestableDragLayer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  pointer-events: none;

  & > ${NestableList} {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
  }
`;
