import { useMutation } from '@apollo/client';
import { faCalendarAlt } from '@fortawesome/pro-duotone-svg-icons/faCalendarAlt';
import { faToolbox } from '@fortawesome/pro-duotone-svg-icons/faToolbox';
import { faWrench } from '@fortawesome/pro-duotone-svg-icons/faWrench';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faCircle } from '@fortawesome/pro-solid-svg-icons/faCircle';
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  DragDropContext,
  Droppable,
  Draggable,
  OnDragEndResponder,
} from 'react-beautiful-dnd';
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import { ItemParams } from 'react-contexify';
import { v4 as uuid4 } from 'uuid';
import { useTaskbarData, useUser } from 'lib/hooks';
import { UserTaskbarTab } from 'lib/types';
import { useLocation } from 'wouter';
import { UPDATE_USER_TASKBAR_MUTATION } from './query';
import { Wrapper, Tab } from './styled';
import ContextMenu from '../ContextMenu';

const LayoutTaskbar = () => {
  const user = useUser();
  const [tabs, setTabs] = useState<UserTaskbarTab[]>(user.taskbar.tabs);

  const [updateTaskbar] = useMutation(UPDATE_USER_TASKBAR_MUTATION);

  const handleOnDragEnd: OnDragEndResponder = ({ source, destination }) => {
    if (!destination) return;

    const result = [...tabs];
    const [removed] = result.splice(source.index, 1);
    result.splice(destination.index, 0, removed);

    setTabs(result);
    updateTaskbar({ variables: { tabs: result } });
  };

  const taskbarData = useTaskbarData();
  const handleOnAddTab = () => {
    if (!taskbarData) return;
    setTabs((prevTabs) => {
      const result = [
        ...prevTabs.map((tab) => ({ ...tab, isActive: false })),
        { id: uuid4(), ...taskbarData, isActive: true },
      ];
      updateTaskbar({ variables: { tabs: result } });
      return result;
    });
  };

  const [, setLocation] = useLocation();
  const handleTabOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { value: tabId } = event.currentTarget;
    setTabs((prevTabs) =>
      prevTabs.map((tab) => {
        if (tab.id === tabId) {
          setLocation(tab.path);
          return { ...tab, isActive: true };
        }
        return { ...tab, isActive: false };
      })
    );
  };

  const handleOnMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (event.button === 1) {
      const { value: tabId } = event.currentTarget;
      const tab = tabs.find((innerTab) => innerTab.id === tabId);
      if (tab) {
        window.open(
          `${process.env.REACT_APP_WEB_HOST}${tab.path}`,
          '_blank',
          'noopener,noreferrer'
        );
      }
    }
  };

  const getIcon = (tab: UserTaskbarTab) => {
    switch (tab.type) {
      case 'JOB':
        return faWrench;
      case 'SCHEDULE':
        return faCalendarAlt;
      case 'JOBS':
        return faToolbox;
      case 'JOB_GROUP':
        return faToolbox;
      default:
        return faCircle;
    }
  };

  const handleSetToCurrentLocationOnClick = ({ data }: ItemParams) => {
    if (!taskbarData) return;
    setTabs((prevTabs) => {
      const result = prevTabs.map((prevTab) =>
        prevTab.id === data.tab.id
          ? { ...prevTab, ...taskbarData, isActive: true }
          : { ...prevTab, isActive: false }
      );
      updateTaskbar({ variables: { tabs: result } });
      return result;
    });
  };

  const handleDeleteOnClick = ({ data }: ItemParams) => {
    setTabs((prevTabs) => {
      const result = prevTabs.filter((tab) => tab.id !== data.tab.id);
      updateTaskbar({ variables: { tabs: result } });
      return result;
    });
  };

  const getOptions = (tab: UserTaskbarTab) => {
    const ret = [
      {
        label: 'Delete',
        value: 'DELETE',
        data: { tab },
        children: (
          <>
            <FontAwesomeIcon className="mr-1" icon={faTimes} fixedWidth />
            Delete
          </>
        ),
        onClick: handleDeleteOnClick,
      },
    ];

    if (taskbarData) {
      ret.unshift({
        label: 'Set to current location',
        value: 'SET_TO_CURRENT_LOCATION',
        data: { tab },
        children: (
          <>
            <FontAwesomeIcon className="mr-1" icon={faTimes} fixedWidth />
            Set to current location
          </>
        ),
        onClick: handleSetToCurrentLocationOnClick,
      });
    }

    return ret;
  };

  if (!user.taskbar.isVisible) return null;
  return (
    <Wrapper>
      <div className="center-vertically h-100">
        <DragDropContext onDragEnd={handleOnDragEnd}>
          <div className="d-flex">
            <Droppable droppableId="taskbar" direction="horizontal">
              {({ innerRef: droppableRef, droppableProps, placeholder }) => (
                <div ref={droppableRef} className="d-flex" {...droppableProps}>
                  {tabs.map((tab, index) => {
                    return (
                      <Draggable
                        key={tab.id}
                        draggableId={tab.id}
                        index={index}
                      >
                        {({
                          innerRef: draggableRef,
                          draggableProps,
                          dragHandleProps,
                        }) => (
                          <ContextMenu
                            id={tab.id}
                            animation="fade"
                            options={getOptions(tab)}
                          >
                            {({ show }) => (
                              <div
                                ref={draggableRef}
                                {...draggableProps}
                                onContextMenu={(event) => {
                                  const rect =
                                    event.currentTarget.getBoundingClientRect();
                                  show(event, {
                                    position: {
                                      x:
                                        rect.x - Math.abs(rect.width - 200) / 2,
                                      y: rect.y - (taskbarData ? 82 : 48),
                                    },
                                  });
                                }}
                              >
                                <Tab
                                  active={tab.isActive}
                                  inButtonToolbar
                                  value={tab.id}
                                  onClick={handleTabOnClick}
                                  onMouseDown={handleOnMouseDown}
                                >
                                  <span {...dragHandleProps}>
                                    <FontAwesomeIcon
                                      className="mr-2"
                                      style={{ fontSize: 12 }}
                                      icon={getIcon(tab)}
                                    />
                                  </span>
                                  <small>{tab.label}</small>
                                </Tab>
                              </div>
                            )}
                          </ContextMenu>
                        )}
                      </Draggable>
                    );
                  })}
                  {placeholder}
                </div>
              )}
            </Droppable>
            {taskbarData && (
              <Tab
                backgroundActiveColor="transparent"
                inButtonToolbar
                icon={faPlus}
                onClick={handleOnAddTab}
              >
                <small>Add tab</small>
              </Tab>
            )}
          </div>
        </DragDropContext>
      </div>
    </Wrapper>
  );
};

export default LayoutTaskbar;
