import styled from 'styled-components';

export const Wrapper = styled.div`
  min-width: 400px;
`;

export const ChatHeadWrapper = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.color.contentBackground.hex()};
`;
