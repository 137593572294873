import React, { Reducer, useReducer } from 'react';
import { useTheme } from 'styled-components';
import { faHome } from '@fortawesome/pro-solid-svg-icons/faHome';
import buildMatcher from 'wouter/matcher';
import { Link, RouteComponentProps, Switch, Route, useLocation } from 'wouter';
import { faWrench } from '@fortawesome/pro-duotone-svg-icons/faWrench';
import { useClient, useSetTaskbarData } from 'lib/hooks';
import { AnyAction } from 'redux';
import { useQuery } from '@apollo/client';
import { JobGroup } from 'lib/types';
import Portal from '../Portal';
import CardWrapper from '../CardWrapper';
import SimpleButton from '../SimpleButton';
import {
  ClientJobGroupState,
  JobGroupQueryData,
  JobGroupQueryVariables,
} from './types';
import reducer, {
  initialState,
  jobGroupQueryOnCompleted,
  ReducerContext,
} from './reducer';
import ClientJobGroupBreadcrumb from '../ClientJobGroupBreadcrumb';
import { JOB_GROUP_QUERY } from './query';
import ClientJobGroupHome from '../ClientJobGroupHome';

const routeMatcher = buildMatcher();

const ClientJobGroup = ({
  params,
}: RouteComponentProps<{ reference: JobGroup['reference'] }>) => {
  const [location] = useLocation();
  const theme = useTheme();
  const client = useClient();

  const [state, dispatch] = useReducer<Reducer<ClientJobGroupState, AnyAction>>(
    reducer,
    initialState
  );

  const { jobGroup } = state;

  useSetTaskbarData({
    type: 'JOB_GROUP',
    label: `Job group ${jobGroup?.reference}`,
    path: `/clients/${client.slug}/job-groups/${jobGroup?.reference}`,
  });

  const { loading } = useQuery<JobGroupQueryData, JobGroupQueryVariables>(
    JOB_GROUP_QUERY,
    {
      fetchPolicy: 'no-cache',
      variables: { jobGroupReference: params.reference },
      onCompleted: (data) => {
        dispatch(jobGroupQueryOnCompleted(data));
      },
    }
  );

  return (
    <ReducerContext.Provider value={[state, dispatch]}>
      <ClientJobGroupBreadcrumb loading={loading} />
      <Portal selector="layout-topbar-extension">
        <CardWrapper
          className="px-4 py-2 rounded-0"
          style={{ borderLeft: theme.border, borderBottom: theme.border }}
        >
          <div className="d-flex justify-content-between">
            <div>
              <Link
                to={`/clients/${client.slug}/job-groups/${jobGroup?.reference}`}
              >
                <SimpleButton
                  icon={faHome}
                  inButtonToolbar
                  active={
                    routeMatcher(
                      '/clients/:slug/job-groups/:reference',
                      location
                    )[0]
                  }
                >
                  Home
                </SimpleButton>
              </Link>
              <Link
                to={`/clients/${client.slug}/job-groups/${jobGroup?.reference}/jobs`}
              >
                <SimpleButton
                  icon={faWrench}
                  inButtonToolbar
                  active={
                    routeMatcher(
                      '/clients/:slug/job-group/:reference/jobs',
                      location
                    )[0]
                  }
                >
                  Jobs
                </SimpleButton>
              </Link>
            </div>
          </div>
        </CardWrapper>
      </Portal>
      <Switch>
        <Route
          path="/clients/:slug/job-groups/:reference"
          component={ClientJobGroupHome}
        />
        <Route
          path="/clients/:slug/job-groups/:reference"
          component={ClientJobGroupHome}
        />
      </Switch>
    </ReducerContext.Provider>
  );
};

export default ClientJobGroup;
