import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons/faSpinnerThird';
import { useReducerContext } from '../ClientPeople/reducer';
import PortalBreadcrumb from '../PortalBreadcrumb';

const ClientPeopleBreadcrumb = ({ loading }: { loading: boolean }) => {
  const [{ userGroup }] = useReducerContext();
  if (!userGroup) {
    return (
      <PortalBreadcrumb>
        <div className="d-flex">
          <h4 className="text-75 client__tab-bar-breadcrumb-link">People</h4>
          <FontAwesomeIcon
            className="mx-2"
            style={{ marginTop: 6 }}
            icon={faChevronRight}
            fixedWidth
          />
          <FontAwesomeIcon
            style={{ fontSize: '1.25rem', marginTop: 5 }}
            icon={faSpinnerThird}
            fixedWidth
            spin
          />
        </div>
      </PortalBreadcrumb>
    );
  }
  return (
    <PortalBreadcrumb>
      <div className="d-flex">
        <h4 className="text-75 client__tab-bar-breadcrumb-link">People</h4>
        <FontAwesomeIcon
          className="mx-2"
          style={{ marginTop: 6 }}
          icon={faChevronRight}
          fixedWidth
        />
        {loading ? (
          <FontAwesomeIcon
            style={{ fontSize: '1.25rem', marginTop: 5 }}
            icon={faSpinnerThird}
            fixedWidth
            spin
          />
        ) : (
          <h4>{userGroup.namePlural}</h4>
        )}
      </div>
    </PortalBreadcrumb>
  );
};

export default ClientPeopleBreadcrumb;
