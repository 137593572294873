import React from 'react';
import { Item, Menu, useContextMenu } from 'react-contexify';
import { ContextMenuProps } from './types';

const ContextMenu = ({
  id,
  children,
  options,
  ...contextMenuProps
}: ContextMenuProps) => {
  const { show } = useContextMenu({ id });

  return (
    <>
      {children({ show })}
      <Menu id={id} {...contextMenuProps}>
        {options.map((option) => (
          <Item
            key={option.label}
            data={{ value: option.value, ...option.data }}
            onClick={option.onClick}
          >
            {option.children}
          </Item>
        ))}
      </Menu>
    </>
  );
};

export default ContextMenu;
