import React, { useRef } from 'react';
import classNames from 'classnames';
import { Link, useLocation } from 'wouter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrain } from '@fortawesome/pro-light-svg-icons/faTrain';
import { faCog } from '@fortawesome/pro-light-svg-icons/faCog';
import { faUsers } from '@fortawesome/pro-light-svg-icons/faUsers';
import { useDispatch, useSelector } from 'react-redux';
import { useHovered } from 'lib/hooks';
import buildMatcher from 'wouter/matcher';
import Logo from 'lib/theme/logo';
import { faGlobeEurope } from '@fortawesome/pro-light-svg-icons/faGlobeEurope';
import { faStickyNote } from '@fortawesome/pro-light-svg-icons/faStickyNote';
import { State } from 'lib/redux/types';
import LayoutOverlay from '../LayoutOverlay';
import LayoutSidebarClientMenu from '../LayoutSidebarClientMenu';
import { LayoutSidebarItemProps } from './types';
import {
  LayoutSidebarIcon,
  LayoutSidebarIconButton,
  LayoutSidebarMenu,
  LayoutSidebarNav,
  LayoutSidebarWrapper,
} from './styled';
import { faList } from '@fortawesome/pro-light-svg-icons/faList';

const matcher = buildMatcher();

const LayoutSidebarItem = ({
  icon,
  label,
  to,
  notActive,
  active: activeOverride = false,
  activeFor,
  onClick,
}: LayoutSidebarItemProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const [location] = useLocation();
  const { isOpen: sidebarMenuIsOpen } = useSelector(
    (state: State) => state.layout.sidebarMenuMeta
  );

  const [hovered, hoveredCallbacks] = useHovered();

  const iconInner = (active: boolean) => {
    return (
      <>
        <div ref={ref} className={classNames({ 'mr-3': active })}>
          <FontAwesomeIcon className="icon" icon={icon} fixedWidth size="2x" />
          {hovered && <div className="label-tooltip">{label}</div>}
        </div>
      </>
    );
  };

  const active =
    !notActive &&
    (activeOverride ||
      activeFor?.some((path) => matcher(path, location)[0]) ||
      false);

  if (to) {
    return (
      <Link to={to} onClick={onClick}>
        <LayoutSidebarIcon
          active={active && !sidebarMenuIsOpen}
          {...hoveredCallbacks}
        >
          {iconInner(active && !sidebarMenuIsOpen)}
        </LayoutSidebarIcon>
      </Link>
    );
  }

  if (onClick) {
    return (
      <LayoutSidebarIconButton
        active={active}
        onClick={onClick}
        {...hoveredCallbacks}
      >
        {iconInner(sidebarMenuIsOpen || active)}
      </LayoutSidebarIconButton>
    );
  }

  return iconInner(false);
};

const LayoutSidebarMenuRouter = () => {
  const { isOpen: sidebarMenuIsOpen } = useSelector(
    (state: State) => state.clients
  );
  if (sidebarMenuIsOpen) {
    return <LayoutSidebarClientMenu />;
  }
  return null;
};

const LayoutSidebar = () => {
  const dispatch = useDispatch();
  const { isOpen: clientsIsOpen } = useSelector(
    (state: State) => state.clients
  );
  const { isOpen: notesIsOpen } = useSelector(
    (state: State) => state.layout.notesMeta
  );
  const { isOpen: todosIsOpen } = useSelector((state: State) => state.todos);

  const handleCloseSideMenu = () => {
    if (clientsIsOpen) {
      dispatch({ type: 'CLIENTS__CLOSE_SIDEBAR' });
    }
  };

  const handleClientsOnClick = () => {
    dispatch({ type: 'CLIENTS__OPEN_SIDEBAR' });
  };

  const handleNotesOnClick = () => {
    dispatch({
      type: 'LAYOUT__NOTES_META',
      payload: { isOpen: true },
    });
  };

  const handleTodosOnClick = () => {
    dispatch({
      type: 'TODOS__TOGGLE',
    });
  };

  return (
    <>
      <LayoutSidebarWrapper>
        <div className="text-center">
          <Link to="/">
            <div>
              <Logo />
            </div>
          </Link>
        </div>
        <LayoutSidebarNav className="d-flex flex-column justify-content-between">
          <div>
            <LayoutSidebarItem
              icon={faGlobeEurope}
              label="Global"
              to="/"
              notActive={clientsIsOpen}
              activeFor={['/']}
              onClick={handleCloseSideMenu}
            />
            <LayoutSidebarItem
              icon={faTrain}
              label="Clients"
              active={clientsIsOpen}
              activeFor={['/clients/:slug/:rest*']}
              onClick={handleClientsOnClick}
            />
          </div>
          <div>
            <LayoutSidebarItem
              icon={faList}
              label="Todos"
              active={todosIsOpen}
              onClick={handleTodosOnClick}
            />
            <LayoutSidebarItem
              icon={faStickyNote}
              label="Notes"
              active={notesIsOpen}
              onClick={handleNotesOnClick}
            />
            <LayoutSidebarItem
              icon={faUsers}
              label="People"
              to="/people"
              activeFor={['/people/:rest*']}
              onClick={handleCloseSideMenu}
            />
            <LayoutSidebarItem icon={faCog} label="Settings" to="/settings" />
          </div>
        </LayoutSidebarNav>
      </LayoutSidebarWrapper>
      <LayoutSidebarMenu
        className={classNames({ shadow: clientsIsOpen })}
        isOpen={clientsIsOpen}
      >
        <LayoutSidebarMenuRouter />
      </LayoutSidebarMenu>
      <LayoutOverlay
        isOpen={clientsIsOpen || notesIsOpen}
        onClick={handleCloseSideMenu}
      />
    </>
  );
};

export default LayoutSidebar;
