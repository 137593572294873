import React from 'react';
import { TablePageIndicatorProps } from './types';

const TablePageIndicator = ({
  page,
  count,
  size = 20,
  small,
}: TablePageIndicatorProps) => {
  return (
    <span
      className="text-50"
      style={{
        fontSize: small ? 12 : '1rem',
        lineHeight: small ? '25px' : '34px',
      }}
    >
      Page <span className="mono">{page}</span> of{' '}
      <span className="mono">{Math.ceil(count / size)}</span>
    </span>
  );
};

export default TablePageIndicator;
