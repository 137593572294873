import React, { Dispatch, useContext } from 'react';
import { AnyAction } from 'redux';
import { JobVisit } from 'lib/types';
import { DateTime } from 'luxon';
import { SendReportToClientMutationData } from '../ClientJobReport/types';
import { CreateJobReportsMutationData } from '../ClientJobReportsButton/types';
import {
  DeleteJobReportMutationData,
  JobVisitQueryData,
} from '../JobVisitSchedulerEditSidePanel/types';
import { ClientJobState, JobQueryData, JobSubscriptionData } from './types';
import {
  CreateJobVisitMutationData,
  DeleteJobVisitMutationData,
  UpdateJobVisitMutationData,
} from '../ClientJobScheduler/types';
import { JobHistoryQueryData } from '../ClientJobHistory/types';
import { InstantiateJobMutationData } from '../ClientJobInstantiation/types';
import { JobIssuesQueryData } from '../ClientJobIssues/types';
import { JobReportsQueryData } from '../ClientJobReports/types';

export const initialState: ClientJobState = {
  job: undefined,
  jobVisit: undefined,
  selectedJobVisit: undefined,
  scheduler: {
    readOnly: false,
    selectMode: undefined,
    scroll: 0,
  },
};

export const ReducerContext = React.createContext<
  [ClientJobState, Dispatch<AnyAction>]
>([initialState, () => {}]);

export const useReducerContext = () => useContext(ReducerContext);

export const jobQueryOnCompleted = (responseData: JobQueryData) => ({
  type: 'JOB_QUERY_ON_COMPLETED',
  job: responseData.job,
});

export const jobSubscriptionOnUpdate = (
  subscriptionData: JobSubscriptionData
) => ({
  type: 'JOB_SUBSCRIPTION_ON_UPDATE',
  job: subscriptionData.jobSubscription.job,
});

export const jobInstantiationOnCompleted = (
  data: InstantiateJobMutationData
) => ({
  type: 'JOB_INSTANTIATION_ON_COMPLETED',
  job: data.instantiateJob.job,
  logBlock: data.instantiateJob.logBlock,
});

export const jobSubscriptionHistoryOnCreate = (
  subscriptionData: JobSubscriptionData
) => ({
  type: 'JOB_SUBSCRIPTION_HISTORY_ON_CREATE',
  logItem: subscriptionData.jobSubscription.logItem,
});

export const jobSubscriptionReportOnCreate = (
  subscriptionData: JobSubscriptionData
) => ({
  type: 'JOB_SUBSCRIPTION_REPORT_ON_CREATE',
  report: subscriptionData.jobSubscription.report,
});

export const jobSubscriptionReportOnUpdate = (
  subscriptionData: JobSubscriptionData
) => ({
  type: 'JOB_SUBSCRIPTION_REPORT_ON_UPDATE',
  report: subscriptionData.jobSubscription.report,
});

export const jobSubscriptionHistoryOnUpdate = (
  subscriptionData: JobSubscriptionData
) => ({
  type: 'JOB_SUBSCRIPTION_HISTORY_ON_UPDATE',
  logItem: subscriptionData.jobSubscription.logItem,
});

export const createJobVisitOnCompleted = (
  responseData: CreateJobVisitMutationData
) => ({
  type: 'CREATE_JOB_VISIT_ON_COMPLETED',
  jobVisit: responseData.createJobVisit.jobVisit,
  jobReports: responseData.createJobVisit.jobReports,
  jobIssues: responseData.createJobVisit.jobIssues,
  logBlock: responseData.createJobVisit.logBlock,
});

export const updateJobVisitOnCompleted = (
  responseData: UpdateJobVisitMutationData
) => ({
  type: 'UPDATE_JOB_VISIT_ON_COMPLETED',
  jobVisit: responseData.updateJobVisit.jobVisit,
  logBlock: responseData.updateJobVisit.logBlock,
});

export const deleteJobVisitOnCompleted = (
  responseData: DeleteJobVisitMutationData
) => ({
  type: 'DELETE_JOB_VISIT_ON_COMPLETED',
  deleted: responseData.deleteJobVisit.deleted,
  deletedId: responseData.deleteJobVisit.deletedId,
  job: responseData.deleteJobVisit.job,
  logBlock: responseData.deleteJobVisit.logBlock,
});

export const jobHistoryQueryOnCompleted = (
  responseData: JobHistoryQueryData
) => ({
  type: 'JOB_HISTORY_QUERY_ON_COMPLETED',
  jobHistory: responseData.jobHistory,
});

export const jobReportsQueryOnCompleted = (
  responseData: JobReportsQueryData
) => ({
  type: 'JOB_REPORTS_QUERY_ON_COMPLETED',
  reports: responseData.job.reports,
});

export const jobIssuesQueryOnCompleted = (
  responseData: JobIssuesQueryData
) => ({
  type: 'JOB_ISSUES_QUERY_ON_COMPLETED',
  issues: responseData.job.issues,
});

export const selectJobVisit = (jobVisit?: JobVisit) => ({
  type: 'SELECT_JOB_VISIT',
  jobVisit,
});

export const jobVisitQueryOnCompleted = (data: JobVisitQueryData) => ({
  type: 'JOB_VISIT_QUERY_ON_COMPLETED',
  jobVisit: data.jobVisit,
});

export const setSchedulerReadOnly = (readOnly: boolean) => ({
  type: 'SET_SCHEDULER_READ_ONLY',
  readOnly,
});

export const setSchedulerSelectMode = (selectMode?: 'eot' | 'parent') => ({
  type: 'SET_SCHEDULER_SELECT_MODE',
  selectMode,
});

export const setSchedulerTimeAxis = (timeAxis: {
  dateTimeStart: DateTime;
  dateTimeEnd: DateTime;
}) => ({
  type: 'SET_SCHEDULER_SCROLL',
  timeAxis,
});

export const setSchedulerScroll = (scroll: number) => ({
  type: 'SET_SCHEDULER_SCROLL',
  scroll,
});

export const createJobReportsMutationOnCompeted = (
  data: CreateJobReportsMutationData
) => ({
  type: 'CREATE_JOB_REPORTS_MUTATION_ON_COMPLETED',
  jobReports: data.createJobReports.jobReports,
  logBlock: data.createJobReports.logBlock,
});

export const deleteJobReportsMutationOnCompleted = (
  data: DeleteJobReportMutationData
) => ({
  type: 'DELETE_JOB_REPORT_MUTATION_ON_COMPLETED',
  deletedId: data.deleteJobReport.deletedId,
  logBlock: data.deleteJobReport.logBlock,
});

export const sendReportToClientMutationOnCompleted = (
  data: SendReportToClientMutationData
) => ({
  type: 'SEND_REPORT_TO_CLIENT_MUTATION_ON_COMPLETED',
  jobReport: data.sendJobReportToClient.jobReport,
});

const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case 'JOB_QUERY_ON_COMPLETED':
      return {
        ...state,
        job: {
          ...action.job,
          initial: true,
        },
      };
    case 'JOB_SUBSCRIPTION_ON_UPDATE':
      if (!state.job) return state;
      return {
        ...state,
        job: {
          ...state.job,
          ...action.job,
          // logBlocks: [...state.job.logBlocks, action.job.logBlocks[0]],
        },
      };
    case 'JOB_INSTANTIATION_ON_COMPLETED':
      if (!state.job) return state;
      return {
        ...state,
        job: {
          ...state.job,
          ...action.job,
          logBlocks: [action.logBlock, ...state.job.logBlocks],
        },
      };
    case 'CREATE_JOB_VISIT_ON_COMPLETED':
      if (!state.job) return state;
      return {
        ...state,
        job: {
          ...state.job,
          initial: false,
          status: {
            ...state.job,
            ...action.jobVisit.job.status,
          },
          reports: [...action.jobReports, ...state.job.reports],
          reportCount: state.job.reportCount + action.jobReports.length,
          issues: [...action.jobIssues, ...state.job.issues],
          issueCount: state.job.issueCount + action.jobIssues.length,
          logBlocks: [action.logBlock, ...state.job.logBlocks],
        },
      };
    case 'UPDATE_JOB_VISIT_ON_COMPLETED':
      if (!state.job) return state;
      return {
        ...state,
        job: {
          ...state.job,
          logBlocks: [action.logBlock, ...state.job.logBlocks],
        },
      };
    case 'DELETE_JOB_VISIT_ON_COMPLETED':
      if (!state.job) return state;
      return {
        ...state,
        job: {
          ...state.job,
          ...action.job,
          logBlocks: [action.logBlock, ...state.job.logBlocks],
        },
      };
    case 'JOB_HISTORY_QUERY_ON_COMPLETED':
      if (!state.job) return state;
      return {
        ...state,
        job: {
          ...state.job,
          logBlocks: [...state.job?.logBlocks, ...action.jobHistory.results],
        },
      };
    case 'JOB_REPORTS_QUERY_ON_COMPLETED':
      if (!state.job) return state;
      return {
        ...state,
        job: {
          ...state.job,
          reports: action.reports,
        },
      };
    case 'JOB_ISSUES_QUERY_ON_COMPLETED':
      if (!state.job) return state;
      return {
        ...state,
        job: {
          ...state.job,
          issues: action.issues,
        },
      };
    case 'JOB_SUBSCRIPTION_HISTORY_ON_CREATE':
      if (!state.job) return state;
      return {
        ...state,
        job: {
          ...state.job,
          logBlocks: [action.logItem, ...state.job.logBlocks],
        },
      };
    case 'JOB_SUBSCRIPTION_HISTORY_ON_UPDATE':
      if (!state.job) return state;
      return {
        ...state,
        job: {
          ...state.job,
          logBlocks: state.job.logBlocks.map((logBlock) => {
            if (logBlock.id === action.logItem.parent.id) {
              return {
                ...logBlock,
                children: logBlock.children.map((logItem) => {
                  if (logItem.id === action.logItem.previous.id) {
                    return action.logItem;
                  }
                  return logItem;
                }),
              };
            }
            return logBlock;
          }),
        },
      };
    case 'JOB_SUBSCRIPTION_REPORT_ON_CREATE':
      if (!state.job) return state;
      return {
        ...state,
        job: {
          ...state.job,
          reports: [action.report, ...state.job.reports],
        },
      };
    case 'JOB_SUBSCRIPTION_REPORT_ON_UPDATE':
      if (!state.job) return state;
      return {
        ...state,
        job: {
          ...state.job,
          reports: state.job.reports.map((report) =>
            report.id === action.report.id
              ? { ...report, ...action.report }
              : report
          ),
        },
      };
    case 'SELECT_JOB_VISIT':
      return {
        ...state,
        selectedJobVisit: action.jobVisit,
      };
    case 'JOB_VISIT_QUERY_ON_COMPLETED':
      return {
        ...state,
        jobVisit: action.jobVisit,
      };
    case 'SET_SCHEDULER_READ_ONLY':
      return {
        ...state,
        scheduler: {
          ...state.scheduler,
          readOnly: action.readOnly,
        },
      };
    case 'SET_SCHEDULER_SELECT_MODE':
      return {
        ...state,
        scheduler: {
          ...state.scheduler,
          selectMode: action.selectMode,
        },
      };
    case 'SET_SCHEDULER_TIME_AXIS':
      return {
        ...state,
        scheduler: {
          ...state.scheduler,
          timeAxis: {
            ...state.scheduler.timeAxis,
            dateTimeStart: action.timeAxis.dateTimeStart.toISOString(),
            dateTimeEnd: action.timeAxis.dateTimeEnd.toISOString(),
          },
        },
      };
    case 'SET_SCHEDULER_SCROLL':
      return {
        ...state,
        scheduler: {
          ...state.scheduler,
          scroll: action.scroll,
        },
      };
    case 'CREATE_JOB_REPORTS_MUTATION_ON_COMPLETED':
      if (!state.job || !state.jobVisit) return state;
      return {
        ...state,
        job: {
          ...state.job,
          logBlocks: [action.logBlock, ...state.job.logBlocks],
        },
        jobVisit: {
          ...state.jobVisit,
          reports: [...state.jobVisit.reports, ...action.jobReports],
        },
      };
    case 'DELETE_JOB_REPORT_MUTATION_ON_COMPLETED':
      if (!state.job || !state.jobVisit) return state;
      return {
        ...state,
        job: {
          ...state.job,
          logBlocks: [action.logBlock, ...state.job.logBlocks],
          reports: state.jobVisit.reports.filter(
            (jobReport) => jobReport.id !== action.deletedId
          ),
        },
        jobVisit: {
          ...state.jobVisit,
          reports: state.jobVisit.reports.filter(
            (jobReport) => jobReport.id !== action.deletedId
          ),
        },
      };
    case 'SEND_REPORT_TO_CLIENT_MUTATION_ON_COMPLETED':
      if (!state.job) return state;
      return {
        ...state,
        job: {
          ...state.job,
          reports: state.job.reports.map((report) =>
            report.id === action.jobReport.id
              ? { ...report, ...action.jobReport }
              : report
          ),
        },
      };
    default:
      return state;
  }
};

export default reducer;
