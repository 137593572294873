import React, { useCallback, useMemo } from 'react';
import { BryntumGrid } from '@bryntum/grid-react';
import { v4 as uuid4 } from 'uuid';
// eslint-disable-next-line import/extensions
import _ from 'lodash';
import ScrollDiv from '../ScrollDiv';

const parseKey = (key: string) => _.startCase(key);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const JsonRenderer = ({ json, expanded }: { json: any; expanded: boolean }) => {
  const parse = useCallback((obj): any => {
    if (!obj) return null;
    if (Array.isArray(obj)) {
      return obj.flatMap(parse);
    }
    if (typeof obj === 'object') {
      return Object.entries(obj).map(([key, value]) => {
        const parsedKey = parseKey(key);
        const parsedValue = parse(value);
        if (Array.isArray(parsedValue)) {
          return {
            id: uuid4(),
            name: parsedKey,
            children: parsedValue,
          };
        }
        return {
          id: uuid4(),
          name: parsedKey,
          value: parsedValue,
        };
      });
    }
    return obj;
  }, []);

  const data = useMemo(() => parse(json), [json, parse]);

  if (!data) return null;
  return (
    <ScrollDiv style={{ overflowY: 'auto', height: expanded ? 'auto' : 800 }}>
      <BryntumGrid
        columns={[
          { type: 'tree', field: 'name', text: 'Name', flex: 1 },
          { field: 'value', text: 'Value', flex: 1 },
        ]}
        data={data}
        cellEditFeature={false}
        quickFindFeature
        autoHeight
        treeFeature
      />
    </ScrollDiv>
  );
};

export default JsonRenderer;
