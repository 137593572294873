import React from 'react';
import { LogItem } from '../types';

export const getMetaText = (logItem: LogItem) => {
  switch (logItem.meta.type) {
    case 'REASSIGNED':
      return (
        <>
          Reassigned visit to <strong>{logItem.meta.to.name}</strong>
        </>
      );
    case 'MOVE_USER_TO_USER_GROUP':
      return (
        <>
          Removed from <strong>{logItem.meta.from.namePlural}</strong>
        </>
      );
    default:
      return null;
  }
};

export default getMetaText;
