import styled from 'styled-components';

export const Wrapper = styled.button`
  position: relative;
  display: inline;
  background: none;
  border: none;
  padding: 0;
  color: ${({ theme }) => theme.color.cardText.hex()};

  div.click-to-copy-tooltip {
    position: absolute;
    left: 50%;
    bottom: calc(100% - 0.5rem);
    transform: translateX(-50%);
    white-space: nowrap;
    font-size: 0.6rem;
    font-family: 'Poppins', sans-serif;
  }
`;
