import React from 'react';
import { Form, Field } from 'react-final-form';
import { useMutation } from '@apollo/client';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import SidePanel from '../SidePanel';
import { SidePanelComponentProps } from '../SidePanel/types';
import {
  updateUserGroupMutationOnCompleted,
  useReducerContext,
} from '../ClientPeople/reducer';
import CharField from '../CharField';
import { UPDATE_USER_GROUP_MUTATION } from './query';
import {
  FormValues,
  UpdateUserGroupMutationData,
  UpdateUserGroupMutationVariables,
} from './types';
import SimpleButton from '../SimpleButton';

const ClientPeopleGroupSidePanel = ({
  isOpen,
  toggle,
}: SidePanelComponentProps<any>) => {
  const [{ userGroup }, dispatch] = useReducerContext();

  const [updateUserGroup, { loading: updating }] = useMutation<
    UpdateUserGroupMutationData,
    UpdateUserGroupMutationVariables
  >(UPDATE_USER_GROUP_MUTATION);

  const handleOnSubmit = (values: FormValues) => {
    if (!userGroup) return;
    updateUserGroup({
      variables: { userGroupId: userGroup.id, data: values },
    }).then((response) => {
      if (!response.data) return;
      dispatch(updateUserGroupMutationOnCompleted(response.data));
      toggle(false);
    });
  };

  if (!userGroup) return null;
  return (
    <SidePanel
      title={`Edit ${userGroup.namePlural.toLowerCase()}`}
      isOpen={isOpen}
      toggle={toggle}
    >
      <Form
        initialValues={{
          name: userGroup.name,
          namePlural: userGroup.namePlural,
        }}
        onSubmit={handleOnSubmit}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <Field name="name" component={CharField} />
            </div>
            <div className="mb-3">
              <Field
                name="namePlural"
                label="Name (plural)"
                component={CharField}
              />
            </div>
            <div className="d-flex justify-content-end">
              <SimpleButton className="mr-2" icon={faTimes} onClick={toggle}>
                Cancel
              </SimpleButton>
              <SimpleButton icon={faCheck} loading={updating}>
                Save
              </SimpleButton>
            </div>
          </form>
        )}
      </Form>
    </SidePanel>
  );
};

export default ClientPeopleGroupSidePanel;
