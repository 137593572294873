import React from 'react';
import { Field, Form } from 'react-final-form';
import { useMutation } from '@apollo/client';
import SlateField from '../SlateField';
import SimpleButton from '../SimpleButton';
import { CREATE_JOB_ISSUE_MESSAGE_MUTATION } from './query';
import { ClientJobIssueMessageFormProps } from './types';

const ClientJobIssueMessageForm = ({
  issue,
  onCreateCompleted,
}: ClientJobIssueMessageFormProps) => {
  const [createMessage] = useMutation(CREATE_JOB_ISSUE_MESSAGE_MUTATION);

  const handleMessageFormOnSubmit = (values: any) => {
    return createMessage({
      variables: { jobIssueId: issue.id, data: values },
    }).then(onCreateCompleted);
  };

  return (
    <Form onSubmit={handleMessageFormOnSubmit}>
      {({ handleSubmit, form }) => (
        <form
          onSubmit={(event) => handleSubmit(event)?.then(() => form.reset())}
        >
          <div className="mb-3">
            <Field
              name="body"
              component={SlateField}
              minHeight={100}
              placeholder="Leave a message"
            />
          </div>
          <div className="d-flex justify-content-end">
            <SimpleButton>Send</SimpleButton>
          </div>
        </form>
      )}
    </Form>
  );
};

export default ClientJobIssueMessageForm;
