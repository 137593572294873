import chroma from 'chroma-js';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { DeckGL } from 'deck.gl';
import { Marker, StaticMap } from 'react-map-gl';
import { useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons/faMapMarkerAlt';
import UserPill from '../UserPill';
import { ClientInProgressMapProps } from './types';
import { MarkerWrapper } from '../ClientSitesMap/styled';

const MAPBOX_API_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_API_ACCESS_TOKEN;

const ClientInProgressMap = ({ jobs, hoveredId }: ClientInProgressMapProps) => {
  const theme = useTheme();

  const [viewState, setViewState] = useState({
    longitude: -0.115,
    latitude: 51.5074,
    zoom: 13,
    pitch: 45,
    bearing: 30,
  });

  useEffect(() => {
    const hoveredJob = jobs?.find((job) => job.id === hoveredId);
    if (!hoveredJob) return;
    setViewState((prevViewState) => ({
      ...prevViewState,
      ...hoveredJob?.site?.location?.point,
    }));
  }, [hoveredId, jobs]);

  return (
    <div
      className="position-relative overflow-hidden h-100 p-1"
      style={{ borderRadius: '0.25rem' }}
    >
      <DeckGL
        width="100%"
        height="100%"
        initialViewState={viewState}
        controller
      >
        <StaticMap
          mapboxApiAccessToken={MAPBOX_API_ACCESS_TOKEN}
          mapStyle={theme.mapboxStyle}
          width="100%"
          height="100%"
        >
          {jobs?.map((job) => {
            if (!job?.site?.location?.point) return null;
            return (
              <Marker key={job.id} {...job.site.location.point}>
                <MarkerWrapper>
                  <FontAwesomeIcon
                    className="marker-icon"
                    icon={faMapMarkerAlt}
                  />
                  <div
                    className="text-nowrap"
                    style={{
                      position: 'absolute',
                      top: -16,
                      left: 16,
                    }}
                  >
                    {job.assignees.map((user, index) => {
                      const last = index === job.assignees.length - 1;
                      const color = chroma(theme.color.primary).darken(
                        (index + 1) / 4
                      );
                      return (
                        <UserPill
                          key={user.id}
                          user={user}
                          className={classNames({ 'mr-2': !last })}
                          style={{ backgroundColor: color.hex() }}
                        />
                      );
                    })}
                  </div>
                  {job.id === hoveredId && (
                    <div className="marker-inner shadow">
                      <p className="mb-0">{job.site.name}</p>
                      <p className="mb-0 text-75">
                        {job.site.location.address}
                      </p>
                    </div>
                  )}
                </MarkerWrapper>
              </Marker>
            );
          })}
        </StaticMap>
      </DeckGL>
    </div>
  );
};

export default ClientInProgressMap;
