import { ApolloClient, from } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';
import { msalApplication } from '../msal';
import { getUri } from '../utils';
import cache from './cache';

const apiUri = getUri();
const uri = `${process.env.REACT_APP_HTTP_PROTOCOL}://${apiUri}/graphql/web/`;
const scope = process.env.REACT_APP_AZURE_SCOPE;

const httpLink = createUploadLink({ uri });

const authenticationLink = setContext((query, context) => {
  return new Promise((success) => {
    const request = { scopes: [scope as string] };
    msalApplication
      .acquireTokenSilent(request)
      .then((response) => {
        success({
          ...context,
          headers: {
            ...context?.headers,
            Authorization: `Bearer ${response.accessToken}`,
            'x-csrftoken':
              'YGzG6RnUm2nOTil2fgHuVNjs8mvirQ122DQMnKBWwijrsgQMYgJOdNRbNZpS1Pjg',
          },
        });
      })
      .catch(() => {
        window.location.href = '/login';
      });
  });
});

const httpClient = new ApolloClient({
  cache,
  link: from([authenticationLink, httpLink]),
});

export default httpClient;
