import { gql } from '@apollo/client';

export const ACTION_ITEMS_QUERY = gql`
  query ($clientId: ID!, $page: Int, $size: Int) {
    actionItems(clientId: $clientId, page: $page, size: $size) {
      results {
        id
        type
        dateTime
        meta
        instanceObjectId
        contextObjectId
      }
      count
    }
  }
`;
