import styled, { DefaultTheme } from 'styled-components';
import { ConfirmWindowProps } from './types';

export const Wrapper = styled.div`
  position: relative;
`;

const getBorderColor = (
  theme: DefaultTheme,
  defaultColor: string,
  cancelOnHovered: boolean,
  confirmOnHovered: boolean
) => {
  if (cancelOnHovered) {
    return 'red';
  }
  if (confirmOnHovered) {
    return theme.color.primary.hex();
  }
  return defaultColor;
};

export const ConfirmWindow = styled.div<ConfirmWindowProps>`
  position: absolute;
  left: -10000px;
  background-color: ${({ theme }) => theme.color.contentBackground.hex()};
  color: ${({ theme }) => theme.color.cardText.hex()};
  border-radius: 0.25rem;
  padding: 0.5rem;
  opacity: 0;
  transition: opacity 50ms, border-color 100ms;
  border: 2px solid
    ${({ theme, cancelOnHovered, confirmOnHovered }) =>
      getBorderColor(theme, 'orange', cancelOnHovered, confirmOnHovered)};
  z-index: 9999;
`;
