import styled from 'styled-components';
import ScrollDiv from '../ScrollDiv';

export const Wrapper = styled(ScrollDiv)`
  width: 100%;
  padding: 0.5rem;
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.color.contentBackground.hex()};
  overflow-y: auto;
`;
