import styled from 'styled-components';
import { InputProps } from './types';

export const Input = styled.input<InputProps>`
  height: 38px;
  border: 2px solid
    ${({ hasError }) =>
      hasError ? 'rgba(255, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.05)'};
  border-radius: 0.25rem;
  background-color: ${({ theme }) => theme.color.inputBackground.hex()};
  color: ${({ theme }) => theme.color.inputText.hex()};
  padding-left: 1.25rem;
  width: 100%;
  transition: border-color 150ms;

  &:focus {
    outline: none;
  }

  &:hover {
    transition: border-color 50ms;
    border-color: ${({ theme }) => theme.color.inputBorderColorHover.hex()};
  }
`;

export const Label = styled.label`
  margin-bottom: 0;
  padding-left: 6px;
`;
