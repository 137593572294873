import styled from 'styled-components';
import CardWrapper from '../CardWrapper';

export const Wrapper = styled(CardWrapper)`
  position: fixed;
  top: 80px;
  right: 0;
  height: calc(100vh - 80px);
  width: 410px;
  border-left: ${({ theme }) => theme.border};
  background-color: ${({ theme }) => theme.color.cardBackground.hex()};
  border-radius: 0;
  z-index: 10;
`;

export const NotificationWrapper = styled.div`
  display: block;
  position: relative;
  padding: 1rem 2rem 1rem calc(1rem + 40px + 0.75rem);
  border-radius: 0.25rem;
  transition: background-color 150ms;
  color: ${({ theme }) => theme.color.cardText.hex()};

  &:hover {
    transition: background-color 50ms;
    background-color: ${({ theme }) => theme.color.contentBackground.hex()};
    color: ${({ theme }) => theme.color.cardText.hex()};
    text-decoration: none;
  }

  div.notification-icon {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }

  div.notification-read {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    color: ${({ theme }) => theme.color.primary.brighten(1).hex()};
  }
`;
