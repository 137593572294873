import React from 'react';
import BaseSelectAsync, { AsyncProps } from 'react-select/async';
import { Theme, StylesConfig, NamedProps, ControlProps } from 'react-select';
import { Option } from 'lib/types';
import { useTheme } from 'styled-components';
import chroma from 'chroma-js';
import { ThemeState } from 'lib/redux/types';

const getBorderColor = (
  state: ControlProps<any, any>,
  active: boolean,
  hasError: boolean
) => {
  if (hasError) {
    return 'rgba(255, 0, 0, 0.5)';
  }
  if (state.isFocused || active) {
    return state.theme.colors.primary;
  }
  return 'rgba(0, 0, 0, 0.05)';
};

const styles = <T, IsMulti extends boolean>({
  active,
  height,
  hasError,
  customStyles,
}: {
  active: boolean;
  height?: number;
  hasError: boolean;
  customStyles?: StylesConfig<T, IsMulti>;
}): StylesConfig<T, IsMulti> => ({
  control: (provided, state) => ({
    ...provided,
    height,
    minHeight: height,
    borderRadius: '3rem',
    paddingLeft: '0.5rem',
    borderWidth: state.isFocused ? 2 : 2,
    // eslint-disable-next-line no-nested-ternary
    borderColor: getBorderColor(state, active, hasError),
    backgroundColor: state.theme.colors.background,
    boxShadow: 'none',
    ...customStyles?.control,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.theme.colors.text,
    ...customStyles?.input,
  }),
  menu: (provided, state) => ({
    ...provided,
    borderRadius: '1rem 1rem',
    backgroundColor: state.theme.colors.background,
    overflow: 'hidden',
    ...customStyles?.menu,
  }),
  option: (provided, state) => ({
    ...provided,
    ':hover': {
      backgroundColor: chroma(state.theme.colors.background).darken(0.5).hex(),
    },
    backgroundColor: state.isFocused
      ? chroma(state.theme.colors.background).darken(0.5).hex()
      : state.theme.colors.background,
    color: state.theme.colors.text,
    ...customStyles?.menu,
  }),
  input: (provided, state) => ({
    ...provided,
    color: state.theme.colors.text,
    ...customStyles?.input,
  }),
});

const getTheme =
  (theme: ThemeState, height: number) => (defaultTheme: Theme) => ({
    ...defaultTheme,
    controlHeight: height,
    colors: {
      ...defaultTheme.colors,
      primary: theme.color.primary.hex(),
      text: theme.color.cardText.hex(),
      background: theme.color.inputBackground.hex(),
    },
  });

const SelectAsync = <T extends Option, IsMulti extends boolean = false>({
  active = false,
  customStyles = {},
  height = 38,
  loadOptions,
  hasError = false,
  ...props
}: NamedProps<T, IsMulti> &
  AsyncProps<T> & {
    active?: boolean;
    hasError?: boolean;
    customStyles?: any;
    height?: number;
  }) => {
  const theme = useTheme();
  return (
    <BaseSelectAsync
      styles={styles<T, IsMulti>({ active, customStyles, hasError })}
      theme={getTheme(theme, height)}
      loadOptions={loadOptions}
      {...props}
    />
  );
};

export default SelectAsync;
