import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import React, { useCallback, useEffect } from 'react';
import { Form, Field } from 'react-final-form';
// eslint-disable-next-line import/extensions
import { SchedulerEventModel } from '@bryntum/schedulerpro/schedulerpro.umd.js';
import { JobReportTemplate, JobVisitWorkType } from 'lib/types';
import { Col, Row } from 'reactstrap';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { useLazyQuery, useMutation } from '@apollo/client';
import Loading from '../Loading';
import SidePanel from '../SidePanel';
import JobVisitAssigneeField from '../JobVisitAssigneeField';
import TimeField from '../TimeField';
import SimpleButton from '../SimpleButton';
import {
  DeleteJobReportMutationData,
  DeleteJobReportMutationVariables,
  JobVisitQueryData,
  JobVisitQueryVariables,
  JobVisitSchedulerCreateSidePanelProps,
} from './types';
import CharField from '../CharField';
import {
  deleteJobReportsMutationOnCompleted,
  jobVisitQueryOnCompleted,
  setSchedulerReadOnly,
  setSchedulerSelectMode,
  useReducerContext,
} from '../ClientJob/reducer';
import { Small } from '../Typography';
import { DELETE_JOB_REPORT_MUTATION, JOB_VISIT_QUERY } from './query';
import JobVisitWorkTypeField from '../JobVisitWorkTypeField';
import ClientJobReportCard from '../ClientJobReportCard';
import ClientJobReportsButton from '../ClientJobReportsButton';
import ScrollDiv from '../ScrollDiv';

// TODO: Add read only mode.

const JobVisitSchedulerCreateSidePanel = ({
  schedulerRef,
  isOpen,
  eventRecord,
  toggle,
  visitOnChange,
}: JobVisitSchedulerCreateSidePanelProps) => {
  const [{ jobVisit }, dispatch] = useReducerContext();

  const [getJobVisit, { loading }] = useLazyQuery<
    JobVisitQueryData,
    JobVisitQueryVariables
  >(JOB_VISIT_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      dispatch(jobVisitQueryOnCompleted(data));
    },
  });

  useEffect(() => {
    if (!isOpen || !eventRecord) return;
    getJobVisit({ variables: { jobVisitId: eventRecord.id as string } });
  }, [eventRecord, getJobVisit, isOpen]);

  const getEventRecordObject = (): SchedulerEventModel | null => {
    if (!schedulerRef.current || !eventRecord) return null;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return schedulerRef.current.instance.eventStore.getById(eventRecord);
  };

  const handleOnSubmit = (values: any) => {
    if (!eventRecord) return;
    const data = {
      label: values.label,
      dateTimeStart: values.dateTimeStart,
      dateTimeEnd: values.dateTimeEnd,
      userId: values.user.id,
      reportTemplates: values.createNewReports
        ? values.createNewReports.templates.map(
            (template: JobReportTemplate) => template.id
          )
        : [],
      // reports: [],
      workTypes: values.workTypes.map(
        (workType: JobVisitWorkType) => workType.id
      ),
    };
    visitOnChange({
      id: eventRecord.id.toString(),
      ...data,
    });
    toggle(false);
  };

  const handleOnCancel = () => {
    if (!schedulerRef.current) return;
    if (!eventRecord) return;
    dispatch(setSchedulerReadOnly(false));
    dispatch(setSchedulerSelectMode(undefined));
    toggle(false);
  };

  const [deleteJobReport] = useMutation<
    DeleteJobReportMutationData,
    DeleteJobReportMutationVariables
  >(DELETE_JOB_REPORT_MUTATION);

  const handleJobReportOnDelete = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    if (!jobVisit) return;
    const { value: jobReportId } = event.currentTarget;
    const { data } = await deleteJobReport({
      variables: { jobVisitId: jobVisit.id, jobReportId },
    });
    if (!data || !data.deleteJobReport.deleted) return;
    dispatch(deleteJobReportsMutationOnCompleted(data));
  };

  const renderTitle = useCallback(() => {
    if (!jobVisit) return <>Edit visit</>;
    return (
      <div>
        <div>{jobVisit.id}</div>
      </div>
    );
  }, [jobVisit]);

  const readOnly = jobVisit?.status?.name !== 'SCHEDULED';

  return (
    <>
      <SidePanel
        style={{ width: 'auto' }}
        title={renderTitle()}
        isOpen={isOpen}
        wrapperStyle={{
          height: isOpen && eventRecord && !loading ? 434 : 24,
          transition: 'height 100ms',
        }}
        toggle={handleOnCancel}
      >
        {eventRecord && !loading ? (
          <Form initialValues={jobVisit} onSubmit={handleOnSubmit}>
            {({ handleSubmit, values }) => (
              <form onSubmit={handleSubmit}>
                <Row className="position-relative">
                  <Col style={{ minWidth: 400, height: 400 }}>
                    <div className="mb-3">
                      <Field
                        name="label"
                        disabled={readOnly}
                        component={CharField}
                        parse={(value) => {
                          const eventRecordObject = getEventRecordObject();
                          if (eventRecordObject) {
                            eventRecordObject.name = value;
                          }
                          return value;
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <Field
                        name="user"
                        disabled={readOnly}
                        component={JobVisitAssigneeField}
                        schedulerRef={schedulerRef}
                        parse={(value) => {
                          const eventRecordObject = getEventRecordObject();
                          if (eventRecordObject) {
                            eventRecordObject.resourceId = value.id;
                          }
                          return value;
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <Field
                        label="Start time"
                        name="dateTimeStart"
                        disabled={readOnly}
                        component={TimeField}
                        parse={(value) => {
                          const eventRecordObject = getEventRecordObject();
                          if (eventRecordObject) {
                            eventRecordObject.startDate = value;
                            eventRecordObject.endDate = values.dateTimeEnd;
                          }
                          return value;
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <Field
                        label="End time"
                        name="dateTimeEnd"
                        disabled={readOnly}
                        component={TimeField}
                        parse={(value) => {
                          const eventRecordObject = getEventRecordObject();
                          if (eventRecordObject) {
                            eventRecordObject.startDate = values.dateTimeStart;
                            eventRecordObject.endDate = value;
                          }
                          return value;
                        }}
                      />
                    </div>
                    <div className="mb-4">
                      <div>
                        <Small className="ml-2">Work types (optional)</Small>
                      </div>
                      <Field
                        disabled={readOnly}
                        label="Work types (optional)"
                        name="workTypes"
                        component={JobVisitWorkTypeField}
                      />
                    </div>
                  </Col>
                  <Col
                    style={{
                      minWidth: 400,
                      minHeight: 0,
                      height: 400,
                    }}
                  >
                    <div className="d-flex flex-column h-100">
                      <div className="mb-3">
                        <div className="d-flex justify-content-between">
                          <Small style={{ lineHeight: '34px' }}>Parent</Small>
                          <small>
                            <SimpleButton icon={faPlus}>
                              Add parent
                            </SimpleButton>
                          </small>
                        </div>
                        {jobVisit?.parent ? (
                          <div>{jobVisit.parent.label}</div>
                        ) : (
                          <div>
                            <small>
                              This is a root visit therefore it has no parent
                            </small>
                          </div>
                        )}
                      </div>
                      {(jobVisit?.children ?? []).length > 0 && (
                        <div className="mb-3">
                          <div>
                            <Small>Children</Small>
                          </div>
                        </div>
                      )}
                      <div
                        className="d-flex flex-column flex-grow-1"
                        style={{ minHeight: 0 }}
                      >
                        <div className="d-flex justify-content-between">
                          <Small style={{ lineHeight: '34px' }}>Reports</Small>
                          <small>
                            <ClientJobReportsButton jobVisit={jobVisit} />
                          </small>
                        </div>
                        {jobVisit && (
                          <ScrollDiv
                            className="flex-grow-1"
                            style={{ minHeight: 0, overflowY: 'auto' }}
                          >
                            {jobVisit.reports.map((report, index) => {
                              const last =
                                index === jobVisit.reports.length - 1;
                              return (
                                <ClientJobReportCard
                                  key={report.id}
                                  jobReport={report}
                                  last={last}
                                  onDelete={handleJobReportOnDelete}
                                />
                              );
                            })}
                          </ScrollDiv>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="d-flex justify-content-end">
                  <SimpleButton icon={faCheck}>Save</SimpleButton>
                </div>
              </form>
            )}
          </Form>
        ) : (
          <div style={{ width: 770 }}>
            <Loading className="mr-1" />
            Loading...
          </div>
        )}
      </SidePanel>
    </>
  );
};

export default JobVisitSchedulerCreateSidePanel;
