import React from 'react';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons/faChevronDown';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { getLogIcon, getLogText, getMetaText } from 'lib/logs';
import classNames from 'classnames';
import { HistoryItem } from './styled';
import { LogBlockProps } from './types';

const LogBlock = ({
  logBlock,
  first,
  last,
  invertColors,
  simple,
}: LogBlockProps) => {
  return (
    <HistoryItem
      key={logBlock.id}
      className="d-flex pl-3 pr-3"
      first={first}
      last={last}
      invertColors={invertColors}
    >
      <div>
        <span className="mr-3 text-50 history-time text-nowrap">
          {DateTime.fromISO(logBlock.dateTime).toFormat('dd MMM HH:mm:ss')}:
        </span>
      </div>
      <div className="flex-grow-1">
        <div>
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-nowrap">
              {!simple && (
                <FontAwesomeIcon
                  className="mr-1"
                  style={{ marginTop: 3 }}
                  icon={
                    logBlock.children.length > 0 || logBlock.meta
                      ? faChevronDown
                      : faChevronRight
                  }
                  fixedWidth
                />
              )}
              <span>{getLogText(logBlock)}</span>
            </div>
            <div className={classNames({ 'ml-2': simple })}>
              <FontAwesomeIcon icon={faCheckCircle} fixedWidth />
            </div>
          </div>
        </div>
        {!simple && logBlock.meta && (
          <div
            className="d-flex justify-content-between"
            style={{ marginLeft: 7 }}
          >
            <div className="text-75">
              <div className="d-flex position-relative">
                <div
                  style={{
                    width: 28,
                    height: 14,
                    borderLeft: '1px solid rgb(150, 150, 150)',
                    borderBottom: '1px solid rgb(150, 150, 150)',
                    borderBottomLeftRadius: '0.45rem',
                  }}
                />
                {logBlock.children.length > 0 && (
                  <div
                    style={{
                      position: 'absolute',
                      width: 28,
                      height: '100%',
                      borderLeft: '1px solid rgb(150, 150, 150)',
                    }}
                  />
                )}
                <span className="ml-1 pt-1">{getMetaText(logBlock)}</span>
              </div>
            </div>
            <div className="d-flex">
              <div className="text-75 pt-1">
                <FontAwesomeIcon icon={faCheckCircle} fixedWidth />
              </div>
            </div>
          </div>
        )}
        {!simple &&
          logBlock.children.map((logItem, index) => {
            const lastChild = index === logBlock.children.length - 1;
            return (
              <div
                key={logItem.id}
                className="d-flex justify-content-between"
                style={{ marginLeft: 7 }}
              >
                <div className="text-75">
                  <div className="d-flex position-relative">
                    <div
                      style={{
                        width: 28,
                        height: 14,
                        borderLeft: '1px solid rgb(150, 150, 150',
                        borderBottom: '1px solid rgb(150, 150, 150)',
                        borderBottomLeftRadius: '0.45rem',
                      }}
                    />
                    {!lastChild && (
                      <div
                        style={{
                          position: 'absolute',
                          width: 28,
                          height: '100%',
                          borderLeft: '1px solid rgb(150, 150, 150)',
                        }}
                      />
                    )}
                    <span className="ml-1 pt-1">{getLogText(logItem)}</span>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="text-75 pt-1">{getLogIcon(logItem)}</div>
                </div>
              </div>
            );
          })}
      </div>
    </HistoryItem>
  );
};

export default LogBlock;
