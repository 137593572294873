import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  right: 1.5rem;
  top: 4.5rem;
  z-index: 10;
  font-size: 0.65rem;
`;

export const LegendItemWrapper = styled.div`
  .text-75 {
    color: ${({ theme }) => theme.color.cardText.alpha(0.75).hex()};
  }
`;
