import { gql } from '@apollo/client';

export const USERS_QUERY = gql`
  query ($searchTerm: String) {
    users(searchTerm: $searchTerm) {
      id
      name
    }
  }
`;

export const UPDATE_USER_GROUP_MUTATION = gql`
  mutation ($userGroupId: ID!, $data: UpdateUserGroupInput!) {
    updateUserGroup(userGroupId: $userGroupId, data: $data) {
      userGroup {
        id
        name
        namePlural
      }
    }
  }
`;
