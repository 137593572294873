import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useClient, useSortTable } from 'lib/hooks';
import { JobGroup } from 'lib/types';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
import { useTheme } from 'styled-components';
import { TableHeadNames } from '../ClientJobsReactiveTable/types';
import {
  JobGroupsQueryData,
  JobGroupsQueryVariables,
} from '../ClientJobsPPM/types';
import { JOB_GROUPS_QUERY } from './query';
import CardWrapper from '../CardWrapper';
import ClientJobsReactiveTable from '../ClientJobsReactiveTable';
import Button from '../Button';
import ClientJobsReactiveTableFilter from '../ClientJobsReactiveTableFilter';
import PortalBreadcrumb from '../PortalBreadcrumb';
import TablePageIndicator from '../TablePageIndicator';

const ClientJobsReactive = () => {
  const client = useClient();
  const [page, setPage] = useState(1);
  const [refetching, setRefetching] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [jobGroups, setJobGroups] = useState<{
    results: JobGroup[];
    count: number;
  }>({
    count: 0,
    results: [],
  });
  const { refetch } = useQuery<JobGroupsQueryData, JobGroupsQueryVariables>(
    JOB_GROUPS_QUERY,
    {
      fetchPolicy: 'no-cache',
      variables: {
        clientId: client.id,
        page: 1,
        sort: { name: 'reference', order: false },
      },
      onCompleted: (data) => {
        setJobGroups(data.jobGroups);
      },
    }
  );

  const tableHeadNames: TableHeadNames = [
    'reference',
    'jobs',
    'name',
    'site',
    'dateTimeStart',
    'dateTimeEnd',
  ];
  const [sortState, handleSort] = useSortTable<TableHeadNames>(tableHeadNames, {
    defaultActiveName: 'reference',
    onSort: (sort) => {
      refetch({ sort }).then((response) => {
        setJobGroups(response.data.jobGroups);
      });
    },
  });

  const handleSearchTermOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target;
    setSearchTerm(value);
    setRefetching(true);
    refetch({ searchTerm: value }).then((response) => {
      setJobGroups(response.data.jobGroups);
      setRefetching(false);
    });
  };

  const handleChangePage = (delta: number) => {
    setRefetching(true);
    setPage((prevPage) => {
      const newPage = prevPage + delta;
      refetch({ page: newPage }).then((response) => {
        setJobGroups(response.data.jobGroups);
        setRefetching(false);
      });
      return newPage;
    });
  };

  const theme = useTheme();
  return (
    <>
      <PortalBreadcrumb>
        <h4 className="text-capitalize">Reactive Jobs</h4>
      </PortalBreadcrumb>
      <div className="h-100">
        <CardWrapper className="h-100 px-0 py-3">
          <ClientJobsReactiveTableFilter
            jobGroups={jobGroups}
            refetching={refetching}
            searchTerm={searchTerm}
            searchTermOnChange={handleSearchTermOnChange}
          />
          <ClientJobsReactiveTable
            jobGroups={jobGroups}
            sortState={sortState}
            sort={handleSort}
          />
          <div
            className="d-flex justify-content-between px-3 pt-3"
            style={{ borderTop: theme.border }}
          >
            <div className="d-flex flex-column justify-content-center">
              <TablePageIndicator page={page} count={jobGroups.count} />
            </div>
            <div>
              <Button
                className="mr-3"
                icon={faChevronLeft}
                iconSide="left"
                disabled={page === 1}
                onClick={() => handleChangePage(-1)}
              >
                Previous page
              </Button>
              <Button
                disabled={jobGroups?.count <= 10 || false}
                icon={faChevronRight}
                iconSide="right"
                onClick={() => handleChangePage(1)}
              >
                Next page
              </Button>
            </div>
          </div>
        </CardWrapper>
      </div>
    </>
  );
};

export default ClientJobsReactive;
