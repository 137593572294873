import { gql } from '@apollo/client';

export const SEARCH_USERS_QUERY = gql`
  query ($searchTerm: String) {
    users(searchTerm: $searchTerm) {
      id
      name
      email
    }
  }
`;

export const SYNC_USERS_MUTATION = gql`
  mutation {
    syncUsers {
      users {
        id
        name
        email
      }
    }
  }
`;
