import { faUsers } from '@fortawesome/pro-duotone-svg-icons/faUsers';
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import React, { Reducer, useCallback, useMemo, useReducer } from 'react';
import { AnyAction } from 'redux';
import { useMutation, useQuery } from '@apollo/client';
import { useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSessionId, useUser, useWebsocket } from '../../lib/hooks';
import { Chat } from '../../lib/types';
import CommunicationsChat from '../CommunicationsChat';
import CommunicationsChatInput from '../CommunicationsChatInput';
import SimpleButton from '../SimpleButton';
import { CREATE_CHAT_MUTATION, INITIAL_QUERY } from './query';
import reducer, {
  createChatMutationOnCompleted,
  initialQueryOnCompleted,
  initialState,
  onCreateChat,
  onWebsocketEvent,
  ReducerContext,
  setChat,
  useReducerContext,
} from './reducer';
import {
  CommunicationChatsProps,
  CreateChatMutationData,
  CreateChatMutationVariables,
  CreateJobChatProps,
  InitialQueryData,
  InitialQueryVariables,
  State,
  WebsocketEventData,
} from './types';
import { Wrapper } from './styled';
import ScrollDiv from '../ScrollDiv';

const CreateJobChat = ({ job }: CreateJobChatProps) => {
  const theme = useTheme();
  const [{ input }, dispatch] = useReducerContext();

  const [createChat] = useMutation<
    CreateChatMutationData,
    CreateChatMutationVariables
  >(CREATE_CHAT_MUTATION);

  const handleOnCommit = async () => {
    if (!input.value) return;
    dispatch(onCreateChat());
    const { data } = await createChat({
      variables: { jobId: job.id, body: input.value },
    });
    if (data) dispatch(createChatMutationOnCompleted(data));
  };

  return (
    <div className="h-100 d-flex flex-column">
      <div className="p-2 " style={{ borderBottom: theme.border }}>
        <h5 className="mb-0">Create group chat</h5>
        <small style={{ color: theme.color.cardText.alpha(0.75).hex() }}>
          Send a message to start a chat for this job
        </small>
      </div>
      <ScrollDiv className="flex-grow-1 m-2" style={{ overflowY: 'scroll' }} />
      <div className="p-2">
        <CommunicationsChatInput onCommit={handleOnCommit} />
      </div>
    </div>
  );
};

const CommunicationChats = ({ job }: CommunicationChatsProps) => {
  const user = useUser();
  const theme = useTheme();
  const sessionId = useSessionId();

  const [state, dispatch] = useReducer<Reducer<State, AnyAction>>(
    reducer,
    initialState
  );

  const { chats, chat } = state;

  const { loading } = useQuery<InitialQueryData, InitialQueryVariables>(
    INITIAL_QUERY,
    {
      fetchPolicy: 'no-cache',
      variables: { jobId: job?.id },
      onCompleted: (data) => {
        dispatch(initialQueryOnCompleted(data));
      },
    }
  );

  const onEvent = useCallback(
    (event) => {
      if (!event.data) return;
      dispatch(onWebsocketEvent(event.data, sessionId as string, user));
    },
    [sessionId, user]
  );

  const chatIds = useMemo(
    () =>
      [chat, ...chats]
        .filter((innerChat) => !!innerChat)
        .map((innerChat) => (innerChat as Chat).id)
        .join(','),
    [chat, chats]
  );

  useWebsocket<WebsocketEventData>('chats/', { chats: chatIds }, onEvent, [
    chatIds,
  ]);

  const handleChatIconOnClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    const { value: chatId } = event.currentTarget;
    if (chatId !== chat?.id) {
      dispatch(setChat(chatId));
    }
  };

  if (!job) return null;
  return (
    <ReducerContext.Provider value={[state, dispatch]}>
      <Wrapper>
        <div className="d-flex">
          <div style={{ borderRight: theme.border }}>
            <div
              className="d-flex justify-content-center p-2"
              style={{ borderBottom: theme.border }}
            >
              <SimpleButton
                className="d-block center p-0"
                backgroundColor={theme.color.contentBackground.alpha(0.5).hex()}
                style={{ width: 48, height: 48 }}
                value={job.chat?.id}
                onClick={handleChatIconOnClick}
              >
                <FontAwesomeIcon
                  className="icon"
                  icon={faUsers}
                  style={{ fontSize: '22px' }}
                />
              </SimpleButton>
            </div>
            <div className="d-flex justify-content-center p-2">
              <div>
                {chats
                  .filter((innerChat) => innerChat.id !== job.chat?.id)
                  .map((innerChat) => (
                    <SimpleButton
                      key={innerChat.id}
                      className="d-block center p-0 mb-2"
                      backgroundColor={theme.color.contentBackground
                        .alpha(0.5)
                        .hex()}
                      style={{ width: 48, height: 48 }}
                      active={chat?.id === innerChat.id}
                      value={innerChat.id}
                      onClick={handleChatIconOnClick}
                    >
                      JW
                    </SimpleButton>
                  ))}
                <SimpleButton
                  className="center p-0"
                  backgroundColor={theme.color.contentBackground
                    .alpha(0.5)
                    .hex()}
                  style={{ width: 48, height: 48 }}
                >
                  <FontAwesomeIcon
                    className="icon mt-1"
                    icon={faPlus}
                    style={{ fontSize: '22px' }}
                  />
                </SimpleButton>
              </div>
            </div>
          </div>
          <div className="flex-grow-1" style={{ height: 400 }}>
            {!loading && (
              <div className="h-100">
                {chat ? (
                  <CommunicationsChat chat={chat} job={job} />
                ) : (
                  <CreateJobChat job={job} />
                )}
              </div>
            )}
          </div>
        </div>
      </Wrapper>
    </ReducerContext.Provider>
  );
};

export default CommunicationChats;
