import React from 'react';
import classNames from 'classnames';
import { useTheme } from 'styled-components';
import CardWrapper from '../CardWrapper';
import ClientJobIssueStatusPill from '../ClientJobIssueStatusPill';
import ClientJobIssueCardBody from '../ClientJobIssueCardBody';
import { ClientJobIssueHeaderWrapper } from './styled';
import { ClientJobIssueHeaderBlockProps } from './types';

const ClientJobIssueHeaderBlock = ({
  innerRef,
  issue,
}: ClientJobIssueHeaderBlockProps) => {
  const theme = useTheme();

  return (
    <ClientJobIssueHeaderWrapper ref={innerRef}>
      <CardWrapper className="p-0">
        <div
          className="d-flex"
          style={{ backgroundColor: theme.color.contentBackground.hex() }}
        >
          <div>
            <span className="mono text-50 mx-2" style={{ lineHeight: '32px' }}>
              #{issue.number}
            </span>
          </div>
          <ClientJobIssueStatusPill
            className="rounded-0"
            status={issue.status}
          />
          <span
            className={classNames('ml-2', {
              'font-weight-bold': issue.status === 'OPEN',
            })}
            style={{ lineHeight: '32px' }}
          >
            {issue.issueTypeDisplay}
          </span>
        </div>
        <div className="p-3" style={{ fontSize: '0.9rem' }}>
          <ClientJobIssueCardBody {...issue} />
        </div>
      </CardWrapper>
      <div className="line-decoration" />
    </ClientJobIssueHeaderWrapper>
  );
};

export default ClientJobIssueHeaderBlock;
