import React, { useState } from 'react';
import { OptionsType, ValueType } from 'react-select';
import { Duration, DurationObject } from 'luxon';
import Select from '../Select';
import { DurationPickerProps, Option } from './types';
import SimpleButton from '../SimpleButton';
import { Input } from '../CharField/styled';

const hourOptions: OptionsType<Option> = [
  { label: '1 hour', value: { hours: 1 } },
  { label: '2 hours', value: { hours: 2 } },
];

const minuteOptions: OptionsType<Option> = [
  { label: '0 minutes', value: { minutes: 0 } },
  { label: '15 minutes', value: { minutes: 15 } },
  { label: '30 minutes', value: { minutes: 30 } },
  { label: '45 minutes', value: { minutes: 45 } },
];

const parseDurationString = (rawDurationString: string): DurationObject => {
  return rawDurationString.split(', ').reduce((obj, segment) => {
    const [rawValue, unit] = segment.split(' ');
    return {
      ...obj,
      [unit]: parseInt(rawValue, 10),
    };
  }, {});
};

const renderDurationString = (durationObject: DurationObject) => {
  return Object.entries(durationObject)
    .reduce((ret, [innerValue, unit]) => `${ret}, ${unit} ${innerValue}`, '')
    .replace(', ', '');
};

const DurationPicker = ({ value, onChange }: DurationPickerProps) => {
  const [mode, setMode] = useState<'select' | 'manual'>('select');
  const [durationString, setDurationString] = useState(
    renderDurationString(value)
  );
  const [error, setError] = useState<string>();

  const handleChangeModeOnClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    const { value: nextMode } = event.currentTarget;
    setMode(nextMode as 'select' | 'manual');
  };

  const handleHourOnChange = (selected: ValueType<Option, false>) => {
    let newValue;
    if (!selected?.value) {
      newValue = { ...value, hour: 0 };
    } else {
      newValue = { ...value, ...selected.value };
    }
    onChange(newValue);
    setDurationString(renderDurationString(newValue));
  };

  const handleMinuteOnChange = (selected: ValueType<Option, false>) => {
    let newValue;
    if (!selected?.value) {
      newValue = { ...value, minute: 0 };
    } else {
      newValue = { ...value, ...selected.value };
    }
    onChange(newValue);
    setDurationString(renderDurationString(newValue));
  };

  const handleManualDurationOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value: newDurationString } = event.target;
    setDurationString(newDurationString);
  };

  const handleManualDurationOnKeyPress = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    switch (event.key) {
      case 'Enter': {
        event.preventDefault();
        const parsedDuration = parseDurationString(durationString);
        try {
          const cleanedParsedDuration = (Duration.fromObject(parsedDuration).as(
            'seconds'
          ) >= 0
            ? Duration.fromObject(parsedDuration)
            : Duration.fromObject({ hours: 0, minutes: 0 })
          ).toObject();
          onChange(cleanedParsedDuration);
          setDurationString(renderDurationString(cleanedParsedDuration));
          setError('');
        } catch {
          setError('Invalid input');
        }
        break;
      }
      default:
    }
  };

  return (
    <div>
      <div className="mb-2">
        <SimpleButton
          className="mr-2"
          active={mode === 'select'}
          value="select"
          onClick={handleChangeModeOnClick}
        >
          Select a duration
        </SimpleButton>
        <SimpleButton
          active={mode === 'manual'}
          value="manual"
          onClick={handleChangeModeOnClick}
        >
          Enter manually
        </SimpleButton>
      </div>
      {mode === 'select' && (
        <div className="d-flex">
          <div className="mr-2" style={{ minWidth: 200 }}>
            <Select
              menuPortalTarget={document.body}
              options={hourOptions}
              value={{
                label: `${value.hours || value.hour || 0} hours`,
                value: { hours: value.hours || value.hour },
              }}
              onChange={handleHourOnChange}
            />
          </div>
          <div style={{ minWidth: 200 }}>
            <Select
              menuPortalTarget={document.body}
              options={minuteOptions}
              value={{
                label: `${value.minutes || value.minute || 0} minutes`,
                value: { minutes: value.minutes || value.minute },
              }}
              onChange={handleMinuteOnChange}
            />
          </div>
        </div>
      )}
      {mode === 'manual' && (
        <div className="d-flex">
          <Input
            className="flex-grow-1"
            value={durationString}
            placeholder="X hours, Y minutes"
            onChange={handleManualDurationOnChange}
            onKeyPress={handleManualDurationOnKeyPress}
          />
          {error && (
            <div className="text-nowrap ml-2">
              <p className="mb-0 text-danger mt-1" style={{ lineHeight: 0.9 }}>
                {error}
              </p>
              <small>
                <span className="text-75">Must be of form</span> X hours, Y
                minutes{' '}
              </small>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DurationPicker;
