import React from 'react';
import { ClientJobIssueMessageBlockProps } from '../ClientJobIssue/types';
import SlateViewer from '../SlateViewer';
import ClientJobIssueBlock from '../ClientJobIssueBlock';

const ClientJobIssueMessageBlock = ({
  innerRef,
  index,
  message,
}: ClientJobIssueMessageBlockProps) => {
  return (
    <ClientJobIssueBlock
      index={index}
      innerRef={innerRef}
      user={message.user}
      dateTime={message.dateTimeCreated}
      header={
        <div className="center-vertically">
          <small>
            <strong>{message.user.name}</strong>{' '}
            <span className="text-75">left a message</span>
          </small>
        </div>
      }
    >
      <div className="p-3">
        <SlateViewer value={message.body} />
      </div>
    </ClientJobIssueBlock>
  );
};

export default ClientJobIssueMessageBlock;
