import React from 'react';
import { DateTime } from 'luxon';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons/faSpinnerThird';
import { faCircle } from '@fortawesome/pro-regular-svg-icons/faCircle';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle';
import { LogItem } from '../types';

export const getLogIcon = (logItem: LogItem) => {
  let icon;
  switch (logItem.status) {
    case 'INITIATED': {
      if (
        DateTime.fromISO(logItem.dateTime) < DateTime.local().minus({ hour: 1 })
      ) {
        icon = faCircle;
      } else {
        icon = faSpinnerThird;
      }
      break;
    }
    case 'SUCCESS':
      icon = faCheckCircle;
      break;
    case 'ERROR':
      icon = faTimesCircle;
      break;
    default:
      return null;
  }
  return (
    <FontAwesomeIcon
      className={classNames('log-item-icon', {
        error: logItem.status === 'ERROR',
      })}
      icon={icon}
      fixedWidth
      spin={
        logItem.status === 'INITIATED' &&
        DateTime.fromISO(logItem.dateTime) > DateTime.local().minus({ hour: 1 })
      }
    />
  );
};

export default getLogIcon;
