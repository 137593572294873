import { gql } from '@apollo/client';

export const JOBS_QUERY = gql`
  query ($clientId: ID!, $jobGroupId: ID!, $sort: Sort) {
    jobs(clientId: $clientId, jobGroupId: $jobGroupId, sort: $sort) {
      results {
        id
        reference
        name
        dateTimeStart
        dateTimeEnd
        status {
          id
          name
          nameDisplay
          flag {
            id
            name
            nameDisplay
            color
            icon
          }
        }
      }
    }
  }
`;

export const JOB_VISITS_QUERY = gql`
  query ($clientId: ID!, $jobId: ID) {
    jobVisits(clientId: $clientId, jobId: $jobId) {
      id
      label
      dateTimeStart
      dateTimeEnd
      user {
        id
        name
      }
    }
  }
`;
