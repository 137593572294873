import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons/faSpinnerThird';
import { LoadingProps } from './types';

const Loading = ({ ...props }: LoadingProps) => {
  return <FontAwesomeIcon {...props} icon={faSpinnerThird} spin fixedWidth />;
};

export default Loading;
