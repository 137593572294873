import { gql } from '@apollo/client';

export const JOB_GROUP_QUERY = gql`
  query ($jobGroupReference: String!) {
    jobGroup(jobGroupReference: $jobGroupReference) {
      id
      reference
      name
      averageJobInterval
      jobs {
        id
        reference
        name
        positionInJobGroup
        isCurrent
        dateTimeCreated
        dateTimeStart
        dateTimeEnd
        targetDateTimeStart
        targetDateTimeStartMet
        targetDateTimeEnd
        originalTargetDateTimeStart
        actualDateTimeStart
        actualDateTimeEnd
        viewDateTimeStart
        viewDateTimeEnd
        dateTimeStartDiff
        dateTimeEndDiff
        client {
          slug
        }
        priority {
          id
          code
          color
          responseName
          completionName
        }
        workType
        status {
          id
          name
          nameDisplay
          flag {
            id
            color
            name
          }
        }
        site {
          id
          name
          location {
            id
            address
            point {
              latitude
              longitude
            }
          }
          client {
            id
            slug
          }
        }
        assignees {
          id
          name
        }
      }
      nextUnassignedJob {
        id
        reference
        viewDateTimeStart
        viewDateTimeEnd
      }
      nextJobOfInterest {
        id
        reference
        viewDateTimeStart
        viewDateTimeEnd
      }
      jobStatuses {
        incoming
        assigned
        completed
      }
      assignees {
        id
        initials
        name
      }
    }
  }
`;
