import { gql } from '@apollo/client';

export const JOB_VISIT_QUERY = gql`
  query ($id: ID!) {
    jobVisit(jobVisitId: $id) {
      id
      dateTimeStart
      dateTimeEnd
      number
      reports {
        id
        name
        number
        template {
          id
          name
        }
      }
      user {
        id
        initials
        name
      }
    }
  }
`;

export const CREATE_JOB_VISIT_MUTATION = gql`
  mutation (
    $sessionId: ID!
    $clientId: ID!
    $jobId: ID!
    $data: JobVisitCreateInput!
  ) {
    createJobVisit(
      sessionId: $sessionId
      clientId: $clientId
      jobId: $jobId
      data: $data
    ) {
      jobVisit {
        id
        dateTimeStart
        dateTimeEnd
        label
        number
        status {
          id
          name
          nameDisplay
          color
        }
        user {
          id
          name
        }
        job {
          status {
            id
            name
            nameDisplay
            flag {
              id
              name
              color
            }
          }
        }
      }
      jobVisitNumbers {
        id
        number
      }
      jobReports {
        id
        name
        status
        template {
          id
          name
        }
        versions {
          id
          versionNumber
        }
      }
      jobIssues {
        id
        status
      }
      logBlock {
        id
        status
        type
        dateTime
        instanceContentType
        data
        meta

        children {
          id
          status
          type
          dateTime
          instanceContentType
          data
          meta
        }
      }
    }
  }
`;

export const UPDATE_JOB_VISIT_MUTATION = gql`
  mutation ($jobVisitId: ID!, $sessionId: ID!, $data: JobVisitUpdateInput!) {
    updateJobVisit(
      jobVisitId: $jobVisitId
      sessionId: $sessionId
      data: $data
    ) {
      jobVisit {
        id
        dateTimeStart
        dateTimeEnd
        label
        number
        status {
          id
          name
          nameDisplay
          color
        }
        user {
          id
          name
        }
      }
      jobVisitNumbers {
        id
        number
      }
      jobIssues {
        id
      }
      logBlock {
        id
        status
        type
        dateTime
        instanceContentType
        data
        meta

        children {
          id
          status
          type
          dateTime
          instanceContentType
          data
          meta
        }
      }
    }
  }
`;

export const DELETE_JOB_VISIT_MUTATION = gql`
  mutation ($jobVisitId: ID!, $sessionId: ID!) {
    deleteJobVisit(jobVisitId: $jobVisitId, sessionId: $sessionId) {
      isDeleted
      deletedId
      jobVisitNumbers {
        id
        number
      }
      job {
        status {
          id
          name
          nameDisplay
          flag {
            id
            name
            color
          }
        }
      }
      logBlock {
        id
        status
        type
        dateTime
        instanceContentType
        data
        meta
        children {
          id
          status
          type
          dateTime
          instanceContentType
          data
          meta
        }
      }
    }
  }
`;
