import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/pro-duotone-svg-icons/faPaperclip';
import { useUser } from '../../lib/hooks';
import {
  ChatMessageAttachmentComponentProps,
  ChatMessageAttachmentProps,
  CommunicationsChatMessageAttachmentRouterProps,
} from './types';
import { ChatMessageAttachmentWrapper } from './styled';
import { Small } from '../Typography';

const ChatMessageAttachment = ({ children }: ChatMessageAttachmentProps) => {
  return (
    <ChatMessageAttachmentWrapper>
      <div className="mb-1">
        <FontAwesomeIcon className="mr-1" icon={faPaperclip} />
        <Small>Attachment</Small>
      </div>
      <div>{children}</div>
    </ChatMessageAttachmentWrapper>
  );
};

const JobVisitHelpRequestUserThrough = ({
  chatMessageAttachment,
}: ChatMessageAttachmentComponentProps) => {
  const user = useUser();
  const { instance } = chatMessageAttachment;
  const { helpRequest, user: requested } = instance.data;

  return (
    <ChatMessageAttachment>
      <div>
        {user.id === helpRequest.requester.id
          ? 'You'
          : helpRequest.requester.name}{' '}
        requested help from {user.id === requested.id ? 'You' : requested.name}
      </div>
    </ChatMessageAttachment>
  );
};

const CommunicationsChatMessageAttachmentRouter = ({
  chatMessageAttachment,
}: CommunicationsChatMessageAttachmentRouterProps) => {
  switch (chatMessageAttachment.instance.type) {
    case 'JOB_VISIT_HELP_REQUEST_USER_THROUGH':
      return (
        <JobVisitHelpRequestUserThrough
          chatMessageAttachment={chatMessageAttachment}
        />
      );
    default:
      return null;
  }
};

export default CommunicationsChatMessageAttachmentRouter;
