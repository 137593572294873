import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser';
import { faUsers } from '@fortawesome/pro-solid-svg-icons/faUsers';
import React, { Reducer, useReducer } from 'react';
import { Row, Col } from 'reactstrap';
import { AnyAction } from 'redux';
import { useTheme } from 'styled-components';
import { useQuery } from '@apollo/client';
import { useClient } from 'lib/hooks';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import PortalTopbarExtension from '../PortalTopbarExtension';
import SimpleButton from '../SimpleButton';
import reducer, {
  initialQueryOnCompleted,
  initialState,
  ReducerContext,
} from './reducer';
import {
  ClientPeopleState,
  InitialQueryData,
  InitialQueryVariables,
} from './types';
import CardWrapper from '../CardWrapper';
import ClientPeopleGroupsTable from '../ClientPeopleGroupsTable';
import ClientPeopleGroup from '../ClientPeopleGroup';
import { INITIAL_QUERY } from './query';
import ClientPeopleBreadcrumb from '../ClientPeopleBreadcrumb';

const ClientPeople = () => {
  const theme = useTheme();
  const client = useClient();
  const [state, dispatch] = useReducer<Reducer<ClientPeopleState, AnyAction>>(
    reducer,
    initialState
  );

  const { loading } = useQuery<InitialQueryData, InitialQueryVariables>(
    INITIAL_QUERY,
    {
      fetchPolicy: 'no-cache',
      variables: { clientId: client.id },
      onCompleted: (data) => {
        dispatch(initialQueryOnCompleted(data));
      },
    }
  );

  return (
    <ReducerContext.Provider value={[state, dispatch]}>
      <ClientPeopleBreadcrumb loading={loading} />
      <PortalTopbarExtension>
        <CardWrapper
          className="px-4 py-2 rounded-0"
          style={{ borderLeft: theme.border, borderBottom: theme.border }}
        >
          <SimpleButton icon={faUsers} active inButtonToolbar>
            User groups
          </SimpleButton>
          <SimpleButton icon={faUser} inButtonToolbar>
            Users
          </SimpleButton>
        </CardWrapper>
      </PortalTopbarExtension>
      <CardWrapper className="h-100 p-0">
        <DndProvider backend={HTML5Backend}>
          <Row className="h-100" noGutters>
            <Col className="h-100" style={{ borderRight: theme.border }} xs={3}>
              <ClientPeopleGroupsTable />
            </Col>
            <Col className="h-100">
              <ClientPeopleGroup />
            </Col>
          </Row>
        </DndProvider>
      </CardWrapper>
    </ReducerContext.Provider>
  );
};

export default ClientPeople;
