type Instance = {
  id: string;
};

type Options = {
  push?: boolean;
};

const addOrUpdate = <T extends Instance>(
  instances: T[],
  instance: T,
  { push }: Options = { push: false }
) => {
  if (instances.find((innerInstance) => innerInstance.id === instance.id)) {
    return instances.map((innerInstance) =>
      innerInstance.id === instance.id ? instance : innerInstance
    );
  }
  if (push) {
    return [instance, ...instances];
  }
  return [instance, ...instances];
};

export default addOrUpdate;
