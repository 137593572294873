import React from 'react';
import { Switch, Route } from 'wouter';
import '../index.css';
import { ThemeProvider } from 'styled-components';
import { darkTheme } from 'lib/theme';
import Login from '../components/Login';
import AppInner from '../AppInner';

const WrappedLogin = () => (
  <ThemeProvider theme={{ mode: 'DARK', ...darkTheme }}>
    <Route path="/login" component={Login} />
  </ThemeProvider>
);

const App = () => {
  return (
    <Switch>
      <Route path="/login" component={WrappedLogin} />
      <Route component={AppInner} />
    </Switch>
  );
};

export default App;
