import styled from 'styled-components';

export const Highlighter = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 8999;
  pointer-events: none;
  cursor: not-allowed;

  polygon {
    fill: rgba(0, 0, 0, 0.5);
    pointer-events: auto;
  }
`;
