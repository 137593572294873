import React from 'react';
import { Wrapper } from './styled';
import { UserPillProps } from './types';

const UserPill = ({ user, ...rest }: UserPillProps) => {
  return (
    <Wrapper {...rest}>
      <div className="center h-100 w-100">
        <span className="mono" style={{ marginRight: 2 }}>
          {user.initials}
        </span>
      </div>
    </Wrapper>
  );
};

export default UserPill;
