import styled from 'styled-components';
import { InputProps } from './types';

export const Input = styled.input<InputProps>`
  height: 38px;
  border: 2px solid
    ${({ hasError }) =>
      hasError ? 'rgba(255, 0, 0, 0.5)' : 'rgba(0, 0, 0, 0.05)'};
  border-radius: 3rem;
  background-color: ${({ theme }) => theme.color.inputBackground.hex()};
  color: ${({ theme }) => theme.color.inputText.hex()};
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  transition: border-color 150ms;

  &:focus {
    outline: none;
  }

  &:hover {
    transition: border-color 50ms;
    border-color: ${({ theme }) => theme.color.inputBorderColorHover.hex()};
  }
`;
