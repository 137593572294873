import React, { useEffect, useRef, useState } from 'react';
import useScroll from './useScroll';

type Options = {
  offset?: number;
  detectionEdge?: 'top' | 'bottom';
  onScrollBeyond?: () => void;
};

type Return<T> = [boolean, React.RefObject<T>];

const useScrollBeyond = <T extends HTMLElement>(
  options?: Options
): Return<T> => {
  const ref = useRef<T>(null);
  const [scrolledBeyond, setScrolledBeyond] = useState(false);

  const scroll = useScroll();
  useEffect(() => {
    if (!ref.current) return;
    setScrolledBeyond(
      (options?.offset ?? 0) >
        ref.current.getBoundingClientRect()[options?.detectionEdge ?? 'top']
    );
  }, [options?.detectionEdge, options?.offset, scroll]);

  useEffect(() => {
    if (options?.onScrollBeyond && scrolledBeyond) options.onScrollBeyond();
  }, [options, options?.onScrollBeyond, scrolledBeyond]);

  return [scrolledBeyond, ref];
};

export default useScrollBeyond;
