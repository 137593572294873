import React, { useState } from 'react';
import { Form, Field } from 'react-final-form';
import { faEnvelope } from '@fortawesome/pro-duotone-svg-icons/faEnvelope';
import { useMutation, useQuery } from '@apollo/client';
import DropzoneField from '../DropzoneField';
import SlateField from '../SlateField';
import { Small } from '../Typography';
import SimpleConfirmButton from '../SimpleConfirmButton';
import { useReducerContext } from '../ClientJob/reducer';
import { CREATE_JOB_QUOTE_MUTATION, JOB_QUOTE_MESSAGE_QUERY } from './query';
import { ClientJobQuoteFormProps } from './types';

const ClientJobQuoteForm = ({
  issue,
  onCreateCompleted,
}: ClientJobQuoteFormProps) => {
  const [{ job }] = useReducerContext();

  const [quoteMessage, setQuoteMessage] = useState();
  useQuery(JOB_QUOTE_MESSAGE_QUERY, {
    variables: { jobId: job?.id },
    onCompleted: (data) => {
      setQuoteMessage(data.jobQuoteMessage);
    },
  });

  const [createQuote] = useMutation(CREATE_JOB_QUOTE_MUTATION);
  const handleOnSubmit = (values: any) => {
    if (!job) return;
    createQuote({
      variables: {
        jobIssueId: issue.id,
        file: values.file,
        message: values.message,
      },
    }).then(onCreateCompleted);
  };

  if (!job) return null;
  return (
    <div>
      <Form initialValues={{ message: quoteMessage }} onSubmit={handleOnSubmit}>
        {({ handleSubmit, form }) => (
          <form
            onSubmit={(event) => handleSubmit(event)?.then(() => form.reset())}
          >
            <div className="mb-3 d-flex">
              <div className="mr-3">
                <Small>Attached to:</Small>
                <div>
                  <span className="mono">{job.reference}</span>
                </div>
              </div>
              <div>
                <Small>For:</Small>
                <div>Remedial work</div>
              </div>
            </div>
            <div className="mb-3">
              <div className="mb-1">
                <Small>Excel file</Small>
              </div>
              <Field
                name="file"
                component={DropzoneField}
                label="Upload quote"
              />
            </div>
            <div className="mb-3">
              <div className="mb-1">
                <Small>Message</Small>
              </div>
              <Field name="message" component={SlateField} />
            </div>
            <div className="d-flex justify-content-end">
              <SimpleConfirmButton icon={faEnvelope} onClick={handleSubmit}>
                Send to client
              </SimpleConfirmButton>
            </div>
          </form>
        )}
      </Form>
    </div>
  );
};

export default ClientJobQuoteForm;
