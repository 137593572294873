import React from 'react';
import { ConditionalWrapperProps } from './types';

const ConditionalWrapper = ({
  wrap,
  wrapper,
  children,
}: ConditionalWrapperProps) => {
  return <>{wrap ? wrapper({ children }) : children}</>;
};

export default ConditionalWrapper;
