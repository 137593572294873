import { DateTime } from 'luxon';
import { Job } from 'lib/types';
import { useMemo } from 'react';

export const useInitialDates = (job: Job) => {
  const initialViewDateTimes = useMemo(
    () => ({
      dateTimeStart: DateTime.fromISO(job.viewDateTimeStart),
      dateTimeEnd: DateTime.fromISO(job.viewDateTimeEnd),
    }),
    [job]
  );

  return {
    initialViewDateTimes,
    initialTargetDateTimeStart: DateTime.fromISO(job.targetDateTimeStart),
    initialTargetDateTimeEnd: DateTime.fromISO(job.targetDateTimeEnd),
  };
};
