import { faFileCode } from '@fortawesome/pro-duotone-svg-icons/faFileCode';
import React from 'react';
import { parseDateTime } from 'lib/utils';
import { useTheme } from 'styled-components';
import { Link } from 'wouter';
import { useReducerContext } from '../ClientJob/reducer';
import JobPriorityPill from '../JobPriorityPill';
import SimpleButton from '../SimpleButton';
import { Small } from '../Typography';
import ScrollDiv from '../ScrollDiv';

const ClientJobExtraInfo = () => {
  const theme = useTheme();
  const [{ job }] = useReducerContext();

  if (!job) return null;
  return (
    <div className="h-100 d-flex flex-column">
      <div
        className="d-flex justify-content-end px-3 py-2"
        style={{ borderBottom: theme.border }}
      >
        <Link to={`/clients/${job.client.slug}/jobs/${job.reference}/logs`}>
          <small style={{ display: 'block', lineHeight: '34px' }}>
            <SimpleButton
              className="text-75"
              icon={faFileCode}
              hoverStyles={false}
            >
              View raw job data
            </SimpleButton>
          </small>
        </Link>
      </div>
      <ScrollDiv className="p-3" style={{ overflowY: 'auto' }}>
        <div className="d-flex mb-3">
          <div className="mr-4">
            <Small>Instantiated as:</Small>
            <p className="mb-0">{job.instantiationType.name}</p>
          </div>
          {job.workType === 'REACTIVE' && (
            <div>
              <Small>Priority:</Small>
              <JobPriorityPill job={job} type="both" />
            </div>
          )}
        </div>
        <div className="d-flex mb-3">
          <div className="mr-4">
            <Small>Group Ref #:</Small>
            <p className="mono mb-0">{job.group.reference}</p>
          </div>
          <div className="mr-4">
            <Small>Ref #:</Small>
            <p className="mono mb-0">{job.reference}</p>
          </div>
          <div className="mr-4">
            <Small>Name</Small>
            <p className="mb-0">{job.name}</p>
          </div>
          <div>
            <Small>Site</Small>
            <p className="mb-0">{job.site.name}</p>
          </div>
        </div>
        <div className="d-flex mb-3">
          <div className="mr-4">
            <Small>Response deadline</Small>
            <p className="mb-0 mono">
              {parseDateTime(job.targetDateTimeStart)}
            </p>
          </div>
          <div>
            <Small>Completion deadline</Small>
            <p className="mb-0 mono">{parseDateTime(job.targetDateTimeEnd)}</p>
          </div>
        </div>
        <div>
          <Small>Description</Small>
          <p className="mb-0">{job.description}</p>
        </div>
      </ScrollDiv>
    </div>
  );
};

export default ClientJobExtraInfo;
