import { pluralize } from 'inflection';
import React from 'react';
import { useTheme } from 'styled-components';
import CardWrapper from '../CardWrapper';
import { useReducerContext } from '../ClientDashboard/reducer';
import ActivityLog from '../ClientDashboardActivityLog';
import ImmediateActions from '../ClientDashboardImmediateActions';
import ClientDashboardIncoming from '../ClientDashboardIncoming';
import InProgress from '../ClientDashboardInProgress';
import ClientDashboardIssues from '../ClientDashboardIssues';
import ClientDashboardOverviewCard from '../ClientDashboardOverviewCard';
import ClientDashboardReports from '../ClientDashboardReports';
import PortalBreadcrumb from '../PortalBreadcrumb';
import { Grid, Cell } from './styled';

const ClientDashboardOverview = () => {
  const theme = useTheme();
  const [
    {
      incomingJobs,
      incomingJobsChart,
      jobIssues,
      jobIssuesChart,
      jobReports,
      jobReportsChart,
    },
  ] = useReducerContext();

  return (
    <>
      <PortalBreadcrumb>
        <h4>Overview</h4>
      </PortalBreadcrumb>
      <Grid>
        <Cell className="d-flex flex-column" style={{ gridArea: 'incoming' }}>
          <ClientDashboardOverviewCard
            total={incomingJobs?.count}
            data={incomingJobsChart}
            color={
              (incomingJobs?.count ?? 0) > 0
                ? '#00ffff'
                : theme.color.primary.hex()
            }
            label={`Incoming ${
              incomingJobs?.count === 1 ? 'job' : pluralize('job')
            }`}
          />
          <CardWrapper className="p-0 mt-4 flex-grow-1">
            <ClientDashboardIncoming />
          </CardWrapper>
        </Cell>
        <Cell className="d-flex flex-column" style={{ gridArea: 'issues' }}>
          <ClientDashboardOverviewCard
            total={jobIssues?.count}
            data={jobIssuesChart}
            areaOptions={{
              type: 'stepAfter',
            }}
            color={
              (jobIssues?.count ?? 0) > 0
                ? '#ff0000aa'
                : theme.color.primary.hex()
            }
            label={`Open ${
              jobReports?.count === 1 ? 'issue' : pluralize('issue')
            }`}
          />
          <CardWrapper className="p-0 mt-4 flex-grow-1">
            <ClientDashboardIssues />
          </CardWrapper>
        </Cell>
        <Cell className="d-flex flex-column" style={{ gridArea: 'reports' }}>
          <ClientDashboardOverviewCard
            total={jobReports?.count}
            data={jobReportsChart}
            color={
              (jobReports?.count ?? 0) > 0
                ? '#ff0000aa'
                : theme.color.primary.hex()
            }
            label={`${
              jobReports?.count === 1 ? 'Report' : pluralize('Report')
            } awaiting review`}
          />
          <CardWrapper className="p-0 mt-4 flex-grow-1">
            <ClientDashboardReports />
          </CardWrapper>
        </Cell>
        <Cell style={{ gridArea: 'immediate' }}>
          <ImmediateActions />
        </Cell>
        <Cell style={{ gridArea: 'inprogress' }}>
          <InProgress />
        </Cell>
        <Cell style={{ gridArea: 'activity' }}>
          <ActivityLog />
        </Cell>
      </Grid>
    </>
  );
};

export default ClientDashboardOverview;
