import { UserAgentApplication } from 'msal';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import runtimeEnv from '@mars/heroku-js-runtime-env';

const env = runtimeEnv();

const msalApplication = new UserAgentApplication({
  auth: {
    clientId: process.env.REACT_APP_AZURE_CLIENT_ID as string,
    authority: process.env.REACT_APP_AZURE_AUTHORITY,
    validateAuthority: true,
    redirectUri: env.REACT_APP_AZURE_REDIRECT_URL,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
});

export default msalApplication;
