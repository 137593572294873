import chroma from 'chroma-js';
import classNames from 'classnames';
import React from 'react';
import { Row, Col } from 'reactstrap';
import { useClient, useHoveredId, useTablePagination } from 'lib/hooks';
import { useTheme } from 'styled-components';
import { useQuery } from '@apollo/client';
import InProgressMap from '../ClientInProgressMap';
import CardWrapper from '../CardWrapper';
import JobLink from '../JobLink';
import Table from '../Table';
import TableFooter from '../TableFooter';
import {
  inProgressJobsQueryOnCompleted,
  useReducerContext,
} from '../ClientDashboard/reducer';
import UserPill from '../UserPill';
import { IN_PROGRESS_JOBS_QUERY } from './query';
import { InProgressJobQueryData, InProgressJobQueryVariables } from './types';

const ClientDashboardInProgress = () => {
  const client = useClient();
  const [{ inProgressJobs }, dispatch] = useReducerContext();
  const [[hoveredId, setHoveredId], hoveredCallbacks] = useHoveredId(
    inProgressJobs?.results?.[0]?.id ?? '-1'
  );

  const { refetch } = useQuery<
    InProgressJobQueryData,
    InProgressJobQueryVariables
  >(IN_PROGRESS_JOBS_QUERY, {
    fetchPolicy: 'no-cache',
    variables: { clientId: client.id, page: 1 },
    onCompleted: (data) => {
      dispatch(inProgressJobsQueryOnCompleted(data));
      setHoveredId(data.results?.[0]?.id ?? '-1');
    },
  });

  const [page, handleChangePage] = useTablePagination({
    onChange: async (newPage) => {
      const { data } = await refetch({ clientId: client.id, page: newPage });
      if (data) dispatch(inProgressJobsQueryOnCompleted(data));
    },
  });

  const theme = useTheme();
  return (
    <CardWrapper className="h-100 p-0">
      <Row noGutters className="h-100">
        <Col xs={5} className="h-100 pr-2">
          <div className="d-flex flex-column h-100">
            <div className="d-flex justify-content-between m-3">
              <h5 className="mb-0">In progress</h5>
              <small style={{ lineHeight: '24px' }}>
                <span className="mono text-75">{inProgressJobs?.count}</span>{' '}
                <span className="text-50">jobs in progress</span>
              </small>
            </div>
            <Table
              className="flex-grow-1"
              style={{ minHeight: 0 }}
              flexBases={[30, 40, 30]}
            >
              <thead>
                <tr>
                  <th>Reference</th>
                  <th>Name</th>
                  <th>Assignees</th>
                </tr>
              </thead>
              <tbody>
                {inProgressJobs?.results.map((job) => (
                  <tr key={job.id} data-id={job.id} {...hoveredCallbacks}>
                    <td className="mono py-2">{job.reference}</td>
                    <td className="pr-2 py-2">
                      <JobLink job={job}>{job.name}</JobLink>
                    </td>
                    <td className="py-2">
                      {job.assignees.map((user, index) => {
                        const last = index === job.assignees.length - 1;
                        const color = chroma(theme.color.primary).darken(
                          (index + 1) / 4
                        );
                        return (
                          <UserPill
                            user={user}
                            className={classNames({ 'mr-2': !last })}
                            style={{ backgroundColor: color.hex() }}
                          />
                        );
                      })}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <TableFooter
              page={page}
              length={inProgressJobs?.results.length}
              count={inProgressJobs?.count}
              small
              handleChangePage={handleChangePage}
            />
          </div>
        </Col>
        <Col xs={7}>
          <div className="h-100 ml-0 pl-1 pr-1 py-1">
            <InProgressMap
              jobs={inProgressJobs?.results}
              hoveredId={hoveredId}
            />
          </div>
        </Col>
      </Row>
    </CardWrapper>
  );
};

export default ClientDashboardInProgress;
