import React, { useState } from 'react';
import { getId } from '../utils';
import { HoveredCallbacks } from '../types';

type UseHoveredId = (
  initialValue: string,
  options?: { reset?: boolean }
) => [[string, (value: string) => void], HoveredCallbacks];

const useHoveredId: UseHoveredId = (
  initialValue: string,
  options = { reset: false }
) => {
  const impossibleImageId = '-1';
  // The id of the hover image is used to conditionally render the
  // image control buttons. If the id of the image matches the id
  // stored as `idOfHoveredImage` then render the buttons. The
  // impossible value of -1 is used to indicate no image is hovered.
  const [idOfHoveredImage, setIdOfHoveredImage] =
    useState<string>(initialValue);
  const onMouseEnter = (event?: React.MouseEvent<HTMLElement>) => {
    if (!event) return;
    /* In order to render the image action buttons we need
     * to keep track of what image the user is currently
     * hovering over by storing the id of the image. */
    const id = getId(event) as string;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { hoverEnabled: _hoverEnabled } = event.currentTarget.dataset;
    const hoverEnabled = _hoverEnabled !== 'false';
    if (!hoverEnabled) return;
    setIdOfHoveredImage(id);
  };

  const onMouseLeave = (event?: React.MouseEvent<HTMLElement>) => {
    /* This is a very simple function that sets `idOfHoveredImage`
     * to the impossible value of -1 to indicate the image is no
     * longer being hovered by the user.  */
    // eslint-disable-next-line @typescript-eslint/naming-convention
    let hoverEnabled = true;
    if (event) {
      const { hoverEnabled: hoverEnabledString } = event.currentTarget.dataset;
      hoverEnabled = hoverEnabledString !== 'false';
    }
    if (!hoverEnabled) return;
    setIdOfHoveredImage(options.reset ? initialValue : impossibleImageId);
  };

  return [
    [idOfHoveredImage, setIdOfHoveredImage],
    { onMouseEnter, onMouseLeave },
  ];
};

export default useHoveredId;
