import { AnyAction } from 'redux';
import { v4 as uuid4 } from 'uuid';
import {
  LayoutState,
  ClientsState,
  UtilsState,
  ThemeState,
  ToastState,
  NotificationsState,
  TodosState,
} from './types';
import { Client, User } from '../types';
import { darkTheme, lightTheme } from '../theme';
import { CreateUserTodoMutationData } from '../../components/UserTodoButton/types';

const initialLayoutState = {
  sidebarMenuMeta: {
    isOpen: false,
    type: '',
  },
  notesMeta: {
    isOpen: false,
  },
};

export const layoutReducer = (
  state: LayoutState = initialLayoutState,
  action: AnyAction
) => {
  switch (action.type) {
    case 'LAYOUT__SET_SIDEBAR_MENU_META':
      return {
        ...state,
        sidebarMenuMeta: {
          ...state.sidebarMenuMeta,
          ...action.payload,
        },
      };
    case 'LAYOUT__NOTES_META':
      return {
        ...state,
        notesMeta: {
          ...state.notesMeta,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

const initialClientsState: ClientsState = {
  clients: [] as Client[],
  client: {} as Client,
  isOpen: false,
};

export const clientsReducer = (
  state: ClientsState = initialClientsState,
  action: AnyAction
) => {
  switch (action.type) {
    case 'CLIENTS__SET_CLIENTS':
      return {
        ...state,
        clients: action.payload,
      };
    case 'CLIENTS__SET_CLIENT':
      return {
        ...state,
        client: action.payload,
      };
    case 'CLIENTS__OPEN_SIDEBAR':
      return {
        ...state,
        isOpen: true,
      };
    case 'CLIENTS__CLOSE_SIDEBAR':
      return {
        ...state,
        isOpen: false,
      };
    default:
      return state;
  }
};

const initialUtilsState: UtilsState = {
  websocketToken: '',
  taskbarData: null,
};

export const utilsReducer = (
  state: UtilsState = initialUtilsState,
  action: AnyAction
) => {
  switch (action.type) {
    case 'UTILS__SET_WEBSOCKET_TOKEN':
      return {
        ...state,
        websocketToken: action.payload,
      };
    case 'UTILS__SET_TASKBAR_DATA':
      return {
        ...state,
        taskbarData: action.payload,
      };
    default:
      return state;
  }
};

const initialThemeState: ThemeState = {
  mode: 'LIGHT',
  color: {},
  border: '',
  schedulerGridBorder: '',
  mapboxStyle: '',
};

export const themeReducer = (
  state: ThemeState = initialThemeState,
  action: AnyAction
) => {
  switch (action.type) {
    case 'SET_THEME_MODE': {
      const mode = action.payload;
      return {
        ...state,
        mode,
        ...(mode === 'LIGHT' ? lightTheme : darkTheme),
      };
    }
    default:
      return state;
  }
};

const initialUserState: User | null = null;

export const userReducer = (
  state: User | null = initialUserState,
  action: AnyAction
) => {
  switch (action.type) {
    case 'SET_USER':
      return action.user;
    default:
      return state;
  }
};

const initialErrorsState = {
  errors: [],
  error: null,
  errorWindowIsOpen: false,
};

export const errorsReducer = (
  state = initialErrorsState,
  action: AnyAction
) => {
  switch (action.type) {
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload,
        errors: [...state.errors, action.payload],
      };
    case 'SET_ERROR_WINDOW_IS_OPEN':
      return {
        ...state,
        errorWindowIsOpen: action.payload,
      };
    default:
      return state;
  }
};

const initialToastState: ToastState = {
  notifications: [],
};

export const toastReducer = (state = initialToastState, action: AnyAction) => {
  switch (action.type) {
    case 'ADD_WARNING':
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            id: uuid4(),
            type: 'WARNING',
            visible: true,
            message: action.payload,
          },
        ],
      };
    case 'ADD_ERROR':
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            id: uuid4(),
            type: 'ERROR',
            visible: true,
            message: action.payload,
          },
        ],
      };
    case 'REMOVE_NOTIFICATION':
      return {
        ...state,
        notifications: state.notifications.map((notification) =>
          notification.id === action.payload
            ? { ...notification, visible: false }
            : notification
        ),
      };
    default:
      return state;
  }
};

const initialNotificationsState: NotificationsState = {
  notifications: [],
  notificationCount: null,
  isOpen: false,
  opened: false,
};

export const notificationsReducer = (
  state = initialNotificationsState,
  action: AnyAction
) => {
  switch (action.type) {
    case 'NOTIFICATIONS__INITIAL_QUERY_ON_COMPLETED':
      return {
        ...state,
        notifications: [...state.notifications, ...action.payload],
        opened: true,
      };
    case 'NOTIFICATIONS__ON_NEW_NOTIFICATION':
      return {
        ...state,
        notifications: state.opened
          ? [action.payload.notification, ...state.notifications]
          : state.notifications,
        notificationCount: action.payload.notificationCount,
      };
    case 'NOTIFICATIONS__TOGGLE':
      return {
        ...state,
        isOpen: !state.isOpen,
      };
    case 'NOTIFICATIONS__CLOSE':
      return {
        ...state,
        isOpen: false,
      };
    case 'NOTIFICATIONS__MARK_ALL_NOTIFICATIONS_AS_READ':
      return {
        ...state,
        notifications: state.notifications.map((notification) => ({
          ...notification,
          read: true,
        })),
        notificationCount: action.payload.notificationCount,
      };
    case 'NOTIFICATIONS__MARK_NOTIFICATION_AS_READ':
      return {
        ...state,
        notifications: state.notifications.map((notification) =>
          notification.id === action.payload.notificationId
            ? { ...notification, read: true }
            : notification
        ),
        notificationCount: action.payload.notificationCount,
      };
    default:
      return state;
  }
};

const initialTodosState: TodosState = {
  todos: [],
  isOpen: false,
};

export const createUserTodoMutationOnCompleted = (
  data: CreateUserTodoMutationData
) => ({
  type: 'TODOS__CREATE_USER_TODO_MUTATION_ON_COMPLETED',
  userNote: data.createUserTodo.userTodo,
});

export const todosReducer = (
  state: TodosState = initialTodosState,
  action: AnyAction
) => {
  switch (action.type) {
    case 'TODOS__TOGGLE':
      return {
        ...state,
        isOpen: !state.isOpen,
      };
    case 'TODOS__CREATE_USER_TODO_MUTATION_ON_COMPLETED':
      return {
        ...state,
        todos: [...state.todos, action.userTodo],
      };
    default:
      return state;
  }
};
