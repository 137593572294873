import { gql } from '@apollo/client';

export const INITIAL_QUERY = gql`
  query ($chatId: ID!) {
    chat(chatId: $chatId) {
      id
      isGeneral
      messages {
        id
        body
        isPrimary
        device
        dateTime
        attachments {
          id
          instance {
            ... on ChatMessageJobVisitHelpRequestUserThrough {
              type
              data {
                id
                helpRequest {
                  id
                  requester {
                    id
                    name
                  }
                }
                user {
                  id
                  name
                }
              }
            }
          }
        }
      }
      users {
        id
        initials
        name
      }
    }
  }
`;

export const CREATE_CHAT_MESSAGE_MUTATION = gql`
  mutation (
    $sessionId: ID!
    $tempId: ID!
    $chatId: ID!
    $jobId: ID!
    $body: JSONScalarInput!
  ) {
    createChatMessage(
      sessionId: $sessionId
      tempId: $tempId
      chatId: $chatId
      jobId: $jobId
      body: $body
    ) {
      chatMessage {
        id
        tempId
        isPrimary
        dateTime
        body
        device
      }
    }
  }
`;
