import { NestedReportTemplateBlock } from 'lib/types';
import { Descendant } from 'slate';
import { v4 as uuid4 } from 'uuid';

export const getItemByPath = (
  path: number[],
  items: NestedReportTemplateBlock[]
): NestedReportTemplateBlock | null => {
  let item: NestedReportTemplateBlock | null = null;

  path.forEach((segment) => {
    const list = item ? item.children : items;
    item = list[segment];
  });

  return item;
};

export const generateId = () => uuid4();

export const generateLabel = (defaultText?: string): Descendant[] => [
  { type: 'paragraph', children: [{ text: defaultText ?? 'New question' }] },
];
