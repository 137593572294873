import styled from 'styled-components';
import CardWrapper from '../CardWrapper';

export const Wrapper = styled.div`
  position: absolute;
  bottom: 1rem;
  right: 0;
  width: 370px;
  height: 500px;
  z-index: 9999;

  ${CardWrapper} {
    border: 2px solid ${({ theme }) => theme.color.danger.hex()};
  } ;
`;
