import React from 'react';
import { useToggle } from 'react-use';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
import { useTheme } from 'styled-components';
import { CollapseProps } from './types';
import { CollapseButton } from './styled';
import ScrollDiv from '../ScrollDiv';

const Collapse = ({ children, closedLabel, openLabel }: CollapseProps) => {
  const [isOpen, toggle] = useToggle(false);
  const theme = useTheme();
  return (
    <div>
      <CollapseButton
        icon={faChevronRight}
        iconProps={{ rotation: isOpen ? 90 : undefined }}
        onClick={toggle}
      >
        {isOpen ? openLabel : closedLabel}
      </CollapseButton>
      {isOpen && (
        <ScrollDiv
          style={{
            overflowY: 'auto',
            maxHeight: 800,
            border: theme.border,
            borderRadius: '0.25rem',
            marginTop: '0.5rem',
          }}
        >
          {children}
        </ScrollDiv>
      )}
    </div>
  );
};

export default Collapse;
