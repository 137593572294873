import React from 'react';
import { faSync } from '@fortawesome/pro-duotone-svg-icons/faSync';
import { useMutation } from '@apollo/client';
import { useSearch } from 'lib/hooks';
import SearchBar from '../SearchBar';
import Select from '../Select';
import Button from '../Button';
import { SEARCH_USERS_QUERY, SYNC_USERS_MUTATION } from './query';
import {
  usersQueryOnCompleted,
  syncUsersMutationOnCompleted,
  useReducerContext,
} from '../People/reducer';

const PeopleTableFilter = () => {
  const [, dispatch] = useReducerContext();

  const [inputProps, { loading }] = useSearch(SEARCH_USERS_QUERY, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      dispatch(usersQueryOnCompleted(data));
    },
  });

  const [
    syncUsers,
    { loading: syncingUsers },
  ] = useMutation(SYNC_USERS_MUTATION, { fetchPolicy: 'no-cache' });

  const handleSyncUsersOnClick = () => {
    syncUsers().then((response) => {
      dispatch(syncUsersMutationOnCompleted(response.data));
    });
  };

  return (
    <div className="m-3 d-flex">
      <div className="flex-grow-1">
        <SearchBar
          wrapperClassName="mr-2"
          placeholder="Search for an individual..."
          loading={loading}
          {...inputProps}
        />
      </div>
      <div className="w-25 mr-2">
        <Select
          defaultValue={{ value: 'ALL', label: 'All' }}
          options={[
            { value: 'ALL', label: 'All' },
            { value: 'OPERATIVES', label: 'Operatives' },
          ]}
        />
      </div>
      <div>
        <Button
          icon={faSync}
          loading={syncingUsers}
          onClick={handleSyncUsersOnClick}
        >
          Sync users
        </Button>
      </div>
    </div>
  );
};

export default PeopleTableFilter;
