import styled from 'styled-components';
import { getColorForFlagType } from 'lib/utils';
import { StatusWrapperProps } from './types';

export const StatusWrapper = styled.div<StatusWrapperProps>`
  margin-bottom: 0.25rem;
  border-radius: 0.5rem;

  .icon {
    color: ${({ theme, flagType }) =>
      getColorForFlagType(theme, flagType).color};
  }
`;
