import { faClock } from '@fortawesome/pro-duotone-svg-icons/faClock';
import { faInfoCircle } from '@fortawesome/pro-duotone-svg-icons/faInfoCircle';
import { faTruck } from '@fortawesome/pro-duotone-svg-icons/faTruck';
import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/pro-duotone-svg-icons/faUserCircle';
import { useTheme } from 'styled-components';
import ClientJobExtraInfo from '../ClientJobExtraInfo';
import ClientJobVisits from '../ClientJobVisits';
import JobStatusFlag from '../JobStatusFlag';
import { useReducerContext } from '../ClientJob/reducer';
import SimpleButton from '../SimpleButton';
import { Small } from '../Typography';
import ClientJobHistory from '../ClientJobHistory';
import JobPriorityPill from '../JobPriorityPill';
import ClickToCopy from '../ClickToCopy';
import ScrollDiv from '../ScrollDiv';

const ClientJobInformation = () => {
  const theme = useTheme();
  const [{ job }] = useReducerContext();

  const [tab, setTab] = useState('history');

  if (!job) return null;
  return (
    <Row className="h-100">
      <Col
        xl={4}
        className="m-3 d-flex flex-column"
        style={{ minHeight: 0, height: 'calc(100% - 2rem)' }}
      >
        <div className="mb-3">
          <div className="d-flex mb-1">
            <Small className="mr-2" style={{ lineHeight: '26px' }}>
              Ref:{' '}
              <ClickToCopy
                copy={job.reference}
                className="mono font-weight-normal"
              >
                {job.reference}
              </ClickToCopy>
            </Small>
            <JobPriorityPill includeCode={false} job={job} type="response" />
          </div>
          <h4 className="mb-0">{job.name}</h4>
        </div>
        <div className="mb-2">
          <Small>Status</Small>
          <p className="mb-0">
            <JobStatusFlag className="mr-2" flag={job.status.flag} />
            {job.status.nameDisplay}
          </p>
        </div>
        <div className="mb-2">
          <Small>Site</Small>
          <p className="mb-0">{job.site.name}</p>
        </div>
        {job.assignees.length > 0 && (
          <ScrollDiv className="flex-grow-1" style={{ overflowY: 'auto' }}>
            <Small>Assignees</Small>
            <div className="d-flex flex-wrap">
              {job.assignees.map((user) => (
                <div key={user.id} className="mr-2 text-nowrap">
                  <FontAwesomeIcon
                    className="mr-1"
                    icon={faUserCircle}
                    fixedWidth
                  />
                  <span>{user.name}</span>
                </div>
              ))}
            </div>
          </ScrollDiv>
        )}
      </Col>
      <Col className="h-100">
        <div className="h-100" style={{ borderLeft: theme.border }}>
          <div className="py-2 px-3" style={{ position: 'absolute' }}>
            <SimpleButton
              active={tab === 'history'}
              inButtonToolbar
              icon={faClock}
              onClick={() => setTab('history')}
            >
              History
            </SimpleButton>
            <SimpleButton
              active={tab === 'info'}
              inButtonToolbar
              icon={faInfoCircle}
              onClick={() => setTab('info')}
            >
              Details
            </SimpleButton>
            <SimpleButton
              active={tab === 'visits'}
              inButtonToolbar
              icon={faTruck}
              onClick={() => setTab('visits')}
            >
              Visits
            </SimpleButton>
          </div>
          {tab === 'history' && <ClientJobHistory />}
          {tab === 'info' && <ClientJobExtraInfo />}
          {tab === 'visits' && <ClientJobVisits />}
        </div>
      </Col>
    </Row>
  );
};

export default ClientJobInformation;
