import React from 'react';
import { Route, Redirect, DefaultParams } from 'wouter';
import { msalApplication } from 'lib/msal';
import { ProtectedRouteProps } from './types';

const ProtectedRoute = <T extends DefaultParams = any>({
  component,
  path,
  ...rest
}: ProtectedRouteProps<T>) => {
  const account = msalApplication.getAccount();
  return account === null ? (
    <Redirect to="/login" />
  ) : (
    <Route component={component} path={path as string} {...rest} />
  );
};

export default ProtectedRoute;
