import { gql } from '@apollo/client';

export const JOB_GROUP_QUERY = gql`
  query ($jobGroupId: ID!) {
    jobGroup(jobGroupId: $jobGroupId) {
      id
      reference
      name
      jobs {
        id
        reference
        status {
          name
          nameDisplay
          flag {
            color
            icon
          }
        }
        name
      }
    }
  }
`;
