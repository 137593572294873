import React from 'react';
import ReactDOM from 'react-dom';
import { PortalProps } from './types';

const Portal = ({
  children,
  selector,
  element: providedElement,
}: React.PropsWithChildren<PortalProps>) => {
  if (providedElement) {
    return ReactDOM.createPortal(children, providedElement);
  }
  if (selector) {
    const element = document.getElementById(selector);
    if (!element) return null;
    return ReactDOM.createPortal(children, element);
  }
  return ReactDOM.createPortal(children, document.body);
};

export default Portal;
