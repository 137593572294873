import { gql } from '@apollo/client';

export const INITIAL_QUERY = gql`
  query {
    userNotes {
      id
      dateTime
      title
      body
      path
    }
  }
`;

export const CREATE_USER_NOTE_MUTATION = gql`
  mutation ($data: UserNoteInput!) {
    createUserNote(data: $data) {
      userNote {
        id
        dateTime
        title
        body
        path
      }
    }
  }
`;

export const UPDATE_USER_NOTE_MUTATION = gql`
  mutation ($userNoteId: ID!, $data: UserNoteInput!) {
    updateUserNote(userNoteId: $userNoteId, data: $data) {
      userNote {
        id
        dateTime
        title
        body
        path
      }
    }
  }
`;

export const DELETE_USER_NOTE_MUTATION = gql`
  mutation ($userNoteId: ID!) {
    deleteUserNote(userNoteId: $userNoteId) {
      deletedId
    }
  }
`;
