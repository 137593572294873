import React from 'react';
import { useSortTable } from 'lib/hooks';
import { getId } from 'lib/utils';
import { faChevronLeft } from '@fortawesome/pro-light-svg-icons/faChevronLeft';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons/faChevronRight';
import { useTheme } from 'styled-components';
import Table from '../Table';
import TableHeadButton from '../TableHeadButton';
import ClientSitesTableFilter from '../ClientSitesTableFilter';
import { siteTableRowOnClick, useReducerContext } from '../ClientSites/reducer';
import Button from '../Button';

const ClientSitesTable = () => {
  const [{ sites }, dispatch] = useReducerContext();

  const tableHeadNames: ['name', 'location', 'buildings'] = [
    'name',
    'location',
    'buildings',
  ];

  const handleOnSort = () => {};

  const [sortState, sort] = useSortTable(tableHeadNames, {
    defaultActiveName: 'name',
    onSort: handleOnSort,
  });

  const handleRowOnClick = (event: React.MouseEvent<HTMLTableRowElement>) => {
    const id = getId(event);
    if (!id) return;
    dispatch(siteTableRowOnClick(id));
  };

  const page = 1;
  const theme = useTheme();
  return (
    <div className="h-100">
      <ClientSitesTableFilter />
      <Table flexBases={[10, 40, 25, 25]}>
        <thead>
          <tr>
            <th>
              Map <span className="mono">#</span>
            </th>
            <th>
              <TableHeadButton
                {...sortState.columns.name}
                onClick={() => sort('name')}
              >
                Name
              </TableHeadButton>
            </th>
            <th>
              <TableHeadButton
                {...sortState.columns.location}
                onClick={() => sort('location')}
              >
                Location
              </TableHeadButton>
            </th>
            <th>
              <TableHeadButton
                {...sortState.columns.buildings}
                onClick={() => sort('buildings')}
              >
                Buildings
              </TableHeadButton>
            </th>
          </tr>
        </thead>
        <tbody>
          {sites.map((site) => (
            <tr key={site.id} data-id={site.id} onClick={handleRowOnClick}>
              <td className="mono"> {(site.index || 0) + 1}</td>
              <td>{site.name}</td>
              <td>{site.location.address}</td>
              <td>{site.buildings.length}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div
        className="d-flex justify-content-between px-3 pt-3"
        style={{ borderTop: theme.border }}
      >
        <div className="d-flex flex-column justify-content-center">
          <span className="text-50">Page 1 of 1</span>
        </div>
        <div>
          <Button
            className="mr-3"
            icon={faChevronLeft}
            iconSide="left"
            disabled={page === 1}
            // onClick={() => handleChangePage(-1)}
          >
            Previous page
          </Button>
          <Button
            disabled={sites?.length <= 30 || false}
            icon={faChevronRight}
            iconSide="right"
            // onClick={() => handleChangePage(1)}
          >
            Next page
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ClientSitesTable;
