import React from 'react';
import { useLocation } from 'wouter';
import { getId } from '../utils';

const useTableRowOnClick = (
  urlMethod: (id: string) => string,
  callback?: (event: React.MouseEvent<HTMLTableRowElement>) => void
) => {
  const [, setLocation] = useLocation();
  return (event: React.MouseEvent<HTMLTableRowElement>) => {
    const id = getId(event);
    setLocation(urlMethod(id as string));
    if (callback) {
      callback(event);
    }
  };
};

export default useTableRowOnClick;
