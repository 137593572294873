import React from 'react';
import { Switch, Route, Link } from 'wouter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons/faSpinnerThird';
import { useClient } from 'lib/hooks';
import { truncateReference } from 'lib/utils';
import PortalBreadcrumb from '../PortalBreadcrumb';
import { useReducerContext } from '../ClientJob/reducer';

const ClientJobBreadcrumb = ({ loading }: { loading: boolean }) => {
  const [{ job }] = useReducerContext();
  const client = useClient();
  if (!job) {
    return (
      <PortalBreadcrumb>
        <div className="d-flex">
          <Link to={`/clients/${client.slug}/jobs`}>
            <h4 className="text-75 client__tab-bar-breadcrumb-link">Jobs</h4>
          </Link>
          <FontAwesomeIcon
            className="mx-2"
            style={{ marginTop: 6 }}
            icon={faChevronRight}
            fixedWidth
          />
          <FontAwesomeIcon
            style={{ fontSize: '1.25rem', marginTop: 5 }}
            icon={faSpinnerThird}
            fixedWidth
            spin
          />
        </div>
      </PortalBreadcrumb>
    );
  }

  const jobReference = job.parent
    ? `${job.parent.reference}/${truncateReference(job.reference)}`
    : job.reference;

  return (
    <PortalBreadcrumb>
      <Switch>
        <Route
          path="/clients/:slug/jobs/:id"
          component={() => (
            <div className="d-flex">
              <Link to={`/clients/${job.client.slug}/jobs`}>
                <h4 className="text-75 client__tab-bar-breadcrumb-link">
                  Jobs
                </h4>
              </Link>
              <FontAwesomeIcon
                className="mx-2"
                style={{ marginTop: 6 }}
                icon={faChevronRight}
                fixedWidth
              />
              {loading ? (
                <FontAwesomeIcon
                  style={{ fontSize: '1.25rem', marginTop: 5 }}
                  icon={faSpinnerThird}
                  fixedWidth
                  spin
                />
              ) : (
                <>
                  <h4 className="mono text-75">{jobReference}</h4>
                  <FontAwesomeIcon
                    className="mx-2"
                    style={{ marginTop: 6 }}
                    icon={faChevronRight}
                    fixedWidth
                  />
                  <h4>{job.name}</h4>
                </>
              )}
            </div>
          )}
        />
        <Route
          path="/clients/:slug/jobs/:id/reports"
          component={() => (
            <div className="d-flex">
              <Link to={`/clients/${job.client.slug}/jobs`}>
                <h4 className="text-75 client__tab-bar-breadcrumb-link">
                  Jobs
                </h4>
              </Link>
              <FontAwesomeIcon
                className="mx-2"
                style={{ marginTop: 6 }}
                icon={faChevronRight}
                fixedWidth
              />
              <Link to={`/clients/${job.client.slug}/jobs/${job.reference}`}>
                <h4 className="text-75 client__tab-bar-breadcrumb-link mono">
                  {jobReference}
                </h4>
              </Link>
              <FontAwesomeIcon
                className="mx-2"
                style={{ marginTop: 6 }}
                icon={faChevronRight}
                fixedWidth
              />
              <Link to={`/clients/${job.client.slug}/jobs/${job.reference}`}>
                <h4 className="text-75 client__tab-bar-breadcrumb-link">
                  {job.name}
                </h4>
              </Link>
              <FontAwesomeIcon
                className="mx-2"
                style={{ marginTop: 6 }}
                icon={faChevronRight}
                fixedWidth
              />
              <h4>Reports</h4>
            </div>
          )}
        />
        <Route<{ reportNumber: string }>
          path="/clients/:slug/jobs/:id/reports/:reportNumber"
          component={({ params }) => (
            <div className="d-flex">
              <Link to={`/clients/${job.client.slug}/jobs`}>
                <h4 className="text-75 client__tab-bar-breadcrumb-link">
                  Jobs
                </h4>
              </Link>
              <FontAwesomeIcon
                className="mx-2"
                style={{ marginTop: 6 }}
                icon={faChevronRight}
                fixedWidth
              />
              <Link to={`/clients/${job.client.slug}/jobs/${job.reference}`}>
                <h4 className="text-75 client__tab-bar-breadcrumb-link mono">
                  {jobReference}
                </h4>
              </Link>
              <FontAwesomeIcon
                className="mx-2"
                style={{ marginTop: 6 }}
                icon={faChevronRight}
                fixedWidth
              />
              <Link to={`/clients/${job.client.slug}/jobs/${job.reference}`}>
                <h4 className="text-75 client__tab-bar-breadcrumb-link">
                  {job.name}
                </h4>
              </Link>
              <FontAwesomeIcon
                className="mx-2"
                style={{ marginTop: 6 }}
                icon={faChevronRight}
                fixedWidth
              />
              <Link
                to={`/clients/${job.client.slug}/jobs/${job.reference}/reports`}
              >
                <h4 className="text-75 client__tab-bar-breadcrumb-link">
                  Reports
                </h4>
              </Link>
              <FontAwesomeIcon
                className="mx-2"
                style={{ marginTop: 6 }}
                icon={faChevronRight}
                fixedWidth
              />
              <h4 className="mono">#{params.reportNumber}</h4>
            </div>
          )}
        />
        <Route
          path="/clients/:slug/jobs/:id/issues"
          component={() => (
            <div className="d-flex">
              <Link to={`/clients/${job.client.slug}/jobs`}>
                <h4 className="text-75 client__tab-bar-breadcrumb-link">
                  Jobs
                </h4>
              </Link>
              <FontAwesomeIcon
                className="mx-2"
                style={{ marginTop: 6 }}
                icon={faChevronRight}
                fixedWidth
              />
              <Link to={`/clients/${job.client.slug}/jobs/${job.reference}`}>
                <h4 className="text-75 client__tab-bar-breadcrumb-link mono">
                  {jobReference}
                </h4>
              </Link>
              <FontAwesomeIcon
                className="mx-2"
                style={{ marginTop: 6 }}
                icon={faChevronRight}
                fixedWidth
              />
              <Link to={`/clients/${job.client.slug}/jobs/${job.reference}`}>
                <h4 className="text-75 client__tab-bar-breadcrumb-link">
                  {job.name}
                </h4>
              </Link>
              <FontAwesomeIcon
                className="mx-2"
                style={{ marginTop: 6 }}
                icon={faChevronRight}
                fixedWidth
              />
              <h4>Issues</h4>
            </div>
          )}
        />
        <Route<{ number: string }>
          path="/clients/:slug/jobs/:id/issues/:number"
          component={({ params: { number } }) => (
            <div className="d-flex">
              <Link to={`/clients/${job.client.slug}/jobs`}>
                <h4 className="text-75 client__tab-bar-breadcrumb-link">
                  Jobs
                </h4>
              </Link>
              <FontAwesomeIcon
                className="mx-2"
                style={{ marginTop: 6 }}
                icon={faChevronRight}
                fixedWidth
              />
              <Link to={`/clients/${job.client.slug}/jobs/${job.reference}`}>
                <h4 className="text-75 client__tab-bar-breadcrumb-link mono">
                  {jobReference}
                </h4>
              </Link>
              <FontAwesomeIcon
                className="mx-2"
                style={{ marginTop: 6 }}
                icon={faChevronRight}
                fixedWidth
              />
              <Link to={`/clients/${job.client.slug}/jobs/${job.reference}`}>
                <h4 className="text-75 client__tab-bar-breadcrumb-link">
                  {job.name}
                </h4>
              </Link>
              <FontAwesomeIcon
                className="mx-2"
                style={{ marginTop: 6 }}
                icon={faChevronRight}
                fixedWidth
              />
              <Link
                to={`/clients/${job.client.slug}/jobs/${job.reference}/issues`}
              >
                <h4 className="text-75 client__tab-bar-breadcrumb-link">
                  Issues
                </h4>
              </Link>
              <FontAwesomeIcon
                className="mx-2"
                style={{ marginTop: 6 }}
                icon={faChevronRight}
                fixedWidth
              />
              <h4 className="mono">#{number}</h4>
            </div>
          )}
        />
        <Route
          path="/clients/:slug/jobs/:id/logs"
          component={() => (
            <div className="d-flex">
              <Link to={`/clients/${job.client.slug}/jobs`}>
                <h4 className="text-75 client__tab-bar-breadcrumb-link">
                  Jobs
                </h4>
              </Link>
              <FontAwesomeIcon
                className="mx-2"
                style={{ marginTop: 6 }}
                icon={faChevronRight}
                fixedWidth
              />
              <Link to={`/clients/${job.client.slug}/jobs/${job.reference}`}>
                <h4 className="text-75 client__tab-bar-breadcrumb-link mono">
                  {jobReference}
                </h4>
              </Link>
              <FontAwesomeIcon
                className="mx-2"
                style={{ marginTop: 6 }}
                icon={faChevronRight}
                fixedWidth
              />
              <Link to={`/clients/${job.client.slug}/jobs/${job.reference}`}>
                <h4 className="text-75 client__tab-bar-breadcrumb-link">
                  {job?.name}
                </h4>
              </Link>
              <FontAwesomeIcon
                className="mx-2"
                style={{ marginTop: 6 }}
                icon={faChevronRight}
                fixedWidth
              />
              <h4>Logs</h4>
            </div>
          )}
        />
        <Route
          path="/clients/:slug/jobs/:id/map"
          component={() => (
            <div className="d-flex">
              <Link to={`/clients/${job.client.slug}/jobs`}>
                <h4 className="text-75 client__tab-bar-breadcrumb-link">
                  Jobs
                </h4>
              </Link>
              <FontAwesomeIcon
                className="mx-2"
                style={{ marginTop: 6 }}
                icon={faChevronRight}
                fixedWidth
              />
              <Link to={`/clients/${job.client.slug}/jobs/${job.reference}`}>
                <h4 className="text-75 client__tab-bar-breadcrumb-link mono">
                  {jobReference}
                </h4>
              </Link>
              <FontAwesomeIcon
                className="mx-2"
                style={{ marginTop: 6 }}
                icon={faChevronRight}
                fixedWidth
              />
              <Link to={`/clients/${job.client.slug}/jobs/${job.reference}`}>
                <h4 className="text-75 client__tab-bar-breadcrumb-link">
                  {job?.name}
                </h4>
              </Link>
              <FontAwesomeIcon
                className="mx-2"
                style={{ marginTop: 6 }}
                icon={faChevronRight}
                fixedWidth
              />
              <h4>Map</h4>
            </div>
          )}
        />
      </Switch>
    </PortalBreadcrumb>
  );
};

export default ClientJobBreadcrumb;
