import { useLazyQuery, useMutation } from '@apollo/client';
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import React, { useCallback, useMemo, useState } from 'react';
import { JobReportTemplate, QueryResult } from 'lib/types';
import { useClient } from 'lib/hooks';
import { getId } from 'lib/utils';
import Checkbox from '../Checkbox';
import {
  createJobReportsMutationOnCompeted,
  useReducerContext,
} from '../ClientJob/reducer';
import SimpleButton from '../SimpleButton';
import SimpleButtonWithWindow from '../SimpleButtonWithWindow';
import {
  CREATE_JOB_REPORTS_MUTATION,
  JOB_REPORT_TEMPLATES_QUERY,
} from './query';
import {
  ClientJobReportsButtonProps,
  CreateJobReportsMutationData,
  CreateJobReportsMutationVariables,
  JobReportTemplatesQueryData,
  JobReportTemplatesQueryVariables,
} from './types';
import { Small } from '../Typography';
import { Wrapper, Duplicates } from './styled';

const ClientJobReportsButton = ({ jobVisit }: ClientJobReportsButtonProps) => {
  const client = useClient();
  const [, dispatch] = useReducerContext();

  const [jobReportTemplates, setJobReportTemplates] = useState<
    QueryResult<JobReportTemplate>
  >({ results: [], count: 0 });

  const [selected, setSelected] = useState<JobReportTemplate['id'][]>([]);

  const [getJobReportTemplates] = useLazyQuery<
    JobReportTemplatesQueryData,
    JobReportTemplatesQueryVariables
  >(JOB_REPORT_TEMPLATES_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setJobReportTemplates(data.jobReportTemplates);
    },
  });

  const handleOnOpened = () => {
    setSelected([]);
    if (!jobVisit) return;
    getJobReportTemplates({
      variables: { clientId: client.id, jobVisitId: jobVisit.id },
    });
  };

  const handleOnSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const id = getId(event) as string;
    setSelected((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter(
          (jobReportTemplateId) => jobReportTemplateId !== id
        );
      }
      return [...prevSelected, id];
    });
  };

  const [createJobReports] = useMutation<
    CreateJobReportsMutationData,
    CreateJobReportsMutationVariables
  >(CREATE_JOB_REPORTS_MUTATION);

  const handleOnAdd = useCallback(async () => {
    if (!jobVisit) return;
    const { data } = await createJobReports({
      variables: { jobReportTemplateIds: selected, jobVisitId: jobVisit.id },
    });
    if (!data) return;
    dispatch(createJobReportsMutationOnCompeted(data));
  }, [createJobReports, dispatch, jobVisit, selected]);

  const renderJobReportTemplate = useCallback(
    (jobReportTemplate: JobReportTemplate) => {
      return (
        <div key={jobReportTemplate.id} className="text-nowrap">
          <Checkbox
            checked={selected.includes(jobReportTemplate.id)}
            label={jobReportTemplate.name}
            data-id={jobReportTemplate.id}
            onChange={handleOnSelect}
          />
        </div>
      );
    },
    [selected]
  );

  const notIncludedJobReportTemplates = useMemo(() => {
    return jobReportTemplates.results.filter(
      (jobReportTemplate) => !jobReportTemplate.isIncluded
    );
  }, [jobReportTemplates]);

  const includedJobReportTemplates = useMemo(() => {
    return jobReportTemplates.results.filter(
      (jobReportTemplate) => jobReportTemplate.isIncluded
    );
  }, [jobReportTemplates]);

  const renderWindowChildren = useCallback(
    ({ close }) => {
      return (
        <Wrapper className="p-3">
          {notIncludedJobReportTemplates.length > 0 && (
            <div className="mb-4">
              <div className="mb-2">
                <Small>Add new report</Small>
              </div>
              <div>
                {notIncludedJobReportTemplates.map(renderJobReportTemplate)}
              </div>
            </div>
          )}
          {includedJobReportTemplates.length > 0 && (
            <Duplicates className="mb-4">
              <div className="mb-2">
                <Small>Add duplicate report</Small>
              </div>
              <div>
                {includedJobReportTemplates.map(renderJobReportTemplate)}
              </div>
            </Duplicates>
          )}
          <div className="d-flex justify-content-between">
            <SimpleButton icon={faTimes} secondary onClick={close}>
              Close
            </SimpleButton>
            <SimpleButton
              icon={faPlus}
              onClick={async () => {
                await handleOnAdd();
                close();
              }}
            >
              Add
            </SimpleButton>
          </div>
        </Wrapper>
      );
    },
    [
      handleOnAdd,
      includedJobReportTemplates,
      notIncludedJobReportTemplates,
      renderJobReportTemplate,
    ]
  );

  return (
    <SimpleButtonWithWindow
      position="right"
      openMode="click"
      windowChildren={renderWindowChildren}
      onOpened={handleOnOpened}
      icon={faPlus}
    >
      Add report
    </SimpleButtonWithWindow>
  );
};

export default ClientJobReportsButton;
