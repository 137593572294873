import React, { Reducer, useMemo, useReducer } from 'react';
import { Row, Col } from 'reactstrap';
import { useQuery } from '@apollo/client';
import { AnyAction } from 'redux';
import { useClient } from 'lib/hooks';
import { DeckGL } from 'deck.gl';
import { _MapContext, Marker, StaticMap } from 'react-map-gl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons/faMapMarkerAlt';
import { useTheme } from 'styled-components';
import { GeoJsonLayer } from '@deck.gl/layers';
import CardWrapper from '../CardWrapper';
import PortalBreadcrumb from '../PortalBreadcrumb';
import ClientSitesTable from '../ClientSitesTable';
import { SITES_QUERY } from './query';
import reducer, {
  initialState,
  mapViewStateOnChange,
  ReducerContext,
  sitesQueryOnCompleted,
  toggleSitesSidePanel,
} from './reducer';
import { ClientSitesState, SitesQueryData, SitesQueryVariables } from './types';
import ClientSitesSidePanel from '../ClientsSitesSidePanel';
import { MarkerWrapper } from '../ClientSitesMap/styled';
import { Overlay } from './styled';

const MAPBOX_API_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_API_ACCESS_TOKEN;

const ClientSites = () => {
  const [state, dispatch] = useReducer<Reducer<ClientSitesState, AnyAction>>(
    reducer,
    initialState
  );
  const { sites, map } = state;

  const client = useClient();

  useQuery<SitesQueryData, SitesQueryVariables>(SITES_QUERY, {
    fetchPolicy: 'no-cache',
    variables: {
      clientId: client?.id,
    },
    onCompleted: (data) => {
      dispatch(sitesQueryOnCompleted(data));
    },
  });

  const handleToggleSitesSidePanel = (override?: boolean) => {
    dispatch(toggleSitesSidePanel(override));
  };

  const handleOnViewStateChange = (event: any) => {
    dispatch(mapViewStateOnChange(event.viewState));
  };

  const layer = useMemo(
    () =>
      new GeoJsonLayer({
        id: 'polygon',
        data: map.polygon,
        pickable: true,
        filled: true,
        extruded: true,
        getFillColor: () => [16, 108, 18, 75],
        getElevation: 30,
      }),
    [map.polygon]
  );

  const theme = useTheme();
  return (
    <>
      <PortalBreadcrumb>
        <h4>Sites</h4>
      </PortalBreadcrumb>
      <ReducerContext.Provider value={[state, dispatch]}>
        <div className="h-100">
          <Row className="h-100">
            <Col xl={7} className="h-100">
              <CardWrapper className="p-0 pb-3 h-100">
                <div style={{ height: 'calc(100% - 70px - 56px)' }}>
                  <ClientSitesTable />
                </div>
              </CardWrapper>
            </Col>
            <Col className="h-100">
              <CardWrapper className="position-relative h-100 p-1">
                <Overlay>
                  <p className="mb-0">
                    Showing {sites.length} site{sites.length === 1 ? '' : 's'}
                  </p>
                </Overlay>
                <div
                  className="position-relative overflow-hidden"
                  style={{ height: '100%', borderRadius: '0.25rem' }}
                >
                  <DeckGL
                    width="100%"
                    height="100%"
                    viewState={map.viewState}
                    controller
                    onViewStateChange={handleOnViewStateChange}
                    ContextProvider={_MapContext.Provider}
                    layers={[layer]}
                  >
                    <StaticMap
                      mapboxApiAccessToken={MAPBOX_API_ACCESS_TOKEN}
                      mapStyle={theme.mapboxStyle}
                      width="100%"
                      height="100%"
                    />
                    {sites
                      .filter((site) => site.location.point)
                      .map((site) => (
                        <Marker {...site.location.point}>
                          <MarkerWrapper>
                            <FontAwesomeIcon
                              className="marker-icon"
                              icon={faMapMarkerAlt}
                            />
                            <p
                              className="mb-0"
                              style={{ transform: 'translate(-50%, 6px)' }}
                            >
                              {(site.index || 0) + 1}
                            </p>
                          </MarkerWrapper>
                        </Marker>
                      ))}
                  </DeckGL>
                </div>
              </CardWrapper>
            </Col>
          </Row>
        </div>
        <ClientSitesSidePanel toggle={handleToggleSitesSidePanel} />
      </ReducerContext.Provider>
    </>
  );
};

export default ClientSites;
