import { gql } from '@apollo/client';

export const UPDATE_EOT_REQUEST_MUTATION = gql`
  mutation ($jobIssueId: ID!, $status: String!) {
    updateJobEotRequest(jobIssueId: $jobIssueId, status: $status) {
      block {
        type
        item {
          ... on LogItem {
            id
            type
            opensIssue
            closesIssue
            context
            meta
            data
            dateTime
            user {
              id
              initials
              name
            }
          }
          ... on JobEOTRequest {
            id
            dateTimeCreated
            dateTimeOld
            dateTimeNew
            dateTimeResponded
            status
            requestType
            message
            user {
              id
              initials
              name
            }
          }
        }
      }
    }
  }
`;
