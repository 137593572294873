import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons/faSpinnerThird';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Provider as ReduxProvider,
  useDispatch,
  useSelector,
} from 'react-redux';
import { ApolloProvider, useQuery } from '@apollo/client';
import { httpClient } from 'lib/apollo';
import Router from 'components/LayoutRouter';
import { v4 as uuid4 } from 'uuid';
import '../index.css';
import { ThemeProvider } from 'styled-components';
import { Client, User } from 'lib/types';
import { useUser, useWebsocketUri } from 'lib/hooks';
import { State } from 'lib/redux/types';
import makeStore from 'lib/redux/store';
import { INITIAL_QUERY } from './query';
import LoginAwaitingConfiguration from '../components/LoginAwaitingConfiguration';
import 'lib/theme/bryntum/dark.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from './logo.svg';

const ThemeProviderWrapper = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  const theme = useSelector((state: State) => state.theme);
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

const NotificationsSubscriber = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  const dispatch = useDispatch();
  const user = useUser();

  const websocketUri = useWebsocketUri('notification/');
  useEffect(() => {
    if (!user || !user.isConfigured) return () => {};
    const websocket = new WebSocket(websocketUri);
    websocket.onmessage = (rawResponse) => {
      const response = {
        data: JSON.parse(rawResponse.data),
      };
      if (!response.data) return;
      const { notification, notificationCount } =
        response.data.notificationSubscription;
      dispatch({
        type: 'NOTIFICATIONS__ON_NEW_NOTIFICATION',
        payload: {
          notification,
          notificationCount,
        },
      });
    };
    return () => {
      websocket.close();
    };
  }, [dispatch, user, websocketUri]);

  return <>{children}</>;
};

const AppInner = () => {
  const [initialData, setInitialData] = useState<{
    clients: Client[];
    user: User;
    notificationCount: number;
  }>();

  useQuery(INITIAL_QUERY, {
    client: httpClient,
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setInitialData(data);
      sessionStorage.setItem('sessionId', uuid4());
    },
  });

  const store = useMemo(() => {
    if (!initialData) return null;
    return makeStore({
      clients: initialData.clients,
      themeMode: initialData.user?.themeMode || 'DARK',
      user: initialData.user,
      notificationCount: initialData.notificationCount,
    });
  }, [initialData]);

  return store ? (
    <ReduxProvider store={store}>
      <ApolloProvider client={httpClient}>
        <NotificationsSubscriber>
          <ThemeProviderWrapper>
            {initialData?.user?.isConfigured ? (
              <Router />
            ) : (
              <LoginAwaitingConfiguration />
            )}
          </ThemeProviderWrapper>
        </NotificationsSubscriber>
      </ApolloProvider>
    </ReduxProvider>
  ) : (
    <div className="center" style={{ height: 'calc(100vh - 2rem)' }}>
      <img src={logo} alt="logo" style={{ width: 100, opacity: 0.9 }} />
      <div className="text-center" style={{ marginTop: '2rem' }}>
        <FontAwesomeIcon
          icon={faSpinnerThird}
          spin
          color="#fff"
          style={{ width: 32, height: 32 }}
        />
      </div>
    </div>
  );
};

export default AppInner;
