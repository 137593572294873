import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { NamedProps } from 'react-select/base';
import { Small } from '../Typography';
import Select from '../Select';
import { Label } from './styled';

const SelectField = ({
  input,
  meta,
  label,
  noLabel = false,
  ...rest
}: FieldRenderProps<{ label: string; noLabel?: boolean; value: string }> &
  NamedProps) => {
  const hasError = !!meta.touched && !!meta.error;
  return (
    <div>
      {!noLabel && (
        <Label
          htmlFor={input.name}
          className="d-inline-flex w-100 justify-content-between"
        >
          <Small className="mb-1">{label || input.name}</Small>
          {hasError && (
            <Small className="text-danger" capitalize={false}>
              {meta.error}
            </Small>
          )}
        </Label>
      )}
      <Select hasError={hasError} {...input} {...rest} />
    </div>
  );
};

export default SelectField;
