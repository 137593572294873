import React, { useEffect, useMemo, useRef, useState } from 'react';
import SimpleButton from '../SimpleButton';
import Window from '../Window';
import { SimpleButtonWithWindowProps } from './types';

const SimpleButtonWithWindow = ({
  windowChildren,
  children,
  active,
  openMode = 'hover',
  isOpen: isOpenExternal,
  position = 'center',
  onOpened,
  onClosed,
  ...props
}: SimpleButtonWithWindowProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const windowRef = useRef<HTMLDivElement>(null);
  const [isOpenState, setIsOpenState] = useState(false);
  const isOpen = useMemo(
    () => isOpenState || isOpenExternal,
    [isOpenState, isOpenExternal]
  );

  useEffect(() => {
    const buttonElement = buttonRef.current;
    const windowElement = windowRef.current;
    if (!buttonElement || !windowElement) return;
    const buttonRect = buttonElement.getBoundingClientRect();
    if (isOpen) {
      windowElement.style.opacity = '1';
      windowElement.style.top = `${buttonRect.bottom}px`;
      switch (position) {
        case 'center': {
          const left = Math.min(
            buttonRect.left,
            window.innerWidth - windowElement.offsetWidth
          );
          windowElement.style.left = `${left}px`;
          break;
        }
        case 'right': {
          const right = Math.max(window.innerWidth - buttonRect.right, 0);
          windowElement.style.right = `${right - 8}px`;
          break;
        }
        default:
          throw Error();
      }
    } else {
      windowElement.removeAttribute('style');
    }
  });

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setIsOpenState((prevIsOpen) => {
      if (onOpened) onOpened();
      if (prevIsOpen) {
        return false;
      }
      setTimeout(() => {
        if (windowRef.current) windowRef.current.focus();
      }, 50);
      return true;
    });
  };

  const handleOnMouseEnter = () => {
    setIsOpenState(true);
    if (onOpened) onOpened();
  };

  const handleOnMouseLeave = () => {
    setIsOpenState(false);
    if (onClosed) onClosed();
  };

  const handleClose = () => {
    setIsOpenState(false);
    if (onClosed) onClosed();
  };

  const getButtonEventHandlerProps = () => {
    switch (openMode) {
      case 'click':
        return {
          onClick: handleOnClick,
        };
      case 'hover':
        return {
          onMouseEnter: handleOnMouseEnter,
          onMouseLeave: handleOnMouseLeave,
        };
      default:
        return {};
    }
  };

  const getWindowEventHandlerProps = () => {
    switch (openMode) {
      case 'click':
        return {};
      case 'hover':
        return {
          onMouseEnter: handleOnMouseEnter,
          onMouseLeave: handleOnMouseLeave,
        };
      default:
        return {};
    }
  };

  return (
    <>
      <SimpleButton
        {...props}
        active={isOpen || active}
        innerRef={buttonRef}
        {...getButtonEventHandlerProps()}
      >
        {children}
      </SimpleButton>
      {isOpen && (
        <Window
          tabIndex={0}
          position={position}
          innerRef={windowRef}
          // onBlur={() => {
          //   setIsOpenState(false);
          // }}
          {...getWindowEventHandlerProps()}
        >
          {windowChildren({ close: handleClose })}
        </Window>
      )}
    </>
  );
};

export default SimpleButtonWithWindow;
