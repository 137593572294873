import styled from 'styled-components';

export const Wrapper = styled.div`
  .text-75 {
    color: ${({ theme }) => theme.color.cardText.alpha(0.75).hex()};
  }
`;

export const Feed = styled.div``;

export const Message = styled.div`
  background-color: ${({ theme }) => theme.color.contentBackground.hex()};
`;
