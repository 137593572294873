import React from 'react';
import { faCog } from '@fortawesome/pro-duotone-svg-icons/faCog';
import { faFileChartLine } from '@fortawesome/pro-duotone-svg-icons/faFileChartLine';
import { useClient } from 'lib/hooks';
import { useTheme } from 'styled-components';
import { Switch, Route, useLocation } from 'wouter';
import { faTasksAlt } from '@fortawesome/pro-duotone-svg-icons/faTasksAlt';
import { routeMatcher } from '../../lib/utils';
import ClientSettingsReports from '../ClientSettingsReports';
import ClientSettingsReportTemplateEditor from '../ClientSettingsReportTemplateEditor';
import PortalBreadcrumb from '../PortalBreadcrumb';
import PortalTopbarExtension from '../PortalTopbarExtension';
import SimpleButtonLink from '../SimpleButtonLink';
import CardWrapper from '../CardWrapper';
import ClientSettingsManagement from '../ClientSettingsManagement';

const ClientSettings = () => {
  const theme = useTheme();
  const client = useClient();
  const [location] = useLocation();

  return (
    <>
      <PortalBreadcrumb>
        <h4>Settings</h4>
      </PortalBreadcrumb>
      <PortalTopbarExtension>
        <CardWrapper
          className="px-4 py-2 rounded-0"
          style={{ borderLeft: theme.border, borderBottom: theme.border }}
        >
          <SimpleButtonLink
            to={`/clients/${client.slug}/settings`}
            buttonProps={{
              icon: faCog,
              inButtonToolbar: true,
              active: routeMatcher('/clients/:slug/settings', location)[0],
            }}
          >
            General
          </SimpleButtonLink>
          <SimpleButtonLink
            to={`/clients/${client.slug}/settings/reports`}
            buttonProps={{
              icon: faFileChartLine,
              inButtonToolbar: true,
              active: routeMatcher(
                '/clients/:slug/settings/reports/:rest*',
                location
              )[0],
            }}
          >
            Reports
          </SimpleButtonLink>
          <SimpleButtonLink
            to={`/clients/${client.slug}/settings/management`}
            buttonProps={{
              icon: faTasksAlt,
              inButtonToolbar: true,
              active: routeMatcher(
                '/clients/:slug/settings/management/:rest*',
                location
              )[0],
            }}
          >
            Management
          </SimpleButtonLink>
        </CardWrapper>
      </PortalTopbarExtension>
      <Switch>
        <Route
          path="/clients/:slug/settings/reports"
          component={ClientSettingsReports}
        />
        <Route
          path="/clients/:slug/settings/reports/templates/:id"
          component={ClientSettingsReportTemplateEditor}
        />
        <Route
          path="/clients/:slug/settings/management"
          component={ClientSettingsManagement}
        />
      </Switch>
    </>
  );
};

export default ClientSettings;
