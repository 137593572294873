import React from 'react';
import classNames from 'classnames';
import { useHovered } from 'lib/hooks';
import { useTheme } from 'styled-components';
import Button from '../Button';
import SimpleButton from '../SimpleButton';
import { ButtonMultiProps } from './types';
import { Wrapper } from './styled';

const getBorderRadius = (first: boolean, last: boolean) => {
  if (first) {
    return '0.75rem 0.75rem 0.5rem 0.5rem';
  }
  if (last) {
    return '0.5rem 0.5rem 0.75rem 0.75rem';
  }
  return '0.5rem';
};

const ButtonMulti = ({ label, icon, options }: ButtonMultiProps) => {
  const [hovered, hoveredCallbacks] = useHovered();
  const theme = useTheme();
  return (
    <Wrapper {...hoveredCallbacks}>
      <Button className="w-100 button-main" icon={icon}>
        {label}
      </Button>
      {hovered && (
        <div className="options-list-outer">
          <div className="options-list-inner shadow">
            {options.map((option, index) => {
              const first = index === 0;
              const last = index === options.length - 1;
              return (
                <SimpleButton
                  key={option.label}
                  className={classNames('option-button', { 'mb-1': !last })}
                  style={{ borderRadius: getBorderRadius(first, last) }}
                  icon={option.icon}
                  backgroundHoverColor={theme.color.buttonMultiHover.hex()}
                  onClick={option.onClick}
                >
                  {option.label}
                </SimpleButton>
              );
            })}
          </div>
        </div>
      )}
    </Wrapper>
  );
};

export default ButtonMulti;
