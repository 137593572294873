import React, { useEffect, useRef, useState } from 'react';
import { useToggle } from 'react-use';
import { useTheme } from 'styled-components';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-light-svg-icons/faExclamationTriangle';
import { useHovered, useScroll } from 'lib/hooks';
import { SimpleButtonProps } from '../SimpleButton/types';
import { Wrapper, ConfirmWindow } from './styled';
import SimpleButton from '../SimpleButton';
import Portal from '../Portal';

const SimpleConfirmButton = ({
  confirmMessage = <span>Are you sure?</span>,
  backgroundColor,
  onClick,
  position = 'bottom',
  hoverStyles,
  value,
  ...props
}: React.PropsWithChildren<SimpleButtonProps> & {
  position?: 'bottom' | 'top';
  confirmMessage?: React.ReactNode;
}) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const confirmWindowRef = useRef<HTMLDivElement>(null);
  const [isOpen, toggle] = useToggle(false);
  const [storedEvent, setStoredEvent] =
    useState<React.MouseEvent<HTMLButtonElement> | null>();

  useScroll();
  useEffect(() => {
    const buttonElement = buttonRef.current;
    const confirmWindowElement = confirmWindowRef.current;
    if (!buttonElement || !confirmWindowElement) return;
    const buttonRect = buttonElement.getBoundingClientRect();
    if (isOpen) {
      confirmWindowElement.style.opacity = '1';
      switch (position) {
        case 'bottom':
          confirmWindowElement.style.top = `${buttonRect.bottom + 8}px`;
          break;
        case 'top':
          confirmWindowElement.style.bottom = `${
            window.innerHeight - buttonRect.top + 8
          }px`;
          break;
        default:
      }
      if (
        buttonRect.left - window.innerWidth - confirmWindowElement.offsetWidth >
        0
      ) {
        confirmWindowElement.style.left = `${buttonRect.left}px`;
      } else {
        confirmWindowElement.style.left = 'auto';
        confirmWindowElement.style.right = `${
          window.innerWidth - buttonRect.right
        }px`;
      }
    } else {
      confirmWindowElement.removeAttribute('style');
    }
  });

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    toggle();
    setStoredEvent(event);
  };

  const handleOnConfirm = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    toggle();
    if (onClick && storedEvent) {
      storedEvent.currentTarget = event.currentTarget;
      onClick(storedEvent);
    }
  };

  const handleOnCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();
    toggle();
  };

  const theme = useTheme();

  const [cancelOnHovered, cancelHoveredCallbacks] = useHovered();
  const [confirmOnHovered, confirmHoveredCallbacks] = useHovered();

  return (
    <Wrapper>
      <SimpleButton
        innerRef={buttonRef}
        hoverStyles={hoverStyles}
        backgroundColor={
          isOpen && hoverStyles
            ? theme.color.contentBackground.hex()
            : backgroundColor
        }
        onClick={handleOnClick}
        {...props}
      />
      <Portal>
        <ConfirmWindow
          ref={confirmWindowRef}
          className="shadow"
          cancelOnHovered={cancelOnHovered}
          confirmOnHovered={confirmOnHovered}
        >
          <div className="mb-4">
            <FontAwesomeIcon
              className="mr-1 confirm-message-icon"
              color="orange"
              icon={faExclamationTriangle}
              fixedWidth
            />
            {confirmMessage}
          </div>
          <div className="d-flex justify-content-end">
            <SimpleButton
              className="mr-1"
              icon={faTimes}
              backgroundHoverColor={theme.color.cardBackground.hex()}
              onClick={handleOnCancel}
              {...cancelHoveredCallbacks}
            >
              Cancel
            </SimpleButton>
            <SimpleButton
              icon={faCheck}
              backgroundHoverColor={theme.color.cardBackground.hex()}
              onClick={handleOnConfirm}
              value={value}
              {...confirmHoveredCallbacks}
            >
              Confirm
            </SimpleButton>
          </div>
        </ConfirmWindow>
      </Portal>
    </Wrapper>
  );
};

SimpleConfirmButton.defaultProps = {
  confirmMessage: <span>Are you sure?</span>,
};

export default SimpleConfirmButton;
