import { gql } from '@apollo/client';

export const JOB_QUERY = gql`
  query ($jobId: ID, $jobReference: String) {
    job(jobId: $jobId, jobReference: $jobReference, logViewAction: false) {
      id
      reference
      name
      status {
        name
        nameDisplay
        flag {
          color
          icon
        }
      }
      visits {
        id
        label
        dateTimeStart
        dateTimeEnd
        user {
          id
          name
        }
      }
    }
  }
`;
