import styled from 'styled-components';

export const Wrapper = styled.a`
  position: relative;
  color: ${({ theme }) => theme.color.cardText.hex()};
  transition: background-color 150ms;
  border-radius: 0.25rem;
  overflow: hidden;

  p {
    margin-bottom: 0;
  }

  &:hover {
    color: ${({ theme }) => theme.color.cardText.hex()};
    text-decoration: none;
    background-color: ${({ theme }) => theme.color.contentBackground.hex()};
    transition: background-color 50ms;
  }

  div.job-report-card-delete {
    position: absolute;
    top: 0;
    right: 0;
    width: 34px;
    text-align: center;
    border-radius: 0 0 0 0.25rem;

    &:hover {
      background-color: ${({ theme }) => theme.color.danger.hex()};
    }
  }
`;
