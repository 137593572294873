import React from 'react';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { useMutation } from '@apollo/client';
import SimpleButton from '../SimpleButton';
import { UPDATE_EOT_REQUEST_MUTATION } from './query';
import { ClientJobIssueEOTResponseFormProps } from './types';

const ClientJobIssueEOTResponseForm = ({
  issue,
  updateEotRequestOnCompleted,
}: ClientJobIssueEOTResponseFormProps) => {
  const [updateEotRequest] = useMutation(UPDATE_EOT_REQUEST_MUTATION);

  const handleUpdateEOTRequest = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    const { value: status } = event.currentTarget;
    updateEotRequest({ variables: { jobIssueId: issue.id, status } }).then(
      updateEotRequestOnCompleted
    );
  };

  return (
    <div className="p-3">
      <SimpleButton
        className="mr-2"
        icon={faCheck}
        value="APPROVED"
        onClick={handleUpdateEOTRequest}
      >
        Approve
      </SimpleButton>
      <SimpleButton
        icon={faTimes}
        value="REJECTED"
        onClick={handleUpdateEOTRequest}
      >
        Reject
      </SimpleButton>
    </div>
  );
};

export default ClientJobIssueEOTResponseForm;
