import styled from 'styled-components';
import { OverlayProps } from './types';

export const Overlay = styled.button<OverlayProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  border: none;
  z-index: 5;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: opacity 100ms;
  cursor: default !important;
`;
