import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { useClient } from 'lib/hooks';
import { httpClient } from 'lib/apollo';
import { AsyncProps } from 'react-select/async';
import { ClientSite, Option } from 'lib/types';
import { ValueType } from 'react-select';
import SelectAsync from '../SelectAsync';
import { SEARCH_SITES_QUERY } from './query';
import { SearchSitesQueryData, SearchSitesQueryVariables } from './types';
import { Small } from '../Typography';

const JobSiteField = ({ input, meta }: FieldRenderProps<ClientSite>) => {
  const client = useClient();

  const handleLoadOptions: AsyncProps<any>['loadOptions'] = (
    value: string,
    callback
  ) => {
    httpClient
      .query<SearchSitesQueryData, SearchSitesQueryVariables>({
        query: SEARCH_SITES_QUERY,
        variables: { clientId: client.id, searchTerm: value },
      })
      .then((response) => {
        const { results } = response.data.sites;
        callback(
          results.map((site) => ({
            value: site,
            label: site.name,
          }))
        );
      });
  };

  const handleOnChange = (option: ValueType<Option, false>) => {
    if (!option) return;
    input.onChange(option.value);
  };

  const hasError = !!meta.touched && !!meta.error;

  return (
    <div>
      <div className="d-flex justify-content-between">
        <div>
          <Small className="ml-2">Site</Small>
        </div>
        {hasError && (
          <div>
            <Small className="text-danger" capitalize={false}>
              {meta.error}
            </Small>
          </div>
        )}
      </div>
      <SelectAsync
        onFocus={input.onFocus}
        onBlur={input.onBlur}
        hasError={hasError}
        menuPortalTarget={document.body}
        defaultOptions
        loadOptions={handleLoadOptions}
        onChange={handleOnChange}
        value={{
          value: input.value,
          label: input.value.name,
        }}
      />
    </div>
  );
};

export default JobSiteField;
