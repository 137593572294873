import React, { useEffect, useRef, useState } from 'react';
import { SimpleButtonReactiveProps } from './types';
import SimpleButtonWithWindow from '../SimpleButtonWithWindow';

const SimpleButtonReactive = ({
  children,
  isReactive,
  reactTo,
  ...buttonProps
}: SimpleButtonReactiveProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const prevReactTo = useRef();

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (isReactive !== false && prevReactTo.current !== reactTo) {
      setIsOpen(true);
      timeout = setTimeout(() => {
        setIsOpen(false);
      }, 2000);
    }
    prevReactTo.current = reactTo;
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [isReactive, reactTo]);

  return (
    <SimpleButtonWithWindow
      {...buttonProps}
      openMode="controlled"
      isOpen={isOpen}
    >
      {children}
    </SimpleButtonWithWindow>
  );
};

export default SimpleButtonReactive;
