import qs from 'qs';
import getWebsocketUri from '../utils/getWebsocketUri';
import { useSessionId } from './index';
import useWebsocketToken from './useWebsocketToken';

const useWebsocketUri = (
  path: string,
  query: Record<string, string | undefined> = {}
) => {
  const sessionId = useSessionId();
  const websocketToken = useWebsocketToken();
  const websocketUri = getWebsocketUri();
  const queryString = qs.stringify({
    ...query,
    sessionId,
    token: websocketToken.token,
  });
  return `${websocketUri}${path}?${queryString}`;
};

export default useWebsocketUri;
