import React, { useCallback, useState } from 'react';
import { faClock } from '@fortawesome/pro-duotone-svg-icons/faClock';
import { useClient } from 'lib/hooks';
import { useLazyQuery } from '@apollo/client';
import { parseDateTime } from 'lib/utils';
import { ActionItem, QueryResult } from 'lib/types';
import SimpleButtonWithWindow from '../SimpleButtonWithWindow';
import { ACTION_ITEMS_QUERY } from './query';
import Table from '../Table';
import JobLink from '../JobLink';

const ClientRecentAction = ({
  id,
  type,
  dateTime,
  instanceObjectId,
  meta,
}: ActionItem) => {
  const client = useClient();
  let inner;
  switch (type) {
    case 'VIEW_JOB':
      inner = (
        <>
          <td>Job</td>
          <td className="mono">
            <JobLink
              client={client}
              job={{ id: instanceObjectId, reference: meta?.reference }}
              windowProps={{ style: { zIndex: 9999 } }}
            />
          </td>
        </>
      );
      break;
    case 'VIEW_JOB_ISSUE':
      inner = (
        <>
          <td>Issue</td>
          <td />
        </>
      );
      break;
    default:
      inner = <></>;
  }
  return (
    <tr key={id}>
      <td className="mono">
        <small>{parseDateTime(dateTime)}</small>
      </td>
      {inner}
    </tr>
  );
};

const ClientRecentActions = () => {
  const client = useClient();
  const [actionItems, setActionItems] = useState<QueryResult<ActionItem>>({
    results: [],
    count: 0,
  });

  const [getActionItems] = useLazyQuery(ACTION_ITEMS_QUERY, {
    fetchPolicy: 'no-cache',
    variables: { clientId: client.id, page: 1, size: 20 },
    onCompleted: (data) => {
      setActionItems(data.actionItems);
    },
  });

  const handleOnOpened = () => {
    getActionItems();
  };

  const renderWindowChildren = useCallback(() => {
    return (
      <div
        className="pt-2"
        style={{ borderRadius: '0.5rem', width: 500, height: '50vh' }}
      >
        <Table flexBases={[35, 15, 50]}>
          <thead>
            <tr>
              <th>Time</th>
              <th>Type</th>
              <th>Reference</th>
            </tr>
          </thead>
          <tbody>
            {actionItems.results.map((actionItem) => (
              <ClientRecentAction {...actionItem} />
            ))}
          </tbody>
        </Table>
      </div>
    );
  }, [actionItems.results]);

  return (
    <SimpleButtonWithWindow
      icon={faClock}
      inButtonToolbar
      windowChildren={renderWindowChildren}
      onOpened={handleOnOpened}
    >
      Recently visited
    </SimpleButtonWithWindow>
  );
};

export default ClientRecentActions;
