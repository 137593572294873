import React from 'react';
import { Wrapper } from './styled';
import { FigureProps } from './types';

const Figure = ({ value, small = true, brackets = true }: FigureProps) => {
  return (
    <Wrapper as={small ? 'small' : 'span'} className="figure-value">
      {brackets && '('}
      {value || (value === 0 ? 0 : '-')}
      {brackets && ')'}
    </Wrapper>
  );
};

export default Figure;
