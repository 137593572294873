import React from 'react';
import { ClientJobIssueTagProps } from './types';
import { Wrapper } from './styled';

const ClientJobIssueTag = ({
  name,
  color,
  ...rest
}: ClientJobIssueTagProps) => {
  return (
    <Wrapper color={color} {...rest}>
      <small>{name}</small>
    </Wrapper>
  );
};

export default ClientJobIssueTag;
