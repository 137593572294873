import React, { useState } from 'react';
import { Link } from 'wouter';
import { Form, Field } from 'react-final-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { useMutation, useQuery } from '@apollo/client';
import { Col, Row } from 'reactstrap';
import { faInfo } from '@fortawesome/pro-solid-svg-icons/faInfo';
import stringReplace from 'react-string-replace';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons/faArrowRight';
import { faRadar } from '@fortawesome/pro-light-svg-icons/faRadar';
import { faMicrochip } from '@fortawesome/pro-light-svg-icons/faMicrochip';
import { useTheme } from 'styled-components';
import { faQuestion } from '@fortawesome/pro-solid-svg-icons/faQuestion';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { useClient, useSessionId } from 'lib/hooks';
import { JobInstantiationType } from 'lib/types';
import { faSquare } from '@fortawesome/pro-light-svg-icons/faSquare';
import { faCheckSquare } from '@fortawesome/pro-solid-svg-icons/faCheckSquare';
import { parseDateTime } from '../../lib/utils';
import {
  jobInstantiationOnCompleted,
  useReducerContext,
} from '../ClientJob/reducer';
import JobPriorityPill from '../JobPriorityPill';
import PortalBreadcrumb from '../PortalBreadcrumb';
import { Small } from '../Typography';
import {
  INSTANTIATE_JOB_MUTATION,
  JOB_INSTANTIATION_SUGGESTION_QUERY,
  UPDATE_JOB_INSTANTIATION_STATE_MUTATION,
} from './query';
import {
  InstantiationStageProps,
  JobInstantiationSuggestionQueryData,
  JobInstantiationSuggestionQueryVariables,
  InstantiationDetailsProps,
  FormValues,
  InstantiationSuggestion,
} from './types';
import { InstantiationStageWrapper, InstantiationStageInner } from './styled';
import SimpleButton from '../SimpleButton';
import JsonRenderer from '../JsonRenderer';
import Collapse from '../Collapse';
import JobLink from '../JobLink';
import ClientJobHomeMap from '../ClientJobHomeMap';
import CardWrapper from '../CardWrapper';
import ClientJobInstantiationConfirmationField from '../ClientJobInstantiationConfirmationField';

const inlineJobLink = (string: string) => {
  return stringReplace(
    string,
    /((?:INS|INT|INSS|HLP)(?:\d+))/g,
    (match, index) => (
      <JobLink key={index} job={{ reference: match }}>
        <span className="mono">{match}</span>
      </JobLink>
    )
  );
};

const InstantiationStage = ({
  children,
  className,
  icon,
  blockTitle,
  last = false,
  innerProps,
  ...rest
}: InstantiationStageProps) => {
  return (
    <InstantiationStageWrapper className={className} last={last} {...rest}>
      <div className="instantiation-stage-number-icon shadow-sm">
        <div />
        <span>
          <FontAwesomeIcon icon={icon} fixedWidth />
        </span>
      </div>
      {typeof blockTitle === 'string' ? (
        <h5 className="instantiation-stage-title">{blockTitle}</h5>
      ) : (
        blockTitle
      )}
      <InstantiationStageInner {...innerProps}>
        {children}
      </InstantiationStageInner>
    </InstantiationStageWrapper>
  );
};

const InstantiationDetails = ({
  type,
  name,
  index,
  last,
}: InstantiationDetailsProps) => {
  let inner = <></>;
  switch (type) {
    case 'INSTANTIATE_JOB':
      inner = (
        <div>
          <p className="text-75 mb-0">This job will be accepted.</p>
        </div>
      );
      break;
    case 'CREATE_GEOOP_JOB':
      inner = (
        <div>
          <p className="text-75 mb-0">A job will be created in GeoOp.</p>
        </div>
      );
      break;
    case 'INSTANTIATE_UI':
      inner = (
        <div>
          <p className="text-75 mb-0">Prepare UI.</p>
        </div>
      );
      break;
    default:
      return null;
  }
  return (
    <div>
      <div className="d-flex justify-content-between">
        <div>
          <Small>Step {index + 1}</Small>
          <h5>{name}</h5>
        </div>
        <div>{!last && <FontAwesomeIcon icon={faArrowRight} fixedWidth />}</div>
      </div>
      <div>{inner}</div>
    </div>
  );
};

const ClientJobInstantiation = () => {
  /*
   * This component handles job instantiation. Job instantiation is the process of
   * manual determination of the job state based on information provided by the
   * 3rd party service from whence the job has come.
   * */
  const client = useClient();
  const [{ job }, dispatch] = useReducerContext();
  const [instantiationSuggestion, setInstantiationSuggestion] =
    useState<InstantiationSuggestion | null>(null);
  const [instantiationTypes, setInstantiationTypes] = useState<
    JobInstantiationType[]
  >([]);
  const [, setInstantiationType] = useState<JobInstantiationType | null>(null);
  const [instantiationDetails, setInstantiationDetails] = useState([]);

  useQuery<
    JobInstantiationSuggestionQueryData,
    JobInstantiationSuggestionQueryVariables
  >(JOB_INSTANTIATION_SUGGESTION_QUERY, {
    fetchPolicy: 'no-cache',
    variables: { clientId: client.id, jobId: job?.id },
    onCompleted: (data) => {
      setInstantiationSuggestion(data.jobInstantiationSuggestion);
      setInstantiationTypes(data.jobInstantiationTypes);
    },
  });

  const [updateInstantiationState] = useMutation(
    UPDATE_JOB_INSTANTIATION_STATE_MUTATION
  );
  const handleConfirmOnClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    values: any
  ) => {
    event.preventDefault();
    if (!job) return;
    updateInstantiationState({
      variables: {
        jobId: job.id,
        instantiationTypeId: values.instantiationType.type.id,
        instantiationMeta: values.instantiationType.meta,
      },
    }).then((response) => {
      if (!response.data) return;
      const {
        instantiationType: instantiationTypeInstance,
        instantiationDetails: newInstantiationDetails,
      } = response.data.updateJobInstantiationState;
      setInstantiationDetails(newInstantiationDetails);
      setInstantiationType(instantiationTypeInstance);
    });
  };

  const sessionId = useSessionId();
  const [instantiateJob, { loading: instantiating }] = useMutation(
    INSTANTIATE_JOB_MUTATION
  );
  const handleOnSubmit = (values: FormValues) => {
    if (!job) return;
    instantiateJob({
      variables: {
        sessionId,
        jobId: job.id,
        data: {
          type: values.instantiationType.type.id,
          meta: values.instantiationType.meta ?? {},
        },
      },
    }).then((response) => {
      if (!response.data) return;
      dispatch(jobInstantiationOnCompleted(response.data));
    });
  };

  // TODO: Show suggestion reasoning (with data).
  const theme = useTheme();

  if (!job || !instantiationSuggestion) return null;
  return (
    <>
      <PortalBreadcrumb>
        <div className="d-flex">
          <Link to={`/clients/${job.client.slug}/jobs`}>
            <h4 className="text-75 client__tab-bar-breadcrumb-link">Jobs</h4>
          </Link>
          <FontAwesomeIcon
            className="mx-2"
            style={{ marginTop: 6 }}
            icon={faChevronRight}
            fixedWidth
          />
          <h4 className="text-75 mono">{job.reference}</h4>
          <FontAwesomeIcon
            className="mx-2"
            style={{ marginTop: 6 }}
            icon={faChevronRight}
            fixedWidth
          />
          <h4>Review incoming</h4>
        </div>
      </PortalBreadcrumb>
      <Row style={{ paddingBottom: 400 }}>
        <Col xs={6} className="offset-3">
          <InstantiationStage icon={faRadar} blockTitle="Incoming job">
            <div className="d-flex mb-3">
              <div className="mr-4">
                <Small>Group Ref #:</Small>
                <p className="mono mb-0">{job.group.reference}</p>
              </div>
              <div className="mr-4">
                <Small>Ref #:</Small>
                <p className="mono mb-0">{job.reference}</p>
              </div>
              <div className="mr-4">
                <Small>Name</Small>
                <p className="mb-0">{job.name}</p>
              </div>
              <div>
                <Small>Site</Small>
                <p className="mb-0">{job.site.name}</p>
              </div>
            </div>
            <div className="d-flex mb-3">
              <div className="mr-4">
                <Small>Priority:</Small>
                <JobPriorityPill job={job} type="both" />
              </div>
              <div className="mr-4">
                <Small>Response deadline</Small>
                <p className="mb-0 mono">
                  {parseDateTime(job.targetDateTimeStart)}
                </p>
              </div>
              <div>
                <Small>Completion deadline</Small>
                <p className="mb-0 mono">
                  {parseDateTime(job.targetDateTimeEnd)}
                </p>
              </div>
            </div>
            <div style={{ height: 300 }}>
              <ClientJobHomeMap overlay={false} />
            </div>
          </InstantiationStage>
          <InstantiationStage icon={faInfo} blockTitle="Highlighted details">
            <div className="d-flex mb-2">
              {job.rawMeta.reference && (
                <div className="mr-4">
                  <Small>Reference</Small>
                  <p>{inlineJobLink(job.rawMeta.reference)}</p>
                </div>
              )}
              {job.rawMeta.instructionDesc && (
                <div>
                  <Small>Description</Small>
                  <p>{inlineJobLink(job.rawMeta.instructionDesc)}</p>
                </div>
              )}
            </div>
            <Collapse
              closedLabel="View full details"
              openLabel="Hide full details"
            >
              <JsonRenderer json={job.rawMeta} expanded />
            </Collapse>
          </InstantiationStage>
          <Form
            initialValues={{
              instantiationType: {
                type: instantiationSuggestion.type
                  ? instantiationTypes.find(
                      (innerInstantiationType) =>
                        innerInstantiationType.id ===
                        instantiationSuggestion.type.id
                    )
                  : null,
                meta: instantiationSuggestion.meta?.[0],
              },
            }}
            onSubmit={handleOnSubmit}
          >
            {({ handleSubmit, values }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <InstantiationStage
                    icon={
                      instantiationDetails.length > 0 ? faCheck : faQuestion
                    }
                    blockTitle="Confirm job type"
                    last={instantiationDetails.length === 0}
                  >
                    <div>
                      <div className="mb-2">
                        <Small style={{ lineHeight: '34px' }}>Suggestion</Small>
                      </div>
                      <div className="mb-4">
                        {instantiationSuggestion.type && (
                          <Field
                            name="instantiationType"
                            component={ClientJobInstantiationConfirmationField}
                            instantiationTypes={instantiationTypes}
                            instantiationMetas={instantiationSuggestion.meta}
                          />
                        )}
                      </div>
                      <SimpleButton
                        active
                        icon={
                          instantiationDetails.length > 0
                            ? faCheckSquare
                            : faSquare
                        }
                        onClick={(event) => handleConfirmOnClick(event, values)}
                      >
                        Confirm
                      </SimpleButton>
                    </div>
                  </InstantiationStage>
                  {instantiationDetails.length > 0 && (
                    <>
                      <InstantiationStage
                        icon={faMicrochip}
                        blockTitle="Actions"
                        style={{
                          paddingBottom: '3rem',
                          borderBottomColor: theme.color.cardBackground.hex(),
                          borderBottomStyle: 'solid',
                          borderBottomWidth: 2,
                          borderBottomLeftRadius: '0.5rem',
                        }}
                      >
                        <Row>
                          {instantiationDetails.map((details, index) => {
                            const last =
                              index === instantiationDetails.length - 1;
                            return (
                              <Col xs={4}>
                                <InstantiationDetails
                                  {...details}
                                  index={index}
                                  last={last}
                                />
                              </Col>
                            );
                          })}
                        </Row>
                      </InstantiationStage>
                      <div
                        className="d-flex justify-content-end"
                        style={{ marginTop: -22 }}
                      >
                        <CardWrapper className="p-1">
                          <SimpleButton
                            icon={faArrowRight}
                            iconSide="right"
                            loading={instantiating}
                          >
                            Accept job
                          </SimpleButton>
                        </CardWrapper>
                      </div>
                    </>
                  )}
                </form>
              );
            }}
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default ClientJobInstantiation;
