import React, { useState } from 'react';
import { DocumentNode } from 'graphql';
import {
  LazyQueryHookOptions,
  LazyQueryResult,
  OperationVariables,
  useLazyQuery,
  WatchQueryFetchPolicy,
} from '@apollo/client';

type UseSearchReturn<TData, TVariables> = [
  {
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onClear: () => void;
  },
  LazyQueryResult<TData, TVariables>
];

type Variables<TVariables = OperationVariables> = {
  searchTerm: string;
} & TVariables;

const useSearch = <TData = any, TVariables = OperationVariables>(
  query: DocumentNode,
  options?: LazyQueryHookOptions<TData, Omit<TVariables, 'searchTerm'>>
): UseSearchReturn<TData, Variables> => {
  const [searchTerm, setSearchTerm] = useState('');
  const [saveTimeout, setSaveTimeout] = useState<NodeJS.Timeout>();

  const [search, result] = useLazyQuery<TData, Variables>(query, {
    ...options,
    nextFetchPolicy: options?.nextFetchPolicy as WatchQueryFetchPolicy,
    variables: undefined,
  });

  const handleSearchTermOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value: newSearchTerm } = event.target;
    setSearchTerm(newSearchTerm);
    if (saveTimeout) {
      clearTimeout(saveTimeout);
    }
    setSaveTimeout(
      setTimeout(() => {
        search({
          variables: { ...options?.variables, searchTerm: newSearchTerm },
        });
      }, 250)
    );
  };

  const handleSearchTermOnClear = () => {
    setSearchTerm('');
    search({
      variables: { ...options?.variables, searchTerm: '' },
    });
  };

  return [
    {
      value: searchTerm,
      onChange: handleSearchTermOnChange,
      onClear: handleSearchTermOnClear,
    },
    result,
  ];
};

export default useSearch;
