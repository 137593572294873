import styled from 'styled-components';

export const Wrapper = styled.a`
  display: inline-block;
  font-size: 12px;
  border-radius: 0.25rem;

  div.job-report-pill-number {
    border-radius: 0.25rem 0 0 0.25rem;
  }

  div.job-report-pill-name {
    margin-right: 4px;
    color: ${({ theme }) => theme.color.cardText.alpha(0.75).hex()};
    transition: color 150ms;
  }

  &:hover {
    color: ${({ theme }) => theme.color.cardText.hex()};
    text-decoration: none;

    div.job-report-pill-name {
      transition: color 50ms;
      color: ${({ theme }) => theme.color.cardText.hex()};
    }
  }
`;
