import { gql } from '@apollo/client';

export const REPORT_TEMPLATE_QUERY = gql`
  query ($reportTemplateId: ID!) {
    reportTemplate(reportTemplateId: $reportTemplateId) {
      id
      root {
        id
        children
        blocks {
          id
          label
          valueType
          parent {
            id
          }
          children
          logic {
            id
            logicConditionType
            logicConditionValue
            logicActionType
            children
          }
        }
      }
    }
    reportTemplateState(reportTemplateId: $reportTemplateId) {
      id
      value
      valueDefaults
      collapsedGroups
    }
  }
`;

export const UPDATE_REPORT_TEMPLATE_MUTATION = gql`
  mutation ($reportTemplateData: UpdateReportTemplateInput!) {
    updateReportTemplate(reportTemplateData: $reportTemplateData) {
      reportTemplate {
        id
      }
    }
  }
`;

export const UPDATE_REPORT_TEMPLATE_BLOCK = gql`
  mutation ($reportTemplateBlock: UpdateReportTemplateBlockInput!) {
    updateReportTemplateBlock(reportTemplateBlock: $reportTemplateBlock) {
      reportTemplateBlock {
        id
      }
    }
  }
`;

export const UPDATE_REPORT_TEMPLATE_STATE_LOGIC_MUTATION = gql`
  mutation (
    $reportTemplateStateId: ID!
    $reportTemplateBlockId: ID!
    $reportTemplateLogicId: ID!
  ) {
    updateReportTemplateStateLogic(
      reportTemplateStateId: $reportTemplateStateId
      reportTemplateBlockId: $reportTemplateBlockId
      reportTemplateLogicId: $reportTemplateLogicId
    ) {
      reportTemplateState {
        id
      }
    }
  }
`;

export const UPDATE_REPORT_TEMPLATE_STATE_COLLAPSED_GROUPS_MUTATION = gql`
  mutation ($reportTemplateStateId: ID!, $reportTemplateBlockId: ID!) {
    updateReportTemplateStateCollapsedGroups(
      reportTemplateStateId: $reportTemplateStateId
      reportTemplateBlockId: $reportTemplateBlockId
    ) {
      reportTemplateState {
        id
      }
    }
  }
`;
