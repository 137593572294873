import React from 'react';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag as faFlagDuotone } from '@fortawesome/pro-duotone-svg-icons/faFlag';
import { faFlag as faFlagRegular } from '@fortawesome/pro-regular-svg-icons/faFlag';
import { StatusProps } from './types';
import CardWrapper from '../CardWrapper';
import { StatusWrapper } from './styled';
import PortalBreadcrumb from '../PortalBreadcrumb';

const Status = ({ flagType, name }: StatusProps) => {
  return (
    <StatusWrapper flagType={flagType}>
      <div>
        <FontAwesomeIcon
          className="icon mr-2"
          icon={flagType === 'white' ? faFlagRegular : faFlagDuotone}
          fixedWidth
        />
        {name}
      </div>
    </StatusWrapper>
  );
};

const ClientWorkflowEditor = () => {
  return (
    <>
      <PortalBreadcrumb>
        <h4>Workflow</h4>
      </PortalBreadcrumb>
      <CardWrapper>
        <Row>
          <Col>
            <p>Green</p>
            <div>
              <Status flagType="green" name="Gutter Clearance" />
              <Status flagType="green" name="Platform Repairs" />
              <Status flagType="green" name="To Scope" />
            </div>
          </Col>
          <Col>
            <p>Amber</p>
            <div>
              <Status flagType="amber" name="En route" />
            </div>
          </Col>
          <Col>
            <p>Red</p>
            <div>
              <Status flagType="red" name="On hold" />
            </div>
          </Col>
          <Col>
            <p>White</p>
            <div>
              <Status flagType="white" name="Completed" />
            </div>
          </Col>
        </Row>
      </CardWrapper>
    </>
  );
};

export default ClientWorkflowEditor;
