import React from 'react';
import { getId } from '../utils';

type Params = {
  onSelectRow: (id: string) => void;
};

const useTableSelectRow = ({ onSelectRow }: Params) => {
  return (event: React.MouseEvent<HTMLTableRowElement>) => {
    const id = getId(event) as string;
    onSelectRow(id);
  };
};

export default useTableSelectRow;
