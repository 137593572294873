import { gql } from '@apollo/client';

export const JOB_QUOTE_MESSAGE_QUERY = gql`
  query ($jobId: ID!) {
    jobQuoteMessage(jobId: $jobId)
  }
`;

export const CREATE_JOB_QUOTE_MUTATION = gql`
  mutation ($jobIssueId: ID!, $file: Upload!, $message: JSONScalarInput!) {
    createJobQuote(jobIssueId: $jobIssueId, file: $file, message: $message) {
      jobQuote {
        id
      }
      jobIssue {
        id
        controls
        tags {
          id
          name
          color
        }
      }
      block {
        type
        item {
          ... on LogItem {
            id
            type
            opensIssue
            closesIssue
            context
            meta
            data
            dateTime
            user {
              id
              initials
              name
            }
          }
        }
      }
    }
  }
`;
