import React, { useEffect, useRef } from 'react';
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
import { SearchWrapper, SearchInput, SearchIcon } from './styled';

const ClientSearch = () => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const handleOnKeyPress = (event: KeyboardEvent) => {
      if (event.key === '/') {
        if (!inputRef.current) return;
        inputRef.current.focus();
      }
    };

    document.addEventListener('keypress', handleOnKeyPress);
    return () => {
      document.removeEventListener('keypress', handleOnKeyPress);
    };
  }, []);

  return (
    <SearchWrapper>
      <SearchIcon icon={faSearch} style={{ left: '0.5rem' }} />
      <SearchInput
        ref={inputRef}
        placeholder="Search jobs, immediate actions..."
      />
    </SearchWrapper>
  );
};

export default ClientSearch;
