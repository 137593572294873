import React, { useState } from 'react';
import classNames from 'classnames';
import { useClient } from 'lib/hooks';
import { getId } from 'lib/utils';
import { Link } from 'wouter';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from 'styled-components';
import { Job } from 'lib/types';
import { DateTime } from 'luxon';
import { useLazyQuery } from '@apollo/client';
import { faUserCircle } from '@fortawesome/pro-duotone-svg-icons/faUserCircle';
import CardWrapper from '../CardWrapper';
import ClientJobsTableTimeline from '../ClientJobsAllTableTimeline';
import ClientJobsAllTableMap from '../ClientJobsAllTableMap';
import JobStateDescription from '../JobStateDescription';
import JobTag from '../JobTag';
import { TableProps, TableRowProps } from './types';
import { Wrapper } from './styled';
import TableHeadButton from '../TableHeadButton';
import Table from '../Table';
import { ADDITIONAL_JOB_INFORMATION_QUERY } from '../ClientJobsAll/query';
import { Small } from '../Typography';
import JobStatusFlag from '../JobStatusFlag';
import ScrollDiv from '../ScrollDiv';

const TableRow = ({
  job,
  job: {
    id,
    reference,
    status,
    name,
    instantiationType,
    site,
    dateTimeStart,
    dateTimeEnd,
    assignees,
  },
  selectedRow,
  activeFlagName,
  onClick,
}: TableRowProps) => {
  const theme = useTheme();
  const client = useClient();
  const selected = selectedRow === id;
  const [additionalData, setAdditionalData] = useState<Partial<Job>>({});

  const [getAdditionalJobInformation] = useLazyQuery(
    ADDITIONAL_JOB_INFORMATION_QUERY,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        setAdditionalData(data.job);
      },
    }
  );

  const handleOnClick = (event: React.MouseEvent<HTMLTableRowElement>) => {
    getAdditionalJobInformation({ variables: { jobId: id } });
    onClick(event);
  };

  return (
    <>
      <tr
        className={classNames({ 'border-0': selected })}
        data-id={id}
        onClick={handleOnClick}
      >
        <td className="mono">{reference}</td>
        <td className="py-0 pr-2">
          <JobStateDescription job={job} />
        </td>
        <td className="position-relative">
          <div className="d-flex">
            {job.tags.length > 0 && (
              <div className="mr-2 d-flex" style={{ fontSize: 12 }}>
                {job.tags.map((tag, index) => {
                  const last = index === job.tags.length - 1;
                  return <JobTag job={job} tag={tag} last={last} />;
                })}
              </div>
            )}
            <Link to={`/clients/${client.slug}/jobs/${reference}`}>{name}</Link>
          </div>
        </td>
        {activeFlagName === 'BLUE' && <td>{instantiationType?.name}</td>}
        <td className="pr-5">{site?.name || 'No Site'}</td>
        <td className="mono">
          {DateTime.fromISO(dateTimeStart).toFormat('dd MMM yyyy')}
        </td>
        <td className="mono">
          {DateTime.fromISO(dateTimeEnd).toFormat('dd MMM yyyy')}
        </td>
      </tr>
      {selected && (
        <tr style={{ height: 300, backgroundColor: 'rgba(0, 0, 0, 0.025)' }}>
          <td colSpan={6}>
            <Row className="h-100">
              <Col className="h-100">
                <CardWrapper
                  className="h-100 p-0"
                  style={{
                    backgroundColor: theme.color.contentBackground.hex(),
                  }}
                >
                  <ScrollDiv
                    className="h-100 p-2"
                    style={{ overflowY: 'auto' }}
                  >
                    <div className="mb-2">
                      <Small>Ref: {reference}</Small>
                      <h5>{name}</h5>
                    </div>
                    <div className="mb-2">
                      <Small>Status</Small>
                      <h5>
                        <JobStatusFlag className="mr-2" flag={status.flag} />
                        {status.nameDisplay}
                      </h5>
                    </div>
                    <div className="mb-2">
                      <Small>Site</Small>
                      <h5 className="mb-0">{site.name}</h5>
                      <p className="text-75">{site.location.address}</p>
                    </div>
                    {assignees.length > 0 && (
                      <div>
                        <Small>Assignees</Small>
                        <div>
                          {assignees.map((user) => (
                            <div key={user.id}>
                              <FontAwesomeIcon
                                className="mr-1"
                                icon={faUserCircle}
                                fixedWidth
                              />
                              <span>{user.name}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </ScrollDiv>
                </CardWrapper>
              </Col>
              <Col className="h-100">
                <ClientJobsTableTimeline
                  logBlocks={additionalData?.logBlocks}
                />
              </Col>
              <Col className="h-100 mr-3">
                <ClientJobsAllTableMap site={site} />
              </Col>
            </Row>
          </td>
        </tr>
      )}
    </>
  );
};

const ClientJobsAllTable = ({
  innerRef,
  rows,
  sortState,
  sort,
  activeFlagName,
}: TableProps) => {
  const [selectedRow, setSelectedRow] = useState('-1');

  const handleRowOnClick = (event: React.MouseEvent<HTMLTableRowElement>) => {
    const id = getId(event) as string;
    setSelectedRow(id === selectedRow ? '-1' : id);
  };

  return (
    <Wrapper>
      <Table
        flexBases={
          activeFlagName === 'BLUE'
            ? [10, 10, 20, 20, 20, 10, 10]
            : [10, 10, 35, 25, 10, 10]
        }
        className="w-100"
      >
        <thead>
          <tr>
            <th>
              <TableHeadButton
                active={sortState.activeName === 'reference'}
                order={sortState.columns.reference.order}
                onClick={() => sort('reference')}
              >
                Reference
              </TableHeadButton>
            </th>
            <th>
              <TableHeadButton
                active={sortState.activeName === 'status'}
                order={sortState.columns.status.order}
                onClick={() => sort('status')}
              >
                Status
              </TableHeadButton>
            </th>
            <th>
              <TableHeadButton
                active={sortState.activeName === 'name'}
                order={sortState.columns.name.order}
                onClick={() => sort('name')}
              >
                Name
              </TableHeadButton>
            </th>
            {activeFlagName === 'BLUE' && (
              <th>
                <TableHeadButton
                  active={sortState.activeName === 'type'}
                  order={sortState.columns.type.order}
                  onClick={() => sort('type')}
                >
                  Type
                </TableHeadButton>
              </th>
            )}
            <th>
              <TableHeadButton
                active={sortState.activeName === 'site'}
                order={sortState.columns.site.order}
                onClick={() => sort('site')}
              >
                Site
              </TableHeadButton>
            </th>
            <th>
              <TableHeadButton
                active={sortState.activeName === 'dateTimeStart'}
                order={sortState.columns.dateTimeStart.order}
                onClick={() => sort('dateTimeStart')}
              >
                Start Date
              </TableHeadButton>
            </th>
            <th>
              <TableHeadButton
                active={sortState.activeName === 'dateTimeEnd'}
                order={sortState.columns.dateTimeEnd.order}
                onClick={() => sort('dateTimeEnd')}
              >
                End Date
              </TableHeadButton>
            </th>
          </tr>
        </thead>
        <tbody ref={innerRef}>
          {rows?.map((job) => (
            <TableRow
              key={job.id}
              job={job}
              selectedRow={selectedRow}
              activeFlagName={activeFlagName}
              onClick={handleRowOnClick}
            />
          ))}
        </tbody>
      </Table>
    </Wrapper>
  );
};

export default ClientJobsAllTable;
