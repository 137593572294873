import { DateTime } from 'luxon';
import React, { useEffect, useRef } from 'react';
import { v4 as uuid4 } from 'uuid';
import { useMutation, useQuery } from '@apollo/client';
import { useTheme } from 'styled-components';
import { ChatMessage } from 'lib/types';
import { useSessionId, useUser } from '../../lib/hooks';
import {
  chatQueryOnCompleted,
  createChatMessageMutationOnCompleted,
  onCreateChatMessage,
  useReducerContext,
} from '../CommunicationChats/reducer';
import CommunicationsChatInput from '../CommunicationsChatInput';
import CommunicationsChatMessage from '../CommunicationsChatMessage';
import { CREATE_CHAT_MESSAGE_MUTATION, INITIAL_QUERY } from './query';
import { Wrapper, Feed } from './styled';
import {
  ChatQueryData,
  ChatQueryVariables,
  CommunicationsChatProps,
  CreateChatMessageMutationData,
  CreateChatMessageMutationVariables,
} from './types';
import ScrollDiv from '../ScrollDiv';

const CommunicationsChat = ({ chat, skip, job }: CommunicationsChatProps) => {
  const user = useUser();
  const theme = useTheme();
  const sessionId = useSessionId();
  const [{ input }, dispatch] = useReducerContext();

  useQuery<ChatQueryData, ChatQueryVariables>(INITIAL_QUERY, {
    fetchPolicy: 'no-cache',
    skip,
    variables: { chatId: chat.id },
    onCompleted: (data) => {
      dispatch(chatQueryOnCompleted(data));
    },
  });

  const scrollRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (!scrollRef.current) return;
    scrollRef.current.scrollTo(0, scrollRef.current.scrollHeight);
  };

  useEffect(() => {
    if (!chat?.id) return;
    scrollToBottom();
  }, [chat?.id, chat?.messages.length]);

  const [createChatMessage] = useMutation<
    CreateChatMessageMutationData,
    CreateChatMessageMutationVariables
  >(CREATE_CHAT_MESSAGE_MUTATION);

  const handleOnCommit = async () => {
    if (!chat || !input.value) return;
    const tempId = uuid4();
    const tempValue = [...input.value];
    dispatch(
      onCreateChatMessage({
        tempId,
        body: tempValue,
        isPrimary: true,
        device: 'WEB',
        dateTime: DateTime.local().toISO(),
      } as ChatMessage)
    );
    const { data } = await createChatMessage({
      variables: {
        sessionId: sessionId as string,
        tempId,
        chatId: chat.id,
        jobId: job.id,
        body: tempValue,
      },
    });
    if (data) dispatch(createChatMessageMutationOnCompleted(data));
  };

  if (!chat) return null;
  return (
    <Wrapper className="d-flex flex-column h-100">
      <div className="p-2 " style={{ borderBottom: theme.border }}>
        <h5 className="mb-0">
          {chat.isGeneral
            ? 'General'
            : (chat.users ?? [])
                .map((innerUser) =>
                  innerUser.id === user.id ? 'You' : innerUser.name
                )
                .join(', ')}
        </h5>
        <small className="text-75">
          {(chat.users ?? []).length} users in this group
        </small>
      </div>
      <Feed className="flex-grow-1 p-2" style={{ minHeight: 0 }}>
        <ScrollDiv
          ref={scrollRef}
          className="h-100 pr-2"
          style={{ overflowY: 'scroll' }}
        >
          {chat.messages.map((message, index) => (
            <CommunicationsChatMessage
              key={message.id}
              prevMessage={chat.messages?.[index - 1]}
              message={message}
              nextMessage={chat.messages?.[index + 1]}
              index={index}
              last={index === chat.messages.length - 1}
            />
          ))}
        </ScrollDiv>
      </Feed>
      <div className="p-2">
        <CommunicationsChatInput onCommit={handleOnCommit} />
      </div>
    </Wrapper>
  );
};

export default CommunicationsChat;
