import React from 'react';
import { useTable, useTableSelectRow } from 'lib/hooks';
import { useToggle } from 'react-use';
import { faUserPlus } from '@fortawesome/pro-duotone-svg-icons/faUserPlus';
import { faUserSlash } from '@fortawesome/pro-duotone-svg-icons/faUserSlash';
import { useApolloClient, useMutation } from '@apollo/client';
import { RemoveUserMutationData, RemoveUserMutationVariables } from './types';
import {
  removeUserMutationOnCompleted,
  selectUser,
  useReducerContext,
  usersQueryOnCompleted,
} from '../ClientPeople/reducer';
import SearchBar from '../SearchBar';
import Table from '../Table';
import ClientPeopleGroupUsersSidePanel from '../ClientPeopleGroupUsersSidePanel';
import Button from '../Button';
import SimpleButton from '../SimpleButton';
import Checkbox from '../Checkbox';
import { REMOVE_USER_MUTATION, USERS_QUERY } from './query';

const ClientPeopleGroup = () => {
  const [{ userGroup, selectedUsers }, dispatch] = useReducerContext();
  const [sidePanelIsOpen, toggleSidePanel] = useToggle(false);

  const httpClient = useApolloClient();
  const { searchInputProps } = useTable(
    ['name'],
    (variables?: any) => httpClient.query({ query: USERS_QUERY, variables }),
    {
      defaultActiveName: 'name',
      searchVariables: { userGroupId: userGroup?.id },
      sortOnCompleted: () => {},
      searchOnCompleted: (response) => {
        if (!response.data) return;
        dispatch(usersQueryOnCompleted(response.data));
      },
      changePageCompleted: () => {},
    }
  );

  const handleOnSelectRow = useTableSelectRow({
    onSelectRow: (id) => {
      dispatch(selectUser(id));
    },
  });

  const [removeUser, { loading: removing }] = useMutation<
    RemoveUserMutationData,
    RemoveUserMutationVariables
  >(REMOVE_USER_MUTATION);

  const handleOnRemove = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!userGroup) return;
    const { value: userId } = event.currentTarget;
    removeUser({ variables: { userGroupId: userGroup.id, userId } }).then(
      (response) => {
        if (!response.data) return;
        dispatch(removeUserMutationOnCompleted(response.data));
      }
    );
  };

  if (!userGroup) return null;
  return (
    <>
      <div className="d-flex flex-column h-100">
        <div className="m-3 d-flex">
          <div className="flex-grow-1">
            <SearchBar
              {...searchInputProps}
              placeholder={`Search for users in ${userGroup.namePlural.toLowerCase()}...`}
            />
          </div>
          <div>
            <Button icon={faUserPlus} onClick={toggleSidePanel}>
              Add users
            </Button>
          </div>
        </div>
        <Table
          className="flex-grow-1"
          style={{ minHeight: 0 }}
          flexBases={[5, 75, 20]}
        >
          <thead>
            <tr>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {userGroup.users.map((user) => (
              <tr key={user.id}>
                <td className="table-cell-with-checkbox">
                  <Checkbox
                    checked={selectedUsers.includes(user.id)}
                    data-id={user.id}
                    onClick={handleOnSelectRow}
                  />
                </td>
                <td>{user.name}</td>
                <td className="show-on-hover table-cell-with-button d-flex justify-content-end pr-3">
                  <SimpleButton
                    icon={faUserSlash}
                    loading={removing}
                    value={user.id}
                    onClick={handleOnRemove}
                  >
                    Remove
                  </SimpleButton>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <ClientPeopleGroupUsersSidePanel
        isOpen={sidePanelIsOpen}
        toggle={toggleSidePanel}
      />
    </>
  );
};

export default ClientPeopleGroup;
