import styled from 'styled-components';
import { LayoutSidebarClientMenuItemWrapperProps } from './types';
import ScrollDiv from '../ScrollDiv';

export const LayoutSidebarClientMenuWrapper = styled.div`
  color: ${({ theme }) => theme.color.cardText.hex()};

  .text-50 {
    color: ${({ theme }) => theme.color.cardText.alpha(0.5).hex()};
  }
`;

export const LayoutSidebarClientMenuItemsWrapper = styled(ScrollDiv)`
  height: calc(100vh - 165px - 0.5rem - 64px);
  margin-top: 0.5rem;
  overflow-y: auto;
  color: ${({ theme }) => theme.color.cardText.hex()};
`;

export const LayoutSidebarClientMenuItemWrapper = styled.a<LayoutSidebarClientMenuItemWrapperProps>`
  color: ${({ theme }) => theme.color.cardText.hex()};
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 150ms;

  .layout-sidebar-client-menu__jobs {
    span {
      font-size: 0.85rem;
      margin-right: 0.5rem;
    }
  }

  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.color.sidebarText.hex()};
    background-color: ${({ theme }) => theme.color.simpleButtonHover.hex()};
    transition: background-color 50ms;
  }

  &.active {
    background-color: ${({ theme }) => theme.color.contentBackground.hex()};
  }
`;
