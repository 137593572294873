import styled from 'styled-components';

export const NestableItemWrapper = styled.li`
  position: relative;

  &.is-dragging {
    &.nestable-list {
      pointer-events: none;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #00b8d4;
      border: 1px dashed firebrick;
      border-radius: 5px;
    }
  }
`;

export const NestableItemHandler = styled.span``;
