import { Item } from './types';

export const objectType = (obj: any) => {
  return Object.prototype.toString.call(obj).slice(8, -1);
};

export const isDefined = (obj: any) => {
  return typeof obj !== 'undefined';
};

export const isFunction = (obj: any) => {
  return typeof obj === 'function';
};

export const isNumber = (obj: any) => {
  return typeof obj === 'number';
};

export const isString = (obj: any) => {
  return objectType(obj) === 'String';
};

export const isArray = (obj: any) => {
  return objectType(obj) === 'Array';
};

export const getClosestElement = (target: HTMLElement, selector: string) => {
  while (target) {
    if (target.matches && target.matches(selector)) return target;
    target = target.parentNode as HTMLElement;
  }
  return null;
};

export const getOffsetRect = (element: Element) => {
  const box = element.getBoundingClientRect();

  const { body } = document;
  const { documentElement } = document;

  const scrollTop =
    window.pageYOffset || documentElement.scrollTop || body.scrollTop;
  const scrollLeft =
    window.pageXOffset || documentElement.scrollLeft || body.scrollLeft;

  const clientTop = documentElement.clientTop || body.clientTop || 0;
  const clientLeft = documentElement.clientLeft || body.clientLeft || 0;

  const top = box.top + scrollTop - clientTop;
  const left = box.left + scrollLeft - clientLeft;

  return {
    top: Math.round(top),
    left: Math.round(left),
  };
};

export const getTotalScroll = (element: Element) => {
  let top = 0;
  let left = 0;

  while ((element = element.parentNode as Element)) {
    top += element.scrollTop || 0;
    left += element.scrollTop || 0;
  }

  return {
    top,
    left,
  };
};

export const getTransformProps = (
  x: number,
  y: number
): React.CSSProperties => {
  return {
    transform: `translate(${x}px, ${y}px)`,
  };
};

export const listWithChildren = <T extends Item<T>>(items: T[]): T[] => {
  return items.map((item) => ({
    ...item,
    children: item.children ? listWithChildren(item.children) : [],
  }));
};

export const getAllNonEmptyNodeIds = <T extends Item<T>>(items: T[]) => {
  let childrenIds: string[] = [];
  const ids = items
    .filter((item) => item.children.length)
    .map((item) => {
      childrenIds = [...childrenIds, ...getAllNonEmptyNodeIds(item.children)];
      return item.id;
    });

  return [...ids, ...childrenIds];
};
