import { ApolloQueryResult } from '@apollo/client/core/types';
import {
  ProjectModel,
  SchedulerProConfig,
} from '@bryntum/schedulerpro/schedulerpro.umd.js';
import { DateTime } from 'luxon';
import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { DefaultTheme } from 'styled-components';
import chroma from 'chroma-js';
import {
  Job,
  JobGroup,
  JobGroupMultiAssignPlacement,
  JobVisit,
  UserGroup,
} from 'lib/types';
import { parseDateTime } from 'lib/utils';
import { InitialQueryData } from './types';

export const schedulerConfig: Partial<SchedulerProConfig> = {
  viewPreset: {
    id: 'customHourAndDay',
    base: 'hourAndDay',
    headers: [
      {
        unit: 'day',
        dateFormat: 'dddd Do MMMM YYYY',
      },
      {
        unit: 'minute',
        increment: 30,
        dateFormat: 'HH:mm',
      },
    ],
  },
  eventStyle: 'colored',
};

export const addResources = (
  userGroups: UserGroup[],
  project: ProjectModel,
  theme: DefaultTheme
) => {
  const colors = chroma
    .scale([theme.color.primary, theme.color.contentBackground])
    .colors(userGroups.flatMap((userGroup) => userGroup.users).length);
  let userIndex = 0;
  userGroups.forEach((userGroup) => {
    project.resourceStore.add(
      userGroup.users.map((user) => {
        const ret = {
          id: user.id,
          name: user.name,
          category: userGroup.namePlural,
          userGroup,
          eventColor: colors[userIndex],
        };
        userIndex += 1;
        return ret;
      })
    );
  });
};

export const addDeadlines = (
  job: Job,
  initialViewDateTimeStart: DateTime,
  initialTargetDateTimeEnd: DateTime,
  initialTargetDateTimeStart: DateTime,
  project: ProjectModel
) => {
  project.timeRangeStore.remove([
    'completion-deadline',
    'completion-deadline-line',
  ]);
  project.timeRangeStore.add({
    id: 'completion-deadline',
    name: '',
    startDate: initialTargetDateTimeEnd.toJSDate(),
    endDate: initialTargetDateTimeEnd.plus({ year: 1 }).toJSDate(),
    cls: 'deadline-timerange',
  });
  project.timeRangeStore.add({
    id: 'completion-deadline-line',
    name: renderToStaticMarkup(
      <div>
        <p
          className="mb-0"
          style={{ fontSize: '0.75rem', lineHeight: '12px', marginTop: 8 }}
        >
          Completion deadline
        </p>
        <p className="mb-0 mono" style={{ fontSize: '0.7rem' }}>
          {parseDateTime(initialTargetDateTimeEnd.toISO())}
        </p>
      </div>
    ),
    startDate: initialTargetDateTimeEnd.toJSDate(),
  });
};

export const addAssignedVisits = (
  visits: JobVisit[],
  jobGroup: JobGroup,
  job: Job,
  project: ProjectModel
) => {
  project.eventStore.add(
    visits.map((visit: JobVisit) => {
      const editable = visit.job?.id === job.id;
      const inSeries = [job.id, job.parent?.id].includes(
        visit.job?.id as string
      );

      const getColor = () => {
        if (visit.status.name === 'COMPLETED') {
          return 'gray';
        }
        if (inSeries) {
          return visit.status.color;
        }
        return 'gray';
      };

      return {
        id: visit.id,
        job: visit?.job,
        jobVisit: visit,
        resourceId: visit.user.id,
        name: inSeries ? visit.label : visit.job?.name,
        number: visit.number,
        eventColor: getColor(),
        draggable: editable,
        resizable: editable,
        dependencies: editable,
        startDate: visit.dateTimeStart,
        endDate: visit.dateTimeEnd,
        editable,
        inSeries,
      };
    })
  );
};

export const fetchMoreVisitsOnCompleted = (
  response: ApolloQueryResult<InitialQueryData>,
  job: Job,
  project: ProjectModel
) => {
  if (!response.data) return;
  project.eventStore.add(
    response.data.jobVisits.map((visit: JobVisit) => {
      const editable =
        visit?.status?.name === 'SCHEDULED' && visit.job?.id === job.id;
      const inSeries = [job.id, job.parent?.id].includes(
        visit.job?.id as string
      );

      const getColor = () => {
        if (visit.status.name === 'COMPLETED') {
          return 'gray';
        }
        if (inSeries) {
          return visit.status.color;
        }
        return 'gray';
      };

      return {
        id: visit.id,
        resourceId: visit.user ? visit.user.id : null,
        job: visit?.job,
        jobVisit: visit,
        name: inSeries ? visit.label : visit.job?.name,
        number: visit.number,
        eventColor: getColor(),
        draggable: editable,
        resizable: editable,
        dependencies: editable,
        startDate: visit.dateTimeStart,
        endDate: visit.dateTimeEnd,
        editable,
        inSeries,
      };
    })
  );
};

export const addPlacements = (
  placements: JobGroupMultiAssignPlacement[],
  filterOriginal: boolean,
  project: ProjectModel
) => {
  const toRemove = project.eventStore.records.filter(
    (record) =>
      (filterOriginal && (record as any).data.original) ||
      (record as any).data.isPlacement
  );

  project.eventStore.remove(toRemove);

  return project.eventStore.add(
    placements.map((placement) => ({
      ...placement,
      startDate: placement.dateTimeStart,
      endDate: placement.dateTimeEnd,
      resourceId: placement.user.id,
      original: placement.isOriginal,
      generated: !placement.isOriginal,
      isPlacement: true,
    }))
  );
};
