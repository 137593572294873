import {
  NestedReportTemplateBlock,
  NewLogic,
  ReportTemplateBlock,
} from 'lib/types';
import React, { Dispatch, useContext } from 'react';
import { AnyAction } from 'redux';
import { InitialQueryData, State } from './types';
import { getItemByPath } from './utils';

export const initialState: State = {
  reportTemplate: null,
  reportTemplateState: {
    id: '',
    value: {},
    valueDefaults: {},
    collapsedGroups: [],
  },
  selected: [],
  selectedRect: { top: 0, left: 0, bottom: 0, right: 0 },
};

export const ReducerContext = React.createContext<[State, Dispatch<AnyAction>]>(
  [initialState, () => {}]
);

export const useReducerContext = () => useContext(ReducerContext);

export const initialQueryOnCompleted = (data: InitialQueryData) => ({
  type: 'INITIAL_QUERY_ON_COMPLETED',
  reportTemplate: data.reportTemplate,
  reportTemplateState: data.reportTemplateState,
});

export const nestedLayoutOnChange = (event: {
  items: NestedReportTemplateBlock[];
  dragItem: NestedReportTemplateBlock;
  targetPath: number[];
}) => ({
  type: 'NESTED_LAYOUT_ON_CHANGE',
  items: event.items,
  dragItem: event.dragItem,
  targetPath: event.targetPath,
});

export const reportTemplateStateOnChange = (
  id: ReportTemplateBlock['id'],
  value: ReportTemplateBlock['id']
) => ({
  type: 'REPORT_TEMPLATE_STATE_ON_CHANGE',
  id,
  value,
});

export const reportTemplateBlockOnChange = (
  id: ReportTemplateBlock['id'],
  diff: Partial<ReportTemplateBlock>
) => ({
  type: 'REPORT_TEMPLATE_BLOCK_ON_CHANGE',
  id,
  diff,
});

export const addLogicToBlock = (
  addToBlock: NestedReportTemplateBlock,
  newLogic: NewLogic
) => ({
  type: 'ADD_LOGIC_TO_BLOCK',
  addToBlock,
  newLogic,
});

export const addBlock = (newBlock: Partial<ReportTemplateBlock>) => ({
  type: 'ADD_BLOCK',
  newBlock,
});

export const onSelect = (item: NestedReportTemplateBlock) => ({
  type: 'ON_SELECT',
  item,
});

export const setSelectedRect = (rect: Partial<State['selectedRect']>) => ({
  type: 'SET_SELECTED_RECT',
  rect,
});

const reducer = (state: State, action: AnyAction) => {
  switch (action.type) {
    case 'INITIAL_QUERY_ON_COMPLETED':
      return {
        ...state,
        reportTemplate: action.reportTemplate,
        reportTemplateState: action.reportTemplateState,
      };

    case 'NESTED_LAYOUT_ON_CHANGE': {
      if (!state.reportTemplate) return state;

      const thisAction = action as ReturnType<typeof nestedLayoutOnChange>;
      const { items, dragItem, targetPath } = thisAction;
      const shiftedPath = targetPath.slice(0, targetPath.length - 1);
      const parent =
        shiftedPath.length === 0
          ? ({
              ...state.reportTemplate.root,
              children: items,
              logic: [],
            } as NestedReportTemplateBlock)
          : getItemByPath(shiftedPath, items);

      if (!parent) return state;

      return {
        ...state,
        reportTemplate: {
          ...state.reportTemplate,
          root: {
            ...state.reportTemplate.root,
            ...(parent.id === state.reportTemplate.root.id
              ? {
                  children: items.map(
                    (child: NestedReportTemplateBlock) => child.id
                  ),
                }
              : {
                  children: state.reportTemplate.root.children.filter(
                    (childId) => childId !== dragItem.id
                  ),
                }),
            blocks: state.reportTemplate.root.blocks.map((block) => {
              if (!state.reportTemplate) return [];
              if (block.id === parent.id) {
                if (block.logic.length > 0) {
                  const value =
                    state.reportTemplateState.value[block.id] ||
                    state.reportTemplateState.valueDefaults[block.id];
                  const logic = block.logic.find(
                    (innerLogic) => innerLogic.id === value
                  );
                  if (logic) {
                    return {
                      ...block,
                      logic: block.logic.map((innerLogic) =>
                        innerLogic.id === logic.id
                          ? {
                              ...innerLogic,
                              children: [...innerLogic.children, dragItem.id],
                            }
                          : innerLogic
                      ),
                    };
                  }
                }
                return {
                  ...block,
                  children: parent.children.map((child) => child.id),
                };
              }
              return {
                ...block,
                children: block.children.filter(
                  (childId) => childId !== dragItem.id
                ),
                logic: block.logic.map((logic) => ({
                  ...logic,
                  children: logic.children.filter(
                    (childId) => childId !== dragItem.id
                  ),
                })),
              };
            }),
          },
        },
      };
    }

    case 'REPORT_TEMPLATE_STATE_ON_CHANGE': {
      const thisAction = action as ReturnType<
        typeof reportTemplateStateOnChange
      >;
      return {
        ...state,
        reportTemplateState: {
          ...state.reportTemplateState,
          value: {
            ...state.reportTemplateState.value,
            [thisAction.id]: thisAction.value,
          },
        },
      };
    }

    case 'REPORT_TEMPLATE_BLOCK_ON_CHANGE': {
      if (!state.reportTemplate) return state;
      const thisAction = action as ReturnType<
        typeof reportTemplateBlockOnChange
      >;
      return {
        ...state,
        reportTemplate: {
          ...state.reportTemplate,
          root: {
            ...state.reportTemplate.root,
            blocks: state.reportTemplate.root.blocks.map((block) =>
              block.id === thisAction.id
                ? { ...block, ...(thisAction.diff ?? {}) }
                : block
            ),
          },
        },
      };
    }
    case 'ADD_LOGIC_TO_BLOCK': {
      if (!state.reportTemplate) return state;
      const thisAction = action as ReturnType<typeof addLogicToBlock>;
      return {
        ...state,
        reportTemplate: {
          ...state.reportTemplate,
          root: {
            ...state.reportTemplate.root,
            blocks: [
              ...state.reportTemplate.root.blocks.map((block) =>
                block.id === thisAction.addToBlock.id
                  ? {
                      ...block,
                      children: block.children,
                      logic: [...block.logic, thisAction.newLogic],
                    }
                  : block
              ),
            ],
          },
        },
        reportTemplateState: {
          ...state.reportTemplateState,
          value: {
            ...state.reportTemplateState.value,
            [thisAction.addToBlock.id]: thisAction.newLogic.id,
          },
        },
      };
    }

    case 'ADD_BLOCK': {
      if (!state.reportTemplate) return state;
      const thisAction = action as ReturnType<typeof addBlock>;
      return {
        ...state,
        reportTemplate: {
          ...state.reportTemplate,
          root: {
            ...state.reportTemplate.root,
            blocks: [
              ...state.reportTemplate.root.blocks.map((block) =>
                block.id === thisAction.newBlock.parent.id
                  ? {
                      ...block,
                      children: [...block.children, thisAction.newBlock.id],
                    }
                  : block
              ),
              thisAction.newBlock,
            ],
            children: thisAction.newBlock.parent.parent
              ? state.reportTemplate.root.children
              : [...state.reportTemplate.root.children, thisAction.newBlock.id],
          },
        },
      };
    }

    case 'ON_SELECT': {
      const thisAction = action as ReturnType<typeof onSelect>;
      return {
        ...state,
        selected: state.selected.includes(thisAction.item.id)
          ? state.selected.filter((itemId) => itemId !== thisAction.item.id)
          : [...state.selected, thisAction.item.id],
      };
    }

    case 'SET_SELECTED_RECT': {
      const thisAction = action as ReturnType<typeof setSelectedRect>;
      return {
        ...state,
        selectedRect: { ...state.selectedRect, ...thisAction.rect },
      };
    }

    default:
      return state;
  }
};

export default reducer;
