import { gql } from '@apollo/client';

const LOG_BLOCK_SUBSELECTION = `
  id
  type
  meta
  data
  dateTime
  user {
    id
    name
  }
`;

export const JOB_ISSUES_QUERY = gql`
  query($jobId: ID) {
    job(jobId: $jobId) {
      issues {
        id
        number
        dateTimeCreated
        issueType
        issueTypeDisplay
        status
        openedBy {
          ${LOG_BLOCK_SUBSELECTION}
        }
        closedBy {
          ${LOG_BLOCK_SUBSELECTION}
        }
        worstLogBlock {
          ${LOG_BLOCK_SUBSELECTION}
        }
        tags {
          id
          name
          color
        }
      }
    }
  }
`;
