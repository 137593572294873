import styled from 'styled-components';

export const PreviewHeader = styled.div`
  background-color: ${({ theme }) =>
    theme.color.cardBackground.darken(0.25).hex()};
  padding: 0.5rem 1rem;
  border-radius: 0.25rem 0.25rem 0 0;
  border-bottom: ${({ theme }) => theme.border};
  color: ${({ theme }) => theme.color.cardText.alpha(0.75).hex()};
`;
