import React, { useCallback, useContext, useMemo, useState } from 'react';
import { SlideProps, SlidesProps } from './types';
import { Wrapper, Inner, Page } from './styled';

const Context = React.createContext<{ page?: number }>({});

export const Slide = ({ children, page }: SlideProps) => {
  const context = useContext(Context);
  return (
    <>
      {typeof children === 'function'
        ? // eslint-disable-next-line react/destructuring-assignment
          children({ active: !!page && context?.page === page, ...context })
        : children}
    </>
  );
};

const Slides = ({ children, renderButtonToolbar }: SlidesProps) => {
  const childrenArray = useMemo(() => React.Children.toArray(children), [
    children,
  ]);
  const [page, setPage] = useState(1);

  const handleNextPage = useCallback(() => {
    setPage((prevPage) => Math.min(prevPage + 1, childrenArray.length));
  }, [childrenArray.length]);

  const handlePrevPage = useCallback(() => {
    setPage((prevPage) => Math.max(0, prevPage - 1));
  }, []);

  return (
    <div>
      <Wrapper>
        <Context.Provider value={{ page }}>
          <Inner
            className="d-flex"
            pageCount={childrenArray.length}
            page={page}
          >
            {childrenArray.map((child, index) => {
              const innerPage = index + 1;
              return (
                <Page key={innerPage} page={innerPage} currentPage={page}>
                  {child}
                </Page>
              );
            })}
          </Inner>
        </Context.Provider>
      </Wrapper>
      <div>
        {renderButtonToolbar({
          page,
          setPage,
          nextPage: handleNextPage,
          prevPage: handlePrevPage,
        })}
      </div>
    </div>
  );
};

export default Slides;
