import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDotCircle } from '@fortawesome/pro-regular-svg-icons/faDotCircle';
import { JobIssue } from 'lib/types';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons/faCheckCircle';
import { ClientJobIssueStatusPillProps } from './types';
import { Wrapper } from './styled';

const getIcon = (status: JobIssue['status']) => {
  switch (status) {
    case 'OPEN':
      return faDotCircle;
    case 'CLOSED':
      return faCheckCircle;
    default:
      return faDotCircle;
  }
};

const ClientJobIssueStatusPill = ({
  status,
  ...rest
}: ClientJobIssueStatusPillProps) => {
  return (
    <Wrapper status={status} {...rest}>
      <FontAwesomeIcon className="mr-1" icon={getIcon(status)} fixedWidth />
      <span className="text-capitalize">{status.toLowerCase()}</span>
    </Wrapper>
  );
};

export default ClientJobIssueStatusPill;
