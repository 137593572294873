import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons/faSpinnerThird';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { SearchBarWrapper, SearchBarInput, ClearButton } from './styled';
import { SearchBarProps } from './types';

const SearchBar = ({
  loading,
  wrapperClassName,
  wrapperStyle,
  value,
  onClear,
  ...props
}: SearchBarProps) => (
  <SearchBarWrapper className={wrapperClassName} style={wrapperStyle}>
    <FontAwesomeIcon
      className="icon"
      icon={loading ? faSpinnerThird : faSearch}
      spin={loading}
      fixedWidth
    />
    <SearchBarInput value={value} {...props} />
    {value && (
      <ClearButton
        icon={faTimes}
        persistBackgroundColor
        backgroundHoverColor="cardBackground"
        onClick={onClear}
      >
        Clear search
      </ClearButton>
    )}
  </SearchBarWrapper>
);

export default SearchBar;
