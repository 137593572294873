import { gql } from '@apollo/client';

export const CLIENTS_QUERY = gql`
  query ($searchTerm: String) {
    clients(searchTerm: $searchTerm) {
      id
      name
      slug
      immediateActionCount
      jobStatusFlags {
        id
        name
        color
        count
      }
    }
  }
`;
