import React from 'react';
import { Link } from 'wouter';
import classNames from 'classnames';
import { useTheme } from 'styled-components';
import { useReducerContext } from '../ClientDashboard/reducer';
import JobPill from '../JobPill';
import JobReportPill from '../JobReportPill';
import ScrollDiv from '../ScrollDiv';
import { Wrapper } from './styled';

const ClientDashboardReports = () => {
  const theme = useTheme();
  const [{ jobReports }] = useReducerContext();

  if (!jobReports?.results)
    return <div className="p-2">No reports awaiting review</div>;
  return (
    <ScrollDiv className="h-100" style={{ overflowY: 'auto' }}>
      {jobReports.results.map((jobReport, index) => {
        const last = index === jobReports.results.length - 1;
        const href = `/clients/${jobReport.job.client.slug}/jobs/${jobReport.job.reference}/reports/${jobReport.number}`;
        return (
          <Link key={jobReport.id} href={href}>
            <Wrapper
              href={href}
              className={classNames('p-2', {})}
              style={{ borderBottom: last ? '' : theme.border }}
            >
              <div>
                <JobPill
                  job={jobReport.job}
                  as="span"
                  style={{ backgroundColor: 'transparent' }}
                />
              </div>
              <div>
                <JobReportPill jobReport={jobReport} />
              </div>
            </Wrapper>
          </Link>
        );
      })}
    </ScrollDiv>
  );
};

export default ClientDashboardReports;
