import React, { useEffect, useRef, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/pro-duotone-svg-icons/faUserCircle';
import classNames from 'classnames';
import { useTheme } from 'styled-components';
import { getId, parseDateTime } from '../../lib/utils';
import { selectStartJob, useReducerContext } from '../ClientJobGroup/reducer';
import ClientJobGroupScheduler from '../ClientJobGroupScheduler';
import ClientSitesMap from '../ClientSitesMap';
import Duration from '../Duration';
import JobLink from '../JobLink';
import ScrollDiv from '../ScrollDiv';
import CardWrapper from '../CardWrapper';
import ClickToCopy from '../ClickToCopy';
import { Small } from '../Typography';
import JobPriorityPill from '../JobPriorityPill';
import JobStatusFlag from '../JobStatusFlag';
import { JobCardWrapper } from './styled';

const ClientJobGroupHome = () => {
  const theme = useTheme();
  const [{ jobGroup, selectedJob }, dispatch] = useReducerContext();

  const scrollRef = useRef<HTMLDivElement>(null);
  const [scrollOverflow, setScrollOverflow] = useState(false);

  const checkScrollOverflow = () => {
    if (!scrollRef.current) return;
    setScrollOverflow(
      scrollRef.current.clientWidth < scrollRef.current.scrollWidth
    );
  };

  useEffect(() => {
    checkScrollOverflow();

    window.addEventListener('resize', checkScrollOverflow);

    return () => {
      window.removeEventListener('resize', checkScrollOverflow);
    };
  }, [jobGroup?.jobs]);

  const currentJobRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!currentJobRef.current) return;
    if (!scrollRef.current) return;

    scrollRef.current.scrollTo(currentJobRef.current.offsetLeft - 64, 0);
  }, [jobGroup?.jobs]);

  const [viewState, setViewState] = useState({
    latitude: 0,
    longitude: 0,
    zoom: 15,
    pitch: 45,
    bearing: -10,
  });

  const handleOnViewStateChange = ({ viewState: newViewState }: any) => {
    setViewState(newViewState);
  };

  const handleJobCardOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (jobGroup?.activeMultiAssignPlacementBatch) return;
    const id = getId(event);
    dispatch(selectStartJob(id as string));
  };

  if (!jobGroup) return null;
  return (
    <div className="h-100 d-flex flex-column">
      <Row noGutters className="mb-4">
        <Col xs={3} className="h-100 pr-4">
          <CardWrapper className="h-100">
            <div className="mb-2">
              <div>
                <Small className="mr-2" style={{ lineHeight: '26px' }}>
                  Ref:{' '}
                  <ClickToCopy
                    copy={jobGroup.reference}
                    className="mono font-weight-normal"
                  >
                    {jobGroup.reference}
                  </ClickToCopy>
                </Small>
                <Small className="mr-2" style={{ lineHeight: '26px' }}>
                  Frequency:{' '}
                  <Duration
                    seconds={jobGroup.averageJobInterval}
                    units={['months', 'days']}
                  />
                </Small>
              </div>
              <h5 className="mb-0">{jobGroup.name}</h5>
            </div>
            <div className="mb-2 d-flex">
              <div className="mr-3">
                <Small>Start date</Small>
                <p className="mono mb-0">
                  {parseDateTime(jobGroup.jobs[0].dateTimeStart, 'dd MMM yyyy')}
                </p>
              </div>
              <div>
                <Small>end date</Small>
                <p className="mono mb-0">
                  {parseDateTime(
                    jobGroup.jobs[jobGroup.jobs.length - 1].dateTimeEnd,
                    'dd MMM yyyy'
                  )}
                </p>
              </div>
            </div>
            <div className="mb-2">
              <Small>Progress</Small>
              <p className="mb-0">
                <span className="mono">{jobGroup.jobStatuses.incoming}</span>{' '}
                <span className="text-75">incoming,</span>{' '}
                <span className="mono">{jobGroup.jobStatuses.assigned}</span>{' '}
                <span className="text-75">assigned,</span>{' '}
                <span className="mono">{jobGroup.jobStatuses.completed}</span>{' '}
                <span className="text-75">completed out of</span>{' '}
                <span className="mono">{jobGroup.jobs.length}</span>
              </p>
            </div>
            <div>
              {jobGroup.assignees.length > 0 && (
                <ScrollDiv
                  className="flex-grow-1"
                  style={{ overflowY: 'auto' }}
                >
                  <Small>Assignees</Small>
                  <div className="d-flex flex-wrap">
                    {jobGroup.assignees.map((user) => (
                      <div key={user.id} className="mr-2 text-nowrap">
                        <FontAwesomeIcon
                          className="mr-1"
                          icon={faUserCircle}
                          fixedWidth
                        />
                        <span>{user.name}</span>
                      </div>
                    ))}
                  </div>
                </ScrollDiv>
              )}
            </div>
          </CardWrapper>
        </Col>
        <Col xs={9}>
          <div className="h-100 d-flex flex-column">
            <ScrollDiv
              ref={scrollRef}
              className={classNames('position-relative h-100 text-nowrap', {
                'pb-2': scrollOverflow,
              })}
              style={{
                overflowX: scrollOverflow ? 'scroll' : 'visible',
                overflowY: 'hidden',
              }}
            >
              {jobGroup.jobs.map((job, index) => {
                const selected = job.id === selectedJob?.id;
                const last = index === jobGroup.jobs.length - 1;
                return (
                  <div key={job.id} className="d-inline-flex flex-column h-100">
                    <div>
                      <span
                        className="position-sticky"
                        style={{
                          left: 8,
                          paddingRight: 8,
                          color: theme.color.cardText.alpha(0.75).hex(),
                        }}
                      >
                        <span className="mono">
                          #{job.positionInJobGroup + 1}
                        </span>
                        {selected && <span className="ml-2">Selected</span>}
                        <span className="ml-2 mono">
                          {parseDateTime(
                            job.targetDateTimeStart,
                            'dd MMM yyyy'
                          )}
                        </span>
                      </span>
                    </div>
                    <JobCardWrapper
                      key={job.id}
                      ref={selected ? currentJobRef : undefined}
                      className={classNames('p-0 flex-grow-1', {
                        'mr-2': !last,
                      })}
                      style={{
                        borderColor: selected ? job.status.flag.color : '',
                      }}
                      isCurrent={selected}
                      data-id={job.id}
                      // @ts-ignore
                      onClick={handleJobCardOnClick}
                    >
                      <div className={classNames({ 'd-flex': selected })}>
                        <div className="p-2">
                          <div className="mb-1">
                            <div className="d-flex mb-0">
                              <Small className="mr-2">
                                Ref:{' '}
                                <ClickToCopy
                                  copy={job.reference}
                                  className="mono font-weight-normal"
                                >
                                  {job.reference}
                                </ClickToCopy>
                              </Small>
                              <JobPriorityPill
                                includeCode={false}
                                job={job}
                                type="response"
                              />
                            </div>
                            <JobLink job={job}>
                              <p className="mb-0">{job.name}</p>
                            </JobLink>
                          </div>
                          <div className="mb-1">
                            <Small>Status</Small>
                            <p className="mb-0">
                              <JobStatusFlag
                                className="mr-2"
                                flag={job.status.flag}
                              />
                              {job.status.nameDisplay}
                            </p>
                          </div>
                          <div className="mb-1">
                            <Small>Site</Small>
                            <p className="mb-0">{job.site.name}</p>
                          </div>
                          {job.assignees.length > 0 && (
                            <ScrollDiv
                              className="flex-grow-1"
                              style={{ overflowY: 'auto' }}
                            >
                              <Small>Assignees</Small>
                              <div className="d-flex flex-wrap">
                                {job.assignees.map((user) => (
                                  <div
                                    key={user.id}
                                    className="mr-2 text-nowrap"
                                  >
                                    <FontAwesomeIcon
                                      className="mr-1"
                                      icon={faUserCircle}
                                      fixedWidth
                                    />
                                    <span>{user.name}</span>
                                  </div>
                                ))}
                              </div>
                            </ScrollDiv>
                          )}
                        </div>
                        {selected && (
                          <div className="p-1" style={{ width: 300 }}>
                            <ClientSitesMap
                              sites={[job.site]}
                              viewState={{
                                ...viewState,
                                zoom: 12,
                                ...job.site.location.point,
                              }}
                              markers={false}
                              onViewStateChange={handleOnViewStateChange}
                              height="100%"
                            />
                          </div>
                        )}
                      </div>
                    </JobCardWrapper>
                  </div>
                );
              })}
            </ScrollDiv>
          </div>
        </Col>
      </Row>
      <div className="flex-grow-1" style={{ minHeight: 0 }}>
        <CardWrapper className="p-0 h-100" style={{ borderWidth: 4 }}>
          <ClientJobGroupScheduler />
        </CardWrapper>
      </div>
    </div>
  );
};

export default ClientJobGroupHome;
