import { gql } from '@apollo/client';

export const UPDATE_QUOTE_MUTATION = gql`
  mutation ($jobIssueId: ID!, $status: String!) {
    updateJobQuote(jobIssueId: $jobIssueId, status: $status) {
      block {
        type
        item {
          ... on LogItem {
            id
            type
            opensIssue
            closesIssue
            context
            meta
            data
            dateTime
            user {
              id
              initials
              name
            }
          }
        }
      }
    }
  }
`;
