import React from 'react';
import { useClientSlug, useTable } from 'lib/hooks';
import classNames from 'classnames';
import { useApolloClient, useLazyQuery } from '@apollo/client';
import { getId } from 'lib/utils';
import { faEdit } from '@fortawesome/pro-light-svg-icons/faEdit';
import { useToggle } from 'react-use';
import { UserGroupQueryData, UserGroupQueryVariables } from './types';
import SearchBar from '../SearchBar';
import Table from '../Table';
import {
  useReducerContext,
  userGroupQueryOnCompleted,
  userGroupsQueryOnCompleted,
} from '../ClientPeople/reducer';
import { USER_GROUPS_QUERY, USER_GROUP_QUERY } from './query';
import SimpleButton from '../SimpleButton';
import ClientPeopleGroupSidePanel from '../ClientPeopleGroupSidePanel';

const ClientPeopleGroupsTable = () => {
  const clientSlug = useClientSlug();
  const [{ userGroups, userGroup }, dispatch] = useReducerContext();
  const [sidePanelIsOpen, toggleSidePanel] = useToggle(false);

  const httpClient = useApolloClient();
  const { searchInputProps } = useTable(
    ['name'],
    (variables?: any) =>
      httpClient.query({
        query: USER_GROUPS_QUERY,
        variables: { ...variables, clientSlug },
      }),
    {
      defaultActiveName: 'name',
      sortOnCompleted: () => {},
      searchOnCompleted: (response) => {
        if (!response.data) return;
        dispatch(userGroupsQueryOnCompleted(response.data));
      },
      changePageCompleted: () => {},
    }
  );

  const [getUserGroup] = useLazyQuery<
    UserGroupQueryData,
    UserGroupQueryVariables
  >(USER_GROUP_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      dispatch(userGroupQueryOnCompleted(data));
    },
  });

  const handleTableRowOnClick = (
    event: React.MouseEvent<HTMLTableRowElement>
  ) => {
    const id = getId(event) as string;
    getUserGroup({ variables: { userGroupId: id } });
  };

  const handleOnEdit = () => {
    toggleSidePanel(true);
  };

  return (
    <>
      <div>
        <div className="m-3 d-flex">
          <div className="flex-grow-1">
            <SearchBar
              wrapperClassName="mr-0"
              {...searchInputProps}
              placeholder="Search user groups..."
            />
          </div>
        </div>
        <Table>
          <thead>
            <tr>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {userGroups.map((innerUserGroup) => {
              const active = innerUserGroup.id === userGroup?.id;
              return (
                <tr
                  key={innerUserGroup.id}
                  className={classNames({ active })}
                  data-id={innerUserGroup.id}
                  onClick={handleTableRowOnClick}
                >
                  <td className={classNames({ 'font-weight-bold': active })}>
                    {innerUserGroup.namePlural}
                  </td>
                  <td className="show-on-hover table-cell-with-button d-flex justify-content-end pr-3">
                    <SimpleButton icon={faEdit} onClick={handleOnEdit}>
                      Edit
                    </SimpleButton>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <ClientPeopleGroupSidePanel
        isOpen={sidePanelIsOpen}
        toggle={toggleSidePanel}
      />
    </>
  );
};

export default ClientPeopleGroupsTable;
