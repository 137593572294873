import { JobGroupMultiAssignPlacement } from '../../lib/types';

export const parseMultiAssignPlacements = (
  multiAssignPlacements: JobGroupMultiAssignPlacement[]
) => {
  return multiAssignPlacements.map((placement) => ({
    ...placement,
    isFeasible: placement.statuses.length === 0,
  }));
};
