import React, { Dispatch, useContext } from 'react';
import { AnyAction } from 'redux';
import update from 'immutability-helper';
import { State } from './types';
import { Item } from '../../types';

export const getInitialState = <T extends Item<T>>(
  defaultCollapsedGroups: T['id'][]
): State<T> => ({
  items: [] as T[],
  dragItem: null,
  collapsedGroups: defaultCollapsedGroups,
  meta: {
    itemsOld: null,
    isDirty: false,
  },
});

export const ReducerContext = React.createContext<[State, Dispatch<AnyAction>]>(
  [getInitialState([]), () => {}]
);

export const useReducerContext = () => useContext(ReducerContext);

export const setItems = <T extends Item<T>>(items: T[]) => ({
  type: 'SET_ITEMS',
  items,
});

export const toggleCollapse = <T extends Item<T>>(item: T) => ({
  type: 'TOGGLE_COLLAPSE',
  item,
});

export const onItemsUpdate = <T extends Item<T>>(items: T[]) => ({
  type: 'ON_ITEMS_UPDATE',
  items,
});

export const onDragStart = <T extends Item<T>>(dragItem: T) => ({
  type: 'ON_DRAG_START',
  dragItem,
});

export const onDragApply = () => ({
  type: 'ON_DRAG_APPLY',
});

export const onDragRevert = () => ({
  type: 'ON_DRAG_REVERT',
});

export const onMoveItems = (removePath: any, insertPath: any) => ({
  type: 'ON_MOVE_ITEMS',
  removePath,
  insertPath,
});

const reducer =
  <T extends Item<T>>() =>
  (state: State<T>, action: AnyAction) => {
    switch (action.type) {
      case 'SET_ITEMS':
        return {
          ...state,
          items: action.items,
        };
      case 'TOGGLE_COLLAPSE':
        return {
          ...state,
          collapsedGroups: state.collapsedGroups.includes(action.item.id)
            ? state.collapsedGroups.filter(
                (itemId) => itemId !== action.item.id
              )
            : [...state.collapsedGroups, action.item.id],
        };
      case 'ON_ITEMS_UPDATE':
        return {
          ...state,
          items: action.items,
          dragItem: null,
          meta: {
            ...state.meta,
            isDirty: false,
          },
        };
      case 'ON_DRAG_START':
        return {
          ...state,
          dragItem: action.dragItem,
          meta: {
            ...state.meta,
            itemsOld: state.items,
          },
        };
      case 'ON_DRAG_APPLY':
        return {
          ...state,
          dragItem: null,
          meta: {
            ...state.meta,
            itemsOld: null,
            isDirty: false,
          },
        };
      case 'ON_DRAG_REVERT':
        return {
          ...state,
          items: state.meta.itemsOld,
          dragItem: null,
          meta: {
            ...state.meta,
            itemsOld: null,
            isDirty: false,
          },
        };
      case 'ON_MOVE_ITEMS':
        return {
          ...state,
          items: update(
            update(state.items, action.removePath),
            action.insertPath
          ),
          meta: {
            ...state.meta,
            isDirty: true,
          },
        };
      default:
        return state;
    }
  };

export default reducer;
