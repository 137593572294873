import styled from 'styled-components';
import { JobTagProps } from './types';

export const Wrapper = styled.div`
  overflow: hidden;
`;

export const JobStatus = styled.div``;

export const JobTag = styled.div<JobTagProps>`
  border-radius: 8px;
  transition: width 200ms;
  line-height: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  svg {
    line-height: 14px;
    margin-right: 4px;
  }

  span {
    line-height: 14px;
    opacity: 0.75;
  }
`;
