import { OperationVariables } from '@apollo/client';
import { ApolloQueryResult } from '@apollo/client/core/types';
import useSearchRefetch from './useSearchRefetch';
import useSortTableRefetch from './useSortTableRefetch';
import useTablePaginationRefetch from './useTablePaginationRefetch';

type TableHeadNames = ReadonlyArray<string>;

type OnCompleted<TData> = (response: ApolloQueryResult<TData>) => void;

type Options<T extends Readonly<TableHeadNames>, TData> = {
  defaultActiveName: T[number];
  sortVariables?: any;
  searchVariables?: any;
  paginationVariables?: any;
  sortOnCompleted: OnCompleted<TData>;
  searchOnCompleted: OnCompleted<TData>;
  changePageCompleted: OnCompleted<TData>;
};

const useTable = <
  T extends Readonly<TableHeadNames>,
  TData = any,
  TVariables = OperationVariables
>(
  tableHeadNames: T,
  refetch: (variables?: Partial<any>) => Promise<ApolloQueryResult<TData>>,
  {
    defaultActiveName,
    sortVariables,
    searchVariables,
    paginationVariables,
    sortOnCompleted,
    searchOnCompleted,
    changePageCompleted,
  }: Options<T, TData>
) => {
  const [searchInputProps, { loading: searching }] = useSearchRefetch<
    TData,
    TVariables
  >(refetch, {
    variables: searchVariables,
    onCompleted: searchOnCompleted,
  });

  const [page, handleChangePage, { loading: changingPage, setPage }] =
    useTablePaginationRefetch<TData, TVariables>(refetch, {
      variables: paginationVariables,
      onCompleted: changePageCompleted,
    });

  const [sortState, handleOnSort, { loading: sorting }] = useSortTableRefetch<
    T,
    TData
  >(tableHeadNames, refetch, {
    defaultActiveName,
    variables: sortVariables,
    onCompleted: (data) => {
      setPage(1);
      sortOnCompleted(data);
    },
  });

  return {
    sortState,
    page,
    sorting,
    searching,
    changingPage,
    refetching: sorting || searching || changingPage,
    handleOnSort,
    searchInputProps,
    handleChangePage,
  };
};

export default useTable;
