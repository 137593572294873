import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 1rem;
  z-index: 9999;
  width: 400px;

  div.toast-inner {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100px;
    border-radius: 0.25rem;
    background-color: ${({ theme }) => theme.color.warning.hex()};
    color: ${({ theme }) => theme.color.cardText.hex()};
    transform: translateY(1rem);
    opacity: 0;
    transition: transform 1000ms, opacity 1000ms, top 1000ms;

    &.mounted {
      transform: translateY(0);
    }

    &.visible {
      opacity: 1;
    }
  }
`;
