import React from 'react';
import { useReducerContext } from '../ClientSettingsManagement/reducer';

const ClientSettingsManagementSite = () => {
  const [{ managementTask }] = useReducerContext();

  if (!managementTask) return null;
  return <div>{managementTask.id}</div>;
};

export default ClientSettingsManagementSite;
