import React, { useEffect, useState } from 'react';
import { useClient } from 'lib/hooks';
import { getId } from 'lib/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLazyQuery } from '@apollo/client';
import { Job, JobVisit } from 'lib/types';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons/faSpinnerThird';
import { NestedTableRowProps, NestedTableProps } from './types';
import { Wrapper, JobTable, JobRow, JobVisitRow } from './styled';
import TableHeadButton from '../TableHeadButton';
import Table from '../Table';
import { JOB_VISITS_QUERY, JOBS_QUERY } from './query';
import { Small } from '../Typography';

const TableRow = ({
  jobCount,
  jobGroupRow,
  jobRow,
  jobVisitRow,
  selectedRow,
  selectedInnerRow,
  setSelectedInnerRow,
  onClick,
  ...jobGroup
}: NestedTableRowProps) => {
  const client = useClient();
  const selected = selectedRow === jobGroup.id;
  const [jobs, setJobs] = useState<Job[]>([]);
  const [jobVisits, setJobVisits] = useState<JobVisit[]>([]);

  const [getJobs, { loading: loadingJobs }] = useLazyQuery(JOBS_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      setJobs(data.jobs.results);
    },
  });

  useEffect(() => {
    if (selected) {
      getJobs({
        variables: { clientId: client.id, jobGroupId: jobGroup.id },
      });
    }
  }, [client.id, getJobs, jobGroup.id, selected]);

  const [getVisits, { loading: loadingVisits }] = useLazyQuery(
    JOB_VISITS_QUERY,
    {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        setJobVisits(data.jobVisits);
      },
    }
  );

  const handleInnerRowOnClick = (
    event: React.MouseEvent<HTMLTableRowElement>
  ) => {
    const innerRowId = getId(event) as string;
    setJobVisits([]);
    setSelectedInnerRow((prevSelectedInnerRow) => {
      if (prevSelectedInnerRow === innerRowId) {
        return '-1';
      }
      getVisits({
        variables: {
          clientId: client.id,
          jobId: innerRowId.split(':')[1],
        },
      });
      return innerRowId;
    });
  };

  return (
    <>
      {jobGroupRow({ ...jobGroup, client, jobCount, selected, onClick })}
      {selected && (
        <tr
          style={{
            border: '2px solid #106c12',
            borderTop: 'none',
            borderRadius: '0 0 0.5rem 0.5rem',
            backgroundColor: 'rgba(0, 0, 0, 0.025)',
          }}
        >
          <td colSpan={4} className="p-0">
            <JobTable
              className="w-100 inner-table"
              flexBases={[5, 5, 10, 13, 37, 30]}
            >
              <thead>
                <tr>
                  <th className="decoration-wrapper">
                    <div className="decoration" />
                  </th>
                  <th />
                  <th>
                    <span>Reference</span>
                  </th>
                  <th>Status</th>
                  <th>Name</th>
                  <th>Dates</th>
                </tr>
              </thead>
              <tbody>
                {jobs.length > 0 ? (
                  jobs.map((innerJob, index) => {
                    const last = index === jobs.length - 1;
                    const innerRowSelected =
                      selectedInnerRow === `inner:${innerJob.id}`;
                    return (
                      <>
                        {jobRow({
                          ...innerJob,
                          client,
                          index,
                          last,
                          innerRowSelected,
                          onClick: handleInnerRowOnClick,
                        })}
                        {innerRowSelected && (
                          <tr className="inner-row">
                            <td colSpan={4} className="p-0">
                              <table className="w-100">
                                <tbody>
                                  <JobVisitRow
                                    last={false}
                                    style={{ height: 32 }}
                                    className="border-0"
                                  >
                                    <td className="py-0">
                                      {!last && <div className="decoration" />}
                                    </td>
                                    <td className="py-0 ">
                                      <div className="second-decoration border-bottom-0 rounded-0" />
                                    </td>
                                    <td style={{ lineHeight: 0 }}>
                                      <Small>Visits</Small>
                                    </td>
                                    <td style={{ lineHeight: 0 }}>
                                      {jobVisits.length > 0 && (
                                        <Small>Assignee</Small>
                                      )}
                                    </td>
                                    <td style={{ lineHeight: 0 }}>
                                      {jobVisits.length > 0 && (
                                        <Small>Dates</Small>
                                      )}
                                    </td>
                                    <td />
                                  </JobVisitRow>
                                  {jobVisits.length > 0 ? (
                                    jobVisits.map((jobVisit, innerIndex) => {
                                      const lastInner =
                                        innerIndex === jobVisits.length - 1;
                                      return (
                                        <JobVisitRow
                                          key={`visit-${jobVisit.id}`}
                                          last={lastInner}
                                        >
                                          <td className="py-0">
                                            {!last && (
                                              <div className="decoration" />
                                            )}
                                          </td>
                                          <td className="py-0">
                                            <div className="second-decoration" />
                                          </td>
                                          {jobVisitRow({
                                            ...jobVisit,
                                            index: innerIndex,
                                          })}
                                        </JobVisitRow>
                                      );
                                    })
                                  ) : (
                                    <JobVisitRow last>
                                      <td className="py-0">
                                        {!last && (
                                          <div className="decoration" />
                                        )}
                                      </td>
                                      <td className="py-0">
                                        <div className="second-decoration" />
                                      </td>
                                      <td>
                                        {loadingVisits ? (
                                          <span style={{ lineHeight: '34px' }}>
                                            <FontAwesomeIcon
                                              icon={faSpinnerThird}
                                              spin
                                              fixedWidth
                                            />
                                          </span>
                                        ) : (
                                          <span
                                            className="text-75"
                                            style={{ lineHeight: '34px' }}
                                          >
                                            No visits
                                          </span>
                                        )}
                                      </td>
                                      <td />
                                      <td />
                                      <td />
                                    </JobVisitRow>
                                  )}
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        )}
                      </>
                    );
                  })
                ) : (
                  <JobRow last selected={false}>
                    <td className="py-0">
                      <div className="decoration" />
                    </td>
                    <td>
                      {loadingJobs ? (
                        <span style={{ lineHeight: '34px' }}>
                          <FontAwesomeIcon
                            icon={faSpinnerThird}
                            spin
                            fixedWidth
                          />
                        </span>
                      ) : (
                        <span
                          className="text-75"
                          style={{ lineHeight: '34px' }}
                        >
                          No jobs
                        </span>
                      )}
                    </td>
                    <td />
                    <td />
                    <td />
                    <td />
                  </JobRow>
                )}
              </tbody>
            </JobTable>
          </td>
        </tr>
      )}
    </>
  );
};

const NestedTable = <T extends string[]>({
  rows,
  sortState,
  sort,
  ...rest
}: NestedTableProps<T>) => {
  const [selectedRow, setSelectedRow] = useState('-1');
  const [selectedInnerRow, setSelectedInnerRow] = useState('-1');

  const handleRowOnClick = (event: React.MouseEvent<HTMLTableRowElement>) => {
    const id = getId(event) as string;
    setSelectedRow(id === selectedRow ? '-1' : id);
    setSelectedInnerRow('-1');
  };

  return (
    <Wrapper>
      <Table flexBases={[5, 10, 10, 33, 14, 14, 14]} className="w-100">
        <thead>
          <tr>
            <th />
            <th>
              <TableHeadButton
                active={sortState.activeName === 'reference'}
                // @ts-ignore
                order={sortState.columns.reference.order}
                onClick={() => sort('reference')}
              >
                Reference
              </TableHeadButton>
            </th>
            <th>
              <TableHeadButton
                active={sortState.activeName === 'jobs'}
                // @ts-ignore
                order={sortState.columns.jobs.order}
                onClick={() => sort('jobs')}
              >
                Jobs
              </TableHeadButton>
            </th>
            <th>
              <TableHeadButton
                active={sortState.activeName === 'name'}
                // @ts-ignore
                order={sortState.columns.name.order}
                onClick={() => sort('name')}
              >
                Name
              </TableHeadButton>
            </th>
            <th>Next job</th>
            <th>Current job</th>
            <th>Previous job</th>
          </tr>
        </thead>
        <tbody>
          {rows?.map((result) => (
            <TableRow
              key={`job-group-${result.id}`}
              {...result}
              selectedRow={selectedRow}
              selectedInnerRow={selectedInnerRow}
              setSelectedInnerRow={setSelectedInnerRow}
              onClick={handleRowOnClick}
              {...rest}
            />
          ))}
        </tbody>
      </Table>
    </Wrapper>
  );
};

export default NestedTable;
