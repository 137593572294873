import React from 'react';
import Figure from '../Figure';
import { Wrapper } from './styled';
import { FigureBoxProps } from './types';

const FigureBox = ({ value }: FigureBoxProps) => {
  return (
    <Wrapper>
      <Figure value={value} small={false} brackets={false} />
    </Wrapper>
  );
};

export default FigureBox;
