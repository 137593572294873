import React from 'react';
import { Form, Field } from 'react-final-form';
import { faEnvelope } from '@fortawesome/pro-duotone-svg-icons/faEnvelope';
import { faSave } from '@fortawesome/pro-duotone-svg-icons/faSave';
import { useMutation } from '@apollo/client';
import SlateField from '../SlateField';
import { ClientJobEOTRequestFormProps } from './types';
import ClientJobDateTimeField from '../ClientJobDateTimeField';
import { Small } from '../Typography';
import SimpleButton from '../SimpleButton';
import SimpleConfirmButton from '../SimpleConfirmButton';
import { CREATE_EOT_REQUEST_MUTATION } from './query';

const ClientJobIssueRequestEOTForm = ({
  issue,
  onCreateCompleted,
}: ClientJobEOTRequestFormProps) => {
  const [createEOTRequest] = useMutation(CREATE_EOT_REQUEST_MUTATION);

  const handleOnSubmit = (values: any) => {
    return createEOTRequest({
      variables: { jobIssueId: issue.id, data: values },
    }).then(onCreateCompleted);
  };

  return (
    <div>
      <Form onSubmit={handleOnSubmit}>
        {({ handleSubmit, form }) => (
          <form>
            <div className="mb-4">
              <Field name="dateTime" component={ClientJobDateTimeField} />
            </div>
            <div className="p-2">
              <Small>Message</Small>
              <Field
                name="message"
                component={SlateField}
                placeholder="Write message to be sent with EOT request"
                minHeight={100}
              />
            </div>
            <div className="d-flex justify-content-end p-2">
              <SimpleButton className="text-75 mr-2" icon={faSave}>
                Save draft
              </SimpleButton>
              <SimpleConfirmButton
                icon={faEnvelope}
                position="top"
                onClick={() => handleSubmit()?.then(() => form.reset())}
              >
                Send to client
              </SimpleConfirmButton>
            </div>
          </form>
        )}
      </Form>
    </div>
  );
};

export default ClientJobIssueRequestEOTForm;
