import React, { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DeckGL } from 'deck.gl';
import { StaticMap, _MapContext, Marker, MapRef } from 'react-map-gl';
import { useTheme } from 'styled-components';
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons/faMapMarkerAlt';
import { ClientSitesMapProps } from './types';
import { MarkerWrapper } from './styled';

const MAPBOX_API_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_API_ACCESS_TOKEN;

const ClientSitesMap = ({
  sites,
  viewState,
  markers,
  onViewStateChange,
  height,
  layers,
}: ClientSitesMapProps) => {
  const { mapboxStyle } = useTheme();

  const mapRef = useRef<MapRef>(null);

  return (
    <div
      className="position-relative overflow-hidden"
      style={{ height, borderRadius: '0.25rem' }}
    >
      <DeckGL
        width="100%"
        height="100%"
        viewState={viewState}
        controller
        onViewStateChange={onViewStateChange}
        ContextProvider={_MapContext.Provider}
        layers={layers}
      >
        <StaticMap
          ref={mapRef}
          mapboxApiAccessToken={MAPBOX_API_ACCESS_TOKEN}
          mapStyle={mapboxStyle}
          width="100%"
          height="100%"
          onLoad={() => {
            if (!mapRef.current) return;
            const map = mapRef.current.getMap();
            const { layers: staticLayers } = map.getStyle();
            const { id: labelLayerId } = staticLayers.find(
              (layer: any) =>
                layer.type === 'symbol' && layer.layout['text-field']
            );
            map.addLayer(
              {
                id: 'add-3d-buildings',
                source: 'composite',
                'source-layer': 'building',
                filter: ['==', 'extrude', 'true'],
                type: 'fill-extrusion',
                minzoom: 15,
                paint: {
                  'fill-extrusion-color': '#aaa',

                  // Use an 'interpolate' expression to
                  // add a smooth transition effect to
                  // the buildings as the user zooms in.
                  'fill-extrusion-height': [
                    'interpolate',
                    ['linear'],
                    ['zoom'],
                    15,
                    0,
                    15.05,
                    ['get', 'height'],
                  ],
                  'fill-extrusion-base': [
                    'interpolate',
                    ['linear'],
                    ['zoom'],
                    15,
                    0,
                    15.05,
                    ['get', 'min_height'],
                  ],
                  'fill-extrusion-opacity': 0.6,
                },
              },
              labelLayerId
            );
          }}
        />
        {sites[0].location.point && (
          <Marker {...sites[0].location.point}>
            <MarkerWrapper>
              <FontAwesomeIcon className="marker-icon" icon={faMapMarkerAlt} />
              {markers && (
                <div className="marker-inner shadow">
                  <p className="mb-0">{sites[0].name}</p>
                  <p className="mb-0 text-75">{sites[0].location.address}</p>
                </div>
              )}
            </MarkerWrapper>
          </Marker>
        )}
      </DeckGL>
    </div>
  );
};

export default ClientSitesMap;
