import { useTheme } from 'styled-components';
import { JobIssue } from '../types';
import getIssueColor from '../utils/getIssueColor';

const useIssueColor = (status: JobIssue['status']) => {
  const theme = useTheme();
  return getIssueColor(theme, status);
};

export default useIssueColor;
