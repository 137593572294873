import styled from 'styled-components';
import { HistoryItemProps } from './types';

export const Wrapper = styled.div``;

export const HistoryItem = styled.div<HistoryItemProps>`
  padding: 0.5rem 1rem 0.5rem 0;
  border-top: ${({ theme, first = false }) => (first ? theme.border : null)};
  border-bottom: ${({ theme, last = false }) => (last ? null : theme.border)};
  font-size: 0.8rem;

  .text-50 {
    color: ${({ theme }) => theme.color.cardText.alpha(0.5).hex()};
  }

  .text-75 {
    color: ${({ theme }) => theme.color.cardText.alpha(0.75).hex()};
  }

  .history-time {
    font-family: 'JetBrains Mono', monospace;
  }

  .history-children {
  }

  .log-item-text {
    display: inline-block;

    &::first-letter {
      text-transform: uppercase;
    }

    &.error {
      font-weight: bold;
      color: ${({ theme }) => theme.color.danger.hex()};
    }
  }
`;
