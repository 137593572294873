import React from 'react';
import { Link } from 'wouter';
import { SimpleButtonLinkProps } from './types';
import SimpleButton from '../SimpleButton';

const SimpleButtonLink = ({
  children,
  to,
  href,
  buttonProps,
  onClick,
  ...linkProps
}: SimpleButtonLinkProps) => {
  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (onClick) onClick(event);
  };

  return (
    <Link to={to} {...linkProps}>
      <SimpleButton
        as={onClick ? 'button' : 'a'}
        {...buttonProps}
        onClick={handleOnClick}
      >
        {children}
      </SimpleButton>
    </Link>
  );
};

export default SimpleButtonLink;
