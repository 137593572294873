import React from 'react';
import classNames from 'classnames';
import { faChevronRight } from '@fortawesome/pro-duotone-svg-icons/faChevronRight';
import { Link } from 'wouter';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/pro-duotone-svg-icons/faUserCircle';
import { faExclamationCircle } from '@fortawesome/pro-duotone-svg-icons/faExclamationCircle';
import JobStatusFlag from '../JobStatusFlag';
import { JobRow } from '../NestedTable/styled';
import SimpleButton from '../SimpleButton';
import { TableRowStyled } from '../ClientJobsPPMTable/styled';
import {
  JobGroupRowComponentProps,
  JobRowComponentProps,
  JobVisitRowComponentsProps,
} from '../NestedTable/types';
import NestedTable from '../NestedTable';
import { ClientJobsReactiveTableProps } from './types';

const JobGroupRowComponent = ({
  client,
  id,
  reference,
  name,
  jobCount,
  selected,
  onClick,
}: JobGroupRowComponentProps) => {
  return (
    <TableRowStyled
      className={classNames({ 'border-bottom-0': selected })}
      style={{
        border: selected ? '2px solid #106c12' : 'none',
        borderRadius: selected ? '0.5rem 0.5rem 0 0' : 0,
      }}
      data-id={id}
      onClick={onClick}
    >
      <td className="button-cell">
        <SimpleButton
          icon={faChevronRight}
          iconProps={{ rotation: selected ? 90 : undefined }}
        />
      </td>
      <td className="mono">{reference}</td>
      <td>{jobCount}</td>
      <td
        className={classNames({
          'font-weight-bold': selected,
        })}
      >
        <Link to={`/clients/${client.slug}/job-groups/${reference}`}>
          {name || 'Untitled'}
        </Link>
      </td>
      <td />
      <td />
      <td />
    </TableRowStyled>
  );
};

const JobRowComponent = ({
  client,
  id,
  reference,
  name,
  dateTimeStart,
  dateTimeEnd,
  status,
  innerRowSelected,
  last,
  onClick,
}: JobRowComponentProps) => {
  return (
    <JobRow
      key={`job-${id}`}
      className="d-flex"
      last={last}
      selected={innerRowSelected}
      data-id={`inner:${id}`}
      onClick={onClick}
    >
      <td>
        <div className="decoration" />
      </td>
      <td>
        <SimpleButton
          icon={faChevronRight}
          iconProps={{
            rotation: innerRowSelected ? 90 : undefined,
          }}
        />
      </td>
      <td className="mono text-75">{reference}</td>
      <td>
        <span className="mr-2">
          <JobStatusFlag flag={status.flag} />
        </span>
        <span>{status.name}</span>
      </td>
      <td>
        <span>
          <Link to={`/clients/${client.slug}/jobs/${reference}`}>{name}</Link>
        </span>
      </td>
      <td className="mono">
        {DateTime.fromISO(dateTimeStart).toFormat('dd MMM yyyy')}
        {' - '}
        {DateTime.fromISO(dateTimeEnd).toFormat('dd MMM yyyy')}
      </td>
    </JobRow>
  );
};

const JobVisitRowComponent = ({
  label,
  dateTimeStart,
  dateTimeEnd,
  user,
}: JobVisitRowComponentsProps) => {
  return (
    <>
      <td>
        <span>{label || 'Untitled'}</span>
      </td>
      <td>
        {user ? (
          <div>
            <FontAwesomeIcon className="mr-1" icon={faUserCircle} />
            <span>{user.name}</span>
          </div>
        ) : (
          <span>
            <FontAwesomeIcon
              icon={faExclamationCircle}
              className="mr-1"
              fixedWidth
            />
            Unassigned
          </span>
        )}
      </td>
      <td className="mono">
        {DateTime.fromISO(dateTimeStart).toFormat('HH:mm dd MMM yyyy')}
        {' - '}
        {DateTime.fromISO(dateTimeEnd).toFormat('HH:mm dd MMM yyyy')}
      </td>
      <td />
    </>
  );
};

const ClientJobsReactiveTable = ({
  jobGroups,
  sortState,
  sort,
}: ClientJobsReactiveTableProps) => {
  return (
    <div style={{ height: 'calc(100% - 124px - 56px)' }}>
      <NestedTable
        rows={jobGroups?.results}
        sortState={sortState}
        sort={sort}
        jobGroupRow={JobGroupRowComponent}
        jobRow={JobRowComponent}
        jobVisitRow={JobVisitRowComponent}
      />
    </div>
  );
};

export default ClientJobsReactiveTable;
