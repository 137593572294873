import { gql } from '@apollo/client';

export const JOB_REPORT_TEMPLATES_QUERY = gql`
  query ($clientId: ID!, $jobVisitId: ID!) {
    jobReportTemplates(clientId: $clientId, jobVisitId: $jobVisitId) {
      results {
        id
        name
        isIncluded
      }
      count
    }
  }
`;

export const CREATE_JOB_REPORTS_MUTATION = gql`
  mutation ($jobReportTemplateIds: [ID!]!, $jobVisitId: ID!) {
    createJobReports(
      jobReportTemplateIds: $jobReportTemplateIds
      jobVisitId: $jobVisitId
    ) {
      jobReports {
        id
        status
        statusDisplay
        template {
          name
        }
      }
      logBlock {
        id
        status
        type
        dateTime
        instanceContentType
        data
        meta
        context
        children {
          id
          status
          type
          dateTime
          instanceContentType
          data
          meta
          context
        }
      }
    }
  }
`;
