import React, { useMemo } from 'react';
import { DeckGL, IconLayer, RGBAColor } from 'deck.gl';
import { Marker, StaticMap } from 'react-map-gl';
import { useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons/faMapMarkerAlt';
import { ClientJobsAllTableMapProps } from './types';
import { MarkerWrapper } from '../ClientSitesMap/styled';
import CardWrapper from '../CardWrapper';

const MAPBOX_API_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_API_ACCESS_TOKEN;

const ICON_MAPPING = {
  marker: { x: 0, y: 0, width: 128, height: 128, mask: true },
};

const fakeData = [
  { id: '1', name: 'test', coordinates: [-0.125, 51.5074] },
  { id: '2', name: 'test', coordinates: [-0.1, 51.5074] },
];

const ClientJobsAllTableMap = ({
  site,
  hoveredId,
}: ClientJobsAllTableMapProps) => {
  const getColor = (data: any): RGBAColor => {
    if (hoveredId === '-1') return [16, 108, 18, 255];
    return hoveredId === data.id ? [16, 108, 18, 255] : [16, 108, 18, 50];
  };

  const initialViewState = useMemo(
    () => ({
      ...site.location.point,
      zoom: 13,
      pitch: 45,
      bearing: 30,
    }),
    [site]
  );

  const layer = new IconLayer({
    id: 'icon-layer',
    data: fakeData,
    iconAtlas:
      'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png',
    iconMapping: ICON_MAPPING,
    getIcon: () => 'marker',
    getPosition: (data: any) => data.coordinates,
    getSize: () => 4,
    getColor,
    updateTriggers: { getColor },
    sizeScale: 15,
  });

  const theme = useTheme();

  return (
    <CardWrapper className="position-relative p-1 h-100" invert>
      <div className="position-relative overflow-hidden h-100">
        <DeckGL
          width="100%"
          height="100%"
          initialViewState={initialViewState}
          controller
          layers={[layer]}
        >
          <StaticMap
            mapboxApiAccessToken={MAPBOX_API_ACCESS_TOKEN}
            mapStyle={theme.mapboxStyle}
            width="100%"
            height="100%"
          >
            <Marker {...site.location.point}>
              <MarkerWrapper>
                <FontAwesomeIcon
                  className="marker-icon"
                  icon={faMapMarkerAlt}
                />
                <div className="marker-inner shadow">
                  <p className="mb-0">{site.name}</p>
                  <p className="mb-0 text-75">{site.location.address}</p>
                </div>
              </MarkerWrapper>
            </Marker>
          </StaticMap>
        </DeckGL>
      </div>
    </CardWrapper>
  );
};

export default ClientJobsAllTableMap;
