import styled from 'styled-components';
import { LayoutSpacerProps } from './types';
import ScrollDiv from '../ScrollDiv';

export const LayoutSpacer = styled(ScrollDiv)<LayoutSpacerProps>`
  position: relative;
  margin-left: calc(80px);
  padding-left: 1.5rem;
  background-color: ${({ theme }) => theme.color.contentBackground.hex()};
  height: calc(100vh - 80px);
  overflow-y: auto;
  overflow-x: hidden;
  color: ${({ theme }) => theme.color.cardText.hex()};

  .text-75 {
    color: ${({ theme }) => theme.color.cardText.alpha(0.75).hex()};
  }

  .text-50 {
    color: ${({ theme }) => theme.color.cardText.alpha(0.5).hex()};
  }
`;
