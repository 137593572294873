import React from 'react';
import classNames from 'classnames';
import { Wrapper } from './styled';
import { UserInitialsProps } from './types';

const UserInitials = ({ user, className, ...rest }: UserInitialsProps) => {
  return (
    <Wrapper className={classNames('center', className)} {...rest}>
      {user.initials}
    </Wrapper>
  );
};

export default UserInitials;
