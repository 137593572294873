import { Dispatch, SetStateAction, useState } from 'react';

type Delta = 1 | -1;

type Params = {
  onChange: (newPage: number) => void;
};

type Return = [
  number,
  (delta: Delta) => void,
  Dispatch<SetStateAction<number>>
];

const useTablePagination = ({ onChange }: Params): Return => {
  const [page, setPage] = useState(1);
  const handleOnChange = (delta: Delta) => {
    setPage((prevPage) => {
      const newPage = prevPage + delta;
      onChange(newPage);
      return newPage;
    });
  };
  return [page, handleOnChange, setPage];
};

export default useTablePagination;
