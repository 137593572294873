import { gql } from '@apollo/client';

export const LOG_BLOCKS_QUERY = gql`
  query ($clientId: ID!, $page: Int) {
    logBlocks(clientId: $clientId, page: $page) {
      results {
        id
        status
        type
        dateTime
        contextContentType
        instanceContentType
        data
        meta
        context
        user {
          id
          name
        }
        integration
      }
      count
      lastUpdate {
        id
        dateTime
      }
    }
  }
`;
