import React from 'react';
import { Link } from 'wouter';
import JobStatusFlag from '../JobStatusFlag';
import { JobPillProps } from './types';
import { Wrapper } from './styled';

const JobPill = ({ job, as, ...rest }: JobPillProps) => {
  const href = `/clients/${job.client.slug}/jobs/${job.reference}`;
  return (
    <Link href={href}>
      <Wrapper as={as} {...rest}>
        <div className="d-flex">
          <div className="job-report-pill-name mono">{job.reference}</div>
          <div className="job-report-pill-number text-75">
            <span>
              <JobStatusFlag className="mr-1" flag={job.status.flag} />
              {job.status.nameDisplay}
            </span>
          </div>
        </div>
      </Wrapper>
    </Link>
  );
};

export default JobPill;
