import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100vh;
`;

export const LoginWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.contentBackground.hex()};

  div.login-row {
    margin-top: 150px;
  }

  div.login-col {
    border-radius: 0.25rem;
    background-color: ${({ theme }) => theme.color.cardBackground.hex()};
  }

  div.login-sidebar {
    border-right: ${({ theme }) => theme.border};
    padding-bottom: 100px;
  }

  div.login-body {
    color: ${({ theme }) => theme.color.cardText.hex()};
  }

  img.logo {
    width: 48px;
    margin: 1rem;
  }

  button.login-button {
    border: ${({ theme }) => theme.border};
  }

  div.login-footer {
    color: ${({ theme }) => theme.color.cardText.alpha(0.5).hex()};
  }

  .text-75 {
    color: ${({ theme }) => theme.color.cardText.alpha(0.75).hex()};
  }
`;

export const DisplayWrapper = styled.div``;
