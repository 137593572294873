import React, { useEffect, useState } from 'react';
import { BryntumSchedulerPro } from '@bryntum/schedulerpro-react';
import {
  SchedulerProConfig,
  ProjectModel,
  // eslint-disable-next-line import/extensions
} from '@bryntum/schedulerpro/schedulerpro.umd.js';
import PortalBreadcrumb from '../PortalBreadcrumb';

const config: Partial<SchedulerProConfig> = {
  autoHeight: true,
  rowHeight: 64,
  viewPreset: {
    id: 'customHourAndDay',
    base: 'hourAndDay',
    headers: [
      {
        unit: 'day',
        dateFormat: 'ddd DD MMM',
      },
      {
        unit: 'hour',
        dateFormat: 'HH:mm',
      },
    ],
  },
  columns: [
    {
      text: 'Name',
      field: 'name',
      type: 'resourceInfo',
      width: 240,
    },
  ],
  eventStyle: 'colored',
  resources: [
    {
      id: '1',
      name: 'Test',
    },
  ],
};

const HomeJobs = () => {
  const [schedulerProject] = useState(
    new ProjectModel({ stm: { autoRecord: false } })
  );

  useEffect(() => {
    schedulerProject.eventStore.add([
      {
        id: '13245',
        resourceId: '1',
        name: 'Test',
        startDate: new Date(2021, 8, 22, 18, 0, 0).toISOString(),
        endDate: new Date(2021, 8, 22, 19, 0, 0).toISOString(),
      },
    ]);
  }, [schedulerProject.eventStore]);

  return (
    <>
      <PortalBreadcrumb>
        <h4>Jobs</h4>
      </PortalBreadcrumb>
      <div>
        <BryntumSchedulerPro {...config} project={schedulerProject} />
      </div>
    </>
  );
};

export default HomeJobs;
