import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle';
import { getLogText } from 'lib/logs';
import { useQuery } from '@apollo/client';
import { useClient, useTablePagination } from 'lib/hooks';
import { parseDateTime } from 'lib/utils';
import { Job } from 'lib/types';
import JobPill from '../JobPill';
import { Wrapper } from './styled';
import Loader from './loader';
import { HistoryItem } from '../ClientJobHistory/styled';
import TableFooter from '../TableFooter';
import { LOG_BLOCKS_QUERY } from './query';
import {
  logBlocksQueryOnCompleted,
  useReducerContext,
} from '../ClientDashboard/reducer';
import { LogBlockQueryData, LogBlockQueryVariables } from './types';
import JobLink from '../JobLink';
import ScrollDiv from '../ScrollDiv';

const ClientDashboardActivityLog = () => {
  const client = useClient();
  const [{ logBlocks }, dispatch] = useReducerContext();

  const { refetch } = useQuery<LogBlockQueryData, LogBlockQueryVariables>(
    LOG_BLOCKS_QUERY,
    {
      fetchPolicy: 'no-cache',
      variables: { clientId: client.id, page: 1 },
      onCompleted: (data) => {
        dispatch(logBlocksQueryOnCompleted(data));
      },
    }
  );

  const [page, handleChangePage] = useTablePagination({
    onChange: async (newPage) => {
      const { data } = await refetch({ clientId: client.id, page: newPage });
      if (data) dispatch(logBlocksQueryOnCompleted(data));
    },
  });

  const renderResults = () => {
    if (!logBlocks) return null;
    if (logBlocks.count === 0) {
      return <div className="text-50 p-3">No activity</div>;
    }
    return logBlocks.results.map((logBlock, index) => {
      const first = index === 0;
      const last = index === logBlocks.results.length - 1;
      return (
        <HistoryItem
          key={logBlock.id}
          className="d-flex pl-3"
          first={first}
          last={last}
        >
          <div className="center-vertically">
            <span className="mr-3 text-50 history-time text-nowrap">
              {parseDateTime(logBlock.dateTime)}:
            </span>
          </div>
          <div className="flex-grow-1">
            <div>
              <div className="d-flex justify-content-between">
                <div>
                  {logBlock.contextContentType === 'jobs | job' && (
                    <JobLink job={logBlock.context} client={client}>
                      <JobPill
                        style={{
                          backgroundColor: 'transparent',
                          fontSize: 10,
                          padding: 0,
                        }}
                        job={
                          {
                            client: { slug: client.slug },
                            ...logBlock.context,
                          } as Job
                        }
                      />
                    </JobLink>
                  )}
                  <p className="mb-0">{getLogText(logBlock)}</p>
                </div>
                <div className="center-vertically">
                  <FontAwesomeIcon icon={faCheckCircle} fixedWidth />
                </div>
              </div>
            </div>
          </div>
        </HistoryItem>
      );
    });
  };

  return (
    <Wrapper className="h-100 p-0 d-flex flex-column">
      <div className="d-flex justify-content-between m-3">
        <h5 className="mb-0">Activity Log</h5>
        <small style={{ lineHeight: '24px' }}>
          <span className="text-50">Last updated: </span>
          <span className="text-75 text-capitalize">
            {logBlocks?.lastUpdate &&
              DateTime.fromISO(
                logBlocks.lastUpdate.dateTime
              ).toRelativeCalendar()}
          </span>
        </small>
      </div>
      <ScrollDiv
        className="flex-grow-1"
        style={{ minHeight: 0, overflowY: 'auto' }}
      >
        {logBlocks ? renderResults() : <Loader />}
      </ScrollDiv>
      <TableFooter
        small
        page={page}
        length={logBlocks?.results.length}
        count={logBlocks?.count}
        handleChangePage={handleChangePage}
      />
    </Wrapper>
  );
};

export default ClientDashboardActivityLog;
