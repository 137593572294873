import styled from 'styled-components';
import { InnerProps } from './types';
import Button from '../Button';

export const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 80px;
  height: 500px;
  width: 500px;
  z-index: 14;
  overflow: hidden;
`;

export const Inner = styled.div<InnerProps>`
  position: absolute;
  left: 0;
  top: 0;
  width: 300%;
  height: 462px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: ${({ gridTemplateAreas }) => `'${gridTemplateAreas}'`};
  transition: transform 250ms;
  margin-top: 38px;
`;

export const Cell = styled.div`
  padding: 1rem;
  height: 446px;
`;

export const Note = styled.button`
  display: block;
  width: 100%;
  border: none;
  outline: none;
  color: ${({ theme }) => theme.color.cardText.hex()};
  background-color: ${({ theme }) => theme.color.contentBackground.hex()};
  padding: 1rem;
  text-align: left;
  border-radius: 0.25rem;
`;

export const WindowHeader = styled.div`
  background-color: ${({ theme }) => theme.color.contentBackground.hex()};
  overflow: hidden;
`;

export const WindowButton = styled(Button)`
  border: none;
  border-radius: 0;

  &:hover {
    border: none;
    background-color: #ff0000aa;
  }
`;
