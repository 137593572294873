import React from 'react';
import { Document, Page } from 'react-pdf';
import { SizeMe } from 'react-sizeme';
import { useReportColor } from 'lib/hooks';
import { PDF } from './styled';
import { JobReportPDFPreviewProps } from './types';

const JobReportPDFPreview = ({
  jobReport,
  width,
  includeNumber = true,
  ...rest
}: JobReportPDFPreviewProps) => {
  const color = useReportColor(jobReport.status);

  return (
    <PDF
      className="mr-2"
      {...rest}
      width={width}
      style={{ borderColor: color }}
    >
      {includeNumber && (
        <span
          className="job-report-card-number px-1"
          style={{ backgroundColor: color }}
        >
          <small className="mono">#{jobReport.number}</small>
        </span>
      )}
      {jobReport?.latestVersion ? (
        <Document className="h-100 p-0" file={jobReport.latestVersion.pdfUrl}>
          <SizeMe>
            {({ size }) => <Page width={size.width || 0} pageNumber={1} />}
          </SizeMe>
        </Document>
      ) : (
        <div className="center h-100">
          <span className="text-75">
            <small>No PDF</small>
          </span>
        </div>
      )}
    </PDF>
  );
};

export default JobReportPDFPreview;
