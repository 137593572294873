import styled from 'styled-components';

export const Wrapper = styled.a`
  display: inline-block;
  font-size: 12px;

  div.job-issue-pill-number {
    padding: 0 4px;
    border-radius: 0.25rem 0 0 0.25rem;
    color: ${({ theme }) => theme.color.cardText.hex()};
  }

  div.job-issue-pill-type {
    background-color: ${({ theme }) => theme.color.contentBackground.hex()};
    padding: 0 4px;
    border-radius: 0 0.25rem 0.25rem 0;
    color: ${({ theme }) => theme.color.cardText.alpha(0.75).hex()};
    transition: color 150ms;
  }

  &:hover {
    color: ${({ theme }) => theme.color.cardText.hex()};
    text-decoration: none;

    div.job-issue-pill-type {
      transition: color 50ms;
      color: ${({ theme }) => theme.color.cardText.hex()};
    }
  }
`;
