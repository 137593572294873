import { ResourceModel } from '@bryntum/schedulerpro/schedulerpro.umd.js';
import React, { useMemo } from 'react';
import { Option, User } from 'lib/types';

import { ValueType } from 'react-select';
import Select from '../Select';
import { Small } from '../Typography';
import { JobVisitAssigneeFieldProps } from './types';

type ResourceUser = User &
  ResourceModel & {
    category: string;
  };

const JobVisitAssigneeField = ({
  input,
  schedulerRef,
  ...rest
}: JobVisitAssigneeFieldProps) => {
  const users: ResourceUser[] =
    schedulerRef.current.instance.resourceStore.records.filter(
      (record: ResourceModel) => record.name
    );

  const handleOnChange = (option: ValueType<Option, false>) => {
    if (!option?.value) return;
    input.onChange(users.find((innerUser) => innerUser.id === option.value));
  };

  const options = useMemo(() => {
    const categories = [...new Set(users.map((user) => user.category))];
    return categories.map((category) => ({
      label: category,
      options: users
        .filter((user) => user.category === category)
        .map((user) => ({
          value: user.id,
          label: user.name,
        })),
    }));
  }, [users]);

  return (
    <div>
      <Small className="ml-2">Assignee</Small>
      <Select
        menuPortalTarget={document.body}
        {...input}
        value={{
          value: input.value.id,
          label: input.value.name,
        }}
        options={options}
        onChange={handleOnChange}
        {...rest}
      />
    </div>
  );
};

export default JobVisitAssigneeField;
