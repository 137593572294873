import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useBreadcrumb } from '../Layout';

const PortalBreadcrumb = ({ children }: React.PropsWithChildren<unknown>) => {
  const breadcrumbRef = useBreadcrumb();
  const [canMount, setCanMount] = useState(false);
  useEffect(() => {
    if (!breadcrumbRef?.current) return;
    setCanMount(true);
  }, [breadcrumbRef]);
  if (!canMount) return null;
  return ReactDOM.createPortal(
    children,
    breadcrumbRef?.current as HTMLDivElement
  );
};

export default PortalBreadcrumb;
