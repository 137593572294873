import { gql } from '@apollo/client';

export const JOB_HISTORY_QUERY = gql`
  query ($jobId: ID!, $before: ID!) {
    jobHistory(jobId: $jobId, before: $before) {
      results {
        id
        status
        type
        dateTime
        instanceContentType
        data
        meta
        context
        children {
          id
          status
          type
          dateTime
          instanceContentType
          data
          meta
        }
      }
      count
    }
  }
`;
