import { gql } from '@apollo/client';

export const SEARCH_SITES_QUERY = gql`
  query($clientId: ID, $searchTerm: String) {
    sites(clientId: $clientId, searchTerm: $searchTerm) {
      results {
        id
        name
        location {
          meta
          point {
            longitude
            latitude
          }
        }
      }
      centroid {
        longitude
        latitude
      }
    }
  }
`;
