import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { faEllipsisH } from '@fortawesome/pro-solid-svg-icons/faEllipsisH';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { Link } from 'wouter';
import { faBriefcase } from '@fortawesome/pro-duotone-svg-icons/faBriefcase';
import { faExclamationCircle } from '@fortawesome/pro-duotone-svg-icons/faExclamationCircle';
import { faCircle } from '@fortawesome/pro-solid-svg-icons/faCircle';
import { DateTime } from 'luxon';
import { useLazyQuery, useMutation } from '@apollo/client';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { State } from 'lib/redux/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getLogText } from 'lib/logs';
import ConditionalWrapper from '../ConditionalWrapper';
import LayoutOverlay from '../LayoutOverlay';
import ScrollDiv from '../ScrollDiv';
import SimpleButton from '../SimpleButton';
import SimpleButtonWithWindow from '../SimpleButtonWithWindow';
import { Wrapper, NotificationWrapper } from './styled';
import {
  INITIAL_QUERY,
  MARK_ALL_NOTIFICATIONS_AS_READ_MUTATION,
  MARK_NOTIFICATION_AS_READ_MUTATION,
} from './query';

const getIcon = (icon: string) => {
  switch (icon) {
    case 'faBriefcase':
      return faBriefcase;
    default:
      return faExclamationCircle;
  }
};

const UserNotifications = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { isOpen, notifications } = useSelector(
    (state: State) => state.notifications
  );

  const [hasMore, setHasMore] = useState(notifications.length === 10);
  const [getNotifications, { called }] = useLazyQuery(INITIAL_QUERY, {
    onCompleted: (data) => {
      dispatch({
        type: 'NOTIFICATIONS__INITIAL_QUERY_ON_COMPLETED',
        payload: data.notifications.results,
      });
      setHasMore(data.notifications.results.length === 10);
    },
  });

  useEffect(() => {
    if (isOpen && !called) getNotifications();
  }, [called, getNotifications, isOpen]);

  const handleNext = () => {
    getNotifications({
      variables: { before: notifications[notifications.length - 1].id },
    });
  };

  const handleClose = () => {
    dispatch({ type: 'NOTIFICATIONS__CLOSE' });
  };

  const [markAllNotificationsAsRead] = useMutation(
    MARK_ALL_NOTIFICATIONS_AS_READ_MUTATION,
    {
      onCompleted: (data) => {
        if (data.markAllNotificationsAsRead.result) {
          dispatch({
            type: 'NOTIFICATIONS__MARK_ALL_NOTIFICATIONS_AS_READ',
            payload: data.markAllNotificationsAsRead,
          });
        }
      },
    }
  );

  const [markNotificationAsRead] = useMutation(
    MARK_NOTIFICATION_AS_READ_MUTATION,
    {
      onCompleted: (data) => {
        if (data.markNotificationAsRead.result) {
          dispatch({
            type: 'NOTIFICATIONS__MARK_NOTIFICATION_AS_READ',
            payload: data.markNotificationAsRead,
          });
        }
      },
    }
  );

  return (
    <>
      {isOpen && (
        <Wrapper className="shadow d-flex flex-column">
          <div className="d-flex justify-content-between mb-1">
            <h4 className="mb-0" style={{ lineHeight: '34px' }}>
              Notifications
            </h4>
            <SimpleButtonWithWindow
              icon={faEllipsisH}
              iconProps={{ style: { width: 16 } }}
              style={{ borderRadius: '50%', width: 34 }}
              openMode="hover"
              windowChildren={() => (
                <>
                  <div
                    className="p-3"
                    style={{
                      border: theme.border,
                      borderRadius: '0.25rem',
                      backgroundColor: theme.color.cardBackground.hex(),
                    }}
                  >
                    <SimpleButton
                      icon={faCheck}
                      onClick={() => markAllNotificationsAsRead()}
                    >
                      Mark all as read
                    </SimpleButton>
                  </div>
                </>
              )}
            />
          </div>
          <ScrollDiv
            id="user-notifications-scroll-div"
            className="h-100 flex-grow-1"
            style={{ overflowY: 'auto', minHeight: 0 }}
          >
            <InfiniteScroll
              scrollableTarget="user-notifications-scroll-div"
              hasMore={hasMore}
              next={handleNext}
              dataLength={notifications.length}
              loader={null}
            >
              {notifications.map((notification) => (
                <ConditionalWrapper
                  key={notification.id}
                  wrap={!!notification.link}
                  wrapper={({ children }) => (
                    <Link
                      href={notification.link as string}
                      onClick={() => {
                        markNotificationAsRead({
                          variables: { notificationId: notification.id },
                        });
                        handleClose();
                      }}
                    >
                      {children}
                    </Link>
                  )}
                >
                  <NotificationWrapper
                    as={notification.link ? 'a' : 'div'}
                    className="d-flex"
                  >
                    <div className="notification-icon mr-2">
                      <FontAwesomeIcon
                        icon={getIcon(notification.icon)}
                        fixedWidth
                        size="2x"
                      />
                    </div>
                    <div>
                      <p
                        className={classNames('mb-0', {
                          'font-weight-bold': !notification.read,
                        })}
                      >
                        {getLogText(notification.logBlock)}
                      </p>
                      <small className="text-75 d-block mt-n1">
                        {DateTime.fromISO(notification.dateTime).toRelative()}
                      </small>
                    </div>
                    {!notification.read && (
                      <div className="notification-read">
                        <FontAwesomeIcon icon={faCircle} />
                      </div>
                    )}
                  </NotificationWrapper>
                </ConditionalWrapper>
              ))}
            </InfiniteScroll>
          </ScrollDiv>
        </Wrapper>
      )}
      <LayoutOverlay isOpen={isOpen} onClick={handleClose} />
    </>
  );
};

export default UserNotifications;
