import React from 'react';
import { Form, Field } from 'react-final-form';
import { faPlus } from '@fortawesome/pro-light-svg-icons/faPlus';
import { useMutation } from '@apollo/client';
import { useClient } from 'lib/hooks';
import { faCheck } from '@fortawesome/pro-light-svg-icons/faCheck';
import { ClientSiteLocation } from 'lib/types';
import SidePanel from '../SidePanel';
import {
  createSiteMutationOnCompleted,
  sitesSidePanelOnClosed,
  updateSiteMutationOnCompleted,
  useReducerContext,
} from '../ClientSites/reducer';
import {
  ClientsSidePanelProps,
  CreateSiteMutationData,
  CreateSiteMutationVariables,
  UpdateSiteMutationData,
  UpdateSiteMutationVariables,
} from './types';
import CharField from '../CharField';
import Button from '../Button';
import { CREATE_SITE_MUTATION, UPDATE_SITE_MUTATION } from './query';

const ClientsSidePanel = ({ toggle }: ClientsSidePanelProps) => {
  const [{ site, sitesSidePanel }, dispatch] = useReducerContext();

  const [createSite] = useMutation<
    CreateSiteMutationData,
    CreateSiteMutationVariables
  >(CREATE_SITE_MUTATION);

  const [updateSite] = useMutation<
    UpdateSiteMutationData,
    UpdateSiteMutationVariables
  >(UPDATE_SITE_MUTATION);

  const client = useClient();
  const handleOnSubmit = (values: {
    name: string;
    location: ClientSiteLocation;
  }) => {
    if (site) {
      updateSite({
        variables: {
          id: site.id,
          data: values,
        },
      }).then((response) => {
        if (!response.data) return;
        dispatch(updateSiteMutationOnCompleted(response.data));
      });
    } else {
      createSite({
        variables: {
          clientId: client?.id,
          data: values,
        },
      }).then((response) => {
        if (!response.data) return;
        dispatch(createSiteMutationOnCompleted(response.data));
      });
    }
  };

  const handleOnClosed = () => {
    dispatch(sitesSidePanelOnClosed());
  };

  return (
    <SidePanel
      title={`${site ? 'Edit' : 'Create'} site`}
      isOpen={sitesSidePanel.isOpen}
      toggle={toggle}
      onClosed={handleOnClosed}
    >
      <Form
        initialValues={site || {}}
        validate={(values) => {
          const errors: { name?: string; location?: string } = {};
          if (!values.name) errors.name = 'Please enter a name';
          if (!values.location?.point) errors.location = '';
          if (!values.location) errors.location = 'Please enter a location';
          return errors;
        }}
        onSubmit={handleOnSubmit}
      >
        {({ handleSubmit, valid }) => (
          <form
            className="h-100 d-flex flex-column justify-content-between"
            onSubmit={handleSubmit}
          >
            <div>
              <div className="mb-4">
                <Field name="name" component={CharField} />
              </div>
              <div className="mb-4">
                <Field
                  name="location.address"
                  label="Address"
                  component={CharField}
                />
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <Button icon={site ? faCheck : faPlus} disabled={!valid}>
                {site ? 'Save changes' : 'Create'}
              </Button>
            </div>
          </form>
        )}
      </Form>
    </SidePanel>
  );
};

export default ClientsSidePanel;
