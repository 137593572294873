import { DefaultTheme } from 'styled-components';
import { JobReport } from '../types';

const getReportColor = (theme: DefaultTheme, status: JobReport['status']) => {
  switch (status) {
    case 'SCHEDULED':
      return theme.color.incoming.alpha(0.5).hex();
    case 'STARTED':
      return theme.color.amber.alpha(0.5).hex();
    case 'COMPLETED':
      return theme.color.primary.alpha(0.5).hex();
    default:
      return '';
  }
};

export default getReportColor;
